import React, { useState, useContext, useEffect, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Button, Tooltip, Popover, ButtonGroup, ListGroup, OverlayTrigger, Form, Badge, Card } from 'react-bootstrap';
import DateTime from 'react-datetime';
import { format, setDay, differenceInDays, addDays, getMinutes, setMinutes, getHours, setHours, differenceInMinutes, addHours, toDate, differenceInCalendarDays, getDay, getDate } from 'date-fns'
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { it } from 'date-fns/locale'

import { workshiftType_dict } from "../../components/constants/global_definitions"

import styles from '../constants/styles.js'
import GlobalDataContext from '../contexts/globaldataContext.js';
import DomainContext from '../../components/contexts/domainContext'


import { AddListButton } from '../StructureGraphicElements.js'
import { getFloatNumber, getTimeStr, propComparator, getQualificationParam, checkComponentPresence, myTimestampToDate, setMyDate, getSelectedElement } from "../../util/ui_utils"

import firebase from 'firebase/app'

import { getEmployerList } from '../../Firebase2/Storage2/dbEmployers';
import { getWorkContractList as getWorkContractList2, } from "../../Firebase2/Storage2/dbWorkContract"
import { getWorkshiftList as getWorkshiftList2, deleteWorkshift as deleteWorkshift2 } from '../../Firebase2/Storage2/dbWorkshift';


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function ModalWorkshiftAssignment({ workarea, t, element, is_workarea, functions, canWrite }) {

	let { cid } = useParams();
	let contractId = cid

	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict, service_dict, } = useContext(GlobalDataContext)
	const { domain, domain_data } = useContext(DomainContext)
	const [showWorkshiftAssignment, setShowEditWorkshiftAssignment] = useState(false)
	const [ui_list, setUiList] = useState({})
	const [curWorkarea, setCurWorkarea] = useState(element)
	const [editedElement, setEditedElement] = useState(false)
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [workday_list, setWorkdayList] = useState([])
	const [employer_choose_mode, setEmployerChooseMode] = useState('ordered')
	const [workshift_same, setEmployerSameShift] = useState('1')
	const [curWorkshift, setCurWorkshift] = useState()

	const workshift_type = workshiftType_dict({ t })

	const handleCloseComponent = () => setShowComponentEdit(false);

	//-----hour analisys form
	const handleShowWorkshiftAssignment = () => {
		setCurWorkarea({
			...curWorkarea,
			start: firebase.firestore.Timestamp.now(),
			end: firebase.firestore.Timestamp.now(),
		})
		setShowEditWorkshiftAssignment(true)
	}

	const handleShowNewComponent = () => {
		setShowComponentEdit(true)
	}

	const handleCloseModal = () => setShowEditWorkshiftAssignment(false);


	useEffect(() => {

		const initValues = async () => {

			let workshift_list = await getWorkshiftList2(domain, contract.id)
			let _contract_list = await getWorkContractList2(domain, contract.id)
			let _employer_list = await getEmployerList(domain, contract.id, {})
			_employer_list = _employer_list.sort(propComparator('surname'))
			const _contract_dict = Object.assign({}, ..._contract_list.map((x) => ({ [x.id]: x })));
			const _employer_dict = Object.assign({}, ..._employer_list.map((x) => ({ [x.id]: x })));
			setUiList({ ...ui_list, workshift_list, risk_area_list, risk_area_dict, service_dict, roomtype_list, roomtype_dict, employer_list: _employer_list, employer_dict: _employer_dict, contract_list: _contract_list })
		}

		initValues()

	}, [])





	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined
		try {
			sub = el.getAttribute('sub')

		} catch (err) {

		}
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = [...curWorkarea[name]]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curWorkarea[sub], [name]: value }
			name = sub;
		}
		if (name === 'workshift') {
			setCurWorkshift(getSelectedElement(value, ui_list.workshift_list, 'id'))
			setWorkdayList([])
		}
		console.log(name, value)
		setCurWorkarea({ ...curWorkarea, [name]: value })
	}


	const handleDeleteElement = (el) => {
		console.log(el)
		let editlist = [...curWorkarea.assignment_employer_list]
		const c = checkComponentPresence(el, editlist, 'id')
		editlist.splice(c, 1)
		setCurWorkarea({ ...curWorkarea, assignment_employer_list: editlist })
		setEditedElement(true)
		//		onChangeHandlerIntervention({ name: 'assignment_employer_list', value: editlist })
	}

	const handleAddElement = (el) => {
		console.log(el)
		let editlist = []
		if (curWorkarea.assignment_employer_list) {
			editlist = [...curWorkarea.assignment_employer_list]
		}
		const c = checkComponentPresence(el, editlist, 'id')
		if (c >= 0) {
			//			editlist[c].quantity += 1;
		} else {
			editlist.push(el)
		}
		setCurWorkarea({ ...curWorkarea, assignment_employer_list: editlist })
		setEditedElement(true)
	}


	const onChangeEmployerMode = (el, index) => {

	}



	const RenderOneAssignedEmployer = ({ i, k }) => {




		return (
			<Row key={k} className="border-bottom pb-1 mb-1">

				<Col sm="2">{i.register}</Col>
				<Col sm="2"><b>{i.firstname}</b></Col>
				<Col><b>{i.lastname}</b></Col>
				<Col>{getQualificationParam(i, ui_list.workcontract_list, 'name')}</Col>
				<Col sm="1">
					<input type="checkbox" className="form-control" />
				</Col>
				<Col className="p-0 text-center" sm="5" md="4" xl="2" >
					{canWrite ?
						<>
							<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteElement(i)} /> </Button>
						</>
						:
						<></>
					}
				</Col>

			</Row>

		)
	}




	const RenderOneEmployer = ({ i, k, increment }) => {

		return (
			<Row key={k} className={styles.source_row}>
				<Col className="p-0 text-center" sm="1">
					<AddListButton i={i} add_action={handleAddElement} element_list={curWorkarea.assignment_employer_list} increment={increment} />
				</Col>
				<Col sm="2">{i.register}</Col>
				<Col sm="2"><b>{i.firstname}</b></Col>
				<Col><b>{i.lastname}</b></Col>
				<Col>{getQualificationParam(i, ui_list.workcontract_list)}</Col>
			</Row>

		)
	}

	var getDaysArray = function (start, end) {
		for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
			arr.push(new Date(dt));
		}
		return arr;
	};


	const getCurEmployer = (op_index, total_index) => {
		if (true) {
			const sel_op = curWorkarea.assignment_employer_list[op_index]
			console.log(op_index, total_index, sel_op)
			op_index += 1
			if (op_index >= total_index) {
				op_index = 0
			}
			return { op: sel_op, index: op_index }
		}
	}


	const preShift = () => {

		let days_list = getDaysArray(myTimestampToDate(curWorkarea.start), myTimestampToDate(curWorkarea.end))
		console.log("GIORNI DI LAVORO", days_list.length)
		const sel_workshift = getSelectedElement(curWorkarea.workshift, ui_list.workshift_list, 'id')
		let _workday_list = []
		let op_index = 0
		let op
		let total_selected = curWorkarea.assignment_employer_list.length
		if (sel_workshift.category === 'monthdays') {
			for (const di in days_list) {
				const d = days_list[di]
				const md = getDate(d)
				if (sel_workshift.month_frequency[md]) {
					const _op = getCurEmployer(op_index, total_selected)
					op_index = _op.index
					const op = _op.op
					_workday_list.push({ ...sel_workshift.month_frequency[md], day: d, employer_id_list: curWorkarea.assignment_employer_list.map(e => e.id) })
				}
			}
		} else if (sel_workshift.category === 'weekdays') {
			for (const di in days_list) {
				const d = days_list[di]
				const wd = getDay(d)
				if (sel_workshift.week_frequency[wd]) {
					const _op = getCurEmployer(op_index, total_selected)
					op_index = _op.index
					const op = _op.op
					_workday_list.push({ ...sel_workshift.week_frequency[wd], day: d, employer_id_list: curWorkarea.assignment_employer_list.map(e => e.id) })
				}
			}
		} else {
			//let employer_shift_days = curWorkarea.assignment_employer_list.map(x => )
			let seq_index = 0
			if (workshift_same === 1 || workshift_same === '1') {
				console.log("---same sequence", sel_workshift.workrest_sequence)
				for (const d_i in days_list) {
					const d = days_list[d_i]
					const sel_w = sel_workshift.workrest_sequence[seq_index % sel_workshift.workrest_sequence.length]
					console.log(d, sel_w, seq_index % sel_workshift.workrest_sequence.length)

					if (sel_w.role !== -1 && sel_w.role !== "-1") {
						const sel_t = getSelectedElement(sel_w.role, sel_workshift.timeslot_list, 'id')
						console.log(sel_t, sel_t.week_timeslot[getDay(d)])
						if (sel_t.week_timeslot[getDay(d)]) {
							_workday_list.push({ ...sel_t.week_timeslot[getDay(d)], day: d, employer_id_list: curWorkarea.assignment_employer_list.map(e => e.id) })
						}
					}
					seq_index += 1
				}

			} else {
				for (const d_i in days_list) {
					const d = days_list[d_i]
					const filter_employer_list = curWorkarea.assignment_employer_list.filter(e => !e.is_jolly)
					let employer_list = []
					for (const e_i in curWorkarea.assignment_employer_list) {
						const e = curWorkarea.assignment_employer_list[e_i]
						const sel_w = sel_workshift.workrest_sequence[(seq_index + parseInt(e_i)) % sel_workshift.workrest_sequence.length]
						if (sel_w.role !== -1 && sel_w.role !== "-1") {
							const sel_t = getSelectedElement(sel_w.role, sel_workshift.timeslot_list, 'id')
							if (sel_t.week_timeslot[getDay(d)]) {
								employer_list.push({ ...sel_t.week_timeslot[getDay(d)], employer: e.id })
							}
						}
					}
					if (employer_list.length > 0) {
						console.log("HAS EMPLOYER")
						seq_index += 1
					}
					_workday_list.push({ day: d, employer_id_list: employer_list })
				}
			}
		}

		console.log(_workday_list)
		setWorkdayList(_workday_list)


	}


	const onChangeSelectedEmployer = (el, index) => {
		let { name, value, type } = el
		console.log(el, index)
		let work_day = [...workday_list]
		work_day[index].employer = ui_list.employer_dict[el.value]
		setWorkdayList(work_day)
	}


	return (
		<>
			<Button variant="outline-primary" size="sm" onClick={() => handleShowWorkshiftAssignment()} ><FontAwesomeIcon icon="user-clock" />{t('workarea.labels.whorkshift_development')}</Button>

			<Modal show={showWorkshiftAssignment} onHide={handleCloseModal} size="xl" className="bg-secondary">
				<Modal.Header closeButton>
					<Modal.Title> {workarea ? <>{workarea.name} | </> : ''}{element.name}<span className="text-primary">&nbsp;<FontAwesomeIcon icon="clock" /> &nbsp;{t('workarea.labels.whorkshift_development')} </span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('workarea.labels.workshift')}</Form.Label>
							<Col sm="8">
								{ui_list.workshift_list ?
									<Form.Control as="select" value={element.workshift} name="workshift" className="mb-2" onChange={(event) => onChangeHandler(event.target)}  >
										<option value="-1">{t('workarea.labels.select_workshift')}</option>
										{ui_list.workshift_list.map((f, k) => (
											<option key={k} value={f.id} >{f.code} - {f.name}</option>
										))}
									</Form.Control>
									: ''}
							</Col>
							{element.workshift ?
								<Col>
									{workshift_type[curWorkshift.category].label}
								</Col>
								: ''}
						</Form.Group>
						<Row>
							<Col sm="3">
								<DateTime initialValue={myTimestampToDate(curWorkarea.start)} dateFormat={"DD/MM/yyyy"} timeFormat={false} name="start" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'start', value: setMyDate(e) })} />
							</Col>
							<Col sm="3">
								<DateTime initialValue={myTimestampToDate(curWorkarea.end)} dateFormat={"DD/MM/yyyy"} timeFormat={false} name="end" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'end', value: setMyDate(e) })} />
							</Col>
							<Col>
								{differenceInCalendarDays(myTimestampToDate(curWorkarea.end), myTimestampToDate(curWorkarea.start))} {t('global.labels.days')}
							</Col>
						</Row>
						<Row>
							<Col className="h5" >
							</Col>
							{canWrite ?
								<Col className="text-right" sm="4">
									<ButtonGroup>

										{showComponentEdit ?
											<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComponent()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
											:
											<Button variant="outline-primary" size="sm" onClick={() => handleShowNewComponent()}><FontAwesomeIcon icon="plus-square" /> {t('workarea.labels.employer_add')}</Button>
										}
									</ButtonGroup>
								</Col>
								: ''}
						</Row>

						<Row className="border-bottom mb-1 pb-1">

							{curWorkarea.assignment_employer_list && curWorkarea.assignment_employer_list.filter.length > 0 ?
								<Col>
									<Row className="mb-1" >
										<Col className="bg-secondary text-light ml-1">{t('employes.labels.register')} </Col>
										<Col className="bg-secondary text-light ml-1">{t('global.labels.name')} </Col>
										<Col className="bg-secondary text-light ml-1" sm="1">{t('workshift.labels.jolly')} </Col>
										<Col className="bg-secondary text-light ml-1" sm="5" md="4" xl="2">
										</Col>
									</Row>
									{curWorkarea.assignment_employer_list.map((i, key) => (
										<RenderOneAssignedEmployer key={key} i={i} />
									))
									}
								</Col>
								: <Col> {t('interventions.messages.no_assigned_employer')} </Col>
							}
							{showComponentEdit
								?
								<>
									<Col className="border-left" className="editSection">
										{ui_list.employer_list && ui_list.employer_list.length > 0 ?
											<>
												<Row className="mb-1" >
													<Col className="bg-secondary text-light ml-1 p-0 text-center" sm="1">{t('global.labels.add')}</Col>
													<Col className="bg-secondary text-light ml-1">{t('structure.labels.component_name')}</Col>
												</Row>
												{ui_list.employer_list.map((i, key) => (
													<RenderOneEmployer key={key} i={i} />
												))
												}
											</>
											: <> {t('interventions.messages.no_employer')}</>
										}
									</Col>
								</>
								: ''
							}
						</Row>
						{/* 						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('workarea.labels.workshift_emplyer_mode')}</Form.Label>
							<Col sm="8">
								<Form.Check type="radio" name="employer_choose_mode" value={'ordered'} checked={employer_choose_mode === 'ordered'} label={'segui ordine lista'} onChange={(event) => setEmployerChooseMode(event.currentTarget.value)} />
								<Form.Check type="radio" name="employer_choose_mode" value={'casual'} checked={employer_choose_mode === 'casual'} label={'casuale'} onChange={(event) => setEmployerChooseMode(event.currentTarget.value)} />
							</Col>
						</Form.Group> */}
						{curWorkshift && curWorkshift.category === 'workrestdays' ?
							<Form.Group as={Row} className="border-bottom">
								<Form.Label column sm="2">{t('workarea.labels.workshift_same')}</Form.Label>
								<Col sm="8">
									<Form.Check type="radio" name="workshift_same" value={1} checked={workshift_same === '1'} label={t('global.labels.yes')} onChange={(event) => setEmployerSameShift(event.currentTarget.value)} />
									<Form.Check type="radio" name="workshift_same" value={0} checked={workshift_same === '0'} label={t('global.labels.no')} onChange={(event) => setEmployerSameShift(event.currentTarget.value)} />
								</Col>
							</Form.Group>
							: ''}
						<Row>
							<Col>
								<Card>
									<Card.Title>
										<Button mode="info" onClick={preShift} disabled={differenceInCalendarDays(myTimestampToDate(curWorkarea.end), myTimestampToDate(curWorkarea.start)) <= 0 || !curWorkarea.assignment_employer_list || curWorkarea.assignment_employer_list.length === 0 || !curWorkarea.workshift}>Pre-sviluppo</Button>
									</Card.Title>
									<Card.Body className="container-fluid">
										{workday_list.length > 0 ?
											<Row>
												<Col className="bg-secondary text-light mr-1" sm="4">
													Data
												</Col>
												<Col className="bg-secondary text-light mr-1">
													Dipendenti
												</Col>
											</Row>
											: ''}
										{workday_list.map((w, k) => (
											<Row key={k} className="border-bottom mb-1 pb-1">
												<Col sm="4">
													{format(w.day, 'EEE  dd/MM/yyyy', { locale: it })}

													{w.start ?
														<> -
															{format(myTimestampToDate(w.start), 'HH:mm')} - {format(myTimestampToDate(w.end), 'HH:mm')}
														</>
														: ''
													}
												</Col>
												<Col>
													{w.employer_id_list.map((e, e_k) => (

														e.employer ?
															<EmployerBadge e={ui_list.employer_dict[e.employer]} t={t} key={k + e_k} hour_data={e} />
															:
															<EmployerBadge e={ui_list.employer_dict[e]} t={t} key={k + e_k} />

													))}
													{/* 									<Form.Control as="select" value={w.employer ? w.employer.id : -1} name="category" className="mb-2" onChange={(event) => onChangeSelectedEmployer(event.target, k)}  >

										{ui_list.employer_list.map((i, k) => (
											<option key={k} value={i.id}>{i.register} - {i.lastname} {i.firstname}</option>
										))}
									</Form.Control> */}
												</Col>
											</Row>
										))}

									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						{t('modal.close')}
					</Button>
					<Button variant="primary" disabled onClick={handleCloseModal}>
						{t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const EmployerBadge = ({ e, t, hour_data }) => {

	return (

		<OverlayTrigger trigger={['focus', 'hover']} placement="auto-start" overlay={renderPopover(t, e)}>
			<Badge variant="info" className="mr-1">{e.register}
				{hour_data ?
					<>
						&nbsp;{format(myTimestampToDate(hour_data.start), 'HH:mm')} - {format(myTimestampToDate(hour_data.end), 'HH:mm')}
					</>
					: ''
				}
			</Badge>
		</OverlayTrigger>
	)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderPopover = (t, employer) => {


	return (
		<Popover id="popover-basic" className="bg-info border border-info">
			<Popover.Title as="h3">{employer.register}
			</Popover.Title>
			<Popover.Content className="bg-info">
				{employer.lastname} {employer.firstname}
			</Popover.Content>
		</Popover>

	)
}




export default translate()(ModalWorkshiftAssignment)