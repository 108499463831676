import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, ButtonGroup, Row, Col, Form, Button, Card, Spinner } from 'react-bootstrap';

import DomainContext from '../contexts/domainContext';
import GlobalDataContext from '../contexts/globaldataContext';

import {  getSelectedElement, checkComponentPresence, rgba2hex, compareName, getFloatNumber, getValue, getStructureFromWorkareas } from '../../util/ui_utils';
import { ItemProgress, ServiceBadge, RiskareaBadge, ContainerInfoPopover, RoomInfoBadge, ModalInitButton } from '../../components/StructureGraphicElements.js'
import AlertSection from '../AlertSection';
import { ModalReferent } from './ModalWorkareaReferent';

import { addWorkarea, editWorkarea } from '../../Firebase2/Storage2/dbWorkarea';
import { getUserList } from '../../Firebase2/Storage2/dbUsers.js';

export const newworkarea = {
	name: '',
	id_badge: '',
	company: '',
	referent_list: [],
	p_risk_area_summary: {},
	room_list: [],
	size: 0,
	intervention_list: [],
	cart_element_list: [],
	structure_list: [],
	pavillon_list: [],
	floor_list: [],
	department_list: [],
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalWorkarea = ({ is_new, element, element_list, functions, t, ui_list, canWrite }) => {
	const { domain } = useContext(DomainContext)
	const { contractId } = useContext(GlobalDataContext)

	const childRefAlert = useRef()

	const [showEditWorkarea, setShowEditWorkarea] = useState(false);
	const [curWorkarea, setCurWorkarea] = useState(newworkarea)
	const [editedElement, setEditedElement] = useState(false)
	const [user_list, setUserList] = useState([])


	const handleCloseWorkarea = () => {
		setShowEditWorkarea(false);
		setEditedElement(false)
	}


	const handleSaveWorkarea = async () => {
		const editlist = [...element_list]
		if (is_new) {
			let return_data = await addWorkarea(domain, contractId, curWorkarea)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				editlist.push(return_data)
				functions.edit(editlist)
				setShowEditWorkarea(false)
				setEditedElement(false)
			}
		} else {
			let editworkarea = { ...curWorkarea, ...sumChildrenData(curWorkarea, ui_list) }
			let return_data = await editWorkarea(domain, contractId, curWorkarea.id, editworkarea)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const c = checkComponentPresence(curWorkarea, editlist, 'id')
				editlist.splice(c, 1, editworkarea)
				functions.edit(editlist)
				setShowEditWorkarea(false)
				setEditedElement(false)
			}


		}

	}

	const handleShowEditWorkarea = () => {
		setCurWorkarea(element)
		setShowEditWorkarea(true)
		setEditedElement(false)
	}
	const handleShowNewWorkarea = () => {
		setCurWorkarea(newworkarea)
		setShowEditWorkarea(true)
		setEditedElement(false)
	}
	const handleShowEditWorkareaRoom = () => {
		if (editedElement) {
			childRefAlert.current.addAlert('warning', t('global.messages.need_to_save'))

		} else {
			setShowEditWorkarea(false)
			functions.editroom(curWorkarea)
		}
	}

	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined
		try {
			sub = el.getAttribute('sub')

		} catch (err) {

		}
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = [...curWorkarea[name]]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curWorkarea[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurWorkarea({ ...curWorkarea, [name]: value })
		setEditedElement(true)
	}


	useEffect(() => {
		const u_list = async () => {
			const u_list = await getUserList(domain)
			setUserList(u_list.filter(u => u.reportsign && u.reportsign.indexOf('structureintervention') >= 0))

		}
		if (canWrite) {
			u_list()


		}
	}, [])

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} title_new={t('workareas.buttons.add_new_workarea')} action_new={handleShowNewWorkarea} action_edit={handleShowEditWorkarea} action_info={handleShowEditWorkarea} />
			{showEditWorkarea ?
				<Modal show={showEditWorkarea} onHide={handleCloseWorkarea} size="xl" backdrop="static" className="bg-secondary">
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						<Modal.Title>
							{is_new ?
								<>
									<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('workareas.labels.new_workarea')}</span>
								</>
								:
								[canWrite ?
									<>
										<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info"> {t('workareas.labels.edit_workarea')}</span>
									</>
									:
									<>
										<FontAwesomeIcon icon="info" /> <span className="text-info"> {t('workareas.labels.info_workarea')}</span>
									</>
								]
							}
							: {curWorkarea.name}&nbsp;
							{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
							<ElementFormDetails element={curWorkarea} t={t} functions={{ edit: onChangeHandler, room: handleShowEditWorkareaRoom }} canWrite={canWrite} is_new={is_new} user_list={user_list} />
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseWorkarea}>
							{t('modal.close')}
						</Button>
						{canWrite ?
							<Button className={editedElement ? "btn-warning" : "btn-myprimary"} onClick={handleSaveWorkarea}>
								<FontAwesomeIcon icon="save" /> {t('modal.save')}
							</Button>
							: ''}
					</Modal.Footer>
				</Modal>
				: ''}
		</>
	)
}





/////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDetails({ element: curWorkarea, functions, t, canWrite, is_new, user_list }) {

	const handleDeleteOneReferent = (el) => {
		console.log(el)
		let editlist = curWorkarea.referent_list
		const c = checkComponentPresence(el, curWorkarea.referent_list)
		editlist.splice(c, 1)
		functions.edit({ name: 'referent_list', value: editlist })

	}


	return (
		<>

			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workareas.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control value={curWorkarea.name} type="text" name="name" placeholder="" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="2">
					<Row>
						<Form.Label column >{t('workareas.labels.referent_lista')} </Form.Label>
					</Row>
				</Col>
				<Col>
					{canWrite ?
						<Row className="mb-2">
							<Col className="text-right">
								<ModalReferent t={t} is_new={true} functions={functions} curWorkarea={curWorkarea} canWrite={canWrite} user_list={user_list} />
							</Col>
						</Row>
						: ''}
					{curWorkarea.referent_list && curWorkarea.referent_list.length > 0
						? <>
							<Row className="bg-secondary text-light">
								<Col>{t('global.labels.lastname')} </Col>
								<Col>{t('workareas.labels.name')} </Col>
								<Col>{t('workareas.labels.structure')} </Col>
								<Col>{t('workareas.labels.area')} </Col>
								<Col>{t('global.labels.claudit_user')} </Col>
								<Col sm="2">{t('global.labels.actions')}</Col>
							</Row>
							{curWorkarea.referent_list.map((i, key) => (
								<Row key={key} className="border-bottom pb-1 mb-1">
									<Col>{i.lastname}</Col>
									<Col>{i.firstname}</Col>
									<Col>{i.structure_name}</Col>
									<Col>{i.area}</Col>
									<Col>{getSelectedElement(i.user_id, user_list, 'id').displayName ? i.user_displayName : <><FontAwesomeIcon icon="exclamation-triangle" />---utente non più presente---</>}</Col>
									<Col sm="2">
										<ButtonGroup>
											{canWrite ?
												<Button size="sm" variant="danger" onClick={() => handleDeleteOneReferent(i)}><FontAwesomeIcon icon="trash" /> </Button>
												: ''}
										</ButtonGroup>
									</Col>
								</Row>))
							}
						</>
						: <Row>
							<Col className="font-italic">
								{t('global.message.no_referent')}
							</Col>
						</Row>
					}
				</Col>
			</Form.Group>
			{canWrite ?
				<Row>
					<Col>
						<Button variant="info" disabled={is_new} onClick={() => functions.room(curWorkarea)}>
							<FontAwesomeIcon icon="building" />
							{t('workareas.labels.save_and_assing')}
						</Button>

					</Col>
				</Row>
				: ''}

		</>
	)

}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const sumChildrenData = (workarea, ui_list) => {
	let size = 0
	let risk_area_summary = {}
	let room_count = 0;
	let service_summary = [];
	let wstructure_list = []
	let wpavillon_list = []
	let wfloor_list = []
	let wdepartment_list = []
	if (workarea.room_list) {
		for (const _room of workarea.room_list) {
			//			console.log('child list', workarea.level, workarea.room_list[c])
			//			const _room = workarea.room_list[c]
			const room = ui_list.all_room_dict[_room.id]
			if (room) {
				size += getFloatNumber(room.size)
				room_count += 1;
				if (room.risk_area) {
					if (risk_area_summary[room.risk_area.Id]) {
						risk_area_summary[room.risk_area.Id].size += getFloatNumber(room.size)
						risk_area_summary[room.risk_area.Id].room_count += 1
					} else {
						risk_area_summary[room.risk_area.Id] = { size: getFloatNumber(room.size), room_count: 1, color: rgba2hex(room.risk_area.color) }
					}
				}


				if (wstructure_list.indexOf(room.parent_list[0]) === -1) {
					wstructure_list.push(room.parent_list[0])
				}
				if (wpavillon_list.indexOf(room.parent_list[1]) === -1) {
					wpavillon_list.push(room.parent_list[1])
				}
				if (wfloor_list.indexOf(room.parent_list[2]) === -1) {
					wfloor_list.push(room.parent_list[2])
				}
				if (wdepartment_list.indexOf(room.parent_list[3]) === -1) {
					wdepartment_list.push(room.parent_list[3])
				}

				if (room.roomtype) {
					const roomtype = ui_list.roomtype_dict[room.roomtype.id]
					if (roomtype && roomtype.service_list) {

						for (let s = 0; s < roomtype.service_list.length; s++) {
							try {
								const one_service = roomtype.service_list[s].service
								console.log("###", one_service)
								if (one_service && checkComponentPresence(one_service, service_summary, 'id') < 0) {
									let myservice = ui_list.service_dict[one_service.id]
									if (myservice) {
										service_summary.push(myservice)
									}
								}

							} catch (err) {
								console.log(err)
								const one_service = roomtype.service_list[s]
								console.log("###--", one_service)
								if (one_service && checkComponentPresence(one_service, service_summary, 'id') < 0) {
									let myservice = ui_list.service_dict[one_service.id]
									if (myservice) {
										service_summary.push(myservice)
									}
								}
							}
						}
					}
				}
			}
		}
	}
	console.log("update group size:", workarea, size, risk_area_summary, room_count)
	return { risk_area_summary: risk_area_summary, room_count: room_count, size: size, service_summary: service_summary, structure_list: wstructure_list, pavillon_list: wpavillon_list, floor_list: wfloor_list, department_list: wdepartment_list }
	//setCurworkareaList({ ...curItem, risk_area_summary: risk_area_summary, size: size, room_count: room_count })
}



