import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Button, Spinner, ButtonGroup, } from 'react-bootstrap';
import Chart from "react-apexcharts";
import { rgba2hex } from "../../util/ui_utils.js"


import LocaleContext from "../contexts/locale.js"
import GlobalDataContext from '../contexts/globaldataContext.js';
import DashboardContext from '../contexts/dashboardContext.js';



//----------------------------------------
//----------------------------------------
export const RiskareaSummary = ({ t }) => {
	const { loadingGraph, loading_step, risk_area_new_summary } = useContext(DashboardContext)
	const { risk_area_list } = useContext(GlobalDataContext)
	const [graphViewMode, setGraphViewMode] = useState('count')


	return (

		loadingGraph ?
			<>
				<Spinner animation="border" role="status" />&nbsp;Caricamento in corso ({loading_step})
			</>
			:
			Object.values(risk_area_new_summary.total).length > 0 ?
				<>
					<Row>
						<Col className="text-center">
							<ButtonGroup>
								<Button onClick={() => setGraphViewMode('count')} variant={graphViewMode === 'count' ? 'secondary' : 'outline-secondary'} size="sm">N° locali</Button>
								<Button onClick={() => setGraphViewMode('size')} variant={graphViewMode === 'size' ? 'secondary' : 'outline-secondary'} size="sm">Dimensione</Button>
							</ButtonGroup>
						</Col>
					</Row>

					{graphViewMode === 'count' ?
						<Row>
							<Col className="text-center font-weight-bold border-right">
								Eseguiti
								<ServiceChart mode='done' data={risk_area_new_summary} risk_area_list={risk_area_list} mylabel={''} value="count" lb='locali' />
							</Col>
							<Col className="text-center font-weight-bold border-right">
								In esecuzione
								<ServiceChart mode='doing' data={risk_area_new_summary} risk_area_list={risk_area_list} mylabel={''} value="count" lb=' locali' />
							</Col>
							<Col className="text-center font-weight-bold">
								Da eseguire
								<ServiceChart mode='undone' data={risk_area_new_summary} risk_area_list={risk_area_list} mylabel={''} value="count" lb=' locali' />
							</Col>
						</Row>
						:
						<Row>
							<Col className="text-center font-weight-bold border-right">
								Eseguiti
								<ServiceChart mode='done' data={risk_area_new_summary} risk_area_list={risk_area_list} mylabel={''} value='size' lb=' m2' />
							</Col>
							<Col className="text-center font-weight-bold border-right">
								In esecuzione
								<ServiceChart mode='doing' data={risk_area_new_summary} risk_area_list={risk_area_list} mylabel={''} value='size' lb=' m2' />
							</Col>
							<Col className="text-center font-weight-bold">
								Da eseguire
								<ServiceChart mode='undone' data={risk_area_new_summary} risk_area_list={risk_area_list} mylabel={''} value='size' lb=' m2' />
							</Col>
						</Row>
					}
				</>
				:
				<i>{t('event.messages.no_event')}</i>

	)

}

///////////////////////////////////////////////////////////////////
const ServiceChart = ({ data, mode, risk_area_list, mylabel, value, lb }) => {
	const { locale } = useContext(LocaleContext)
	const [mydata, setData] = useState([[], [], []])
	const [options, setOptions] = useState({})

	useEffect(() => {
		let dataset = []
		let datavalue = []
		let dataname = []
		let datacolor = []
		const ra_filter = risk_area_list.filter(ra => Object.keys(data[mode]).indexOf(ra.Id) !== -1).reverse()
		for (const ra of ra_filter) {
			const d = data[mode][ra.Id]
			const d_total = data.total[ra.Id]
			if (d) {
				dataset.push(d[value] / d_total[value] * 100)
				datavalue.push(d[value])
				dataname.push(ra.name[locale])
				datacolor.push(rgba2hex(ra.color))
			}
		}
		let fontSize = '9px'
		let cHeight = 290
		let margin= 3
		if (ra_filter.length>10){
			cHeight= 350
			margin= 1
		}
		setData([dataset, dataname, datacolor])

		const demodata = {
			chart: {
				height: cHeight,
				type: 'radialBar',
			},
			plotOptions: {
				radialBar: {
					offsetY: 0,
					startAngle: 0,
					endAngle: 270,
					hollow: {
						margin: 5,
						size: '50%',
						background: 'transparent',
						image: undefined,
					},
					track: {
						strokeWidth: '99%',
						margin: margin

					},
					dataLabels: {
						name: {
							fontSize: '12px'
						},
						value: {
							formatter: function (myvalue, opts) {
								return ''
								//								return datavalue +'%'
							},
							fontSize: '14px',
						},
						total: {
							show: true,
							color: '#000',
							label: lb,
							formatter: function (w) {
								if (value === 'size') {
									return datavalue.reduce((a, b) => a + b, 0).toFixed(2)
								} else {
									return datavalue.reduce((a, b) => a + b, 0)

								}
							}
						},

					}
				}
			},
			colors: datacolor,
			labels: dataname,
			legend: {
				show: true,
				floating: true,
				fontSize: fontSize,
				position: 'left',
				offsetX: -20,
				offsetY: -20,
				horizontalAlign: 'left',
				labels: {
					useSeriesColors: true,
				},
				markers: {
					width:8,
					height:4,
					radius: 4	
				},
				formatter: function (myvalue, opts) {
					if (value === 'size') {
						return myvalue + ": " + datavalue[opts.seriesIndex].toFixed(2) + lb
					} else {
						return myvalue + ": " + datavalue[opts.seriesIndex] + lb
					}
				},
				itemMargin: {
					vertical: 1
				}
			},
			responsive: [{
				breakpoint: 380,
				options: {
					legend: {
						show: false
					}
				}
			}]
		}
		setOptions(demodata)
	}, [data, value])



	return (

		<Chart
			options={options}
			series={mydata[0]}
			labels={mydata[1]}
			colors={mydata[2]}
			type="radialBar"
		/>
	)

}







