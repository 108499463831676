import React, { useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Card, Image } from 'react-bootstrap';
import { translate, useTranslate } from 'react-polyglot'
import { format } from 'date-fns'
import { getElementPreview, myTimestampToDate } from "../util/ui_utils.js"
import QRCode from 'qrcode.react'
import { useParams } from "react-router-dom"
import { useReactToPrint } from 'react-to-print';


import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';



//const domain_data = JSON.parse(sessionStorage.getItem('domain_data')) || {}

function ModalEmployeePreview({ item }) {
    const t = useTranslate();
    const { domain, domain_data } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)
    let { cid } = useParams();
    let contractId = cid

    const componentRef = useRef();
    const [showEmployeePreview, setShowEmployeeEdit] = useState(false)
    const [editedElement, setEditedElement] = useState(false)

    const handleClosePreviewEmployee = () => setShowEmployeeEdit(false);

    const handleShowPreviewEmployee = () => {
        setShowEmployeeEdit(true)

    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: pageStyle
    });
    return (
        <>
            <Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowPreviewEmployee()}><FontAwesomeIcon icon="id-card-alt" /> </Button>
            <Modal show={showEmployeePreview} onHide={handleClosePreviewEmployee} backdrop="static" className="bg-secondary">
                <Modal.Header closeButton >
                    <Modal.Title>
                        <FontAwesomeIcon icon="id-card-alt" /> <span className="text-info">{t('employes.labels.view_employee')}</span>
                        :&nbsp;{item.firstname}&nbsp;{item.lastname}
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Card className="personalcard" ref={componentRef}>
                            <Card.Body className="pt-0 pb-0">
                                <Row className="" >
                                    <Col xs="4" className="text-center">
                                        <Row className="mb-1 mt-1">
                                            <Col>
                                                <Image

                                                    src={item.photo && item.photo.length > 0 ? item.photo : '/avatar_male.png'}
                                                    style={{ maxHeight: '80px', maxWidth: '80px' }}
                                                    alt=""
                                                />
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col className="" style={{ fontSize: '0.8em', lineHeight: '0.7em' }}>
                                        <Row className='bg-secondary text-light mb-2 text-center' style={{ fontSize: '0.8em' }}>
                                            <Col className="p-1" >
                                                Tessera di riconoscimento ai sensi delll'Art.18 co1 lett. u)<br />D.lgs 81/08 e s.m.i. e Art. 5 Legge 136/1
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="5" className="p-1 font-weight-bold">Nome</Col>
                                            <Col className="align-self-center">{item.firstname}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs="5" className="p-1 font-weight-bold">Cognome</Col>
                                            <Col className="align-self-center">{item.lastname}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs="5" className="p-1 font-weight-bold">Data di nascita</Col>
                                            <Col className="align-self-center">{format(myTimestampToDate(item.birthdate), 'dd/MM/yy')}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs="5" className="p-1 font-weight-bold">Nato/a a</Col>
                                            <Col className="align-self-center">{item.birth_city} {item.birth_state}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs="5" className="p-1 font-weight-bold">Matricola</Col>
                                            <Col className="align-self-center">{item.register}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs="5" className="p-1 font-weight-bold">Data di assunzione</Col>
                                            <Col className="align-self-center">{format(myTimestampToDate(item.assumption_date), 'dd/MM/yy')}</Col>
                                        </Row>
                                        <Row className="mt-4 border-top pb-2">
                                            <Col className="p-1">
                                                {domain_data.name}
                                            </Col>
                                            <Col className="text-center">
                                                <Image
                                                    src={(domain_data && domain_data.logo) ? domain_data.logo : "./Logo.Claudit.registrato.png"}
                                                    style={{ maxHeight: '50px', maxWidth: '100px' }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="5" className="p-1 font-weight-bold">Datore di lavoro</Col>
                                            {contract?
                                            <Col className="align-self-center">
                                                {contract.principal}

                                            </Col>
                                            :<></>}
                                        </Row>
                                    </Col>

                                </Row>
                            </Card.Body>
                            <Row className="mt-4" style={{position:'absolute', bottom:'3mm', left:'4mm'}}>
                                <Col>
                                    <QRCode size="80" value={JSON.stringify({ domain: domain, contract_id: contractId, employee_id: item.id })} />
                                </Col>
                            </Row>

                        </Card>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePreviewEmployee}>
                        {t('modal.close')}
                    </Button>
                    <Button variant="info" onClick={handlePrint}>
                        <FontAwesomeIcon icon="print" />  {t('modal.print')}
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}






const pageStyle = `
  @page {
    size: 90mm 60mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;


export default translate()(ModalEmployeePreview)