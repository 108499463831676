import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, Button, ButtonGroup, InputGroup, FormControl, Tooltip, OverlayTrigger, Spinner, Card } from 'react-bootstrap';


import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import styles from './constants/styles.js'

import { getSelectedElement, checkComponentPresence, getBaseObjectValue, getFloatNumber, compareName, getSelectedElementB } from "../util/ui_utils.js"


import AlertSection from "../components/AlertSection.js"
import { AddListButton } from './StructureGraphicElements';
import { getAllCatalogs, getProductsInCatalog } from './FirebaseApi/CatalogProxy';
import { addProduct as addProduct2, editProduct as editProduct2 } from '../Firebase2/Storage2/dbWarehouse';
import { addBaseObjectType as addBaseObjectType2 } from "../Firebase2/Storage2/dbBaseObject"



//const contractId = sessionStorage.getItem('contractId')
const companyId = '0'


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function ModalImportCatalog({ t, ui_list, section, item_list, setItem }) {

	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contract } = useContext(GlobalDataContext)

	let { cid } = useParams();
	let contractId = cid

	const childRefAlert = useRef()

	const [showImport, setShowImport] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [catalog_list, setCatalogList] = useState([])
	const [isSaving, setIsSaving] = useState(false)
	const [import_element_list, setImportElementList] = useState([])
	const [product_list, setProductList] = useState([])
	const [catalogId, setCatalogId] = useState(-1)
	const [loading, setLoading]= useState(false)

	//	let dimensions = { corridor: 0, toilet: 0, other: 0 }
	//	const yeld_mq = contract.yeld_mq || 18



	const handleCloseImport = () => setShowImport(false);

	const handleShowImport = async () => {
		setLoading(true)
		let _catalog_list = await getAllCatalogs()
		console.log(_catalog_list)
		if (_catalog_list && _catalog_list.error) {

		} else {
			try {
				_catalog_list = _catalog_list.data.sort(compareName)
				setCatalogList(_catalog_list)
				setShowImport(true)
				setEditedElement(false)
		setLoading(false)

			} catch (err) {

			}

		}
	}





	const handleDoImport = async () => {
		setIsSaving(true)
		let new_brands = {}
		let new_product_category = {}
		let new_dilution_system = {}
		let new_machine_category = {}
		let new_equipment_material = {}
		let new_equipment_category = {}
		let new_cleaningmaterial_composition = {}
		let new_cleaningmaterial_category = {}
		let newlist = [...item_list]
		let no_error = true
		
		for (const e of import_element_list) {
			console.log(e)
			let curElement = { ...e }
			curElement.catalog_reference_id = e.id
			curElement.catalog_id = catalogId
			delete e.id
			const return_data = await addProduct2(domain, contractId, companyId, section, curElement)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				console.log("ERROR", return_data.error)
				no_error = no_error&& false
			} else {
				newlist.push(return_data)
				console.log(newlist)
			}
			try {
				if (checkComponentPresence(e.brand, ui_list.brand_list, 'Id') < 0) {
					new_brands[e.brand.Id] = e.brand
				}
			} catch (err) {
				console.log(err)

			}
			if (section === 'product') {
				try {
					if (checkComponentPresence(e.product_category, ui_list.category_list, 'Id') < 0) {
						new_product_category[e.product_category.Id] = e.product_category
					}
				} catch (err) {
				console.log(err)

				}
				try {
					if (checkComponentPresence(e.dilution_system, ui_list.dilution_system_list, 'Id') < 0) {
						new_dilution_system[e.dilution_system.Id] = e.dilution_system
					}
				} catch (err) {
				console.log(err)

				}

			} else if (section === 'equipment') {
				try {
					if (checkComponentPresence(e.equipment_category, ui_list.category_list, 'Id') < 0) {
						new_equipment_category[e.equipment_category.Id] = e.equipment_category
					}
				} catch (err) {
				console.log(err)

				}
				try {
					if (checkComponentPresence(e.equipment_material, ui_list.material_list, 'Id') < 0) {
						new_equipment_material[e.equipment_material.Id] = e.equipment_material
					}
				} catch (err) {
				console.log(err)

				}

			} else if (section === 'cleaningmaterial') {
				try {
					if (checkComponentPresence(e.cleaningmaterial_category, ui_list.category_list, 'Id') < 0) {
						new_cleaningmaterial_category[e.cleaningmaterial_category.Id] = e.cleaningmaterial_category
					}
				} catch (err) {
				console.log(err)

				}
				try {
					if (checkComponentPresence(e.cleaningmaterial_composition, ui_list.composition_list, 'Id') < 0) {
						new_cleaningmaterial_composition[e.cleaningmaterial_composition.Id] = e.cleaningmaterial_composition
					}
				} catch (err) {
				console.log(err)

				}

			} else if (section === 'machine') {
				try {
					if (checkComponentPresence(e.machine_category, ui_list.category_list, 'Id') < 0) {
						new_machine_category[e.machine_category.Id] = e.machine_category
					}
				} catch (err) {
				console.log(err)

				}

			} else if (section === 'cart') {

			}
		}
		for (const d in new_brands){
			console.log(d)
			await addBaseObjectType2(domain, 'brands', new_brands[d])
		}
		for (const d in new_product_category){
			console.log(d)
			await addBaseObjectType2(domain, 'product_category', new_product_category[d])
		}
		for (const d in new_dilution_system){
			console.log(d)
			await addBaseObjectType2(domain, 'dilution_system', new_dilution_system[d])
		}
		for (const d in new_machine_category){
			console.log(d)
			await addBaseObjectType2(domain, 'machine_category', new_machine_category[d])
		}
		for (const d in new_equipment_category){
			console.log(d)
			await addBaseObjectType2(domain, 'equipment_category', new_equipment_category[d])
		}
		for (const d in new_cleaningmaterial_composition){
			console.log(d)
			await addBaseObjectType2(domain, 'cleaningmaterial_composition', new_cleaningmaterial_composition[d])
		}
		for (const d in new_cleaningmaterial_category){
			console.log(d)
			await addBaseObjectType2(domain, 'cleaningmaterial_category', new_cleaningmaterial_category[d])
		}
		for (const d in new_equipment_material){
			console.log(d)
			await addBaseObjectType2(domain, 'equipment_material', new_equipment_material[d])
		}
		setItem(newlist)
		setIsSaving(false)
		setShowImport(false);
	}





	const handleDeleteElement = (el) => {
		console.log(el)
		let editlist = [...import_element_list]
		const c = checkComponentPresence(el, editlist, 'id')
		editlist.splice(c, 1)
		setImportElementList(editlist)
		setEditedElement(true)
	}

	const handleAddElement = (el) => {
		console.log(el)
		let editlist = [...import_element_list]
		const c = checkComponentPresence(el, editlist, 'id')
		const d = getSelectedElement(el.id, item_list, 'catalog_reference_id')
		console.log(d)
		if (c <0 && !d.id) {
			editlist.push({ ...el })
			setImportElementList(editlist)
			setEditedElement(true)
		}else{
			childRefAlert.current.addAlert('danger', el.name +' già importato')

		}
	}


	const onChangeCatalog = async (el) => {
		setLoading(true)
		if (el && el.value) {

			setCatalogId(el.value)
			let _product_list = await getProductsInCatalog({ catalogId: el.value, section: section })
			if (_product_list && _product_list.error) {
				setProductList([])

			} else {
				try {
					_product_list = _product_list.data.sort(compareName)
					setProductList(_product_list)
				} catch (err) {
					setProductList([])

				}

			}
		}

		setLoading(false)
	}



	const RenderOneImportElement = ({ i, k }) => {

		return (
			<Row key={k} className="border-bottom pb-1">

				<Col>
					<i>{getBaseObjectValue(i.brand, 'name', locale)}</i> {i.name}</Col>
				<Col className="p-0 text-center" sm="5" md="4" xl="2" >
					<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteElement(i)} /> </Button>
				</Col>

			</Row>

		)
	}



	const RenderOneGood = ({ i, k }) => {

		return (
			<Row key={k} className={styles.source_row}>
				<Col className="p-0 text-center" sm="1">
					<AddListButton i={i} add_action={handleAddElement} element_list={import_element_list} increment={false} />
				</Col>
				<Col>
					<i>{getBaseObjectValue(i.brand, 'name', locale)}</i>
				</Col>
				<Col>
					{i.name}
				</Col>
			</Row>
		)
	}



	return (
		<>
			<Button variant="outline-info" onClick={() => handleShowImport()} ><FontAwesomeIcon icon="puzzle-piece" />
			{loading?
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
							:''}
			{t('catalog.labels.catalog')}</Button>

			<Modal show={showImport} onHide={handleCloseImport} dialogClassName="custom-modal" backdrop="static" className="bg-secondary" scrollable={true}>
			<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ''}>
					<Modal.Title>
						<span className="text-info"><FontAwesomeIcon icon="puzzle-piece" /> &nbsp;{t('catalog.labels.catalog_import')} </span>
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>

						<Form.Group as={Row} className="border-bottom">
							<Col>
								<Card className="mb-3">
									<Card.Header>

										<Container fluid>
											<Row className="h5">

												<Col className="text-primary" sm="7">
													Da importare
												</Col>
												<Col className="text-info">Catalogo
			{loading?
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
							:''}
												</Col>
												<Col>
													<Form.Control as="select" className="mb-2" name="sender_group" onChange={(event) => onChangeCatalog(event.currentTarget)} >
														<option value="-1" >{t('catalog.labels.select_catalog')}</option>
														{catalog_list.map((c, k) => (
															<option key={k} value={c.id}>{c.name}</option>
														))}
													</Form.Control>

												</Col>
											</Row>
										</Container>
									</Card.Header>
									<Card.Body>
										<Container fluid>

											<Row >

												{import_element_list.length > 0 ?
													<Col>
														<Row className="mb-1" >
															<Col className="bg-secondary text-light ml-1">{t('global.labels.name')} </Col>
															<Col className="bg-secondary text-light ml-1" sm="5" md="4" xl="2">
																{t('global.labels.actions')}
															</Col>
														</Row>
														{import_element_list.map((i, key) => (
															<RenderOneImportElement key={key} i={i} />
														))
														}
													</Col>
													: <Col>{t('catalog.messages.no_importing_elements')} </Col>
												}
												<Col className="border-left" className="editSection">
													{product_list.length > 0 ?
														<>
															<Row className="mb-1" >
																<Col className="bg-secondary text-light ml-1 p-0 text-center" sm="1">{t('global.labels.add')}</Col>
																<Col className="bg-secondary text-light ml-1">{t('structure.labels.component_name')}</Col>
															</Row>
															{product_list.map((i, key) => (
																<RenderOneGood key={key} i={i} />
															))
															}
														</>
														: <> {t('catalog.messages.no_elements')}</>
													}
												</Col>
											</Row>
										</Container>

									</Card.Body>
								</Card>


							</Col>
						</Form.Group>

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseImport}>
						{t('modal.close')}
					</Button>
					<Button disabled={isSaving} className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleDoImport}>
						{isSaving ?
							<Spinner size="sm" animation="border" role="status" />
							: <FontAwesomeIcon icon="save" />
						}&nbsp;
						{t('modal.import')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default translate()(ModalImportCatalog)

