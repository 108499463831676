import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button, Card, Form, Spinner, ButtonGroup } from 'react-bootstrap';

import QRCode from 'qrcode.react'
import { useReactToPrint } from 'react-to-print';

import EditContext from "../contexts/edit.js"
import DomainContext from '../contexts/domainContext.js';
import LocaleContext from '../contexts/locale.js'
import GlobalDataContext from '../contexts/globaldataContext.js';


import { getBaseObjectValue } from "../../util/ui_utils.js"

import AlertSection from "../AlertSection.js"
import { RoomInfoBadge } from '../StructureGraphicElements.js';
import { importFullStruct } from '../../Firebase2/Storage2/dbMassiveImport.js';


let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalImportStructureConfirm = ({ t, allData, disabled, onImportData }) => {
	const { contract, structureElements, workarea_list } = useContext(GlobalDataContext)


	const [showModal, setShowModal] = useState(false)
	const [confirmData, setConfirData] = useState(allData)




	const handleShowConfirm = async () => {
		setConfirData(allData)
		setShowModal(true)
	}



	const handleCloseConfirm = () => {
		setShowModal(false)
	}


	const handleConfirmImport = () => {
		setShowModal(false)
		onImportData(confirmData)
	}


	const handleStructureSelect = (el, k) => {
		let _edit_data = { ...confirmData }
		if (el === "-1") {
			delete _edit_data[k].id
		} else {
			_edit_data[k].id = el
		}
		setConfirData(_edit_data)
		console.log(el)
	}

	const isDisabled = () => {
		return findDuplicates(Object.values(confirmData).map(a => a.id).filter(i => i !== undefined)).length > 0
	}

	return (
		<>
			<Button variant={"success"} onClick={() => handleShowConfirm()} disabled={disabled}>
				<FontAwesomeIcon icon="cogs" />	AVVIA IMPORTAZIONE
			</Button>
			<Modal show={showModal} onHide={handleCloseConfirm}  >
				<Modal.Header closeButton >
					Conferma importazione
				</Modal.Header>
				<Modal.Body>
					{Object.keys(confirmData).length > 0 &&
						<Container fluid >
							<Row className="text-info font-style-italic mb-1">
								<Col sm="1">
									<FontAwesomeIcon icon="info" />
								</Col>
								<Col >

									Seleziona se vuoi importare una nuova struttura o se vuoi aggiungere i padiglioni ad una struttura esistente
								</Col>
							</Row>
							<Row>
								<Col className="bg-secondary mr-1 text-light">
									Struttura da importare
								</Col>
								<Col className="bg-secondary mr-1 text-light">
									Struttura destinazione
								</Col>
								<Col className="bg-secondary mr-1 text-light" sm="1">
								</Col>
							</Row>
							{Object.keys(confirmData).map((a, k) => <Row>
								<Col>{a}</Col>
								{structureElements.structure_list.length > 0 ?
									<Col>
										<Form.Control as="select" className="mb-2" name="roomtype" value={confirmData[a].id || -1}
											onChange={(event) => handleStructureSelect(event.target.value, a)} >
											<option value="-1"> -- NUOVA STRUTTURA -- </option>
											{structureElements.structure_list &&
												(structureElements.structure_list.map((i, k) => (
													<option key={k} value={i.id}>{i.name} </option>
												)))
											}
										</Form.Control>
									</Col>
									:
									<Col>
										NUOVA STRUTTURA
									</Col>
								}
								<Col sm="1">
									{confirmData[a].id ?
										<FontAwesomeIcon icon="pencil-alt" />
										:
										<FontAwesomeIcon icon="plus-square" />
									}
								</Col>
							</Row>)}

						</Container>
					}
				</Modal.Body>
				<Modal.Footer>
					<Container fluid>
						<Row className="h4">
							<Col className="text-right">
								<Button variant="secondary" onClick={handleCloseConfirm} className="mr-1">
									Annulla
								</Button>
								<Button variant="success" onClick={handleConfirmImport} disabled={isDisabled()} className="mr-1">
									<FontAwesomeIcon icon="cogs" />Conferma importazione
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	)
}


