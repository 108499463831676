import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Spinner, ButtonGroup, ProgressBar, Badge, Card, DropdownButton } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { format, isSameSecond } from 'date-fns'


import { messagePriority_dict, ticketType_dict } from "../components/constants/global_definitions"

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext'
import { InitContractHelper } from "../util/contract"

import ModalTicket from '../components/ModalTicket.js';
import ModalTicketAnswer from '../components/ModalTicketAnswer.js';
import Footer from "../components/Footer"
import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"

import { myTimestampToDate, compareEditDateParam, getElementCount, getTicketReceiver, stringToColour } from "../util/ui_utils.js"
import { TicketUserName, TicketPath, ServiceBadge, TicketStatus, getColor } from '../components/StructureGraphicElements.js';


import { getTicketListForUser as getTicketListForUser2, getTicketListForGroup as getTicketListForGroup2, getTicketList } from "../Firebase2/Storage2/dbTicket"
import { TicketDropdown } from '../components/TicketDropdown';
import { ModalPrintTicket } from '../components/modal/ModalPrintTicket';
import { ModalDocument } from '../components/modal/ModalDocumentPreview.js';


//FIXME cursor not used
let query_cursor = undefined
const section = "ticket"
let load_page = false





const ticketcmount = 200;

const TicketsView = ({ t, uData }) => {
	const { domain, domain_data } = useContext(DomainContext)
	let { cid } = useParams();
	const { contract } = useContext(GlobalDataContext)
	let contractId = cid
	const { initContract } = InitContractHelper()

	const childRefAlert = useRef()
	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [sentelement_list, setSentElementList] = useState([])
	const [receivedelement_list, setReceivedElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)
	const [filterAppliedList, setFilterAppliedList] = useState({ type: [], status: [], unread: ['read', 'unread'] })
	const [viewSection, setViewMode] = useState('received')

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		console.log('index', c)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		if (section === 'type' && f_data.length!== 1){
			setFilterAppliedList({ ...filterAppliedList, [section]: f_data, status:[] })
		}else{
			setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
		}
	}


	const initValues = async () => {
		const userData = uData
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('messages') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		if (contract && domain.length > 0) {
			load_page = true
			setUiList({ ...ui_list })
			let _sent_message_list = await getTicketListForUser2(domain, contractId, uData.id)
			//let _sent_message_list = await getTicketList(domain, contractId)
			let _received_message_list = []
			console.log(userData.messagegroups)
			if (userData.messagegroups) {
				for (const group of userData.messagegroups.filter(g => g.contract_id === contractId)) {
					//for (const group of userData.messagegroups){
					console.log("GFROUP, ", group)
					let treceive_message_list = await getTicketListForGroup2(domain, contractId, group.message_group_id, ticketcmount)
					console.log("Received-MESSAGES", treceive_message_list)
					if (!treceive_message_list.error) {
						_received_message_list = [..._received_message_list, ...treceive_message_list.messageList]
					}
				}
			}
			console.log(_received_message_list)
			_received_message_list = _received_message_list.filter((value, index, self) => index === self.findIndex((t) => (t.id === value.id)))
			//			receive_message_list = await getMessageList(domain, contractId, mailcount)
			_received_message_list.sort(compareEditDateParam).reverse()
			_sent_message_list.sort(compareEditDateParam).reverse()
			//setElementList(_received_message_list)
			setSentElementList(_sent_message_list)
			setReceivedElementList(_received_message_list)
			setLoading(false)
		}
	}


	useEffect(() => {
		load_page = false
		async function loadData() {
			let contractId = cid
			console.log("MESSAGES reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("MESSAGES init contract return data ", contract, ret_data)
			}

		}
		if (!contract) {
			loadData()
		}
	}, [])




	useEffect(() => {
		console.log('MESSAGES change contract ->', contract)
		if (contract && !load_page) {
			console.log("TRY TO LOAD")
			initValues()
		}
	}, [contract])









	const ticket_type_dict = ticketType_dict({ t })







	const updateSentMessages = (el_list) => {
		setViewMode('sent')
		setSentElementList(el_list)
	}

	const updateReceivedMessages = (el_list) => {
		setViewMode('received')
		setReceivedElementList(el_list)

	}










	return (
		<>
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col sm="6">
						<PageTitle title={t('navs.registries.tickets')} />
						<ButtonGroup>
							{canWrite ?
								<Button onClick={() => setViewMode('sent')} variant={viewSection === 'sent' ? 'primary' : 'outline-secondary'}>{t('ticket.labels.view_created')}<Badge>{sentelement_list.length} </Badge></Button>
								: ''}
							<Button onClick={() => setViewMode('received')} variant={viewSection === 'received' ? 'info' : 'outline-secondary'}>{t('ticket.labels.view_received')}<Badge>{receivedelement_list.length}</Badge> </Button>
						</ButtonGroup>
					</Col>
					<Col sm="4">
						<ButtonGroup>
							<Button variant={filterAppliedList.unread.includes('unread') ? 'secondary' : "outline-secondary"} onClick={() =>updateAppliedFilter('unread', 'unread')} size="sm" >Non Letti</Button>
							<Button variant={filterAppliedList.unread.includes('read') ? 'secondary' :"outline-secondary"} onClick={() =>updateAppliedFilter('unread', 'read')} size="sm" >Letti</Button>
						</ButtonGroup>
						<ButtonGroup>
							<Button disabled variant="secondary" size="sm" ><FontAwesomeIcon icon="filter" /></Button>
							{Object.values(ticket_type_dict).filter(t => t.id !== 'failure' && t.id !== 'manual_event').map((g, k) => (
								<Button key={k} size="sm" variant={filterAppliedList['type'].includes(g.id) ? 'secondary' : "outline-secondary"} onClick={(e) => updateAppliedFilter('type', g.id)}>
									<FontAwesomeIcon icon={g.icon} /> {g.label}
								</Button>

							))}
							{filterAppliedList.type.length > 0 &&
								<Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'type': [] })}><FontAwesomeIcon icon="times" /></Button>
							}
						</ButtonGroup>
						{filterAppliedList.type.length === 1 &&
							<FilterTicketTypeStatus type_data={ticket_type_dict[filterAppliedList.type[0]]} filterAppliedList={filterAppliedList} updateAppliedFilter={updateAppliedFilter} setFilterAppliedList={setFilterAppliedList} />
						}
					</Col>
					<Col className="text-right">
						<ModalPrintTicket viewSection={viewSection} element_list={viewSection === 'received' ? receivedelement_list : sentelement_list} uData={uData} t={t} />
						{canWrite ?
							<ButtonGroup>
								<Button disabled>
									<FontAwesomeIcon icon="plus-square" />
								</Button>

								<TicketDropdown label="Nuovo Messaggio" message_list={sentelement_list} setMessageList={updateSentMessages} ui_list={ui_list} uData={uData} canWrite={canWrite} />
							</ButtonGroup>
							: ''}
					</Col>
				</Row>
				{viewSection === 'received' ?
					receivedelement_list && receivedelement_list.length > 0 ?
						<Row className="p-1 text-light">
							<Col className="bg-secondary ml-1" sm="1">Tipologia</Col>
							<Col className="bg-secondary ml-1" sm="1">Priorità</Col>
							<Col className="bg-secondary ml-1" sm="4">riferimenti</Col>
							<Col className="bg-secondary ml-1">destinatario</Col>
							<Col className="bg-secondary ml-1">creato da</Col>
							<Col className="bg-secondary ml-1" sm="1">data</Col>
							<Col className="bg-secondary ml-1 text-center" sm="1">Azioni</Col>
						</Row>
						: ''
					:
					sentelement_list && sentelement_list.length > 0 ?
						<Row className="p-1 text-light">
							<Col className="bg-secondary ml-1" sm="1">Tipologia</Col>
							<Col className="bg-secondary ml-1" sm="1">Priorità</Col>
							<Col className="bg-secondary ml-1" sm="4">riferimenti</Col>
							<Col className="bg-secondary ml-1">destinatario</Col>
							<Col className="bg-secondary ml-1">creato da</Col>
							<Col className="bg-secondary ml-1" sm="1">data</Col>
							<Col className="bg-secondary ml-1 text-center" sm="1">Azioni</Col>
						</Row>
						: ''
				}
			</Container>
			<Container fluid>
				{loading ? <Row><Col>
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col></Row>
					:

					<>
						{viewSection === 'received' ?
							receivedelement_list && receivedelement_list.length > 0 ?
								<>
									{receivedelement_list.filter(m =>
										(
											filterAppliedList.type.length === 0 ||
											filterAppliedList.type.indexOf(m.type) >= 0
										) && (

											filterAppliedList.status.length === 0 ||
											filterAppliedList.status.indexOf(m.status_history ? m.status_history[m.status_history.length - 1].code_id: m.status) >= 0
										) &&(
											filterAppliedList.unread.length === 0 ||
											filterAppliedList.unread.length === 2 ||
											(filterAppliedList.unread.includes('unread') && !m.read && m.message_list && m.message_list[m.message_list.length - 1].userId !== localStorage.getItem('userId')) || 
											((filterAppliedList.unread.includes('read') && (m.read || (m.message_list && m.message_list[m.message_list.length - 1].userId === localStorage.getItem('userId')))))

										)
									)
										.map((m, k) => (
											<OneTicket key={k} t={t} ticket={m} setElementList={updateReceivedMessages} element_list={receivedelement_list} uData={uData} viewMode={viewSection} />
										))}
									<Row className="mt-4">
										<Col className="text-center bg-info">
											<ButtonGroup>
												<Button variant="secondary" disabled><FontAwesomeIcon icon="arrow-left" /></Button>
												<Button variant="secondary" disabled><FontAwesomeIcon icon="arrow-right" /></Button>
											</ButtonGroup>
										</Col>
									</Row>
								</>
								:
								<Row>
									<Col className="font-italic">Nessun messaggio ricevuto</Col>
								</Row>
							:

							sentelement_list && sentelement_list.length > 0 ?
								<>
									{sentelement_list.filter(m =>
										(
										filterAppliedList.type.length === 0 ||
										filterAppliedList.type.indexOf(m.type) >= 0
										) && (

											filterAppliedList.status.length === 0 ||
											filterAppliedList.status.indexOf(m.status_history ? m.status_history[m.status_history.length - 1].code_id: m.status) >= 0
										) &&(
											filterAppliedList.unread.length === 0 ||
											filterAppliedList.unread.length === 2 ||
											(filterAppliedList.unread.includes('unread') && !m.read && m.message_list && m.message_list[m.message_list.length - 1].userId !== localStorage.getItem('userId')) || 
											((filterAppliedList.unread.includes('read') && (m.read || (m.message_list && m.message_list[m.message_list.length - 1].userId === localStorage.getItem('userId')))))

										)
									).map((m, k) => (
										<OneTicket key={k} t={t} ticket={m} setElementList={updateSentMessages} element_list={sentelement_list} uData={uData} viewMode={viewSection} />
									))}
									<Row className="mt-4">
										<Col className="text-center bg-primary">
											<ButtonGroup>
												<Button variant="secondary" disabled><FontAwesomeIcon icon="arrow-left" /></Button>
												<Button variant="secondary" disabled><FontAwesomeIcon icon="arrow-right" /></Button>
											</ButtonGroup>
										</Col>
									</Row>
								</>
								:
								<Row>
									<Col className="font-italic">Nessun messaggio inviato</Col>
								</Row>
						}
					</>
				}


			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('global.labels.messages') })} />

		</>
	);
}



const FilterTicketTypeStatus = ({ type_data, filterAppliedList, updateAppliedFilter, setFilterAppliedList }) => {
	console.log("TYPE DATA", type_data)

	if (type_data !== undefined) {
		return (
			<ButtonGroup>
				<Button disabled variant="secondary" size="sm" ><FontAwesomeIcon icon="filter" /></Button>
				{Object.entries(type_data.status).map((g, k) => (
					<Button key={k} size="sm" variant={filterAppliedList['status'] && filterAppliedList.status.indexOf(g[0]) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('status', g[0])}>
						{g[1]}
					</Button>

				))}
				{filterAppliedList.status && filterAppliedList.status.length > 0 &&
					<Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'status': [] })}><FontAwesomeIcon icon="times" /></Button>
				}
			</ButtonGroup>

		)

	} else {
		return <></>
	}

}




////////////////////////////////////////////////////////////////////////////////////////////////////////
export const OneTicket = ({ t, ui_list, ticket: one_ticket, element_list, setElementList, uData, viewMode: viewSection, onlyView }) => {
	const { messagegroup_list, service_dict } = useContext(GlobalDataContext)
	const priority_dict = messagePriority_dict({ t })
	const ticket_type_dict = ticketType_dict({ t })
	return (
		<Card className="mt-2">
			<Card.Header className="p-1">
				<Container fluid>
					<Row >
						<Col sm="2">
							<Row>
								<Col>
									{ticket_type_dict[one_ticket.type].icon}&nbsp;
									{ticket_type_dict[one_ticket.type].label}
								</Col>

								{one_ticket.url &&
									<ModalDocument t={t} photo={one_ticket.url} />
								}

							</Row>
							<Row>
								<Col className="text-info">
									{one_ticket.status_history && one_ticket.status_history.length > 0 ?
										<TicketStatus e={one_ticket} show_history={true} ticket_type_dict={ticket_type_dict} />
										:
										<TicketStatus e={one_ticket} ticket_type_dict={ticket_type_dict} />
									}
								</Col>
								<Col>
									<ProgressBar now={33 * one_ticket.priority_level} />
								</Col>

							</Row>

						</Col>
						<Col sm="4">
							<TicketPath ticket={one_ticket} t={t} />
							{one_ticket.service ?
								<>
									<br />
									<ServiceBadge service={service_dict[one_ticket.service.id]} freq={true} />
									{one_ticket.urgent_execution ?
										<>
											<br />
											(Già programmata per il {format(myTimestampToDate(one_ticket.programmed_date), 'dd/MM/yy')} da <TicketUserName ticket={one_ticket} />)
										</>
										:
										one_ticket.status_history && one_ticket.status_history[one_ticket.status_history.length - 1].code_id === 'assigned' ?
											<>(Già programmata)</>
											:
											<Badge variant="info">Da programmare</Badge>
									}
								</>
								: ''}
						</Col>
						<Col className={viewSection === 'received' ? 'bg-info' : 'bg-primary text-white'}>
							{getTicketReceiver(one_ticket, messagegroup_list, 'receiver_group')}
						</Col>
						<Col className={viewSection === 'received' ? 'bg-info' : 'bg-primary text-white'}>
							<TicketUserName ticket={one_ticket} />
							{one_ticket.send_as_group ?
								<i>
									&nbsp;({getTicketReceiver(one_ticket, messagegroup_list, 'sender_group')})
								</i>
								: ''
							}
						</Col>
						<Col sm="2" md="1">
							{format(myTimestampToDate(one_ticket.createdAt), 'dd/MM/yyyy HH:mm')}
							{one_ticket.lastEdit && !(isSameSecond(myTimestampToDate(one_ticket.createdAt), myTimestampToDate(one_ticket.lastEdit))) ?
								<>
									<br />({format(myTimestampToDate(one_ticket.lastEdit), 'dd/MM/yyyy HH:mm')})
								</>
								: ''}
								{one_ticket.id}
						</Col>
						{!onlyView &&
							<Col className="text-center" sm="1">
								<ModalTicketAnswer is_new={false} t={t} item={one_ticket} setItem={setElementList} ui_list={ui_list} item_list={element_list} type={one_ticket.type} userData={uData} canWrite={true} />
							</Col>
						}

					</Row>

				</Container>

			</Card.Header>
			<Card.Body className="p-1 pl-5">
				<Container fluid>
					{one_ticket.message_list && one_ticket.message_list.length > 0 ?
						<>
							{one_ticket.message_list.filter(m => (m.message && m.message.length > 0) || m.media).map((m, mk) => (
								<Row className={`${m.userId === uData.uid ? 'float-right' : 'float-left'} mt-1 pt-1 mb-1 pb-1 w-75`} style={{ border: `1px solid black`, borderColor: stringToColour(m.userId) }} key={mk}>
									<Col className={!one_ticket.read && one_ticket.message_list[one_ticket.message_list.length - 1].userId !== uData.uid ? "font-weight-bold bg-warning" : "bg-light"} >
										<FontAwesomeIcon icon="comment" className="mr-1 text-primary" />
										{mk === 0 && one_ticket.forwardFromId ? <FontAwesomeIcon icon="share" /> : ''}
										{m.message}
									</Col>
									<Col sm="1">

										{m.media &&
											<>{console.log("MEDIA", m)}
												<ModalDocument t={t} photo={m.media} name={m.media_tracker && m.media_tracker.source_name} />
											</>

										}
									</Col>
									<Col sm="2" >
										<Badge style={{ backgroundColor: stringToColour(m.userId) }}>
											&nbsp;&nbsp;
										</Badge>
										{m.userName}
									</Col>
									<Col sm="2">
										{format(myTimestampToDate(m.createdAt), 'dd/MM/yyyy HH:mm')}
									</Col>
								</Row>

							))}
						</>
						:
						<>
							{one_ticket.message}
						</>

					}

				</Container>

			</Card.Body>
		</Card>

	)

}
















export default translate()(TicketsView)




