import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  Container, Row, Col, Button, Spinner, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"


import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import SearchSection from "../components/SearchSection"
import ModalCart from "../components/ModalCart"
import ModalImportCatalog from '../components/ModalImportCatalog';

import { compareName, checkComponentPresence, getMediaIcon, getElementPreview, getElementCount, getBaseObjectValue } from "../util/ui_utils.js"
import { RenderItemFileBadge } from '../components/StructureGraphicElements.js'

import {
	getProductList as getProductList2,
	deleteProduct as deleteProduct2
} from '../Firebase2/Storage2/dbWarehouse';
import { getCatalogProductList, deleteCatalogProduct} from '../Firebase2/Storage2/dbCatalogWarehouse';
import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"






let load_contract = true
const companyId = '0'
const section = 'cart'

////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const CartsView = ({ t, uData }) => {

	const { locale } = useContext(LocaleContext)
	const { domain, domain_data } = useContext(DomainContext)

	const childRefDelete = useRef()
	const childRefAlert = useRef()

	let { cid } = useParams();
	let contractId = cid

	const [element_list, setElementList] = useState([])
	const [loading, setLoading] = useState(false)
	const [ui_list, setUiList] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)

	const initValues = async () => {
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('carts') !== -1)) {
			setWritePage(true)
		}
		const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		setUiList({ ...ui_list, brand_list })
		let newelement_list = []
		if (domain === 'catalog') {
			newelement_list = await getCatalogProductList(contractId, section, null)
		} else {
			newelement_list = await getProductList2(domain, contractId, companyId, section, null)
		}
		newelement_list.sort(compareName)
		setElementList(newelement_list)
		setLoading(false)
	}

	useEffect(() => {
		initValues()
	}, [])

	

	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data 
			if (domain ==='catalog'){
				return_data = await deleteCatalogProduct(contractId, section, el.id)
			}else{
				return_data = await deleteProduct2(domain, contractId, companyId, section, el.id)

			}
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('product.messages.delete_cart_title'), t('product.messages.delete_cart', {name: el.name}), cb)
	}

	return (
		<>

			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('navs.registries.carts')} />
					</Col>
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite ?
						<>
						<ModalImportCatalog t={t} section={section} ui_list={ui_list} item_list={element_list} setItem={setElementList}  />
							<ModalCart is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} canWrite={canWrite} ui_list={ui_list} setUiList={setUiList}/>
							</>
							: ''}
					</Col>
				</Row>
				{!loading && element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="1"></Col>
						<Col className="bg-secondary ml-1">{t('carts.labels.code')}</Col>
						<Col className="bg-secondary ml-1">{t('carts.labels.model')}</Col>
						<Col className="bg-secondary ml-1">{t('carts.labels.brand')}</Col>
						<Col className="bg-secondary ml-1">{t('carts.labels.size')}</Col>
						<Col className="bg-secondary ml-1 p-0 text-center" sm="1"><FontAwesomeIcon icon='image' />&nbsp;<FontAwesomeIcon icon='file' /></Col>
						<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					: <>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
									<Row key={key} className="oneCompany p-1 border-bottom">
										<Col sm="1">{getElementPreview(i)} </Col>
										<Col>{i.code}</Col>
										<Col>

											{i.catalog_id ?
						<OverlayTrigger
							placement="right"
							delay={{ show: 250, hide: 400 }}
							overlay={renderTooltip}
						>
							<FontAwesomeIcon icon="clipboard-list" />
						</OverlayTrigger>
												: ''
											}
										<b>{i.name}</b></Col>
										<Col>{getBaseObjectValue(i.brand, 'name', locale)}</Col>
										<Col>{i.size_w} &times; {i.size_h} &times; {i.size_d}</Col>
										<Col sm="1" className="p0 text-center">
											<RenderItemFileBadge media_list={i.media_list} section={section} position="left-start" />
										</Col>
										<Col sm="1" className="text-center">
											<ButtonGroup>
												<ModalCart is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite  && !i.catalog_id} ui_list={ui_list} setUiList={setUiList}/>
												{canWrite?
													<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
													: ''}
											</ButtonGroup>
										</Col>

									</Row>
								))}
							</>
							: 
							<Row>
								<Col className="font-italic">
									{t('carts.messages.no_cart')}
								</Col>
							</Row>
						}
					</>
				}
				
			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('navs.registries.carts') })} />

		</>
	);
}


const renderTooltip = (props) => (
	<Tooltip id="button-tooltip" {...props}>
		Prodotto da catalogo
	</Tooltip>
);

export default translate()(CartsView)

