import React, { useContext } from 'react';

import {differenceInCalendarDays  } from 'date-fns'

import GlobalDataContext from '../../contexts/globaldataContext.js';

import { myTimestampToDate } from '../../../util/ui_utils.js';


export const checkNextUpdate = (old, current) => {
	if (!old) {
		return current
	}
	if (myTimestampToDate(current) < myTimestampToDate(old)) {
		return current
	} else {
		return old
	}
}



export const PathName = ({ parent_list, parent_id }) => {
	const { structureElements } = useContext(GlobalDataContext)
	try {

		return (
			<span className="text-muted font-italic">
				{structureElements.pavillon_list.filter(p => p.id === parent_list[1])[0].name}
				&nbsp;|&nbsp;
				Piano {structureElements.floor_list.filter(p => p.id === parent_list[2])[0].name}
				&nbsp;|&nbsp;
				{structureElements.department_list.filter(p => p.id === parent_id)[0].name}
			</span>
		)
	} catch (err) {
		console.log(err)
		return (<></>)
	}
}




export const getPlannedDays = (cur_date, next_p, t) => {
	if (next_p) {
		const d = differenceInCalendarDays(myTimestampToDate(next_p.startDate), cur_date)
		if (d === 1) {
			return <>({d} {t('global.labels.day')})</>
		} else {
			return <>({d} {t('global.labels.days')})</>
		}
	} else {
		return ''
	}

}



