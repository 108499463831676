import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, Spinner, Dropdown, Card, ProgressBar, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { format } from 'date-fns'

import styles from '../components/constants/styles'
import { ticketType_dict, messagePriority_dict, payment_mode_dict, popoverTiming } from "../components/constants/global_definitions"

import { firebaseAuth } from '../provider/AuthProvider'
import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext'

import AlertSection from "../components/AlertSection.js"
import { getValue, setMyDate, myTimestampToDate, getTicketReceiver, checkComponentPresence } from "../util/ui_utils.js"

import { ServiceBadge, MediaPreview, TicketPath, isImageFromName } from "../components/StructureGraphicElements"

import { editTicket as editTicket2, addTicket } from "../Firebase2/Storage2/dbTicket"
import { uploadFileComplete as uploadFileComplete2 } from '../Firebase2/Storage2/dbFileStorage';
import { addEvent } from '../Firebase2/Storage2/dbAgenda';
import { ModalDocument } from './modal/ModalDocumentPreview';


const newmessage = {
	message: '',
	priority_level: 1,
	createdAt: setMyDate(new Date())
}

//const contract = JSON.parse(sessionStorage.getItem('contract'))
const section = 'ticket'
let load_page = false

//------------------------------------------
//------------------------------------------
//------------------------------------------
function ModalTicketAnswer({ item, setItem, t, is_new, item_list, ui_list, canWrite, userData }) {

	const { domain } = useContext(DomainContext)
	const { contractId } = useContext(GlobalDataContext)

	const [showAnswerTicket, setShowAnswerTicket] = useState(false)
	const [showEdit, setShow] = useState(false);
	const [curElement, setCurrentElement] = useState(item)
	const [curAnswer, setAnswer] = useState({})
	const [showDoneSection, setShowDoneSection] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	//	const [showForwardTicket, setShowForwardTicket] = useState(false)

	//	const [showSolvedTicket, setShowSolvedTicket] = useState(false)
	//	const [viewSent, setViewSent] = useState(false)
	//	const [viewReceived, setViewReceived] = useState(true)

	const childRefAlert = useRef()

	const ticket_type_dict = ticketType_dict({ t })

	const [ticketType, setTicketType] = useState('request')
	const [uploadingState, setUploadingState] = useState(-1)


	const handleShowEdit = async (showanswer) => {
		setTicketType(item.type)
		setCurrentElement(item)
		setShow(true)
		setShowDoneSection(false)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
		if (showanswer) {
			setShowAnswerTicket(true)
		} else {
			setShowAnswerTicket(false)
		}
		if (!item.read && item.message_list && item.message_list[item.message_list.length - 1].userId !== localStorage.getItem('userId')) {
			let _ticket = { ...item, read: true }
			await editTicket2(domain, contractId, _ticket.id, _ticket)
		}
	}





	const cb_progress = (status, byte, total) => {
		console.log(status, byte, total)
		try {
			setUploadingState(100 * byte / total)
		} catch (e) {
			console.warn("handleSaveMedia - error: ", e)
		}
	}

	const handleClose = () => setShow(false);






	const handleShowAnswerTicket = () => {
		setShowAnswerTicket(false)
		setShowAnswerTicket(true)
		setEditedElement(true)
		setAnswer(newmessage)
	}



	const handleSaveForwardTicket = async () => {
		console.log("USERDATA", userData)
		let _ticket = { ...curElement }
		let _newticket = {
			...curElement,
			createdAt: curElement.createdAt,
			userId: userData.id || userData.uid,
			userName: userData.isGlobalAdmin ? 'admin' : userData.displayName || 'utente',
			receiver_group: curElement.forwardreceiver_group,
			read: false,
			lastEdit: setMyDate(new Date()),
			forwardFromId: curElement.id,
			message_list: [
				{
					...curElement.message_list[0],
					status: 'sent_forward',
					//					createdAt:(new Date()),
					//					userId: localStorage.getItem('userId'),
					//					userName:userData.isGlobalAdmin ? 'admin' : userData.displayName || 'utente',
				}
			], status_history: [{ code_id: 'sent_forward', status_date: setMyDate(new Date()) }]
		}
		delete _newticket.id

		console.log("forward", _newticket)
		let return_data = await addTicket(domain, contractId, _newticket)
		if (return_data && return_data.error) {
			console.error(return_data,)
			childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
		} else {
			_ticket.status_history = [..._ticket.status_history, { code_id: 'forward', status_date: setMyDate(new Date()) }]
			console.log("EDIT", _ticket)
			_ticket.forwardedToId = return_data.id
			let return_dataB = await editTicket2(domain, contractId, _ticket.id, _ticket)
			let edit_list = [...item_list]
			const c = checkComponentPresence(curElement, item_list, 'id')
			edit_list.splice(c, 1, _ticket)
			const newlist = [return_data, ...edit_list]
			setItem(newlist)
			setShow(false);
			setShowAnswerTicket(false)
			const newEvent = {
				start: new Date(),
				end: new Date(),
				extendedProps: {
					type: ticket_type_dict[ticketType].event,
					forwarded_from: curElement.message_list[0].userName,
					message_id: return_data.id,
					sender_group: -1,
					receiver_group: curElement.forwardreceiver_group,
					userId: userData.id || userData.uid
				}
			};
			const return_dataEvent = addEvent(domain, contractId, newEvent)
		}

	}



	const handleSaveAnswerTicket = (done) => {
		const answermessage = {
			...curAnswer,
			createdAt: setMyDate(new Date()),
			status: 'sent',
			userId: localStorage.getItem('userId'),
			userName: userData.isGlobalAdmin ? 'admin' : userData.displayName || 'utente'
		}

		const cb_save = async (url, tracker) => {
			if (url) {
				answermessage.media = url
				answermessage.media_tracker = tracker
			}
			console.log(answermessage)
			delete answermessage.file
			let _ticket = { ...curElement }
			if (done !== undefined) {
				_ticket.status_history = [..._ticket.status_history, { code_id: done, status_date: answermessage.createdAt }]
			}
			_ticket.message_list = (curElement.message_list) ? [...curElement.message_list, answermessage] : [answermessage]
			_ticket.read = false
			_ticket.lastEdit = answermessage.createdAt
			console.log(_ticket)
			 let return_data = await editTicket2(domain, contractId, _ticket.id, _ticket)
			if (return_data && return_data.error) {
				console.error(return_data, _ticket)
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, _ticket)
				console.log("NEW LIST", edit_list)
				setItem(edit_list)
				setShow(false);
				setShowAnswerTicket(false)
			} 
		}

		if (curAnswer.file) {
			const mypath = contractId + '/' + section
			const mediainfo = { description: 'allegato', source_name: curAnswer.file[0].name, section: section, item_reference: {} }
			let file_data = uploadFileComplete2(domain, '0', mypath, curAnswer.file[0], curAnswer.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
			console.log("FILE", file_data)
		} else {
			cb_save()
		}

	}
	




	const onChangeHandlerAnswer = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curAnswer[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'

		}
		console.log("messagechange", name, value)
		setAnswer({ ...curAnswer, [name]: value })
		setEditedElement(true)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}


	const addDoneMsg = () => {
		setShowDoneSection(true);
		setEditedElement(true) 
	}


	const ChangeStatus = ({ t, element }) => {
		//----FIXME
		if (element.type === 'failure' || element.type === 'request' || element.type === 'registry_change' || element.type === 'noncompliance') {
			return (
				<Button className="btn-success" onClick={() => addDoneMsg()}>
					<FontAwesomeIcon icon="check" />{t('modal.done')}
				</Button>
			)
		} else {
			return ('')
		}
	}




	return (
		<>
			{canWrite ?
				<>
					<BtnEdit read={curElement.read} message_list={curElement.message_list} userId={localStorage.getItem('userId')} handleShowEdit={handleShowEdit} />
					<OverlayTrigger
						placement="bottom"
						delay={popoverTiming}
						overlay={<Tooltip id="ticket-tooltip">Risposta rapida</Tooltip>}>

						<Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowEdit(true)}><FontAwesomeIcon icon="reply" /> </Button>
					</OverlayTrigger>
				</>
				:
				<Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="eye" /> </Button>
			}
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title variant="info"><FontAwesomeIcon icon="pencil-alt" />{ticket_type_dict[ticketType].label}{editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TicketDetail ticket={curElement} t={t} />
					<Container>
						<Row>
							<Col className="bg-primary text-light p-3">
								Messaggi
							</Col>
						</Row>
						{curElement.message_list &&
							<>
								{curElement.message_list.map((a, ak) => (
									<Row key={ak} className="border-info mt-2">
										<Col sm="2">
											{format(myTimestampToDate(a.createdAt), 'dd/MM/yyyy HH:mm')}
										</Col>
										<Col sm="3">
											{a.userName}
										</Col>
										<Col>{a.message}</Col>
										<Col sm="2">
											
											{a.media &&
												<ModalDocument t={t} photo={a.media} name={a.media_tracker && a.media_tracker.source_name} />
											}
										</Col>
									</Row>
								))}
							</>
						}
						{showAnswerTicket &&
							<ElementFormAnswerDetails ticket={curElement} element={curAnswer} t={t} ticketType={ticketType} functions={{ edit: onChangeHandlerAnswer }} ui_list={ui_list} uploadingState={uploadingState} userData={userData} />
						}
						{showDoneSection &&
							<ElementFormDoneSection />
						}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					{canWrite && showAnswerTicket &&
						<ChangeStatus element={curElement} t={t} />
					}
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite &&
						<>

							{showAnswerTicket ?
								<>
									<Button variant="outline-primary" onClick={() => handleSaveAnswerTicket()}>
										<FontAwesomeIcon icon="comment" />{t('tocket.buttons.send_reply')}
									</Button>
								</>
								:
								<>
									{localStorage.getItem('userId') === curElement.userId ?
										<Button variant="outline-primary" onClick={() => handleShowAnswerTicket()}>
											<FontAwesomeIcon icon="comment" />{t('tocket.buttons.add_reply')}
										</Button> :
										<Button variant="outline-primary" onClick={() => handleShowAnswerTicket()}>
											<FontAwesomeIcon icon="comment" />{t('tocket.buttons.reply')}
										</Button>
									}
									<ModalForward t={t} onChangeHandler={onChangeHandler} handleForward={handleSaveForwardTicket} userData={userData} />
								</>
							}
							{showDoneSection &&
								<>
									<Button variant={editedElement ? 'warning': "outline-primary"}  onClick={() => handleSaveAnswerTicket('done')}>
										<FontAwesomeIcon icon="save" />Conferma esecuzione
									</Button>
								</>}
						</>
					}
				</Modal.Footer>
			</Modal>
		</>
	)
}


const ModalForward = ({ t, onChangeHandler, handleForward, userData }) => {
	const { contractId, sendmessagegroup_list, service_dict, structureElements } = useContext(GlobalDataContext)
	const [showForward, setShowForward] = useState(false)

	const handleShowForward = () => {
		setShowForward(true)
	}

	const handleSave = () => {
		handleForward()
		setShowForward(false)
	}
	const handleClose = () => {
		setShowForward(false)
	}

	return (
		<>
			<Button variant="outline-info" onClick={handleShowForward}>
				<FontAwesomeIcon icon="share" />{t('ticket.buttons.forward_ticket')}
			</Button>
			<Modal show={showForward} onHide={handleClose} className="bg-secondary" size="md">
				<Modal.Header closeButton  >
					<Modal.Title variant="info">
						<FontAwesomeIcon icon="share" /> {t('ticket.buttons.forward_ticket')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>


						<Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
							<Form.Label column sm="2">{t('ticket.labels.receiver')}</Form.Label>
							<Col sm="8">
								<Form.Control as="select" className="mb-2" defaultValue="1" name="forwardreceiver_group" onChange={(event) => onChangeHandler(event.currentTarget)} >
									<option value="-1" >{t('ticket.labels.select_receiver')}</option>
									{sendmessagegroup_list
										? [sendmessagegroup_list.map((r, k) => (
											<option key={k} value={r.id} >{r.name}</option>
										))]
										: ''
									}
								</Form.Control>
							</Col>
						</Form.Group>
						<Row>
							<Col><i>{format(new Date(), 'dd/MM/yyyy HH:mm')}</i></Col>
							{userData &&
								<Col className="text-right">
									<i>
										inoltrato da: {userData.isGlobalAdmin ? 'admin' : userData.displayName}
									</i>
								</Col>
							}
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button variant="outline-primary" onClick={handleSave}>
						<FontAwesomeIcon icon="share" />{t('ticket.buttons.forward_ticket')}
					</Button>
				</Modal.Footer>
			</Modal>

		</>
	)
}


const BtnEdit = ({ read, message_list, userId, handleShowEdit }) => {
	if (!read && message_list && message_list[message_list.length - 1].userId !== userId) {
		return (

			<OverlayTrigger
				placement="bottom"
				delay={popoverTiming}
				overlay={<Tooltip id="ticket-tooltip">Dettagli e azioni avanzate (nuovi messaggi non letti)</Tooltip>}>

				<Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="eye" style={{color:'orange'}} /><FontAwesomeIcon icon="pencil-alt" /> </Button>
			</OverlayTrigger>
		)

	} else {
		return (

			<OverlayTrigger
				placement="bottom"
				delay={popoverTiming}
				overlay={<Tooltip id="ticket-tooltip">Dettagli e azioni avanzate </Tooltip>}>

				<Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			</OverlayTrigger>
		)

	}

}



////////////////////////////////////////////////////////////////////////////////////////////////////////
const TicketDetail = ({ t, ticket }) => {
	const { messagegroup_list, service_dict } = useContext(GlobalDataContext)
	const priority_dict = messagePriority_dict({ t })
	return (
		<>
			<Card className="mb-3">
				<Card.Header>{ticket.message_list ?
					<> {ticket.message_list[0].userName}</> : ''} {ticket.send_as_group ? <>({getTicketReceiver(ticket, messagegroup_list, 'sender_group')} )</> : ''}<FontAwesomeIcon icon="angle-right" />  {getTicketReceiver(ticket, messagegroup_list, 'receiver_group')} </Card.Header>
				<Card.Body>
					<Card.Text>
						<Row>
							<Col className={styles.subtitle} sm="3">
								{t('ticket.labels.creation_date')}
							</Col>
							<Col>
								{format(myTimestampToDate(ticket.createdAt), 'dd/MM/yyyy HH:mm')}
							</Col>
						</Row>

						<Row>
							<Col className={styles.subtitle} sm="3">
								{t('ticket.labels.structure_reference')}
							</Col>
							<Col>
								<TicketPath ticket={ticket} t={t} full={true}/>
							</Col>
						</Row>
						<Row>
							<Col className={styles.subtitle} sm="3">
								{t('ticket.labels.priority')}
							</Col>
							<Col>
								{priority_dict[ticket.priority_level]}
							</Col>
							<Col sm="1">
								<ProgressBar now={33 * ticket.priority_level} />
							</Col>
						</Row>
						{ticket.type === 'request' &&
							<Row>
								<Col className={styles.subtitle} sm="3">
									{t('global.labels.service')}
								</Col>
								<Col>
									{ticket.service &&
										<ServiceBadge service={service_dict[ticket.service.id]} freq={true} />
									}
								</Col>
							</Row>
						}
					</Card.Text>
				</Card.Body>
			</Card>
		</>

	)
}





////////////////////////////////////////////////////////////////////////////////////////////////////////
const ElementFormAnswerDetails = ({ element, functions, ticket, t, uploadingState, userData, ticketType }) => {
	return (
		<>
			<Card className="mt-3 border-info">
				<Card.Header className="bg-info text-light">
					<Card.Title>
						{t('ticket.labels.answer')}
					</Card.Title>
				</Card.Header>
				<Card.Body className="pb-0">
					<Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
						<Form.Label column sm="2">{t('ticket.labels.message')}</Form.Label>
						<Col sm="8">
							<Form.Control as="textarea" rows="3" name="message" defaultValue={element.message} onChange={(event) => functions.edit(event.currentTarget)} className="mb-2" />
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="border-bottom">
						<Form.Label column sm="2">{t('ticket.labels.media')}</Form.Label>
						<Col>
							<Form.Control type="file" name="file" onChange={(event) => functions.edit(event.target)} />
						</Col>
						<Col>
							{element.file
								?
								<>
									<Image style={{ height: '50px' }} src={element.file} fluid />
									<Button variant="outline-danger" onClick={(event) => functions.edit({ name: 'logo', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
								</>
								: ''}
						</Col>
					</Form.Group>
					{uploadingState > -1 ?
						<Row>
							<Col>
								<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
							</Col>
						</Row>
						: <></>}
					{/* {ticketType === 'request' ?
						<>
							<TicketRequestSection element={element} t={t} functions={functions} ticket={ticket} />
						</>
						: ''} */}
				</Card.Body>
				<Card.Footer>
					<Container fluid>
						<Row>
							<Col><i>{format(myTimestampToDate(element.createdAt), 'dd/MM/yyyy HH:mm')}</i></Col>
							{userData ?
								<Col className="text-right">
									<i>
										inviato da: {userData.isGlobalAdmin ? 'admin' : userData.displayName}
									</i>
								</Col>
								: ''}
						</Row>
					</Container>
				</Card.Footer>
			</Card>
		</>
	)
}



const TicketRequestSection = ({ element, t, functions, ticket }) => {
	const { contract } = useContext(GlobalDataContext)
	const payment_mode = contract && contract.serviceamounthour_list ? contract.serviceamounthour_list.filter(s => s.service && s.service.id === ticket.service.id) : []

	console.log(ticket.service, contract.serviceamounthour_list, payment_mode)

	return (
		<>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('ticket.labels.provided_by_contract')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="provided_by_contract" value={true} checked={element.provided_by_contract} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="provided_by_contract" value={false} checked={!element.provided_by_contract} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			{!element.provided_by_contract && payment_mode.length === 0 ?
				<Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
					<Form.Label column sm="2">Ore effettuate</Form.Label>
					<Col sm="8">
						<Form.Control type="number" min={0} step={0.5} name="hours_performed" defaultValue={element.hours_performed} onChange={(event) => functions.edit(event.currentTarget)} className="mb-2" />
					</Col>
				</Form.Group>
				: ''}
			{/* 			{payment_mode.length>0?
				<Form.Group as={Row} className="border-bottom mb-1">
					<Form.Label column sm="2">{payment_mode_dict[payment_mode[0].payment_mode].label_unit} effettuati/e</Form.Label>
					<Col sm="8">
						<Form.Control type="number" min={0} step={0.5} name="quantity_performed" defaultValue={element.quantity_performed} onChange={(event) => functions.edit(event.currentTarget)} className="mb-2" />
					</Col>
				</Form.Group>
				: ''} */}
		</>
	)
}



const ElementFormDoneSection = () => {
	return (
		<Row className="bg-success">
			<Col className="text-center">
			<FontAwesomeIcon icon={"check-square"} className='text-light' />Eseguito
			</Col>
		</Row>
	)
}



export default translate()(ModalTicketAnswer)