
import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { differenceInMonths } from 'date-fns'


import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InterventionreportProvider } from '../components/contexts/interventionreportContext.js';
import { InitContractHelper } from "../util/contract"


import { month_list } from '../components/constants/global_definitions';
import { myTimestampToDate, compareName, compareStartDateParam, getStructureFromWorkareas } from '../util/ui_utils.js';

import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import Footer from "../components/Footer"

import { ModalStructureInterventionreport } from '../components/modal/ModalStructureInterventionReport';

import { getInterventionLogList } from '../Firebase2/Storage2/dbInterventionLog';
import { getInterventionById as getInterventionById2 } from '../Firebase2/Storage2/dbIntervention'
import { getWorkareaList as getWorkareaList2 } from '../Firebase2/Storage2/dbWorkarea';
import { getInterventionReportValidationRange } from '../Firebase2/Storage2/dbInterventionReportValidation.js';
import { getEmployerList } from '../Firebase2/Storage2/dbEmployers';


import firebase from 'firebase/app'
import { getCleaningCompanyList } from '../Firebase2/Storage2/dbCleaningCompany';

let load_page = false
const popoverTiming = { show: 250, hide: 100 }
let curdate = { start: new Date(), end: new Date() }


let department_dict = {}
let used_service_list = []
let intervention_dict = {}





const InterventionreportView = ({ t, uData }) => {
	return (
		<InterventionreportProvider>
			<InterventionreportViewContent t={t} uData={uData} />
		</InterventionreportProvider>
	)
}
const isDecUser = (uData) => {
	if (uData && (uData.id === 'ceEQOwsWiFX8UENX2FJrw8Pq4EX2' || uData.isGlobalAdmin)) {
		return true
	}
	return false
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const InterventionreportViewContent = ({ t, uData }) => {
	const { domain } = useContext(DomainContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict, all_workarea_dict } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	let { cid } = useParams();
	let contractId = cid
	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(false)
	const [structure_list, setStructureList] = useState([])
	const [employer_dict, setEmployerDict] = useState({})
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)

	const now = new Date()
	const [selectedYear, setSelectedYear] = useState(now.getFullYear())

	const getContractMonths = () => {
		if (contract) {

			const value = parseInt(differenceInMonths(myTimestampToDate(contract.endDate), myTimestampToDate(contract.startDate))) + 1
			let value_ext = 0
			if (contract.extension_month && contract.extension_month_active) {
				value_ext = parseInt(contract.extension_month)
			}
			return [value, value_ext]
		} else {
			return [0, 0]
		}
	}


	const initValues = async () => {
		const userData = uData
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('samplingplan') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		load_page = true

		let temp_intervention_dict = { ...intervention_dict }
		let temp_department_dict = { ...department_dict }
		let temp_service_list = [...used_service_list]
		//console.log(temp_department_dict)
		//    console.log(temp_intervention_dict)
		let event_list = []

		const contract_month = getContractMonths()
		let _structure_list = structureElements.structure_list
		let permission_department_list = []
		let permission_structure_list = []
		let permission_workarea_list = []
		const _start_date = firebase.firestore.Timestamp.fromDate(new Date(selectedYear, 0, 1))
		const _end_date = firebase.firestore.Timestamp.fromDate(new Date(selectedYear, 11, 31))
		if (userData && userData.contract_dict && userData.contract_dict[contractId]) {
			console.log('userData contract', userData.contract_dict[contractId])
			if (userData.contract_dict[contractId][0].mode === 'workarea') {
				permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
				permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
				console.log("permission_structure_list", permission_structure_list)
				_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
			} else {
				if (userData.contract_dict[contractId][0].structure) {
					if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
						permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
						console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
					}
					if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
						permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
						_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

					}
					console.log("permission structure_list", permission_structure_list)
				}
			}
		}
		let db_workarea_list = await getWorkareaList2(domain, contractId)

		if (!userData.isAdmin) {
			if (permission_workarea_list.length > 0) {
				db_workarea_list = db_workarea_list.filter(w => permission_workarea_list.indexOf(w.id) >= 0)
			} else if (permission_structure_list.length > 0) {
				db_workarea_list = db_workarea_list.filter(w => w.structure_list.some(d => permission_structure_list.indexOf(d) !== -1))
				console.log("FILTERED structure", db_workarea_list)
				if (permission_department_list.length > 0) {
					db_workarea_list = db_workarea_list.filter(w => (w.room_list.length === 0 || w.department_list.some(d => permission_department_list.indexOf(d) !== -1)))

				}
			}
		}
		try {
			db_workarea_list.sort(compareName)
		} catch (err) {

		}
		/* 		let return_data = await getEventRange(domain, contractId, _start_date, _end_date)
				console.log(return_data)
				return_data = return_data.filter(e => (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention') && (filterAppliedList.is_deleted || !e.extendedProps.is_deleted)) */
		if (contract.execution_mode === 'claudittrace') {
			console.log("CLAUDITRACE")
			const ev_status_list = await getInterventionLogList(domain, contractId, null, null, _start_date, _end_date)
			for (const ev of ev_status_list) {
//				console.log("EV", ev)
				const intervention = temp_intervention_dict[ev.intervention_id] || await getInterventionById2(domain, contractId, ev.intervention_id)
	//			console.log("intervention", intervention)
				if (intervention) {
					ev.intervention_data = intervention
					temp_intervention_dict[ev.intervention_id] = intervention
				}
			}
			console.log(ev_status_list)
			const el_list = await getEmployerList(domain, contractId, {  })
			console.log("EMPLOYER_LIST_", el_list)
			const em_dict = Object.assign({},...el_list.map((x) => ({[x.id]: x})))

			console.log("EMP DICT", em_dict)
			setEmployerDict(em_dict)

		}
		console.log('RETURN DATA filtered', event_list)
		used_service_list = temp_service_list
		department_dict = temp_department_dict
		intervention_dict = temp_intervention_dict
		event_list = event_list.sort(compareStartDateParam)

		for (let i = 0; i < _structure_list.length; i++) {
			//console.log("**** interventionreport month list", return_data, _structure_list[i].id)
			_structure_list[i].interventionreport_summary_list = event_list.filter(e => e.extendedProps.structure_list.indexOf(_structure_list[i].id) >= 0)
			_structure_list[i].workarea_list = db_workarea_list.filter(w => w.structure_list.indexOf(_structure_list[i].id) >= 0)
			if (_structure_list[i].workarea_list.filter(filterReferent).length > 0) {
//				console.log('ONE REFERENT', _structure_list[i].name, _structure_list[i].workarea_list)
			}
		}
		console.log("- preST", uData.id, _structure_list)
		/* 		if (!isDecUser(uData)) {
					_structure_list = _structure_list.filter(s => (s.workarea_list.filter(filterReferent).length > 0))
				} */

		for (let i = 0; i < _structure_list.length; i++) {
			for (const w in _structure_list[i].workarea_list) {
				const workarea = _structure_list[i].workarea_list[w]
				let return_data = await getInterventionReportValidationRange(domain, contractId, _start_date, _end_date, workarea.id)
//				console.log("validation_data", i, return_data)
				_structure_list[i].workarea_list[w].validation_list = return_data
			}
		}
		console.log("-- ST", uData.id, _structure_list)
		setStructureList(_structure_list)
		setUiList({ ...ui_list, risk_area_list, risk_area_dict, roomtype_list, roomtype_dict, contract_month })
		setLoading(false)

	}


	const filterReferent = (w) => {
		console.log("filter referent", w.id, w.name, w.referent_list)
		if (isDecUser || (w.referen_list && w.referent_list.filter(r => r.user_id === uData.id).length > 0)) {
			console.log("\tHAS REF")
			return true
		} else {
			return false
		}
	}


	useEffect(() => {
		load_page = false
		async function loadData() {
			let contractId = cid
			console.log("MONTH INTERVENTIONREPORT reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("MONTH INTERVENTIONREPORT init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
		return () => {
			load_page = false
		}
	}, [])


	useEffect(() => {
		console.log('MONTH INTERVENTIONREPORT change contract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])



	const updateStructureInterventionreport = (_structure_list) => {
		setStructureList(_structure_list)
	}
	useEffect(() => {
		console.log(selectedYear)
		if (contract) {
			initValues()
		}

	}, [selectedYear])

	const changeYear = (cc) => {
		setSelectedYear(cc)
	}


	const YearSelector = () => {
		const startyear = myTimestampToDate(contract.startDate).getFullYear()
		const endyear = myTimestampToDate(contract.endtDate).getFullYear()
		let curyear = startyear
		let yearlist = []
		while (curyear <= endyear) {
			yearlist.push(curyear)
			curyear = curyear + 1
		}
		return (
			<ButtonGroup>
				{yearlist.map((y, k) => (

					<Button variant={y === selectedYear ? 'info' : 'outline-info'} onClick={() => changeYear(y)} key={k} >{y}</Button>
				))}
			</ButtonGroup>
		)

	}


	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />



			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>

						<PageTitle title={t('global.pages.interventionreport_report')} />
					</Col>
					{!loading && contract ?
						<Col>
							<YearSelector />
						</Col>
						: ''}
				</Row>
				{loading && !contract ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>

						</Col>
					</Row>
					:
					<>
						{structure_list && structure_list.length > 0 ?
							<Row className="mb-1">
								<Col className="bg-secondary ml-1 text-light" sm="2">
									{t('global.labels.name')} ({t('structure.labels.structure')}/{t('workareas.labels.workarea')})
								</Col>
								<Col>
									<Row>
										{month_list.map((m, mk) => (

											<Col key={mk} className="bg-secondary ml-1 text-light" >
												{m.label}
											</Col>
										))}
									</Row>
								</Col>
							</Row>
							: ''}
					</>
				}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col>
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					</Row>
					:
					<Row className="mt-1">
						{structure_list && structure_list.length > 0 ?
							<Col>
								{structure_list.map((s, key) => (
									<Row className="mb-1 pb-1 border-bottom border-info" key={key}>
										<Col>
											<Row className="bg-light border-bottom border-secondary mb-1 pb-1 ">
												<Col className="font-weight-bold" sm="2">
													{s.name} {selectedYear}
												</Col>
												<Col>
													<Row>
														{month_list.map((m, mk) => (
															<Col key={mk}>
																<ModalStructureInterventionreport t={t} item={s} structure={s} item_list={structure_list} month={m} year={selectedYear} ui_list={ui_list} canWrite={canWrite} setItem={updateStructureInterventionreport} uData={uData} employer_dict={employer_dict}/>
															</Col>
														))}
													</Row>
												</Col>

											</Row>
											{s.workarea_list ?
												<>
													{
														//s.workarea_list.filter(w => (isDecUser(uData) ||  (w.referent_list && w.referent_list.filter(r => r.user_id === uData.id).length > 0))).map((w, wk) => (
														s.workarea_list.map((w, wk) => (
															<Row key={`${key}_${wk}`} className="element_row">
																<Col sm="2">
																	<FontAwesomeIcon icon="circle" className="small" /> {w.name}
																</Col>
																<Col>
																	<Row>
																		{month_list.map((m, mk) => (
																			<Col key={mk}>
																				<ModalStructureInterventionreport t={t} item={s} structure={s} item_list={structure_list} month={m} year={selectedYear} ui_list={ui_list} canWrite={canWrite} workarea={w}
																					setItem={updateStructureInterventionreport} uData={uData} employer_dict={employer_dict}/>
																			</Col>
																		))}
																	</Row>
																</Col>
															</Row>

														))}
												</>
												:
												<>
													<i>nessuna area di lavoro</i>
												</>}


										</Col>
									</Row>
								))}
							</Col>
							:
							<Col className="font-italic">
								{t('structure.labels.no_structure')}
							</Col>
						}
					</Row>

				}

			</Container>
			<Footer />
		</>
	);
}

export default translate()(InterventionreportView)