import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab, ButtonGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import DateTime from 'react-datetime';
import { useParams } from "react-router-dom"

import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';


import ModalBaseObject from "../components/ModalBaseObject"
import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"

import { myTimestampToDate, getSelectedElement, checkComponentPresence, makeid, myDateToTimestamp } from "../util/ui_utils.js"
import { ModalInitButton } from '../components/StructureGraphicElements.js'


import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { addCustomerSatisfaction, editCustomerSatisfaction } from '../Firebase2/Storage2/dbCustomerSatisfaction';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';


import firebase from 'firebase/app'
import { CustomerAnswerList } from './CustomerAnswerList.js';

//const contractId = sessionStorage.getItem('contractId')


////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalCustomerSatisfaction({ item, setItem, t, is_new, small, item_list, index, canWrite }) {
	const { domain } = useContext(DomainContext)

	let { cid } = useParams();
	let contractId = cid


	const childRefAlert = useRef()
	const newcustomersatisfaction = {
		code: '',
		note: '',
		question_list: [],
		structure_list: [],
		startDate: firebase.firestore.Timestamp.now(),
		endDate: firebase.firestore.Timestamp.now(),
		creation_date: firebase.firestore.Timestamp.now(),
	}
	const initValues = async () => {
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newcustomersatisfaction)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newcustomersatisfaction.media_list = []
		setCurrentElement(newcustomersatisfaction)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addCustomerSatisfaction(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data = await editCustomerSatisfaction(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('customersatisfaction.buttons.customersatisfaction_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<AlertSection ref={childRefAlert} />
			<Modal show={showEdit} onHide={handleClose} dialogClassName="custom-modal" backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('customersatisfactions.labels.new_customersatisfaction')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('customersatisfactions.labels.edit_customersatisfaction')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('customersatisfactions.labels.view_customersatisfaction')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('global.labels.details')} </Nav.Link>
											<Nav.Link variant="secondary" eventKey="tabQuestionList">{t('global.labels.question_list')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabQuestionList">
											<ElementQuestionList element={curElement} t={t} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ElementFormDetails({ element, functions, ui_list, t, canWrite }) {
	const { locale } = useContext(LocaleContext)
	const { structureElements } = useContext(GlobalDataContext)

	return (
		<>
			<Form.Group as={Row} controlId="customersatisfactionName" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="customersatisfactionCode" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="eventStart" className="border-bottom">
				<Form.Label column sm="2">{t('contract.labels.length')}</Form.Label>
				<Col sm="3">
					<DateTime initialValue={myTimestampToDate(element.startDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'startDate', value: myDateToTimestamp(e) })} />
					<Form.Text className="text-muted">
						{t('contract.labels.start')}
					</Form.Text>
				</Col>
				<Col sm="3">
					<DateTime initialValue={myTimestampToDate(element.endDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="endDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'endDate', value: myDateToTimestamp(e) })} />
					<Form.Text className="text-muted">
						{t('contract.labels.end')}
					</Form.Text>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEmployeeLevel" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.structures')}</Form.Label>
				<Col>
					<Form.Group as={Row} className="pb-1">
						{structureElements.structure_list.map((a, k) => (
							<Col key={k} sm="3" className={element.structure_list && element.structure_list.indexOf(a.id) >= 0 ? "m-1 bg-info text-light" : ' m-1 bg-light'}>
								<Form.Check key={k} type="checkbox" inline name="structure_list" checked={element.structure_list && element.structure_list.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
							</Col>
						))}
					</Form.Group>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="customersatisfactionNote" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}






////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementQuestionList({ element, functions, t, global_section, summary_data, preview, canWrite, res_data }) {
	const newquestion = {
		id: makeid(5),
		question: '',
		coefficient: 1,
		category:'',
		question_type: 'radio',
		answer_list: [],
	}


	const [showQuestionEdit, setShowProcessEdit] = useState(false)
	const [is_new, setNewProcessEdit] = useState(true)
	const [curQuestion, setCurQuestion] = useState(newquestion)
	const [editedElement, setEditedElement] = useState(false)
	//----process
	const handleCloseQuestion = () => setShowProcessEdit(false);
	const handleSaveQuestion = () => {
		console.log('----save', is_new, curQuestion)
		setShowProcessEdit(false);
		if (is_new) {
			let editlist = element.question_list || []
			editlist.push(curQuestion)
			functions.edit({ name: 'question_list', value: editlist })
			setEditedElement(false)
		} else {
			let editlist = element.question_list || []
			const c = checkComponentPresence(curQuestion, element.question_list, 'id')
			if (c >= 0) {
				editlist.splice(c, 1, curQuestion)
			}
			functions.edit({ name: 'question_list', value: editlist })
			setEditedElement(false)
		}
	}

	const handleDeleteQuestion = (el, index) => {
		let editlist = [...element.question_list]
		const c = checkComponentPresence(el, editlist, 'id')
		if (c >= 0) {
			editlist.splice(c, 1)
			functions.edit({ name: 'question_list', value: editlist })
		}
	}

	const handleShowNewQuestion = () => {
		setCurQuestion(newquestion)
		setNewProcessEdit(true)
		setShowProcessEdit(true)
		setEditedElement(false)
	}

	const handleShowEditQuestion = (i, index) => {
		console.log('edit', i, index)
		i.index = index
		setCurQuestion(i)
		setNewProcessEdit(false)
		setShowProcessEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerQuestion = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curQuestion[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurQuestion({ ...curQuestion, [name]: value })
		setEditedElement(true)
	}



	const changeQuestionType = (el) => {
		onChangeHandlerQuestion(el)
	}







	// =====================================================================================================================================
	return (
		<>
			<Container fluid>
				<Row>
					<Col className="text-primary h5">
					</Col>
					{!preview && canWrite ?
						<Col className="text-right">
							<ButtonGroup>
								<Button variant="outline-primary" size="sm" onClick={() => handleShowNewQuestion()}><FontAwesomeIcon icon="plus-square" />{t('customersatisfaction.labels.question_add')}</Button>
							</ButtonGroup>
						</Col>
						: ''}
				</Row>

				<Row >
					{element.question_list && element.question_list.length > 0 ?
						<Col>
							<Row >
								<Col className="bg-secondary text-light ml-1">{t('global.labels.description')} </Col>
								<Col className="bg-secondary text-light ml-1 p-1 text-center" sm="1">{t('customersatisfaction.labels.weight_coefficient_short')} </Col>
								<Col className="bg-secondary text-light ml-1 p-1 text-center" sm="3">{t('customersatisfaction.labels.category')} </Col>
								<Col className="bg-secondary text-light ml-1">{t('customersatisfaction.labels.answer_list')} </Col>
								<Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
							</Row>
							{element.question_list.map((q, key) => (
								<Row key={key} className="border-bottom mb-1 pt-1">
									<Col className="font-weight-bold">{q.question}</Col>
									<Col sm="1">{q.coefficient}</Col>
									<Col sm="3">{q.category}</Col>
									<Col>
										{q.answer_list.length > 0 ?
											<>
												<Row className="bg-light section_title mb-1">
													<Col> {t('customersatisfaction.labels.answer')} </Col>
													<Col> {t('customersatisfaction.labels.icon_list')} </Col>
												</Row>
												{q.answer_list.map((a, key) => (
													<Row key={key} className="border-bottom mb-1 pt-1">
														<Col className="font-weight-bold">{a.answer}</Col>
														<Col>
															<Row>
																{a.icon_list.map((i, key) => (
																	<Col key={key} sm="1">
																		<FontAwesomeIcon icon={i} />
																	</Col>
																))}
															</Row>
														</Col>
													</Row>))
												}
											</>
											: <i>{t('customersatisfaction.messages.no_answer')}</i>
										}
									</Col>
									{canWrite ?
										<Col className="text-center" sm="1">
											<ButtonGroup>
												<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditQuestion(q, key)} /> </Button>
												<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteQuestion(q, key)} /> </Button>
											</ButtonGroup>
										</Col>
										: ''}
								</Row>))
							}
						</Col>
						: <Col className="text-muted"><i>{preview ? '' : <> {t('customersatisfaction.messages.no_question')}</>}</i></Col>
					}
				</Row>

			</Container>





			<Modal show={showQuestionEdit} onHide={handleCloseQuestion} size="xl" className="bg-secondary" backdrop="static">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('customersatisfaction.labels.new_question')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('customersatisfaction.labels.edit_question')}</span>
							</>
						}
						&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('customersatisfaction.labels.question')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="question" value={curQuestion.question} onChange={(event) => onChangeHandlerQuestion(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('customersatisfaction.labels.category')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="category" value={curQuestion.category} onChange={(event) => onChangeHandlerQuestion(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('workarea.labels.weight_coefficient')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="coefficient" value={curQuestion.coefficient} onChange={(event) => onChangeHandlerQuestion(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('customersatisfaction.labels.question_type')}</Form.Label>
							<Col>
								<Form.Check type="radio" name="question_type" value="radio" checked={(curQuestion && curQuestion.question_type === 'radio')} label={t('customersatisfaction.labels.radio')} onChange={(event) => changeQuestionType(event.currentTarget)} />
								<Form.Check type="radio" name="question_type" value="multi" checked={(curQuestion && curQuestion.question_type === 'multi')} label={t('customersatisfaction.labels.multi')} onChange={(event) => changeQuestionType(event.currentTarget)} />
							</Col>
						</Form.Group>
						<CustomerAnswerList functions={{ edit: onChangeHandlerQuestion }} element={curQuestion} t={t} />
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseQuestion}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveQuestion}>
							<FontAwesomeIcon icon="save"></FontAwesomeIcon> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>

		</>
	)
}


export default translate()(ModalCustomerSatisfaction)