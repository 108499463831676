import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  Modal, Container, Image, Row, Col, Button, Spinner, Card } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useTranslate } from 'react-polyglot';
import { format } from 'date-fns'
import { useParams } from "react-router-dom"

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';


import { InitContractHelper } from '../util/contract.js';


import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"


import { myTimestampToDate, getValue } from "../util/ui_utils.js"


import { editContract as editContract2 } from '../Firebase2/Storage2/dbContract.js';
import { mediaType_dict } from '../components/constants/global_definitions.js';
import { ModalDocument } from '../components/modal/ModalDocumentPreview.js';





let load_contract = true


const section = 'contract'

////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
////////////////////////////////////////////////////////////////////////////////////////////////////////
const DocumentsContractView = ({ t, uData }) => {

    const { domain } = useContext(DomainContext)
    const { contract } = useContext(GlobalDataContext)

    const childRefAlert = useRef()
    const { initContract } = InitContractHelper()
	let { cid } = useParams();
	let contractId = cid

    const [loading, setLoading] = useState(true)

    const [showEdit, setShow] = useState(false);
    const [curElement, setCurrentElement] = useState(sessionStorage.getItem('contract'))
    const [canWrite, setWritePage] = useState(false)

    
    const handleShowNew = () => {
        setShow(true)
    }

    const handleClose = () => setShow(false);

    useEffect(() => {
        if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('documentscontract') !== -1)) {
            console.log("======can write")
            setWritePage(true)
        }
        setLoading(true)
        async function fetchData() {
            const ret_data = await initContract(contractId)
            console.log("all ok post")
            setLoading(false)
        }
        if (!contract) {
            console.log("need contract")
            load_contract = false
            if (contractId && contractId !== null) {
                console.log("load contract data")
                fetchData()
            }
        } else {
            console.log("all ok")
            setLoading(false)
        }
    }, [])

    useEffect(() => {

        console.log("has contract")
        if (contract && !load_contract) {
            console.log("preload contract data")
            load_contract = true
        }

    }, [contract])




    const onChangeHandler = async (el) => {
        console.log(el)
        let { name, value } = el
        setCurrentElement({ ...curElement, [name]: value })
        let return_data =  await editContract2(domain, curElement)
        console.log(return_data)
    }

    const getElementCount  =(t) =>{

        if (contract && contract.media_list){
		    return (<>{contract.media_list.filter((f => f.type !== 'image')).length} {t('global.labels.documents')}</>)
        }else{
            return (<>0 {t('global.labels.documents')}</> )
        }
    }

    return (
        <>
            <AlertSection ref={childRefAlert} />
            <Container className="titleStickyTop" fluid>
                <Row className="mt-1">
                    <Col className="h3">
                        <PageTitle title={t('global.pages.contractdocument_management')} />
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                        {canWrite ?
                            <Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('documents.buttons.add_new')}</Button>
                            : ''}
                    </Col>
                </Row>

            </Container>
            <Container fluid>

                {loading ?
                    <Row>
                        <Col>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    :


                    <Row>{contract  && contract.media_list?
                        <>
                            {contract.media_list.filter((f => f.type !== 'image')).map((f, d_index) => (
                                <OneFile doc={f} key={d_index} />
                            ))}
                        </>
                        : <i>Nessun documento presente</i>}
                    </Row>
                }
            </Container>
            <Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount( t)} />




            <Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
                <Modal.Header closeButton  >
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <ElementFormMedia element={contract} t={t} functions={{ edit: onChangeHandler }} section={section} canWrite={canWrite}/>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}





const OneFile = ({ doc }) => {
    const { locale } = useContext(LocaleContext)
    const t = useTranslate();
    const type_dict = mediaType_dict({t, section})
    const getLabel = (doc) => {
        try {
            return type_dict[doc.type].label
        } catch (err) {
            return doc.type
        }
    }

    return (
        <Col sm="6" md="4" xl="2" className="mb-2">
            <Card border="primary" className="h-100">
                {doc.type === 'image' ?
                    <span className="d-flex" style={{ maxHeight: '150px' }}>
                        <Image className="imageDocumentViewer" src={doc.url} />
                    </span>
                    : <Card.Header> {doc.description.length > 0 ? doc.description : <i>{doc.source_name}</i>}</Card.Header>
                }
                <Card.Body>
                    <h3>{doc.name}</h3>
                    <Row>
                        <Col className="text-secondary">{t('documents.labels.' + doc.section)}: {doc.item_reference && doc.item_reference.category ? <>{getValue(doc.item_reference.category.name, locale)}</> : ''}</Col>
                    </Row>
                    <Row>
                        <Col>
                            {doc.description}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {getLabel(doc)}
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col >
                            <small className="text-muted">data inserimento: {doc.tracker && doc.tracker.insered_at ? format(myTimestampToDate(doc.tracker.insered_at), 'dd/MM/yyyy HH:mm') : "non disponibile"}</small>
                        </Col>
                        <Col className="text-right" sm="3">
                            {console.log(doc)}
									<ModalDocument t={t} photo={doc.url} name={doc.tracker.source_name} />
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Col>


    )

}


export default translate()(DocumentsContractView)