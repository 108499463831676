import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab, ButtonGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
import ReactFlow, {
	ReactFlowProvider,
	removeElements,
	addEdge,
	useZoomPanHelper,
	Handle,
	Controls, MiniMap, Background, isNode
} from 'react-flow-renderer';

import LocaleContext from './contexts/locale.js'
import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js'
import { useReactToPrint } from 'react-to-print';


import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"
import PhoneList from "../components/PhoneList.js"

import { getSelectedElement, checkComponentPresence, getValue, makeid, rgba2hex, getBaseObjectValue, myDateToTimestamp } from "../util/ui_utils.js"
import { ModalInitButton } from '../components/StructureGraphicElements.js'


import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { addOrgChart, editOrgChart } from '../Firebase2/Storage2/dbOrganizationChart';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';
import PrintFooter from './generic/PrintFooter.js';




const flowKey = 'example-flow';

const getNodeId = () => `randomnode_${+new Date()}`;

const initialElements = [
];


const CustomEdge = ({
	sourceX,
	sourceY,
	sourcePosition,
	targetX,
	targetY,
	targetPosition,
	connectionLineType,
	connectionLineStyle,
}) => {
	return (
		<g>
			<path
				fill="none"
				stroke="#222"
				strokeWidth={1}
				className="animated"
				d={`M${sourceX},${sourceY} C ${sourceX} ${targetY} ${sourceX} ${targetY} ${targetX},${targetY}`}
			/>
			<circle cx={targetX} cy={targetY} fill="#fff" r={3} stroke="#222" strokeWidth={1.5} />
		</g>
	);
};


const SquaredNodeComponent = ({ data }) => {
	return (
		<div className="node-inner" style={{ ...customNodeStyles, backgroundColor: data.color, color: data.text_color }}>
			<Handle type="target" position="top" style={{ ...triangleStyles.down }} />
			<div className="font-weight-bold">{data.label}</div>
			<div>{data.sub_label}</div>
			<Handle
				type="target"
				position="right"
				id="a"
				style={{ top: 15, ...triangleStyles.left }}
			/>
			<Handle
				type="target"
				position="left"
				id="b"
				style={{ top: 15, ...triangleStyles.right }}
			/>
			<Handle
				type="source"
				position="right"
				id="a2"
				style={{ top: 25, ...triangleStyles.right }}
			/>
			<Handle
				type="source"
				position="left"
				id="b2"
				style={{ top: 25, ...triangleStyles.left }}
			/>
			<Handle
				type="source"
				position="bottom"
				id="c"
				style={{ ...triangleStyles.down }}
			/>
		</div>
	);
};

const RoundedNodeComponent = ({ data }) => {
	{ console.log("NODE DATA", data) }
	return (
		<div className="node-inner" style={{ ...customNodeRoundedStyles, backgroundColor: data.color, color: data.text_color }}>
			<Handle type="target" position="top" style={{ ...triangleStyles.down }} />
			<div className="font-weight-bold">{data.label}</div>
			<div>{data.sub_label}</div>
			<Handle
				type="target"
				position="right"
				id="a"
				style={{ top: 15, ...triangleStyles.left }}
			/>
			<Handle
				type="target"
				position="left"
				id="b"
				style={{ top: 15, ...triangleStyles.right }}
			/>
			<Handle
				type="source"
				position="right"
				id="a2"
				style={{ top: 25, ...triangleStyles.right }}
			/>
			<Handle
				type="source"
				position="left"
				id="b2"
				style={{ top: 25, ...triangleStyles.left }}
			/>
			<Handle
				type="source"
				position="bottom"
				id="c"
				style={{ borderRadius: 10, ...triangleStyles.down }}
			/>
		</div>
	);
};
const nodeTypes = {
	rounded: RoundedNodeComponent,
	squared: SquaredNodeComponent,
};
const edgeTypes = {
	custom: CustomEdge
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalOrganizationChart({ item, setItem, t, is_new, small, item_list, index, canWrite }) {
	const { domain } = useContext(DomainContext)
	const { contractId, contract } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const neworganizationchart = {
		name: '',
		code: '',
		data: {}
	}
	const initValues = async () => {
	}

	const componentRef = useRef();


	const [flowinstance, setInstance] = useState()
	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(neworganizationchart)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)
	const [selectedElement, setSelectedElement] = useState()

	const handleShowNew = () => {
		neworganizationchart.media_list = []
		setCurrentElement(neworganizationchart)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setElements(item.data)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])


	const onNodeDragEnd = (ev, node) => {
		console.log(ev, node)
		let edit_list = [...elements]
		const c = checkComponentPresence(node, edit_list, 'id')
		edit_list.splice(c, 1, node)
		setEditedElement(true)
		setElements(edit_list)
	}

	const handleClose = () => setShow(false);

	const handleSave = async (archive) => {
		console.log("____________________________________SAVE_____________________________", is_new)
		curElement.data = elements
		if (is_new) {
			let return_data = await addOrgChart(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			console.log(domain, archive, contractId, curElement)
			if (archive) {
				curElement.archive_date = myDateToTimestamp(new Date())
				curElement.is_archived = true
				let return_data_arch = await editOrgChart(domain, contractId, curElement.id, curElement)
				delete curElement.archive_date
				delete curElement.is_archived
				const old_id = curElement.id
				const c = checkComponentPresence(curElement, item_list, 'id')
				curElement.parent_element_id = curElement.parent_element_id || curElement.id
				delete curElement.id
				let return_data = await addOrgChart(domain, contractId, curElement)
				console.log("edit", return_data)
				if (return_data && return_data.error) {
					childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					console.log(item_list)
					let edit_list = [...item_list]
					edit_list.splice(c, 1, curElement)
					setItem(edit_list)
					setShow(false);
				}

			} else {
				let return_data = await editOrgChart(domain, contractId, curElement.id, curElement)
				console.log("edit", return_data)
				if (return_data && return_data.error) {
					childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {

					console.log(item_list)
					let edit_list = [...item_list]
					const c = checkComponentPresence(curElement, item_list, 'id')
					edit_list.splice(c, 1, curElement)
					setItem(edit_list)
					setShow(false);
				}
			}
		}
	}


	const onLoad = (reactFlowInstance) => {
		console.log('flow loaded:', reactFlowInstance);
		reactFlowInstance.fitView()
		setTimeout(function () {
			reactFlowInstance.zoomOut()
			reactFlowInstance.zoomOut()
		}, 500)
		setInstance(reactFlowInstance)
		setSelectedElement()
	}
	const [elements, setElements] = useState(initialElements);


	const onElementsRemove = (elementsToRemove) =>
		setElements((els) => removeElements(elementsToRemove, els));


	const onConnect = (params) => setElements((els) => addEdge({ ...params, type: 'step' }, els));

	const onSelect = (event, element) => {
		console.log(event)
		console.log(element)
		setSelectedElement(element)
	}

	const deleteElement = () => {
		let edit_list = [...elements]
		const c = checkComponentPresence(selectedElement, elements, 'id')
		edit_list.splice(c, 1)
		setElements(edit_list)
		setSelectedElement()
	}
	const clearAll = () => {
		setElements([])
		setSelectedElement()
	}

	const addNode = (element_list) => {
		setElements(element_list)
		setTimeout(function () {
			flowinstance.fitView()
			setTimeout(function () {

				flowinstance.zoomOut()
			}, 500)
		}, 500)
	}

	const onPaneClick = () => {
		setSelectedElement()

	}

	const handleZoomIn = () => {
		flowinstance.zoomIn()
	}

	const handleZoomOut = () => {
		flowinstance.zoomOut()
	}

	const handleFit = () => {
		flowinstance.fitView()
	}


	const handlePrint =
		useReactToPrint({
			onBeforePrint: () => {
				flowinstance.fitView()
				setTimeout(function () {
					flowinstance.zoomOut()

				}, 200)

			},
			content: () => componentRef.current,
			pageStyle: pageStyle
		});



	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('organizationchart.buttons.organizationchart_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} dialogClassName="custom-modal" backdrop="static" className="bg-secondary" scrollable={true}>
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('organizationcharts.labels.new_organizationchart')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('organizationcharts.labels.edit_organizationchart')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('organizationcharts.labels.view_organizationchart')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="organizationchartName" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="code" value={curElement.code} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="organizationchartName" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="name" value={curElement.name} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Row>
							{canWrite ?
								<Col>
									{selectedElement ?
										<ButtonGroup>
											{isNode(selectedElement) ?
												<ModalNode setItem={setElements} item={selectedElement} t={t} is_new={false} item_list={elements} canWrite={canWrite} />
												: ''}
											<Button variant="danger" onClick={deleteElement}><FontAwesomeIcon icon="trash" />Cancella selezionato</Button>
										</ButtonGroup>
										: ''}
								</Col>
								: ''}
							<Col>
								<ButtonGroup>
									<Button variant="outline-secondary" onClick={handleZoomIn}><FontAwesomeIcon icon="search-plus" /></Button>
									<Button variant="outline-secondary" onClick={handleZoomOut}><FontAwesomeIcon icon="search-minus" /></Button>
									<Button variant="outline-secondary" onClick={handleFit}><FontAwesomeIcon icon="vector-square" /></Button>
								</ButtonGroup>
							</Col>
							{canWrite ?
								<Col className="text-right">
									<ButtonGroup>

										<ModalImportElement t={t} item_list={elements} setItem={setElements} />
										<ModalNode setItem={addNode} t={t} is_new={true} item_list={elements} canWrite={canWrite} />
										<Button variant="outline-danger" onClick={clearAll}><FontAwesomeIcon icon="trash" />Cancella tutto</Button>
									</ButtonGroup>
								</Col>
								: ''}
						</Row>
					</Container>
					<div style={{ display: 'inline', width: 1920 }} ref={componentRef}>

						<ReactFlow
							elements={elements}
							onElementsRemove={onElementsRemove}
							onConnect={onConnect}
							onLoad={onLoad}
							onElementClick={onSelect}
							nodeTypes={nodeTypes}
							edgeTypes={edgeTypes}
							onPaneClick={onPaneClick}
							snapToGrid={true}
							zoomOnScroll={false}
							onNodeDragStop={onNodeDragEnd}
							snapGrid={[15, 15]}
							style={{ overflow: 'initial' }}
						>
							<Background color="#aaa" gap={16} />
						</ReactFlow>
						<PrintFooter />
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button variant="info" onClick={handlePrint}>
						<FontAwesomeIcon icon="print" /> {t('modal.print')}
					</Button>
					{canWrite ?
						<>
							<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSave}>
								<FontAwesomeIcon icon="save" /> {is_new ? t('modal.save') : t('modal.update')}
							</Button>
							{!is_new ?
								<Button variant={editedElement ? "warning" : "outline-primary"} onClick={() => handleSave(true)}>
									<FontAwesomeIcon icon="save" /> {t('modal.savenew')}
								</Button>
								: ''}
						</>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}




function ModalImportElement({ t, item_list, setItem }) {
	const { locale } = useContext(LocaleContext)
	const { contract } = useContext(GlobalDataContext)
	const [showEdit, setShow] = useState(false)
	const [import_list, setImportList] = useState([])
	const increment = 20

	const newnode = {
		id: makeid(6),
		type: 'squared',
		name: '',
		role: '',
		data: {
			label: '',
			sub_label: '',
			color: '#ffffff',
			text_color: '#000000'
		},
		color: '#ffffff',
		text_color: '#000000',
		position: { x: 0, y: 0 },
	}
	const handleImport = () => {
		let edit_list = [...item_list]
		for (const i of import_list) {
			const el = contract.managementgroup_list[i]
			console.log(i, el)
			const el_node = {
				...newnode,
				id: makeid(6),
				name: el.firstname + " " + el.lastname,
				role: getBaseObjectValue(el.role, 'name', locale),
				position: { x: i * increment, y: i * increment },
				data: {
					label: el.firstname + " " + el.lastname,
					sub_label: getBaseObjectValue(el.role, 'name', locale), color: '#ffffff', text_color: '#000000'
				}


			}
			edit_list.push(el_node)

		}
		setItem(edit_list)
		setShow(false)

	}

	const handleClose = () => {
		setShow(false)
	}

	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let oldcheck = [...import_list]
		if (el.checked) {
			if (oldcheck.indexOf(value) < 0) {
				oldcheck.push(value)
			}
		} else {
			if (oldcheck.indexOf(value) >= 0) {
				oldcheck.splice(oldcheck.indexOf(value), 1)
			}
		}
		setImportList(oldcheck)
	}

	const childRefAlert = useRef()
	return (
		<>
			<Button variant="outline-secondary" onClick={() => setShow(true)} >{t('nodes.labels.import_managementgroup')}</Button>
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton  >
					<Modal.Title>
						<FontAwesomeIcon icon="info" /> <span className="text-info">{t('nodes.labels.import_managementgroup')}</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AlertSection ref={childRefAlert} />
					<Container fluid>
						{contract && contract.managementgroup_list ?
							<>
								<Row>
									<Col>
										Scegli i contatti che vuoi inserire nell'organigramma
									</Col>
								</Row>
								{contract.managementgroup_list.map((c, k) => (
									<Row key={k} className="border-bottom mb-1 pb-1">
										<Col>
											<Form.Check type="checkbox" name={k} value={k} label={''} check={import_list.indexOf(k) >= 0} onChange={(event) => onChangeHandler(event.currentTarget)} />
										</Col>
										<Col>{c.firstname} {c.lastname}</Col>
										<Col>{console.log(c)} {getBaseObjectValue(c.role, 'name', locale)}</Col>
									</Row>
								))
								}</>
							:
							<i>Nessun contatto inserito nel gruppo gestionale del contratto</i>
						}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button form="companyForm" onClick={handleImport}>
						<FontAwesomeIcon icon="save" /> {t('modal.import')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)


}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalNode({ item, setItem, t, is_new, small, item_list, index, canWrite }) {
	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()
	const newnode = {
		id: makeid(6),
		type: 'squared',
		name: '',
		role: '',
		data: {
			label: '',
			sub_label: '',
			color: '#ffffff',
			text_color: '#000000'
		},
		color: '#ffffff',
		text_color: '#000000',
		position: { x: 0, y: 0 },
	}


	const initValues = async () => {
	}

	const [showEdit, setShow] = useState(false)
	const [displayColorPicker, setShowColorPicker] = useState(false)
	const [displayColorPickerB, setShowColorPickerB] = useState(false)
	const [curElement, setCurrentElement] = useState(newnode)
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newnode.media_list = []
		setCurrentElement(newnode)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		item.name = item.data.label
		item.role = item.data.sub_label
		item.color = item.data.color
		item.text_color = item.data.text_color
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}


	const handleChangeColor = (c, n) => {
		onChangeHandler({ name: n, value: c.hex })
	}
	const handleShowColorPicker = () => {
		setShowColorPicker(true)
	}
	const handleShowColorPickerB = () => {
		setShowColorPickerB(true)
	}
	const handleCloseColorPicker = () => {
		setShowColorPicker(false)
		setShowColorPickerB(false)
	}



	useEffect(() => {
		initValues()
	}, [])


	const styles = reactCSS({
		'default': {
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: curElement.color,
			},
			text_color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: curElement.text_color,
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				position: 'absolute',
				zIndex: '2',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		},
	});

	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new, curElement)
		let edit_list = [...item_list]
		const return_data = {
			...curElement,
			data: {
				label: curElement.name,
				sub_label: curElement.role,
				color: curElement.color,
				text_color: curElement.text_color
			}
		}
		if (is_new) {
			console.log(return_data)
			edit_list.push(return_data)
			setItem(edit_list)
			setShow(false);

		} else {
			const c = checkComponentPresence(return_data, item_list, 'id')
			edit_list.splice(c, 1, return_data)
			setItem(edit_list)
			setShow(false);
			/* 			let return_data = await editNode2(domain, contractId, curElement.id, curElement)
						console.log("edit", return_data)
						if (return_data && return_data.error) {
							childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
						} else {
							for (let m = 0; m < curElement.media_list.length; m++) {
								let editTrackData = curElement.media_list[m].tracker
								editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
								console.log("-----edit media----", editTrackData)
								let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
							}
							console.log(item_list)
							let edit_list = [...item_list]
							const c = checkComponentPresence(curElement, item_list, 'id')
							edit_list.splice(c, 1, curElement)
							setItem(edit_list)
							setShow(false);
						} */
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('node.buttons.node_add')} title_edit={"modifica selezionato"} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('nodes.labels.new_node')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('nodes.labels.edit_node')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('nodes.labels.view_node')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AlertSection ref={childRefAlert} />
					<Container fluid>
						<Form.Group as={Row} controlId="clothingName" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="name" value={curElement.name} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('global.labels.role')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="role" value={curElement.role} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.shape')}</Form.Label>
							<Col>
								<Form.Check type="radio" name="type" value="squared" checked={curElement.type === 'squared'} label={t('graph.labels.type_normal')} onChange={(event) => onChangeHandler(event.currentTarget)} />
								<Form.Check type="radio" name="type" value="rounded" checked={curElement.type === 'rounded'} label={t('graph.labels.type_rounded')} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Row>
							<Col sm="2">{t('graph.labels.background_color')}</Col>
							<Col>
								<Button variant="outline-info" onClick={handleShowColorPicker}>
									<div style={styles.color} />
								</Button>
								{displayColorPicker ? <div style={styles.popover}>
									<div style={styles.cover} onClick={handleCloseColorPicker} />
									<SketchPicker color={curElement.color} onChange={(c) => handleChangeColor(c, 'color')} onChangeComplete={(e) => onChangeHandler({ name: 'color', value: e.hex })} />
								</div> : null}
							</Col>
						</Row>
						<Row>
							<Col sm="2">{t('graph.labels.text_color')}</Col>
							<Col>
								<Button variant="outline-info" onClick={handleShowColorPickerB}>
									<div style={styles.text_color} />
								</Button>
								{displayColorPickerB ? <div style={styles.popover}>
									<div style={styles.cover} onClick={handleCloseColorPicker} />
									<SketchPicker color={curElement.text_color} onChange={(c) => handleChangeColor(c, 'text_color')} onChangeComplete={(e) => onChangeHandler({ name: 'text_color', value: e.hex })} />
								</div> : null}
							</Col>
						</Row>
						{/* 						<Form.Group as={Row} controlId="clothingCode" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="note" value={curElement.note} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group> */}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<>
							<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
								<FontAwesomeIcon icon="save" /> {t('modal.save')}
							</Button>
						</>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}

const customNodeStyles = {
	borderColor: '#000'
};
const customNodeRoundedStyles = {
	borderRadius: 20,
	borderColor: '#000'
};

const triangleStyles = {
	down: {
		width: 0,
		height: 0,
		borderLeft: '4px solid transparent',
		borderRight: '4px solid transparent',
		borderTop: '6px solid #555',
		background: 'transparent',
		borderBottom: '1px'
	},
	left: {
		width: 0,
		height: 0,
		borderTop: '4px solid transparent',
		borderRight: '6px solid #555',
		borderLeft: '1px',
		borderBottom: '4px solid transparent',
		background: 'transparent'
	},
	right: {
		width: 0,
		height: 0,
		borderTop: '4px solid transparent',
		borderRight: '1px',
		borderLeft: '6px solid #555',
		borderBottom: '4px solid transparent',
		background: 'transparent'
	}
}

const pageStyle = `
  @page {
    size: { size: landscape; };
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;


export default translate()(ModalOrganizationChart)