import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button, Card, Form, Spinner, ButtonGroup } from 'react-bootstrap';

import QRCode from 'qrcode.react'
import { useReactToPrint } from 'react-to-print';

import EditContext from "../contexts/edit.js"
import DomainContext from '../contexts/domainContext.js';
import LocaleContext from '../contexts/locale.js'
import GlobalDataContext from '../contexts/globaldataContext.js';


import { getBaseObjectValue, getValue } from "../../util/ui_utils.js"

import AlertSection from "../AlertSection.js"
import { RiskareaBadge, RoomInfoBadge } from '../StructureGraphicElements.js';
import { importFullStruct } from '../../Firebase2/Storage2/dbMassiveImport.js';
import ModalRoomType from '../ModalRoomType.js';


let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalImportRoomtypeAssign = ({ t, allData, disabled, onAssignRoomType, canWrite, ui_list }) => {
	const { updateRoomtype, roomtype_list, risk_area_dict } = useContext(GlobalDataContext)


	const [showModal, setShowModal] = useState(false)
	const [confirmData, setConfirmData] = useState()



	const setRoomTypes = async (roomtype_list) => {
		updateRoomtype(roomtype_list)

	}

	const handleShowConfirm = async () => {
		setConfirmData()
		setShowModal(true)
	}



	const handleCloseConfirm = () => {
		setShowModal(false)
	}


	const handleConfirm = async () => {
		onAssignRoomType(confirmData)
		setShowModal(false)
	}


	return (
		<>
			<Button variant={"info"} onClick={() => handleShowConfirm()} className="btn-block">
				<FontAwesomeIcon icon="play-circle" />
				Assegna tipologia
			</Button>
            <Modal show={showModal} onHide={handleCloseConfirm} dialogClassName="custom-modal" backdrop="static" className="bg-secondary" scrollable={true}>
				<Modal.Header closeButton >
					Assegna tipologia
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Row className="mb-1">
							<Col>
								{canWrite &&
									<ModalRoomType is_new={true} setItem={setRoomTypes} element_list={roomtype_list} canWrite={canWrite} ui_list={ui_list} />
								}
							</Col>
						</Row>
						<RtList confirmData={confirmData} setConfirmData={setConfirmData} />

					</Container>

				</Modal.Body>
				<Modal.Footer>
					<Container fluid>
						<Row className="h4">
							<Col className="text-right">
								<Button variant="secondary" onClick={handleCloseConfirm} className="mr-1">
									{t('modal.close')}
								</Button>
								<Button variant="info" onClick={handleConfirm} disabled={!confirmData} className="ml-1">
									<FontAwesomeIcon icon="play-circle" /> Assegna
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	)
}


const RtList = ({confirmData, setConfirmData}) => {
	const {roomtype_list, risk_area_dict} = useContext(GlobalDataContext)
	const section = [...new Set(roomtype_list.map(rt => rt.name[0]))]


	return (
		<>
		{
			section.map(s => (
				<Row ke={s} className="mb-1 pb-1 border-bottom">
						<Col sm="1">
						{s}
						</Col>
						<Col>
			{roomtype_list.filter(rt => rt.name[0] === s).map((rt, k) => (
				<Button key={k} className="mr-1 mb-1" size="sm" variant={confirmData && confirmData.id === rt.id ? "info" : "outline-info"} onClick={() => setConfirmData(rt)}>
					{rt.name}&nbsp;
					<RiskareaBadge riskarea={risk_area_dict[getValue(rt.risk_area, 'Id')]} />
				</Button>
			))}
						</Col>
					</Row>
			))
		}
		</>

	)

}