import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Badge, Container, ButtonGroup, Row, Col, Form, Button, Tab, Nav, OverlayTrigger, Spinner, Tooltip, Card } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import 'react-day-picker/lib/style.css';
import 'moment/locale/it';
import 'moment/locale/de';

import { processCheckSection_dict, materialIntendedUse_dict, timingMode_dict, processcontroltiming_dict } from "../components/constants/global_definitions"


import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';
//import EditContext, { EditProvider } from "../components/contexts/edit.js"
import { ControlEndSection } from "./CheckPreview"


import { ItemProgress, ServiceBadge, ContainerInfoPopover, ResultBadge } from '../components/StructureGraphicElements.js'
import { checkComponentPresence, rgba2hex, getTimeValue, compareName, getValue, getSelectedElement, compareOrder, makeid, getElementPreview, getFixedNumber, getBaseObjectValue, getFloatNumber } from "../util/ui_utils.js"


import AlertSection from "../components/AlertSection.js"
import { ElementFormNoncomplianceAction } from "../components/ElementFormNoncomplianceAction"



import { editIntervention as editIntervention2, getInterventionById as getInterventionById2, deleteIntervention as deleteIntervention2 } from '../Firebase2/Storage2/dbIntervention'






//const contractId = sessionStorage.getItem('contractId')

/* let contract = {}
try {
    contract = JSON.parse(sessionStorage.getItem('contract'))
} catch (err) { } 
console.log(contract)

*/






const companyId = "0"

let intervention_dict = {}
//let structure_list = []

/*
const newworkarea = {
    name: '',
    id_badge: '',
    company: '',
    referent_list: [],
    p_risk_area_summary: {},
    room_list: [],
    size: 0,
    intervention_list: []
}


 const defaultfilter = { service: [] }


let styles = {
    width: 450,
    position: 'relative',
}
const style_normal = {
    position: 'absolute',
    color: 'deeppink',
    padding: '0.2rem 0.1em',
    cursor: 'move',
}
 */
////////////////////////////////////////////////////////////////////////////////////////////////////////
export function ModalProcessCheck({ w, element, functions, t, ui_list, canWrite, _preview, _res_data }) {
	
    const componentRef = useRef();

    const { domain } = useContext(DomainContext)
    const { contractId, structureElements, roomtype_dict, service_dict, operation_dict } = useContext(GlobalDataContext)

    //	const [curWorkarea, setCurWorkarea] = useState(newworkarea)
    const [editedElement, setEditedElement] = useState(false)
    const [currentEditIntervention, setCurrentEditIntervention] = useState(element)
    const [showEditProcessCheck, setShowEditProcessCheck] = useState(false)
    const [preview, showPreview] = useState(_preview)
    const [res_data, setResData] = useState()
    //console.log("...INIT DATA", _preview, _res_data, element)

    const processtiming_dict = processcontroltiming_dict({ t })

    let service_name = ' '
    try {
        service_name = service_dict[element.service.id].name + " |"
    } catch (err) {
        service_name = ''
    }

    let check_section = processCheckSection_dict({ t })
    if (element.cart_element_list && element.cart_element_list.filter(e => e.category_type === 'machine').length > 0) {
        check_section.equipment.list.push({ id: "machine", label: t('global.labels.machines') })
        /*
        const c = checkComponentPresence({ id: 'machine' }, check_section.equipment.list, 'id')
        console.log("index machine", c, check_section)
            if (c!==-1){
            check_section.equipment.list = check_section.equipment.list.slice(c, 1)
        } */

    }
    if (element.cart_element_list && element.cart_element_list.filter(e => e.category_type === 'cart').length > 0) {
        check_section.equipment.list.push({ id: "cart", label: t('global.labels.carts') })
        /* 		const c = checkComponentPresence({ id: 'cart' }, check_section.equipment.list, 'id')
                console.log("index cart", c, check_section)
                if (c!==-1){
                    check_section.equipment.list = check_section.equipment.list.slice(c, 1)
                } */
    }


    //-----Process check form
    const handleShowEditProcessCheck = (i) => {
        //setCurWorkarea(w)
        setCurrentEditIntervention(element)
        setResData(_res_data)
        showPreview(_preview)
        setShowEditProcessCheck(true)
        setEditedElement(false)
    }

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle,
		documentTitle: `${element.name} | ${service_name}  | Scheda controllo di processo }`
	});


    const handleCloseProcessCheck = () => {
        {
            preview && !res_data ?
                showPreview(false)
                :
                setShowEditProcessCheck(false);
        }
    }

    const handleSaveProcessCheck = async (index) => {
        const return_data = await editIntervention2(domain, contractId, currentEditIntervention.id, currentEditIntervention)
        //intervention_dict[currentEditIntervention.id] = currentEditIntervention
        //		const c = checkComponentPresence(currentEditIntervention, editlist, 'id')
        //		if (c !== -1) {

        //		editlist.splice(c, 1, currentEditIntervention)
        //		console.log(editlist)
        //		tmp_editworkarea.intervention_list = editlist
        //}
        functions.edit(currentEditIntervention)
        //		setCurWorkarea({ ...curWorkarea, 'intervention_list': editlist })
        setShowEditProcessCheck(false);
    }


    const onChangeHandlerIntervention = (el) => {
        let { name, value, type } = el
        let sub = undefined
        try {
            sub = el.getAttribute('sub')
        } catch {
            sub = undefined;
        }
        console.log(name, value, type, sub)
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            try {

                let oldcheck = [...currentEditIntervention[name]]
                if (el.checked) {
                    if (oldcheck && oldcheck.indexOf(value) < 0) {
                        oldcheck.push(value)
                    } else {
                        oldcheck = [value]
                    }
                } else {
                    if (oldcheck.indexOf(value) >= 0) {
                        oldcheck.splice(oldcheck.indexOf(value), 1)
                    }
                }
                value = oldcheck
            } catch (err) {
                value = [value]

            }
        }
        if (sub) {
            value = { ...currentEditIntervention[sub], [name]: value }
            name = sub;
        }
        console.log("intervention new value", name, value)
        setCurrentEditIntervention({ ...currentEditIntervention, [name]: value })
        setEditedElement(true)
    }


    const onChangeProcessList = (el) => {
        let editdict = {}
        if (currentEditIntervention.process_list) {
            editdict = { ...currentEditIntervention.process_list }
        }
        editdict[el.name] = el.value
        console.log("new process list complete", editdict)
        setCurrentEditIntervention({ ...currentEditIntervention, process_list: editdict })
        setEditedElement(true)
        //		onChangeHandlerIntervention({ name: 'process_list', value: editdict })
    }


    //	useEffect(() => {
    //		console.log("UPDATE INTERVENTION", currentEditIntervention)
    //	}, [currentEditIntervention])

    const [summary_data, set_summary] = useState({ furniture: [], environment_element: [], economic_material: [], action_list: [] })


    const initValues = () => {

        let temp_furniture_list_summary = []
        let temp_environment_element_list_summary = []
        let temp_economic_material_list_summary = []
        let temp_operation_dict = {}
        let rt_prev_list = []
        if (element.room_list) {
            element.room_list.map((r) => {
                const rt = roomtype_dict[r.roomtype.id]
                if (rt && rt_prev_list.indexOf(rt.id) === -1) {
                    rt_prev_list.push(rt.id)
                    rt.service_list.filter(s => (s.service.id === element.service.id)).map(s => {
                        if (s.operation_list) {
                            s.operation_list.map(o => {
                                const one_o = operation_dict[o.id]
                                if (!temp_operation_dict[one_o.id]) {
                                    temp_operation_dict[one_o.id] = one_o
                                }
                            })
                        }
                    })
                }
                //console.log(dimensions)
            })
        }
        console.log(temp_furniture_list_summary, temp_environment_element_list_summary, temp_economic_material_list_summary)
        set_summary({
            furniture: temp_furniture_list_summary,
            environment_element: temp_environment_element_list_summary,
            economic_material: temp_economic_material_list_summary,
            operation_dict: temp_operation_dict
        })
        //		console.log("process list", element.process_list)
    }


    useEffect(() => {
        console.log("***************************______________________RES DATA")
        initValues()
    }, [currentEditIntervention])

    useEffect(() => {
        console.log("...INIT PREVIEW")
        initValues()
    }, [])
    //set_summary({furniture:temp_furniture_list_summary, emvoronment_element:temp_environment_element_list_summary, economic_material:temp_economic_material_list_summary})









    return (
        <>
            <Button variant="outline-info" size="sm" onClick={() => handleShowEditProcessCheck(element)} ><FontAwesomeIcon icon="clipboard-check" />{t('workarea.labels.processcheck')}</Button>


            {currentEditIntervention ?
                <Modal show={showEditProcessCheck} onHide={handleCloseProcessCheck} dialogClassName="custom-modal" backdrop="static" className="bg-secondary" scrollable={true}>
                    <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                        <Modal.Title> {element.name} | {service_name} <span className="text-info"><FontAwesomeIcon icon="clipboard-check" /> &nbsp;{t('workarea.labels.processcheck')} </span>
                            {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                            {preview ?
                                <>
                                    <ButtonGroup size="sm">
                                        {Object.keys(processtiming_dict).map((tk, k) => (
                                            <Button key={k} className={res_data && preview !== tk ? 'd-none' : ''} variant={preview === tk ? 'secondary' : 'outline-secondary'} onClick={() => showPreview(tk)}>{processtiming_dict[tk].label}</Button>
                                        ))}

                                    </ButtonGroup>
                                </> : ''}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Container fluid ref={componentRef}>
                            {preview ?
                                <>
                                    <Row className="border-bottom mb-3">
                                        <Col sm="3">
                                            {t('global.labels.lqa')}
                                        </Col>
                                        <Col className="h5">
                                            <Badge variant="info" pill >{currentEditIntervention.lqa}</Badge>
                                        </Col>
                                    </Row>
                                    {res_data ?
                                        <>
                                            <Row>
                                                <Col sm="3">
                                                    {t('global.labels.lqr')}
                                                </Col>
                                                <Col className="h5">
                                                    <Badge variant="info">{res_data.lqr.toFixed(2)}</Badge>
                                                </Col>
                                            </Row>
                                            <Row className="border-bottom mb-1 pb-1">
                                                <Col sm="3">
                                                    {t('global.labels.delta')}
                                                </Col>
                                                <Col className="h5">
                                                    <Badge variant="warning">{(parseFloat(res_data.lqr) - getFloatNumber(currentEditIntervention.lqa)).toFixed(2)}</Badge>
                                                </Col>
                                            </Row>
                                <Row className="border-bottom mb-1 pb-1">
                                    <Col>
                                        {res_data.employer ?
                                            <>dipendente: {res_data.employer.register}</>
                                            : ''}
                                    </Col>
                                </Row>
                                        </>
                                        : ''

                                    }
                                    {currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('operator') >= 0 ?
                                        <>
                                            <Row className="bg-primary text-white">
                                                <Col>
                                                    {t('workarea.labels.checkoperator')}
                                                </Col>
                                            </Row>
                                            {check_section['operator'].list.map((s, k) => (
                                                <ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='operator' section={s} preview={preview} canWrite={canWrite} res_data={res_data} />
                                            ))}
                                        </>
                                        : ''}
                                    {currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('equipment') >= 0 ?
                                        <>
                                            <Row className="bg-primary text-white">
                                                <Col>
                                                    {t('workarea.labels.checkequipment')}
                                                </Col>
                                            </Row>
                                            {check_section['equipment'].list.map((s, k) => (
                                                <ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='equipment' section={s} preview={preview} canWrite={canWrite} res_data={res_data} />
                                            ))}
                                        </>
                                        : ''}
                                    {currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('skill') >= 0 ?
                                        <>
                                            <Row className="bg-primary text-white">
                                                <Col>
                                                    {t('workarea.labels.checkskill')}
                                                </Col>
                                            </Row>
                                            {check_section['skill'].list.map((s, k) => (
                                                <ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='skill' section={s} preview={preview} canWrite={canWrite} res_data={res_data} />
                                            ))}
                                        </>
                                        : ''}
                                    {/* 								<Row>
									<Col className="text-primary border-bottom h5">
										{t('checks.labels.global_questions')}
									</Col>
								</Row>
								{ui_list.global_question_list ?
									<>
										{ui_list.global_question_list.map((q, k) => (
											<Row key={k}>
												<Col>
													{q.text}
												</Col>
											</Row>
										))}
									</>
									: ''
								} */}
                                </>
                                :
                                <Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
                                    <Row>
                                        <Col sm={2} >
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item variant="secondary" className="mb-1">
                                                    <Nav.Link eventKey="tabDetails">{t('company.labels.details')} </Nav.Link>
                                                </Nav.Item>
                                                {currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('operator') >= 0 ?
                                                    <Nav.Item className="mb-1">
                                                        <Nav.Link eventKey="tabCheckOperator">{t('workarea.labels.checkoperator')}</Nav.Link>
                                                    </Nav.Item>
                                                    : ''}
                                                {currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('equipment') >= 0 ?
                                                    <Nav.Item className="mb-1">
                                                        <Nav.Link eventKey="tabCheckEquipment">{t('workarea.labels.checkequipment')}</Nav.Link>
                                                    </Nav.Item>
                                                    : ''}
                                                {currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('skill') >= 0 ?
                                                    <Nav.Item className="mb-1">
                                                        <Nav.Link eventKey="tabCheckSkill">{t('workarea.labels.checkskill')}</Nav.Link>
                                                    </Nav.Item>
                                                    : ''}
                                            </Nav>
                                        </Col>
                                        <Col>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="tabDetails">
                                                    <Row className="border-bottom mb-1">
                                                        <Col sm="3" >
                                                            {t('workarea.labels.enabled_checks')}
                                                        </Col>
                                                        <Col sm="5">
                                                            <Col>
                                                                <Form.Check type="checkbox" name="process_check_section" disabled={!canWrite} value="operator" checked={currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('operator') >= 0} label={t('workarea.labels.checkoperator')} onChange={(event) => onChangeHandlerIntervention(event.currentTarget)} />
                                                            </Col>
                                                            <Col>
                                                                <Form.Check type="checkbox" name="process_check_section" disabled={!canWrite} value="equipment" checked={currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('equipment') >= 0} label={t('workarea.labels.checkequipment')} onChange={(event) => onChangeHandlerIntervention(event.currentTarget)} />
                                                            </Col>
                                                            <Col>
                                                                <Form.Check type="checkbox" name="process_check_section" disabled={!canWrite} value="skill" checked={currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('skill') >= 0} label={t('workarea.labels.checkskill')} onChange={(event) => onChangeHandlerIntervention(event.currentTarget)} />
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Form.Group as={Row} controlId="roomtypeName" className="border-bottom">
                                                        <Form.Label column sm="2">{t('global.labels.lqa')}</Form.Label>
                                                        <Col sm="8">
                                                            <Form.Control type="number" name="lqa" step="0.01" min="0" value={currentEditIntervention.lqa} onChange={(event) => onChangeHandlerIntervention(event.currentTarget)} className="mb-2" />
                                                        </Col>
                                                    </Form.Group>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="tabCheckOperator">
                                                    {check_section['operator'].list.map((s, k) => (
                                                        <ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='operator' section={s} canWrite={canWrite} />
                                                    ))}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="tabCheckEquipment">
                                                    {check_section['equipment'].list.map((s, k) => (
                                                        <ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='equipment' section={s} canWrite={canWrite} />
                                                    ))}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="tabCheckSkill">
                                                    {check_section['skill'].list.map((s, k) => (
                                                        <ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='skill' section={s} canWrite={canWrite} />
                                                    ))}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            }
                            {res_data ?
                                <ControlEndSection t={t} res_data={res_data} />
                                : ''}
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProcessCheck}>
                            {t('modal.close')}
                        </Button>
                        {!preview ?
                            <>

                                <Button onClick={() => showPreview('pre')} ><FontAwesomeIcon icon="eye" />{t('structure.labels.show_preview')}</Button>
                                {canWrite ?
                                    <Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveProcessCheck}>
                                        <FontAwesomeIcon icon="save"></FontAwesomeIcon> {t('modal.save')}
                                    </Button>
                                    : ''}
                            </>
                            : 
                            <>
									<Button variant="info" onClick={handlePrint}>
										<FontAwesomeIcon icon="print" />  {t('modal.print')}
									</Button>
                            </>
                            
                            }
                    </Modal.Footer>
                </Modal>
                :
                <></>}
        </>
    )
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
const GoodSection = ({ t, section_list, section }) => {
    if (section_list && section_list.length) {
        let intended_use_dict = materialIntendedUse_dict({ t })
        return (
            <>
                <Row>
                    {section_list.map((e, kf) => (
                        <Col key={kf} className="mb-1" sm="3">
                            <Row className="p-2">
                                <Col className="rounded border border-info">
                                    <Row>

                                        <Col className="p-1">
                                            <Row>
                                                <Col>
                                                    {e.name}
                                                </Col>
                                            </Row>
                                            <Row>
                                                {section === 'cleaningmaterial' ?
                                                    <Col>
                                                        <Badge className="ml-1 mr-1" pill variant="info">qt. {e.quantity}</Badge>
                                                    </Col>
                                                    : ''}
                                                <Col>
                                                    {getBaseObjectValue(e.category, 'name', 'it')}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="font-italic">
                                                    {getBaseObjectValue(intended_use_dict, e.intended_use, 'label')}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="text-center" sm="3">
                                            {getElementPreview(e, '40px')}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    ))}

                </Row>
            </>
        )


    } else {
        return (<></>)
    }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormCheckSection({ element, section, functions, t, global_section, summary_data, preview, canWrite, res_data }) {
    const newprocess = {
        id: makeid(8),
        description: '',
        coefficient: 0,
        control_mode: '',
        timing_type: [],
        section: section.id,
        process_type: 'question',
        action_list: []
    }


    const [showProcessEdit, setShowProcessEdit] = useState(false)
    const [is_new, setNewProcessEdit] = useState(true)
    const [curProcess, setCurProcess] = useState(newprocess)
    const [editedElement, setEditedElement] = useState(false)
    const [showOperationEdit, setShowOperationEdit] = useState(false)
    const { messagegroup_list } = useContext(GlobalDataContext)

    const processtiming_dict = processcontroltiming_dict({ t })
    const timing_mode_dict = timingMode_dict({ t })
    //----process
    const handleCloseProcessParam = () => setShowProcessEdit(false);
    const handleSaveProcessParam = () => {
        console.log('----save', is_new, curProcess)
        setShowProcessEdit(false);
        if (is_new) {
            let newprocess_list = []
            if (element.process_list) {
                newprocess_list = element.process_list[section.id] || []
            }
            newprocess_list.push(curProcess)
            functions.edit({ name: section.id, value: newprocess_list })
            setEditedElement(false)
        } else {
            let editlist = element.process_list[section.id] || []
            const c = checkComponentPresence(curProcess, element.process_list[section.id], 'id')
            console.log("INDEX", c, element.process_list[section.id], curProcess.id)
            if (c >= 0) {
                editlist.splice(c, 1, curProcess)

            }
            functions.edit({ name: section.id, value: editlist })
            setEditedElement(false)
        }
    }

    const handleImportProcess = (source_list) => {
        let newprocess_section_list = []
        if (element.process_list) {
            newprocess_section_list = element.process_list[section.id] || []
        }
        newprocess_section_list = [...newprocess_section_list, ...source_list]
        functions.edit({ name: section.id, value: newprocess_section_list })


    }
    const handleDeleteProcess = (el, index) => {
        let editlist = [...element.process_list[section.id]]
        const c = checkComponentPresence(el, editlist, 'id')
        if (c >= 0) {
            editlist.splice(c, 1)
            functions.edit({ name: section.id, value: editlist })
        }
    }

    const handleShowNewProcess = () => {
        setCurProcess(newprocess)
        setNewProcessEdit(true)
        setShowProcessEdit(true)
        setEditedElement(false)
    }

    const handleShowEditProcess = (i, index) => {
        console.log('edit', i, index)
        i.index = index
        setCurProcess(i)
        setNewProcessEdit(false)
        setShowProcessEdit(true)
        setEditedElement(false)
    }

    const onChangeHandlerProcess = (el) => {
        console.log(el)
        let { name, value, type } = el
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = []
            try {
                oldcheck = [...curProcess[name]]
            } catch (err) { }
            if (el.checked) {
                if (oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        console.log(name, value)
        setCurProcess({ ...curProcess, [name]: value })
        setEditedElement(true)
    }

    const handleShowEditOperation = () => {
        setShowOperationEdit(true)
    }

    const handleCloseEditOperation = () => {
        setShowOperationEdit(false)
    }


    const checkPresence = () => {
        try {
            console.log(element.process_list[section.id], section.id)
        } catch (err) {

            console.log("---error check", section.id, err)
        }
    }


    const ExternalSummary = () => {
        //console.log("global section", global_section, section.id)
        if (global_section === 'equipment') {
            //console.log("cart element list", element.cart_element_list)
            if (element.cart_element_list && element.cart_element_list.length > 0) {

                if (preview && element.cart_element_list.filter(e => e.category_type === section.id).length === 0) {
                    return (
                        <>
                        </>)

                } else {
                    return (
                        <>
                            <Row>
                                <Col className="text-info">
                                    {section.label} presente/i nel carrello
							</Col>
                            </Row>
                            <GoodSection section_list={element.cart_element_list.filter(e => e.category_type === section.id)} section={section.id} t={t} />

                        </>

                    )
                }
            } else {
                return (
                    <>
                        <i className="text-muted">{t('workarea.messages.no_cart')}</i>
                    </>

                )

            }
        } else if (global_section === 'skill' && section.id === 'procedures') {
            return (
                <>
                    <Row>
                        <Col className="text-info">
                            azioni operative per <Badge>{element.name}</Badge>
                        </Col>
                        {canWrite && !preview ?
                            <Col className="text-right" sm="2">
                                {showOperationEdit ?
                                    <Button variant="outline-secondary" size="sm" onClick={() => handleCloseEditOperation()}><FontAwesomeIcon icon="check"></FontAwesomeIcon>{t('structure.labels.component_modend')}</Button>
                                    :
                                    <Button variant="outline-primary" size="sm" onClick={() => handleShowEditOperation()}><FontAwesomeIcon icon="plus-square"></FontAwesomeIcon>{t('workarea.labels.operationcheck_add')}</Button>
                                }
                            </Col>
                            : ''}
                    </Row>
                    <Row>
                        {checkPresence()}
                        {element.process_list && element.process_list[section.id] && element.process_list[section.id].filter(f => f.process_type === 'operation').length > 0 ?
                            <Col>
                                <Row className="bg-light section_title">
                                    <Col sm="3">
                                        {t('operations.labels.operation')}
                                    </Col>
                                    <Col>
                                        {t('operations.labels.actions')}
                                    </Col>
                                    {canWrite ?
                                        <Col className="text-center" sm="1">
                                            {t('global.labels.actions')}
                                        </Col>
                                        : ''}
                                </Row>

                                <OperationSection />

                            </Col>
                            : ''
                        }
                        {showOperationEdit ?
                            <Col className="editSection">
                                <Row className="mb-1" >
                                    <Col className="bg-secondary text-light ml-1 p-0 text-center" sm="1">{t('global.labels.add')}</Col>
                                    <Col className="bg-secondary text-light ml-1">{t('structure.labels.component_name')} </Col>
                                </Row>
                                <OperationEditSection />

                            </Col>

                            : ''}

                        {/* 							<FurnitureLikeSection section_list={summary_data.furniture} />
							<FurnitureLikeSection section_list={summary_data.economic_material} />
							<FurnitureLikeSection section_list={summary_data.environment_element} /> */}
                    </Row>
                </>
            )
        } else {
            return (
                <>
                </>
            )
        }
    }
    //------------------------------------------------------------------------------------------------------
    const handleAddOperation = (o) => {
        console.log(o)
        let proc = { ...curProcess }
        proc.operation_id = o
        proc.process_type = 'operation'
        let newprocess_list = []
        if (element.process_list) {
            newprocess_list = element.process_list[section.id] || []
        }
        newprocess_list.push(proc)
        functions.edit({ name: section.id, value: newprocess_list })
        setEditedElement(false)

    }


    //------------------------------------------------------------------------------------------------------
    const handleRemoveOperation = (o) => {
        let editlist = [...element.process_list[section.id]]
        console.log("REMOVE", o, element)
        const c = checkComponentPresence(o, editlist, 'operation_id')
        console.log(c)
        if (c >= 0) {

            editlist.splice(c, 1)
            functions.edit({ name: section.id, value: editlist })
        }

    }




    //------------------------------------------------------------------------------------------------------
    const OperationEditSection = () => {
        console.log("OPERATION DICT", summary_data.operation_dict)
        return (
            <>
                {Object.keys(summary_data.operation_dict).map((o, k) => (
                    <Row key={o} className="border-bottom">
                        <Col sm="1">
                            {!preview ?
                                <>
                                    {element.process_list && element.process_list[section.id] && (checkComponentPresence({ operation_id: o }, element.process_list[section.id].filter(f => f.process_type === 'operation'), 'operation_id') !== -1) ?
                                        <Button size="sm" disabled variant="outline-info" onClick={() => handleAddOperation(o)}><FontAwesomeIcon icon="check" /> </Button>
                                        :
                                        <Button size="sm" variant="light" onClick={() => handleAddOperation(o)}><FontAwesomeIcon icon="plus-square" />{summary_data.operation_dict[o].do_process_check ? <FontAwesomeIcon icon="star" /> : ''} </Button>
                                    }
                                </>
                                : ''}
                        </Col>
                        {summary_data.operation_dict[o] ?
                            <Col>
                                {summary_data.operation_dict[o].do_process_check ?
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 200 }}
                                        overlay={renderCheckTooltip}
                                    >
                                        <FontAwesomeIcon className="text-info" icon="star" />
                                    </OverlayTrigger>
                                    : ''}
                                {summary_data.operation_dict[o].name}
                            </Col>
                            : <></>}
                    </Row>
                ))}
            </>
        )

    }



    //------------------------------------------------------------------------------------------------------
    const OperationSection = () => {
        console.log(element.process_list[section.id].filter(f => f.process_type === 'operation'))
        return (
            <>
                {element.process_list[section.id].filter(f => f.process_type === 'operation').map((o, k) => (
                    <Row key={k} className="border-bottom">
                        {console.log("----test", summary_data.operation_dict, o.operation_id)}
                        {summary_data.operation_dict[o.operation_id] ?
                            <>
                                {console.log("---test operation", summary_data.operation_dict[o.operation_id])}
                                <Col sm="3">
                                    {summary_data.operation_dict[o.operation_id].do_process_check ?
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 200 }}
                                            overlay={renderCheckTooltip}
                                        >
                                            <FontAwesomeIcon className="text-info" icon="star" />
                                        </OverlayTrigger>
                                        : ''}
                                    {summary_data.operation_dict[o.operation_id].name}
                                </Col>
                                <Col>
                                    <OperationActionList a={summary_data.operation_dict[o.operation_id]} />
                                </Col>
                                {console.log('OPERATION PREVIEW"', preview)}
                                {!preview ?
                                    <Col className="text-center" sm="1">
                                        <Button size="sm" variant="outline-danger" onClick={() => handleRemoveOperation(o)}><FontAwesomeIcon icon="trash" /> </Button>
                                    </Col>
                                    : ''}
                                    {res_data?
                                    <>
                                    {console.log("----result data", res_data.result_dict)}
                                <ResultBadge res={res_data.result_dict["operation_" + o.operation_id]} ratio={1} />
                                    </>
                                    :''}
                            </>
                            : <>!!</>}
                    </Row>
                ))}
            </>
        )

    }


    //------------------------------------------------------------------------------------------------------
    const OperationActionList = ({ a }) => {

        if (a.action_list && a.action_list.length > 0) {
            return (
                <>
                    {a.action_list.map((o, k) => (
                        <Row key={k}>
                            - {o.description}

                        </Row>

                    ))}
                </>
            )
        } else {
            return (<i>nessuna azione configurata</i>)
        }
    }








    // =====================================================================================================================================
    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <Container fluid>
                        <Row>
                            <Col className="text-primary h5">
                                {section.label}
                            </Col>
                            {!preview && canWrite ?
                                <Col className="text-right">
                                    <ButtonGroup>
                                        <ModalImportProcess section={section} dest_intervention_id={element.id} dest_param_list={element.process_list ? element.process_list[section.id] : []} functions={{ import: handleImportProcess }} t={t} />
                                        <Button variant="outline-primary" size="sm" onClick={() => handleShowNewProcess()}><FontAwesomeIcon icon="plus-square" />{t('workarea.labels.process_add')}</Button>
                                    </ButtonGroup>
                                </Col>
                                : ''}
                        </Row>

                    </Container>

                </Card.Header>
                <Card.Body className="p-2">
                    <Container fluid>
                        <Row>
                            <Col>
                                <ExternalSummary />
                            </Col>
                        </Row>
                        <Row >
                            {element.process_list && element.process_list[section.id] && element.process_list[section.id].filter((i) => (!i.process_type || i.process_type === 'question')
                                && (!preview || !i.timing_type || i.timing_type.indexOf(preview) >= 0)).length > 0 ?
                                <Col>
                                    <Row >
                                        <Col className="bg-secondary text-light ml-1">{t('global.labels.description')} </Col>
                                        <Col className="bg-secondary text-light ml-1 p-1" sm="1">{t('global.labels.timing_type')} </Col>
                                        <Col className="bg-secondary text-light ml-1 p-1 text-center" sm="1">{t('workarea.labels.weight_coefficient_short')} </Col>
                                        <Col className="bg-secondary text-light ml-1" sm="1">{t('workarea.labels.control_tool')} </Col>
                                        <Col className="bg-secondary text-light ml-1">{t('workarea.labels.noncompliance_action')} </Col>
                                        {!preview ?
                                            <Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
                                            : ''}
                                    </Row>
                                    {element.process_list[section.id].filter((i) => (!i.process_type || i.process_type === 'question') && (!preview || !i.timing_type || i.timing_type.indexOf(preview) >= 0)).map((a, key) => (
                                        <Row key={key} className="border-bottom mb-1 pt-1">
                                            {console.log("LIST", element.process_list[section.id].filter((i) => (!i.process_type || i.process_type === 'question') && (!preview || !i.timing_type || i.timing_type.indexOf(preview) >= 0)))}
                                            <Col className="font-weight-bold">{a.description}</Col>
                                            <Col sm="1" className="p-1">
                                                {a.timing_type ?
                                                    <>
                                                        {a.timing_type.map((tp, tkey) => (
                                                            <Badge key={tkey} variant="light" className="mr-1">
                                                                {processtiming_dict[tp].label}
                                                            </Badge>
                                                        ))}
                                                    </> : ''}
                                            </Col>
                                            <Col sm="1">{a.coefficient}</Col>
                                            <Col sm="1">{a.control_mode}</Col>
                                            {res_data ?
                                                <Col>
                                                {console.log("RES DATA", res_data)}
                                                    <ResultBadge res={res_data.result_dict[a.id]} ratio={a.coefficient} />
                                                </Col>
                                                :
                                                <Col>
                                                    {a.action_list.length > 0 ?
                                                        <>
                                                            <Row className="bg-light section_title mb-1">
                                                                <Col >{t('workarea.labels.action')} </Col>
                                                                <Col >{t('workarea.labels.responsability')} </Col>
                                                                <Col >{t('ticket.labels.receiver')}</Col>
                                                                <Col >{t('workarea.labels.timing')} </Col>
                                                            </Row>
                                                            {a.action_list.map((a, key) => (
                                                                <Row key={key} className="border-bottom mb-1 pt-1">
                                                                    <Col className="font-weight-bold p-1 ">{a.action}</Col>
                                                                    <Col>{a.responsability}</Col>
                                                                    <Col>{getValue(getSelectedElement(a.receiver_group, messagegroup_list, 'id'), 'name')}</Col>
                                                                    <Col>{a.timing_mode && a.timing_mode.length > 0 ?
                                                                        <>
                                                                            {a.timing_mode.map((m, k) => (
                                                                                <Badge key={k}>{getValue(timing_mode_dict[m], 'label')}</Badge>
                                                                            ))}
                                                                        </>
                                                                        : ''
                                                                    }
                                                                        {a.timing.length > 0 ?
                                                                            <>
                                                                                ({a.timing})
																		</> : ''}
                                                                    </Col>
                                                                </Row>))
                                                            }
                                                        </>
                                                        : <i>{t('workearea.messages.no_noncompliance_actions')}</i>
                                                    }
                                                </Col>
                                            }
                                            {!preview && canWrite ?
                                                <Col className="text-center" sm="1">
                                                    <ButtonGroup>
                                                        <Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditProcess(a, key)} /> </Button>
                                                        <Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteProcess(a, key)} /> </Button>
                                                    </ButtonGroup>
                                                </Col>
                                                : ''}
                                        </Row>))
                                    }
                                </Col>
                                : <Col className="text-muted"><i>{preview ? '' : <> {t('workarea.messages.no_process')}</>}</i></Col>
                            }
                        </Row>

                    </Container>

                </Card.Body>
            </Card>





            <Modal show={showProcessEdit} onHide={handleCloseProcessParam} size="xl" className="bg-secondary" backdrop="static">
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        {is_new ?
                            <>
                                <span className="text-primary"><FontAwesomeIcon icon="plus-square" />  {t('workarea.labels.new_process')}</span> | {section.label}
                            </>
                            :
                            <>
                                <span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('workarea.labels.edit_process')}</span> | {section.label}
                            </>
                        }
                        &nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
                            <Form.Label column sm="2">{t('global.labels.description')}</Form.Label>
                            <Col sm="8">
                                <Form.Control size="sm" type="text" name="description" value={curProcess.description} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
                            <Form.Label column sm="2">{t('workarea.labels.weight_coefficient')}</Form.Label>
                            <Col sm="8">
                                <Form.Control size="sm" type="text" name="coefficient" value={curProcess.coefficient} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
                            <Form.Label column sm="2">{t('workarea.labels.control_tool')}</Form.Label>
                            <Col sm="8">
                                <Form.Control size="sm" type="text" name="control_mode" value={curProcess.control_mode} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
                            <Form.Label column sm="2">{t('workarea.labels.timing_control_type')}</Form.Label>
                            <Col sm="8">
                                <Form.Check type="checkbox" name="timing_type" checked={curProcess.timing_type && curProcess.timing_type.indexOf("pre") >= 0} value="pre" label={t('workarea.labels.timing_type_pre')} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
                                <Form.Check type="checkbox" name="timing_type" checked={curProcess.timing_type && curProcess.timing_type.indexOf("during") >= 0} value="during" label={t('workarea.labels.timing_type_during')} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
                                <Form.Check type="checkbox" name="timing_type" checked={curProcess.timing_type && curProcess.timing_type.indexOf("post") >= 0} value="post" label={t('workarea.labels.timing_type_post')} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <ElementFormNoncomplianceAction functions={{ edit: onChangeHandlerProcess }} element={curProcess} t={t} />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseProcessParam}>
                        {t('modal.close')}
                    </Button>
                    {canWrite ?
                        <Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveProcessParam}>
                            <FontAwesomeIcon icon="save"></FontAwesomeIcon> {t('modal.save')}
                        </Button>
                        : ''}
                </Modal.Footer>
            </Modal>

        </>
    )
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkParamPresence(source_list, dest_list) {
    if (dest_list) {
        let ispresent = false
        source_list.forEach(sp => {
            dest_list.forEach(dp => {
                if (sp.id === dp.id) {
                    console.log("------presence")
                    ispresent = true
                }
            })
        });
        return ispresent
    } else {
        console.log("----no data ")
        return false
    }

}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderCheckTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Operazione con controllo di processo obbligatorio
    </Tooltip>
);

////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalImportProcess = ({ dest_intervention_id, dest_param_list, section, functions, t }) => {

    const [showImportProcessCheck, setShowImportProcessCheck] = useState(false)
    const childRefAlert = useRef()

    //-----Process check form
    const handleShowImportProcessCheck = (w, i) => {
        setShowImportProcessCheck(true)
    }

    const handleCloseProcessCheck = () => {
        setShowImportProcessCheck(false);
    }
    const handleImportSectionParameters = (d) => {
        if (!checkParamPresence(d, dest_param_list)) {
            functions.import(d)
            setShowImportProcessCheck(false);
        } else {
            childRefAlert.current.addAlert('danger', "uno o più parametri già presenti nell'intervento di destinazione. Cancellarli per poter proseguire")
        }
    }
    let section_list = []

    for (let d in intervention_dict) {
        const inter = intervention_dict[d]
        if (d !== dest_intervention_id && inter.process_list && inter.process_list[section.id] && inter.process_list[section.id].filter((i) => i.section === section.id && (!i.process_type || i.process_type === 'question')).length > 0) {
            section_list.push({
                intervention_id: inter.id,
                name: inter.name,
                section_process_list: inter.process_list[section.id].filter((i) => i.section === section.id && (!i.process_type || i.process_type === 'question'))
            })
        }
    }

    //	console.log("import summary", section, section_list)

    return (

        <>
            <Button variant="outline-info" size="sm" disabled={section_list.length === 0} onClick={() => handleShowImportProcessCheck()}>
                <FontAwesomeIcon icon="arrow-right" />{t('workarea.labels.checkprocess_import')}
            </Button>
            {showImportProcessCheck ?
                <Modal show={showImportProcessCheck} onHide={handleCloseProcessCheck} size="xl" className="bg-secondary" >
                    <AlertSection ref={childRefAlert} />
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t('workarea.labels.checkprocess_import')} | {section.label}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container fluid>
                            {section_list.length > 0 ?
                                <>
                                    <Row className="mb-1">
                                        <Col className="bg-secondary text-light ml-1">{t('workareas.labels.intervention_name')} </Col>
                                        <Col className="bg-secondary text-light ml-1">{t('workareas.labels.process_indicator')} </Col>
                                        <Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
                                    </Row>
                                    {section_list.map((s, k) => (
                                        <Row key={k} className="element_row border-bottom mb-1 pb-1">
                                            <Col>
                                                {s.name}
                                            </Col>
                                            <Col>
                                                {s.section_process_list.map((p, kp) => (
                                                    <Row key={kp}>
                                                        <Col>
                                                            {p.description}
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <Row>

                                                </Row>
                                            </Col>
                                            <Col sm="1">
                                                <Button variant="info" onClick={() => handleImportSectionParameters(s.section_process_list)} size="sm" >
                                                    <FontAwesomeIcon icon="arrow-right" />{t('global.labels.import')}</Button>
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                                : <i>{t('workareas.message.no_indicators')}</i>}
                        </Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProcessCheck}>
                            {t('modal.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                : ''}
        </>

    )

}


const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;
