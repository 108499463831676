import React, { useState, useEffect, useRef, createRef, useContext } from 'react';
import { Modal, Container, Row, Col, Form, Button, ButtonGroup, Spinner, Popover, OverlayTrigger, Badge, Tooltip } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { format, getDay, setHours, addMonths } from 'date-fns'
// pick a date util library
import DateTime from 'react-datetime';
import { translate } from 'react-polyglot'
import { useParams, Link } from "react-router-dom"
import 'react-day-picker/lib/style.css';
import { it } from 'date-fns/locale'



import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"



import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"

import { myTimestampToDate, makeid, getValue, compareName, getSelectedElement, getElementCount, propComparator, checkComponentPresence, getQualificationParam } from "../util/ui_utils.js"



import firebase from 'firebase'


import { getTrainingList, getTrainingEventRange, addTrainingEvent, editTrainingEvent, deleteTrainingEvent } from '../Firebase2/Storage2/dbTraining';
import { getTrainerList } from '../Firebase2/Storage2/dbTrainers';
import { getEmployerList, editEmployer as editEmployer2 } from '../Firebase2/Storage2/dbEmployers';








let curdate = { start: new Date(), end: new Date() }
const newEvent = {
	start: new Date(),
	end: new Date(),
	extendedProps: {
		training: {},
		trainer: {},
		employer_list: []
	}
};
let filterAppliedList = { structure: [], training: [] }




let load_page = false

const defaultFilter = { training: [] }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const TrainingPlanningView = ({ t, uData }) => {
	//console.log("plannint", uData)

	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)

	const { initContract } = InitContractHelper()
	const calendarRef = createRef()

	let { cid } = useParams();
	let contractId = cid


	const [title, setTitle] = useState('')
	const [showEdit, setShow] = useState(false);
	const [showViewEvent, setShowView] = useState(false);
	const [newEdit, setNew] = useState(true);
	const [curEvent, setCurEvent] = useState(newEvent);
	const [currentEvents, setCurrentEvents] = useState([]);
	const [loading, setLoading] = useState(true)
	const [ui_list, setUiList] = useState({})
	const [training_list, setTrainingList] = useState([])
	const [training_dict, setTrainingDict] = useState({})
	const [trainer_list, setTrainerList] = useState([])
	const [employer_list, setEmployerList] = useState([])
	const [employer_dict, setEmployerDict] = useState({})
	const [exptraining_list, setExpTrainingList] = useState([])
	const [canWrite, setWritePage] = useState(false)
	const [filterSidebar, setFilterSidebar] = useState(['undone', 'done', 'expired'])


	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		console.log('index', c)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		//   setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
		filterAppliedList = { ...filterAppliedList, [section]: f_data }
		let calendarApi = calendarRef.current.getApi()
		//    setShowFilter(false)
		calendarApi.refetchEvents()
	}




	const resetAppliedFilter = (section) => {
		filterAppliedList = { ...filterAppliedList, [section]: [] }
		setShowFilter(false)
		let calendarApi = calendarRef.current.getApi()
		calendarApi.refetchEvents()
	}





	useEffect(() => {

	}, [filterAppliedList])



	const onChangeHandler = (event) => {
		const { name, value } = event;
		if (name !== 'start' && name !== 'end') {
			console.log(name, value)
			let newprops = { ...curEvent.extendedProps, [name]: value }
			setCurEvent({ ...curEvent, extendedProps: newprops })
		} else {
			setCurEvent({ ...curEvent, [name]: value })
			console.log("newEventDate", curEvent)
		}
	}



	const handleClose = () => {
		setShow(false)
	}



	const handleCloseViewEvent = () => {
		setShowView(false)
	}



	const [showFilterEvent, setShowFilter] = useState(false)


	const handleUpdate = (_edited_dict) => {
		console.log("UPDATE EMPLOYERS")
		const edited_dict = { ...employer_dict, _edited_dict }
		setEmployerDict(edited_dict)

	}

	useEffect(() => {
		const _list = Object.entries(employer_dict)
		setEmployerList(_list)
		const _expiring_training = getExpiringTraining(_list)
		setExpTrainingList(_expiring_training)

	}, [employer_dict])


	const handleSave = async () => {
		const _event = { ...curEvent, start: firebase.firestore.Timestamp.fromDate(curEvent.start), end: firebase.firestore.Timestamp.fromDate(curEvent.end) }
		if (newEdit) {
			const return_data = await addTrainingEvent(domain, contractId, _event)
			handleEventAdd(return_data)
			console.log(return_data)

		} else {
			await editTrainingEvent(domain, contractId, curEvent.id, curEvent)
		}
		try {

			let calendarApi = calendarRef.current.getApi()
			calendarApi.refetchEvents()
		} catch (err) {
			console.log(err, calendarRef)
		}
		setShow(false)
	}


	const handleDelete = async () => {
		let return_data = await deleteTrainingEvent(domain, contractId, curEvent.id)
		try {

			let calendarApi = calendarRef.current.getApi()
			calendarApi.refetchEvents()
		} catch (err) {
			console.log(err, calendarRef)
		}
		setShow(false)
	}


	const handleDateSelect = (selectInfo) => {
		console.log("handleDateSelect - selectInfo:", selectInfo)
		const start = setHours(selectInfo.date, 8)
		const end = setHours(selectInfo.date, 18)
		setCurEvent({ ...newEvent, start: start, end: end })
		setNew(true)
		setShow(true)
	}




	const getEventList = async (el) => {
		console.log("start data", el)
		if (el.start >= curdate.start && el.end <= curdate.end) {
			console.log('*************keep data')
		} else {
			curdate = el
		}
		curdate = el
		setLoading(true)

		let event_list = []

		if (contract && domain.length > 0) {

			console.log(contractId, domain, el.start, el.end)
			let return_data = await getTrainingEventRange(domain, contractId, firebase.firestore.Timestamp.fromDate(el.start), firebase.firestore.Timestamp.fromDate(el.end))

			//    return_data = return_data.filter(e => (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention'))
			console.log("return data", return_data)
			//      console.log(uData)
			//      for (let d = 0; d < return_data.length; d++) {
			for (const event of return_data) {
				let insert = false
				if ((filterAppliedList.training.length === 0 || filterAppliedList.training.indexOf(event.extendedProps.training) !== -1)) {
					event.start = myTimestampToDate(event.start)
					event.end = myTimestampToDate(event.end)
					//          console.log("return_data[" + d + "].extendedProps: ", return_data[d].extendedProps)
					event_list.push(event)
				}
			}
		}

		console.log("EVENT COUNT", event_list)
		setCurrentEvents(event_list)
		setLoading(false)
		return event_list
	}




	const handleEventClick = (clickInfo) => {
		console.log("myevent", clickInfo.event.extendedProps)
		console.log(clickInfo.event.start, clickInfo.event.end)
		setCurEvent({ ...newEvent, id: clickInfo.event.id, start: clickInfo.event.start, end: clickInfo.event.end, extendedProps: clickInfo.event.extendedProps })
		console.log(curEvent)

		setNew(false)
		setShow(true)
	}






	const handleEventAdd = (addInfo) => {
		console.log("ADD", addInfo)

	}




	const handleEvents = (events) => {
		setCurrentEvents(events)
	}

	const getExpiringTraining = (_employer_list) => {
		let _training_list = []
		for (const e of _employer_list.filter(e => e.need_training_list)) {
			for (let nt of e.need_training_list) {
				nt.user_id = e.id
				const one_training_list = e.training_list.filter(ct => ct.training_id === nt.id).sort(propComparator('expiration_date'))
				if (one_training_list.length > 0) {
					const training = one_training_list[0]
					nt.done = training.done
					nt.execution_id = training.id
					nt.execution_date = training.execution_date
					nt.expiration_date = training.expiration_date || ''
				} else {
					nt.done = false
					delete nt.execution_id
				}
				_training_list.push(nt)
			}

			/* 
						_training_list = [..._training_list, ...e.need_training_list.filter(nt => 
							e.training_list.filter()
							!t.done || (t.done && t.expiration)).map(t => ({ ...t, user_id: e.id }))]
			 */
		}
		return _training_list.sort(propComparator('expiration_date'))
	}



	const initValues = async () => {
		console.log('PLANNING uData', uData)
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('trainingplanning') !== -1)) {
			setWritePage(true)
		}
		let userData = uData

		let _training_list = await getTrainingList(domain, contractId)
		let _trainer_list = await getTrainerList(domain, contractId)
		let _employer_list = await getEmployerList(domain, contractId, {})
		_employer_list = _employer_list.sort(propComparator('surname'))
		const _training_dict = Object.assign({}, ..._training_list.map((x) => ({ [x.id]: x })));
		const _employers_dict = Object.assign({}, ..._employer_list.map((x) => ({ [x.id]: x })));
		_training_list.sort(compareName)
		_trainer_list.sort(compareName)
		setTrainingList(_training_list)
		setTrainingDict(_training_dict)
		setTrainerList(_trainer_list)
		setEmployerList(_employer_list)
		setEmployerDict(_employers_dict)
		setUiList({ ...ui_list, training_list: _training_list, trainer_list: _trainer_list, employer_list: _employer_list })
		const _expiring_training = getExpiringTraining(_employer_list)
		setExpTrainingList(_expiring_training)


		load_page = true
		if (calendarRef.current) {
			let calendarApi = calendarRef.current.getApi()
			calendarApi.refetchEvents()
		}

	}







	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("PLANNING reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("PLANNING init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])








	useEffect(() => {
		console.log('PLANNING loadingcontract', contract)
		if (contract && loading) {
			initValues()
		}
	}, [contract])





	useEffect(() => {
		if (calendarRef && calendarRef.current) {
			let calendarApi = calendarRef.current.getApi()
			console.log(calendarApi.view)
			setTitle(calendarApi.view.title)
		}
	}, [calendarRef])



	// --------------------------------------------------------
	const renderEventContent = (info) => {
		if (info.timeText.length === 2) {
			info.timeText = info.timeText + ":00"
		}
		let evtId = "event-" + info.event.id;
		let tt = ''
		let room_count = ''
		try {
			tt = format(info.event.start, 'HH:mm', { locale: it }) + " - " + format(info.event.end, 'HH:mm', { locale: it })
		} catch (err) {
			//console.log(info.event.start, info.event.end)
		}

		if (training_dict[info.event.extendedProps.training.id]) {
			return (
				<>
					<div id={evtId} className="fc-content text-light bg-primary" style={{ cursor: 'pointer' }}>
						<b>{tt}</b>&nbsp;
						{training_dict[info.event.extendedProps.training.id].name}
						{training_dict[info.event.extendedProps.training.id].employer_list ?
							<Badge>{training_dict[info.event.extendedProps.training.id].employer_list.length}</Badge>
							: ''}
					</div>
				</>
			)

		} else {
			<>
				<div id={evtId} className="fc-content text-light bg-primary">
					<b>{tt}</b>&nbsp;
					{info.event.extendedProps.training.name}
				</div>
			</>

		}


	}






	// --------------------------------------------------------
	const EventInfo = () => {
		try {
			return (
				<>
					<Modal.Header closeButton>
						<Modal.Title>
							{format(curEvent.start, 'cccc dd/MM (HH:mm', { locale: it })} - {format(curEvent.end, 'HH:mm)', { locale: it })} | {getValue(curEvent.extendedProps.training, 'name')}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container>
							<Row>
								<Col>
								</Col>
								<Col>
								</Col>
							</Row>

							<Row>
								<Col>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</>
			)
		} catch (err) {
			console.log(err, curEvent)
			return (
				<Modal.Body>

				</Modal.Body>
			)

		}

	}



	const updateFilterSidebar = (f) => {
		const c = filterSidebar.indexOf(f)
		if (c >= 0) {
			let l = [...filterSidebar]
			l.splice(c, 1)
			setFilterSidebar(l)
		} else {
			setFilterSidebar((old_f) => [...old_f, f])
		}
	}

	//######/////####////calenda API external call
	function handleCalendarNext() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.next()
	}

	function handleCalendarPrev() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.prev()
	}

	function handleCalendarHome() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.today()
	}

	function handleCalendarChangeView(view) {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.changeView(view)
	}








	return (
		<>
			<Container className="titleStickyTop" fluid>
				<Row>
					<Col sm="6">
						<PageTitle title={t('global.pages.agenda_trainingplanning')} counter={<b>{title}</b>} />
						{loading ?
							<>
								<Spinner animation="border" role="status" />
								&nbsp;
								Caricamento in corso
							</>
							: ''}
					</Col>
					<Col className="text-right" >
						<ButtonGroup className="ml-2">
							<Button variant="secondary" size="sm" onClick={handleCalendarPrev} ><FontAwesomeIcon icon="angle-left" /></Button>
							<Button variant="secondary" size="sm" onClick={handleCalendarHome} ><FontAwesomeIcon icon="home" /></Button>
							<Button variant="secondary" size="sm" onClick={handleCalendarNext} ><FontAwesomeIcon icon="angle-right" /></Button>
						</ButtonGroup>
					</Col>
					<Col sm="3"></Col>
				</Row>

			</Container>



			<Container fluid id="domContainer">
				<Row>

					<Col>
						{contract ?
							<FullCalendar

								ref={calendarRef}
								plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
								headerToolbar={false}
								buttonText={
									{
										today: 'oggi',
										month: 'mese',
										week: 'settimana',
										day: 'giorno',
										list: 'lista'
									}

								}
								allDaySlot={false}
								allDayText={'tutto il giorno'}
								firstDay="1"
								locale='it'
								initialView='dayGridMonth'
								eventResize={false}
								editable={false}
								selectable={false}
								height='auto'
								selectMirror={true}
								dayMaxEvents={true}
								weekends={true}
								slotLabelFormat={{ hour: "numeric", minute: "2-digit", omitZeroMinute: false }}
								slotDuration="00:15:00"
								initialEvents={getEventList}
								select={handleDateSelect}
								eventContent={renderEventContent}
								eventClick={handleEventClick}
								eventsSet={handleEvents}
								eventAdd={handleEventAdd}
								dateClick={handleDateSelect}
							/* 
							eventAdd={function(){}}
							eventChange={function(){}}
							eventRemove={function(){}}
							*/
							/>
							: <></>}
					</Col>
					<Col sm="3">
						<Row>
							<Col>Moduli formativi in scadenza</Col>
						</Row>
						<Row>
							<Col>
								<ButtonGroup size="sm">
									<Button disabled variant="outline-secondary"><FontAwesomeIcon icon="filter" /></Button>
									<Button size="sm" variant={filterSidebar.indexOf('undone') >= 0 ? 'danger' : "outline-danger"} onClick={() => updateFilterSidebar('undone')}>non eseguiti</Button>
									<Button size="sm" variant={filterSidebar.indexOf('expired') >= 0 ? 'danger' : "outline-danger"} onClick={() => updateFilterSidebar('expired')}>scaduti</Button>
									<Button size="sm" variant={filterSidebar.indexOf('done') >= 0 ? 'success' : "outline-success"} onClick={() => updateFilterSidebar('done')}>eseguiti</Button>
								</ButtonGroup>
							</Col>
						</Row>
						<Row className="p-1 text-light">
							<Col className="bg-secondary ml-1">{t('global.labels.training')}</Col>
							<Col className="bg-secondary ml-1">{t('global.labels.employer')}</Col>
							<Col className="bg-secondary ml-1">{t('employes.labels.expiration_date')}</Col>
						</Row>
						{filterSidebar.indexOf('undone') >= 0 ?
							exptraining_list.filter(one_t => !one_t.done).map((one_t, k) => (
								<OneTraining t={t} training={one_t} key={k} employer_dict={employer_dict} />
							))
							: <></>}
						{filterSidebar.indexOf('expired') >= 0 ?
							exptraining_list.filter(one_t => one_t.done && (one_t.expiration && myTimestampToDate(one_t.expiration_date) < new Date())).map((one_t, k) => (
								<OneTraining t={t} training={one_t} key={k} employer_dict={employer_dict} />
							))
							: <></>}
						{filterSidebar.indexOf('done') >= 0 ?
							exptraining_list.filter(one_t => one_t.done && (!one_t.expiration || myTimestampToDate(one_t.expiration_date) > new Date())).map((one_t, k) => (
								<OneTraining t={t} training={one_t} key={k} employer_dict={employer_dict} />
							))
							: <></>}
					</Col>
				</Row>



				<ModalEditEvent t={t} newEdit={newEdit} editedEvent={curEvent} handleSave={handleSave} handleDelete={handleDelete} handleClose={handleClose} handleUpdate={handleUpdate} showEdit={showEdit} onChangeHandler={onChangeHandler} ui_list={ui_list} />




				<Modal show={showViewEvent} onHide={handleCloseViewEvent} size="xl">
					<EventInfo />
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseViewEvent}>
							{t('modal.close')}
						</Button>
					</Modal.Footer>
				</Modal>




			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list: currentEvents, name: t('trainings.labels.trainings') })} />
		</>
	)

}


const getTrainingClassname = (training, text) => {
	if (!training.done || (training.expiration && myTimestampToDate(training.expiration_date) < new Date())) {
		return text ? " text-danger" : " border-danger"
	} else {
		return ""
	}
}

const InfoButton = (employer_id) => {
	return (
					<Link to={{ pathname: `employes?elemId=${employer_id}` }}>
						<Button component={Link} variant="outline-info" size="sm">
							<FontAwesomeIcon icon="info-circle" />
						</Button>
					</Link>

	)

}

const OneTraining = ({ t, training, employer_dict }) => {
	const employer = employer_dict[training.user_id]
	const {contract} = useContext(GlobalDataContext)

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Modulo formativo non ancora eseguito
		</Tooltip>
	);

	if (!training.done || (training.expiration && myTimestampToDate(training.expiration_date) < new Date())) {
		return (
			<Row className={"border-bottom mb-1 pb-1 border-danger text-danger"}>
				<Col>
					{training.name}
				</Col>
				<Col>
					<InfoButton employer_id={employer.id} />
					{employer.firstname}&nbsp;{employer.lastname}
				</Col>
				<Col className={"text-danger"}>
					{training.done ?
						<FontAwesomeIcon icon="check-square" />
						:
						<OverlayTrigger
							placement="left"
							delay={{ show: 250, hide: 400 }}
							overlay={renderTooltip}
						>
							<FontAwesomeIcon icon={['far', 'square']} />
						</OverlayTrigger>
					}
					{training.expiration && training.done ?
						<>
							{format(myTimestampToDate(training.expiration_date), 'dd/MM/yyyy')}
						</> : ''}
				</Col>
			</Row>
		)
	} else {
		return (
			<Row className={`border-bottom mb-1 pb-1`}>
				<Col>
					{training.name}
				</Col>
				<Col className="p-1">
					<InfoButton employer_id={employer.id} /> &nbsp;
					{employer.firstname}&nbsp;{employer.lastname}
				</Col>
				<Col>
					{training.done ?
						<FontAwesomeIcon className="text-success" icon="check-square" />
						:
						<OverlayTrigger
							placement="left"
							delay={{ show: 250, hide: 400 }}
							overlay={renderTooltip}
						>
							<FontAwesomeIcon icon={['far', 'square']} />
						</OverlayTrigger>
					}
					{training.expiration && training.done ?
						<>
							{format(myTimestampToDate(training.expiration_date), 'dd/MM/yyyy')}
						</> : ''}
				</Col>
			</Row>
		)
	}


}


const ModalEditEvent = ({ t, newEdit, editedEvent, handleSave, handleClose, handleDelete, showEdit, ui_list, onChangeHandler, handleUpdate }) => {
	const childRefDelete = useRef()

	const [showEventEdit, setShowEventEdit] = useState(false)
	const [showAll, setShowAll] = useState(false)
	const [training, setTraining] = useState(-1)
	const [curEvent, setCurEvent] = useState(editedEvent)

	const handleCloseEvent = () => setShowEventEdit(false);
	const handleOpenEvent = () => {
		console.log(editedEvent)
		setCurEvent(editedEvent)
		setShowEventEdit(true)
	}
	useEffect(() => {
		if (showEdit) {

			setShowAll(false)
			setShowEventEdit(false)
			const training = getSelectedElement(curEvent.extendedProps.training.id, ui_list.training_list, 'id')
			setTraining(training)
		}
	}, [showEdit])

	useEffect(() => {
		setCurEvent(editedEvent)
		console.log("EDITED", editedEvent)
	}, [editedEvent])

	//---------------generic function
	const handleAddEl = (el, section) => {
		console.log("ADD EL", section, el)
		let editlist = curEvent.extendedProps.employer_list
		if (editlist) {
			const c = checkComponentPresence(el, curEvent.extendedProps.employer_list, 'id')
			if (c >= 0) {
				editlist[c].quantity = parseInt(editlist[c].quantity) + 1
			} else {
				editlist.push({ ...el })
			}
		} else {
			editlist = [{ ...el, quantity: 1 }]
		}
		onChangeHandler({ name: 'employer_list', value: editlist })
	}

	const onChangeTraining = (training_id) => {
		const training = getSelectedElement(training_id, ui_list.training_list, 'id')
		if (training) {
			onChangeHandler({ name: 'training', value: { id: training_id, name: training.name } })
		} else {
			onChangeHandler({ name: 'training', value: {} })
		}
		setTraining(training)
	}



	const handleDeleteEl = (el) => {
		const cb = () => {
			let editlist = curEvent.extendedProps.employer_list
			const c = checkComponentPresence(el, curEvent.extendedProps.employer_list, 'id')
			editlist.splice(c, 1)
			onChangeHandler({ name: 'employer_list', value: editlist })
		}
		cb()

	}

	const OneTrainedEmployer = ({ i, k, element_list, section, showComponentEdit }) => {
		return (

			<Row key={k} className="p-1">
				<Col>{i.lastname} {i.firstname} {i.code && i.code.length > 0 ? <>[{i.code}]</> : ''}</Col>
				<Col>{getQualificationParam(i, ui_list.contract_list, 'name')}</Col>
				<Col sm="1" >
					<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteEl(i)} /> </Button>
				</Col>
			</Row>
		)

	}


	const OneEmployer = ({ i, k }) => {
		return (
			<Row key={k} className="border-bottom p-1">
				<Col sm="1" className="p-0 text-center">
					<Button size="sm" variant="light" onClick={() => handleAddEl(i)}><FontAwesomeIcon className="text-warning" icon="arrow-left" />&nbsp;<FontAwesomeIcon icon="plus-square" /> </Button>
				</Col>
				<Col>{i.lastname} {i.firstname} {i.code && i.code.length > 0 ? <>[{i.code}]</> : ''}</Col>
				<Col>{getQualificationParam(i, ui_list.contract_list, 'name')}</Col>
			</Row>
		)
	}



	const _handleDelete = (el) => {
		const cb = async () => {
			handleDelete(el)
		}
		childRefDelete.current.confirmDelete(t('training.messages.delete_training_title'), t('training.messages.delete_training', { curdate: format(myTimestampToDate(curEvent.start), 'dd/MM/yy HH:mm') }), cb)
	}


	const isValid = () => {
		return (curEvent.training && curEvent.training.id)
	}


	return (
		<Modal show={showEdit} onHide={handleClose} size="xl">
			<DeleteConfirmationSection ref={childRefDelete} />
			<Modal.Header closeButton>
				<Modal.Title>
					{newEdit ?
						<>{t('trainingplan.labels.new_training')}

						</>
						:
						<>
							{t('trainingplan.labels.edit_training')}
						</>}
					&nbsp;
					{format(myTimestampToDate(curEvent.start), 'cccc dd/MM ', { locale: it })}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container fluid>
					<Form >
						<Form.Group as={Row} controlId="eventSubject">
							<Form.Label column sm="2"> {t('global.labels.training')}</Form.Label>
							<Col>
								<Form.Control as="select" value={curEvent.extendedProps.training.id} className="mb-2" name="training"
									onChange={(event) => onChangeTraining(event.currentTarget.value)} >
									<option value={"-1"} >{t('training.labels.select_training')}</option>
									{ui_list.training_list && ui_list.training_list.length > 0
										? [ui_list.training_list.map((f, k) => (
											<option key={k} value={f.id} >{f.name}</option>
										))]
										: ''
									}
								</Form.Control>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="eventSubject">
							<Form.Label column sm="2"> {t('global.labels.trainer')}</Form.Label>
							<Col>
								<Form.Control as="select" value={curEvent.extendedProps.trainer.id} className="mb-2" name="trainer"
									onChange={(event) => onChangeHandler({ name: 'trainer', value: { id: event.currentTarget.value, name: getSelectedElement(event.currentTarget.value, ui_list.trainer_list, 'id') } })} >
									<option value={"-1"} >{t('trainer.labels.select_trainer')}</option>
									{ui_list.trainer_list && ui_list.trainer_list.length > 0
										? [ui_list.trainer_list.map((f, k) => (
											<option key={k} value={f.id} >{f.firstname} {f.lastname}</option>
										))]
										: ''
									}
								</Form.Control>
							</Col>
						</Form.Group>

						<Form.Group as={Row} controlId="eventStart">
							<Form.Label column sm="2">{t('agenda.labels.time')}</Form.Label>
							<Col sm="4">
								<DateTime initialValue={myTimestampToDate(curEvent.start)} dateFormat={false} timeFormat={'HH:mm'} name="start" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'start', value: e.toDate() })} />
							</Col>
							<Col sm="4">
								<DateTime initialValue={myTimestampToDate(curEvent.end)} dateFormat={false} timeFormat={'HH:mm'} name="end" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'end', value: e.toDate() })} />
							</Col>
						</Form.Group>
						<Row className="border-bottom h5 mt-1">
							<Col sm="7" className="text-primary">
								{t('training.labels.employer_list')}
							</Col>
							{showEventEdit ?
								<>
									<Col><Button onClick={() => setShowAll(!showAll)} size="sm" variant="outline secondary">{showAll ? <FontAwesomeIcon icon="eye-slash" /> : <><FontAwesomeIcon icon="eye" />Tutti</>}</Button></Col>
									<Col className="text-info">{t('training.labels.available_employers')}</Col>
								</>
								: ''}
						</Row>
						<Row className="mb-1">
							<Col className="text-right">
								{showEventEdit ?
									<Button variant="outline-secondary" size="sm" onClick={() => handleCloseEvent()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
									:
									<Button variant="outline-primary" size="sm" disabled={!curEvent.extendedProps.training.id} onClick={() => handleOpenEvent()}><FontAwesomeIcon icon="pencil-alt" /> {t('training.labels.employers_mod')}</Button>
								}
							</Col>
						</Row>
						<Row className="border-bottom mb-2 pb-2">


							{curEvent.extendedProps.employer_list && curEvent.extendedProps.employer_list.length > 0 ?
								<Col className="border-right">
									<Row >
										<Col className="bg-secondary text-light ml-1">{t('structure.labels.component_name')} </Col>
										<Col className="bg-secondary text-light ml-1">{t('employers.labels.qualification')} </Col>
										<Col className="bg-secondary text-light ml-1" sm="1" >&nbsp;</Col>
									</Row>
									{curEvent.extendedProps.employer_list.map((i, key) => (

										<OneTrainedEmployer i={i} key={key} k={key} showComponentEdit={showEventEdit} element_list={curEvent.employer_list} />

									))}

								</Col>
								: <Col className="font-italic"> { }</Col>
							}
							{showEventEdit
								?
								<>
									<Col sm="1"></Col>
									<Col className="border-left editSection">
										{console.log(training)}
										{ui_list.employer_list && ui_list.employer_list.filter(e => showAll || needTraining(e, training)).length > 0 ?
											<>
												<Row >
													<Col className="bg-secondary text-light ml-1" sm="1">&nbsp;</Col>
													<Col className="bg-secondary text-light ml-1">{t('structure.labels.component_name')} </Col>
													<Col className="bg-secondary text-light ml-1">{t('employers.labels.qualification')} </Col>
												</Row>
												{ui_list.employer_list.filter(e => showAll || needTraining(e, training)).map((i, key) => (
													<OneEmployer i={i} key={key} />
												))}
											</>
											: <> { }</>
										}
									</Col>
								</>
								: ''
							}
						</Row>
						<Form.Group as={Row}>
							<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="note" defaultValue={curEvent.extendedProps.note} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Form>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				{myTimestampToDate(curEvent.end) < new Date() ?
					<ModalTrainingExecution editedEvent={curEvent} t={t} ui_list={ui_list} handleUpdate={handleUpdate} />
					: ''}
				{!newEdit ?
					<Button className="btn-danger" onClick={_handleDelete}>
						<FontAwesomeIcon icon="trash" />{t('modal.delete')}
					</Button>
					: ''}
				<Button variant="secondary" onClick={handleClose}>
					{t('modal.close')}
				</Button>
				<Button className="btn-myprimary" onClick={handleSave}>
					<FontAwesomeIcon icon="save" />{t('modal.save')}
				</Button>
			</Modal.Footer>
		</Modal>


	)

}


const ModalTrainingExecution = ({ t, editedEvent, ui_list, handleUpdate }) => {
	const childRefDelete = useRef()

	let { cid } = useParams();
	let contractId = cid

	const { domain } = useContext(DomainContext)
	const [showTrainingEdit, setShowEventTraining] = useState(false)
	const [curEvent, setCurEvent] = useState(editedEvent)
	const [execution_list, setExecutionList] = useState([])
	const [curTraining, setCurTraining] = useState({})

	const handleCloseTraining = () => setShowEventTraining(false);
	const handleOpenEvent = () => {
		console.log(editedEvent)
		setCurEvent(editedEvent)
		setCurTraining(getSelectedElement(editedEvent.extendedProps.training.id, ui_list.training_list, 'id'))
		setShowEventTraining(true)
	}

	const handleSaveTrainingDone = () => {
		let training = { ...curTraining }
		const _date = myTimestampToDate(curEvent.start)
		const training_id = training.id
		if (training.expiration) {
			console.log(training.expiration_months)
			const _expiration_date = addMonths(_date, training.expiration_months)
			training = { ...training, done: true, execution_date: firebase.firestore.Timestamp.fromDate(_date), expiration_date: firebase.firestore.Timestamp.fromDate(_expiration_date) }
		} else {
			training = { ...training, done: true, execution_date: firebase.firestore.Timestamp.fromDate(_date) }
		}
		let edited_employer_dict = {}
		for (const a of execution_list) {
			console.log("EXEC", a)
			let employer = getSelectedElement(a, ui_list.employer_list, 'id')
			let _titem_list = []
			if (employer.training_list) {
				_titem_list = [...employer.training_list]
			}
			const nt = checkComponentPresence(training, employer.need_training_list, 'id')

			console.log(_titem_list)
			const c = checkComponentPresence(training, employer.training_list, 'id', 'training_id')
			console.log(c)
			if (nt.done) {
				training.training_id = training_id
				training.id = makeid(6)
				console.log('renew')
				_titem_list.push(training)
			}
			if (c >= 0) {
				training.training_id = training_id
				console.log("do")
				_titem_list.splice(c, 1, training)
			} else {
				training.training_id = training_id
				training.id = makeid(6)
				console.log('renew')
				_titem_list.push(training)
			}
			employer.training_list = _titem_list
			console.log(domain, contractId, employer.id, employer)
			editEmployer2(domain, contractId, employer.id, employer)
			edited_employer_dict[employer.id] = employer
		}
		handleUpdate(edited_employer_dict)
		setShowEventTraining(false)
	}

	useEffect(() => {
		setCurEvent(editedEvent)
		console.log("EDITED", editedEvent)
	}, [editedEvent])


	const addExecution = (el) => {
		let editlist = [...execution_list]
		editlist.push(el)
		setExecutionList(editlist)
	}
	const removeExecution = (el) => {
		let editlist = [...execution_list]
		const c = execution_list.indexOf(el)
		editlist.splice(c, 1)
		setExecutionList(editlist)
	}



	const OneTrainedEmployer = ({ i, k, element_list, section, showComponentEdit }) => {
		return (

			<Row key={k} className="p-1">

				<Col>{i.lastname} {i.firstname} {i.code && i.code.length > 0 ? <>[{i.code}]</> : ''}</Col>
				<Col>{getQualificationParam(i, ui_list.contract_list, 'name')}</Col>
				<Col sm="1" >
					{execution_list.indexOf(i.id) >= 0 ?
						<FontAwesomeIcon className="text-success" icon="check-square" />
						:
						<FontAwesomeIcon className="text-danger" icon={['far', 'square']} />
					}&nbsp;
					{execution_list.indexOf(i.id) < 0 ?
						<Button onClick={() => addExecution(i.id)} variant="outline-info"><FontAwesomeIcon icon="check" /></Button>
						:
						<Button onClick={() => removeExecution(i.id)} variant="outline-info"><FontAwesomeIcon icon="trash" /></Button>
					}
				</Col>
			</Row>
		)

	}





	return (
		<>
			<Button variant="info" onClick={handleOpenEvent}>{t('workcontract.buttons.execution')}</Button>
			<Modal show={showTrainingEdit} onHide={handleCloseTraining} size="xl">
				<Modal.Header closeButton className="bg-info text-white">
					<Modal.Title>
						{curTraining.name}
						&nbsp;-&nbsp;
						{format(myTimestampToDate(curEvent.start), 'cccc dd/MM ', { locale: it })}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="border-info border">
					<Container fluid>
						<Row className="border-bottom h5 mt-1">
							<Col sm="7" className="text-primary">
								{t('training.labels.employer_list')}
							</Col>
						</Row>
						<Row>
							<Col>
								Seleziona i dipendenti che hanno eseguito il corso <Badge variant="info">{execution_list.length}</Badge>
								{curTraining.expiration ?
									<>
										{format(addMonths(myTimestampToDate(curEvent.start), curTraining.expiration_months), 'dd/MM/yyyy')}

									</> : ''}
							</Col>
						</Row>

						<Row className="border-bottom mb-2 pb-2">


							{curEvent.extendedProps.employer_list && curEvent.extendedProps.employer_list.length > 0 ?
								<Col className="border-right">
									<Row >
										<Col className="bg-secondary text-light ml-1">{t('structure.labels.component_name')} </Col>
										<Col className="bg-secondary text-light ml-1">{t('employers.labels.qualification')} </Col>
										<Col className="bg-secondary text-light ml-1" sm="1" >&nbsp;</Col>
									</Row>
									{curEvent.extendedProps.employer_list.map((i, key) => (

										<OneTrainedEmployer i={i} key={key} k={key} showComponentEdit={showTrainingEdit} element_list={curEvent.employer_list} />

									))}

								</Col>
								: <Col className="font-italic"> { }</Col>
							}

						</Row>

					</Container>
				</Modal.Body>
				<Modal.Footer className="bg-info text-white">

					<Button variant="secondary" onClick={handleCloseTraining}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" onClick={handleSaveTrainingDone}>
						<FontAwesomeIcon icon="save" />{t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>

	)

}



const needTraining = (employer, training) => {
	console.log(training, employer.need_training_list)
	if (training.id) {
		return employer.need_training_list && employer.need_training_list.filter(tr => tr.id === training.id && (!tr.done || training.expiration)).length > 0
	} else {
		return false
	}
}




export default translate()(TrainingPlanningView)
