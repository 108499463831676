import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, Tab, Nav, Badge, ButtonGroup, InputGroup, FormControl } from 'react-bootstrap';
import { translate, useTranslate } from 'react-polyglot'
import update from 'immutability-helper'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import LocaleContext from './contexts/locale.js'
import DomainContext from './contexts/domainContext'
import RoomContext, { RoomProvider } from '../components/contexts/roomList.js';
import GlobalDataContext from './contexts/globaldataContext.js';

import styles from './constants/styles.js'
import { good_dict } from "../components/constants/global_definitions"

import { checkComponentPresence, getBaseObjectValue, getValue } from "../util/ui_utils.js"
import { RiskareaBadge, AddListButton } from '../components/StructureGraphicElements.js'

import { ElementOperationChecks } from './FormOperationChecks'
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ElementFormMedia from "../components/FormMedia.js"


import { addOperation as addOperation2, editOperation as editOperation2 } from '../Firebase2/Storage2/dbService';



//const contractId = sessionStorage.getItem('contractId')


/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalOperation({ item, setItem, t, is_new, small, ui_list, index, item_list, setParentList, canWrite }) {
    return (
        <RoomProvider key={"operation_" + index} >
            <MOperation key={"operation_" + index} item={item} setItem={setItem} is_new={is_new} small={small} ui_list={ui_list} index={index} item_list={item_list} setParentList={setParentList} canWrite={canWrite} />
        </RoomProvider>

    )
}




/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// --> ModalOperation
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
function MOperation({ item, setItem, is_new, small, ui_list, index, item_list, setParentList, canWrite }) {
    const t = useTranslate();
    const childRefAlert = useRef()
    const childRefDelete = useRef()

    const { contractId, setOperation_dict } = useContext(GlobalDataContext)
    const { domain } = useContext(DomainContext)

    const newoperation = {
        name: '',
        code: '',
        type: [],
        element_list: [],
        action_list: [],
        do_process_check: false
    }
    const [showOperationEdit, setShowOperationEdit] = useState(false)
    const [curOperation, setCurOperation] = useState(newoperation)
    const [editedElement, setEditedElement] = useState(false)
    const [actionList, setActionList] = useContext(RoomContext)

    const handleCloseOperation = () => setShowOperationEdit(false);
    const handleSaveOperation = async () => {
        setShowOperationEdit(false);
        curOperation.action_list = actionList
        let newoperation_list = [...item_list]
        if (is_new) {
            const return_data = await addOperation2(domain, contractId, curOperation)
            console.log("addOperation", return_data)
            newoperation_list.push(return_data)
            console.log(newoperation_list)
            setParentList(newoperation_list)
        } else {
            let newoperation_list = [...item_list]
            const c = checkComponentPresence(curOperation, item_list, 'id')
            if (c !== -1) {
                newoperation_list.splice(index, 1, curOperation)
                setParentList(newoperation_list)
            }
            const return_data = await editOperation2(domain, contractId, curOperation.id, curOperation)
            if (return_data && return_data.error) {

            } else {
                setOperation_dict(curOperation)

            }
        }
        const operation_dict = Object.assign({}, ...newoperation_list.map((x) => ({ [x.id]: x })));
        setOperation_dict(operation_dict)
    }

    const handleShowNewOperation = () => {
        console.log('show new')
        setCurOperation(newoperation)
        setShowOperationEdit(true)
        setEditedElement(false)
    }

    const handleShowEditOperation = () => {
        setActionList(item.action_list)
        setCurOperation(item)
        setShowOperationEdit(true)
        setEditedElement(false)
        setTimeout(function () {
            setEditedElement(false)
        }, 500)

    }

    const onChangeHandlerOperation = (el) => {
        let { name, value, type } = el
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = []
            try {
                oldcheck = [...curOperation[name]]
            } catch (err) { }
            if (el.checked) {
                if (oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        console.log("CHANGE OPERATION", name, value)
        setCurOperation({ ...curOperation, [name]: value })
        setEditedElement(true)
    }



    return (
        <>
            {is_new ?
                (small ?
                    <Button onClick={() => handleShowNewOperation()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
                    :
                    <Button onClick={() => handleShowNewOperation()}><FontAwesomeIcon icon='plus-square' /> {t('structure.labels.operation_add')}</Button>
                )
                : <Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEditOperation()}><FontAwesomeIcon icon={canWrite ? "pencil-alt" : "info-circle"} /> </Button>
            }
            <AlertSection ref={childRefAlert} />
            <DeleteConfirmationSection ref={childRefDelete} />
            <Modal show={showOperationEdit} onHide={handleCloseOperation} dialogClassName="custom-modal" backdrop="static" className="bg-secondary" scrollable={true}>
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        {is_new ?
                            <>
                                <FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('roomtypes.labels.new_operation')}</span>
                            </>
                            :
                            [canWrite ?
                                <>
                                    <FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('roomtypes.labels.edit_operation')}</span>
                                </>
                                :
                                <>
                                    <FontAwesomeIcon icon="info" /> <span className="text-info">{t('roomtypes.labels.view_operation')}</span>
                                </>
                            ]
                        }
                        : {curOperation.name}&nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Tab.Container id="modal-tab-navigator" defaultActiveKey="tabDetails">
                            <Row>
                                <Col sm={3} md={2}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item variant="secondary" className="mb-1">
                                            <Nav.Link variant="secondary" eventKey="tabDetails">{t('operations.labels.details')} </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="mb-1">
                                            <Nav.Link variant="secondary" eventKey="tabGoods">{t('operations.labels.goods')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="mb-1">
                                            <Nav.Link variant="secondary" eventKey="tabActions">{t('operations.labels.actions')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="mb-1">
                                            <Nav.Link variant="secondary" eventKey="tabChecks">{t('operations.labels.checks')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="mb-1">
                                            <Nav.Link variant="secondary" eventKey="tabDpi">{t('operations.labels.dpi')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="mb-1">
                                            <Nav.Link variant="secondary" eventKey="tabMedia" disabled={is_new}>{t('operations.labels.imagesdocs')} </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tabDetails">
                                            <ElementFormDetails element={curOperation} t={t} functions={{ edit: onChangeHandlerOperation }} canWrite={canWrite} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabGoods">
                                            <ElementGoodListForm element={curOperation} functions={{ edit: onChangeHandlerOperation }} t={t} ui_list={ui_list} canWrite={canWrite} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabChecks">
                                            <ElementOperationChecks element={curOperation} t={t} functions={{ edit: onChangeHandlerOperation, setItem: setCurOperation }} is_type={true} is_operation={true} canWrite={canWrite} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabActions">
                                            <ActionList element={curOperation} functions={{ edit: onChangeHandlerOperation, setEdit: setEditedElement }} t={t} canWrite={canWrite} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabDpi">
                                            <ElementDpiListForm element={curOperation} functions={{ edit: onChangeHandlerOperation, setEdit: setEditedElement }} t={t} ui_list={ui_list} canWrite={canWrite} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabMedia">
                                            <ElementFormMedia element={curOperation} t={t} ui_list={ui_list} functions={{ edit: onChangeHandlerOperation, save: handleSaveOperation }} section='operation' canWrite={canWrite} />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOperation}>
                        {t('modal.close')}
                    </Button>
                    {canWrite ?
                        <Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveOperation}>
                            <FontAwesomeIcon icon="save" /> {t('modal.save')}
                        </Button>
                        : ''}
                </Modal.Footer>
            </Modal>
        </>
    )
}







/////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDetails({ element, functions, t, canWrite }) {

    return (
        <>
            <Form.Group as={Row} controlId="formOperationNme" className="border-bottom">
                <Form.Label column sm="2">{t('operations.labels.name')} </Form.Label>
                <Col sm="6">
                    <Form.Control className="mb-2" type="text" value={element.name} name="name" onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formOperationCode" className="border-bottom">
                <Form.Label column sm="2">{t('operations.labels.code')}</Form.Label>
                <Col sm="6">
                    <Form.Control className="mb-2" type="text" value={element.code} name="code" onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formProductCF" className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
                <Col>
                    <Form.Check type="checkbox" className="ml-2" name="type_list" value="ordinary" checked={element.type_list && element.type_list.indexOf("ordinary") !== -1} label={t('workarea.labels.ordinary')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="checkbox" className="ml-2" name="type_list" value="periodic" checked={element.type_list && element.type_list.indexOf("periodic") !== -1} label={t('workarea.labels.periodic')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="checkbox" className="ml-2" name="type_list" value="requested" checked={element.type_list && element.type_list.indexOf("requested") !== -1} label={t('workarea.labels.requested')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="checkbox" className="ml-2" name="type_list" value="fee" checked={element.type_list && element.type_list.indexOf("fee") !== -1} label={t('workarea.labels.fee')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formProductCF" className="border-bottom">
                <Form.Label column sm="2">{t('operation.labels.ecolabel_service')}</Form.Label>
                <Col>
                    <Form.Check type="radio" name="ecolabel_service" value={true} checked={element.ecolabel_service} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="ecolabel_service" value={false} checked={!element.ecolabel_service} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom pb-1">
                <Form.Label column sm="2">{t('products.labels.washing_solution')}</Form.Label>
                <Col sm="8">
                    <InputGroup>
                        <Form.Control type="number" name="washing_solution" value={element.washing_solution} onChange={(event) => functions.edit(event.currentTarget)} />
                        <InputGroup.Append>
                            <InputGroup.Text id="basic-addon1">litri/m<sup>2</sup></InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formProductCF" className="border-bottom">
                <Form.Label column sm="2">{t('operationc.labels.process_check')}</Form.Label>
                <Col>
                    <Form.Check type="radio" name="do_process_check" value={true} checked={element.do_process_check} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="do_process_check" value={false} checked={!element.do_process_check} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>

        </>
    )
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ActionList = ({ element, functions, t, canWrite }) => {
    const [actionList, setActionList] = useContext(RoomContext)
    //    console.log("modal", actionList, element.action_list)


    const handleAddNewAction = (el) => {
        console.log("handleAddAction", el)
        functions.setEdit(true)
        setActionList(el.value)
    }


    const handleUpdateFromList = async (el) => {
        let editaction_list = element.action_list
        functions.edit({ name: 'action_list', value: el })
    }



    return (
        <Form.Group as={Row} controlId="formCompanyCF" className="border-bottom">
            <Col>
                {canWrite ?
                    <Row>
                        <Col className="text-right mb-1">
                            <ModalAction is_new={true} t={t} item={element} setItem={handleAddNewAction} canWrite={canWrite} />
                        </Col>
                    </Row>
                    : ''}
                {actionList && actionList.length > 0
                    ?
                    <>
                        <Row className="mb-1" >
                            <Col className="bg-secondary text-light ml-1" >{t('operations.labels.action')} </Col>
                            <Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
                        </Row>
                        <DndProvider backend={HTML5Backend}>
                            <ActionListContainer element={element} t={t} functions={{ update: handleUpdateFromList }} canWrite={canWrite} />
                        </DndProvider>
                    </>
                    : <>
                        <Row><Col className="font-italic"> {t('global.messages.no_action')}</Col></Row>
                    </>
                }
            </Col>
        </Form.Group>
    )
}








/////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalAction({ item, index, setItem, t, is_new, small, parent_list, canWrite }) {
    const newaction = {
        description: '',
    }
    const [showActionEdit, setShowActionEdit] = useState(false)
    const [curAction, setCurAction] = useState(newaction)
    const [editedElement, setEditedElement] = useState(false)

    const handleCloseAction = () => setShowActionEdit(false);
    const handleSaveAction = async () => {
        setShowActionEdit(false);
        if (is_new) {
            let newaction_list = [...item.action_list]
            newaction_list.push(curAction)
            setItem({ name: 'action_list', value: newaction_list })
        } else {
            let newaction_list = [...parent_list.action_list]
            newaction_list.splice(index, 1, curAction)
            setItem({ name: 'action_list', value: newaction_list })

        }
    }

    const handleShowNewAction = () => {
        console.log('show new')
        setCurAction(newaction)
        setShowActionEdit(true)
        setEditedElement(false)
    }

    const handleShowEditAction = () => {
        setCurAction(item)
        setShowActionEdit(true)
        setEditedElement(false)
    }

    const onChangeHandlerAction = (el) => {
        let { name, value, type } = el
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = []
            try {
                oldcheck = [...curAction[name]]
            } catch (err) { }
            if (el.checked) {
                if (oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        console.log(name, value)
        setCurAction({ ...curAction, [name]: value })
        setEditedElement(true)

    }




    return (
        <>
            {is_new ?
                (small ?
                    <Button onClick={() => handleShowNewAction()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
                    :
                    <Button onClick={() => handleShowNewAction()} variant="outline-primary" size="sm"><FontAwesomeIcon icon='plus-square' /> {t('structure.labels.action_add')}</Button>
                )
                : <Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowEditAction()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
            }
            <Modal show={showActionEdit} onHide={handleCloseAction} backdrop="static" className="bg-secondary" size="xl">
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        {is_new ?
                            <>
                                <span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('roomtypes.labels.new_action')}</span>
                            </>
                            :
                            <>
                                <span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('roomtypes.labels.edit_action')}</span>
                            </>
                        }
                        &nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form.Control type="textarea" rows="3" value={curAction.description} name="description" onChange={(event) => onChangeHandlerAction(event.currentTarget)} />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAction}>
                        {t('modal.close')}
                    </Button>
                    {canWrite ?
                        <Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveAction}>
                            <FontAwesomeIcon icon="save" /> {t('modal.save')}
                        </Button>
                        : ''}
                </Modal.Footer>
            </Modal>
        </>
    )
}




const ItemTypes = {
    CARD: 'card',
}



const cardstyle = {
    cursor: 'move',
}




/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ActionRow = ({ id, card, index, moveCard, removeCard, editCard, element, t, canWrite }) => {
    const ref = useRef(null)
    const [cardHover, toggleHover] = useState(false)
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            moveCard(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    drag(drop(ref))
    return (
        <Row ref={ref} key={card.id} className={isDragging ? ' border border-primary text-light' : 'border-bottom mb-1 pb-1'} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
            <Col>
                <Badge className="mr-1" variant="secondary" size="sm" >{index + 1}</Badge>
                {card.description}
            </Col>
            <Col sm="1 p-0 text-center">
                {canWrite ?
                    <ButtonGroup>
                        <ModalAction item={card} parent_list={element} index={index} setItem={editCard} t={t} canWrite={canWrite} />
                        <Button variant="outline-danger" size="sm" onClick={() => removeCard({ index: index, card: card })}><FontAwesomeIcon icon='trash' /> </Button>
                    </ButtonGroup>
                    : ''}
            </Col>
        </Row>

    )
}



/////////////////////////////////////////////////////////////////////////////////////
const ActionListContainer = ({ element, functions, t, canWrite }) => {
    const childRefDelete = useRef()

    //    const [action_list, setActionList] = useState(element.action_list)
    const [action_list, setActionList] = useContext(RoomContext)

    console.log("container", element.action_list, action_list)

    const moveAction = useCallback(
        (dragIndex, hoverIndex) => {
            const dragAction = action_list[dragIndex]
            console.log("move", dragAction, dragIndex)
            setActionList(
                update(action_list, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragAction],
                    ],
                }),
            )
        },
        [action_list],
    )

    const removeAction = useCallback(
        (data) => {
            const dragAction = action_list[data.index]
            const cb = () => {
                console.log("removeCard, cb", dragAction, data)
                setActionList(
                    update(action_list, {
                        $splice: [
                            [data.index, 1]
                        ],
                    }),
                )
            }
            childRefDelete.current.confirmDelete(t('action.messages.delete_action_title'), t('action.messages.delete_action_message'), cb)
        },
        [action_list],
    )

    const editAction = (el) => {
        console.log("editAction", el)
        setActionList(el.value)
        //functions.update({name: 'action_list', value:el})
    }

    const renderAction = (card, index) => {
        return (
            <ActionRow
                key={card.id}
                index={index}
                id={card.id}
                card={card}
                t={t}
                element={element}
                moveCard={moveAction}
                removeCard={removeAction}
                editCard={editAction}
                canWrite={canWrite}
            />
        )
    }


    useEffect(() => {
        console.log('new actions', action_list)
        functions.update(action_list)
    },
        [action_list]
    );




    return (
        <>
            <DeleteConfirmationSection ref={childRefDelete} />
            <div style={cardstyle}>
                {action_list.map((action, c) => renderAction(action, c, element))}
            </div>
        </>
    )
}


/////////////////////////////////////////////////////////////////////////////////////
function ElementGoodListForm({ element, functions, t, ui_list, canWrite }) {
    const { locale } = useContext(LocaleContext)
    const { domain } = useContext(DomainContext)

    const goods_labels = good_dict({ t })


    const [element_list, setElementList] = useState(ui_list.good_list)
    const [showComponentEdit, setShowComponentEdit] = useState(false)
    const [searchCategory, setSearchCategory] = useState([])

    const handleCloseComponent = () => setShowComponentEdit(false);

    const handleShowNewComponent = () => {
        setShowComponentEdit(true)
    }

    const handleDeleteElement = (el) => {
        console.log(el)
        let editlist = element.element_list
        const c = checkComponentPresence(el, element.element_list, 'id')
        editlist.splice(c, 1)
        functions.edit({ name: 'element_list', value: editlist })
    }


    const handleAddElement = (el) => {
        console.log(el)
        let editlist = element.element_list
        const c = checkComponentPresence(el, element.element_list, 'id')
        if (c >= 0) {
            alert('element present')
        } else {
            editlist.push({ id: el.id, name: el.name, category_type: el.category_type, brand: el.brand, dilution: 100, quantity: 1, yeld: 0 })
        }
        functions.edit({ name: 'element_list', value: editlist })
    }

    const getElementIcon = (el) => {
        const a = goods_labels[el.category_type]
        return <span className={a.color}><FontAwesomeIcon icon={a.icon} className="margin-right-icon" /></span>
    }

    const toggleFilter = (f) => {
        let oldlist = [...searchCategory]
        if (searchCategory.indexOf(f) < 0) {
            oldlist.push(f)
            setSearchCategory(oldlist)
        } else {
            oldlist.splice(searchCategory.indexOf(f), 1)
            setSearchCategory(oldlist)
        }
    }



    const handleIncrementDilution = (el) => {
        console.log(el)
        let editlist = [...element.element_list]
        const c = checkComponentPresence(el, editlist, 'id')
        if (!isNaN(editlist[c].dilution) && editlist[c].dilution < 100) {
            editlist[c].dilution += 1;
        } else {
            editlist[c].dilution = 100;
        }
        functions.edit({ name: 'element_list', value: editlist })
    }

    const handleDecrementDilution = (el) => {
        console.log(el)
        let editlist = [...element.element_list]
        const c = checkComponentPresence(el, editlist, 'id')
        if (!isNaN(editlist[c].dilution) && editlist[c].dilution > 1) {
            editlist[c].dilution -= 1;
        } else {
            editlist[c].dilution = 1;
        }
        functions.edit({ name: 'element_list', value: editlist })
    }


    const changeDilution = (el, value) => {
        let editlist = [...element.element_list]
        const c = checkComponentPresence(el, editlist, 'id')
        functions.edit({ name: 'element_list', value: editlist })

    }

    console.log("MODAL UI LIST", ui_list)


    return (
        <>

            <Form.Group as={Row} controlId="formStructureComponent" >
                <Col>
                    <Row className="mb-1">
                        <Col sm="2">
                        </Col>
                        <Col className="text-center">
                            <ButtonGroup aria-label="filter group">
                                <Button size="sm" disabled variant="secondary" ><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
                                <Button size="sm" variant={searchCategory.indexOf('equipment') >= 0 ? "primary" : "outline-secondary"} onClick={() => toggleFilter('equipment')}><span className="text-warning" ><FontAwesomeIcon icon='broom' className="margin-right-icon" /> {t('roomtype.labels.equipment_list')}</span></Button>
                                <Button size="sm" variant={searchCategory.indexOf('machine') >= 0 ? "primary" : "outline-secondary"} onClick={() => toggleFilter('machine')}> <span className="text-info" ><FontAwesomeIcon icon='tachometer-alt' className="margin-right-icon" /> {t('roomtype.labels.machine_list')}</span> </Button>
                                <Button size="sm" variant={searchCategory.indexOf('product') >= 0 ? "primary" : "outline-secondary"} onClick={() => toggleFilter('product')}> <span className="text-danger" ><FontAwesomeIcon icon='flask' className="margin-right-icon" /> {t('roomtype.labels.product_list')}</span></Button>
                                <Button size="sm" variant={searchCategory.indexOf('cleaningmaterial') >= 0 ? "primary" : "outline-secondary"} onClick={() => toggleFilter('cleaningmaterial')}><span className="text-dark" ><FontAwesomeIcon icon='square' className="margin-right-icon" /> {t('roomtype.labels.filtercleanig_material_list')}</span> </Button>
                                {searchCategory.length > 0 ?
                                    <Button size="sm" variant={searchCategory.length === 0 ? "outline-secondary" : "secondary"} onClick={() => setSearchCategory([])}><FontAwesomeIcon icon="times" /></Button>
                                    : ''}
                            </ButtonGroup>
                        </Col>
                        {canWrite ?
                            <Col className="text-right" sm="2">
                                {showComponentEdit ?
                                    <Button variant="outline-secondary" size="sm" onClick={() => handleCloseComponent()}><FontAwesomeIcon icon="check" />{t('structure.labels.component_modend')}</Button>
                                    :
                                    <Button variant="outline-primary" size="sm" onClick={() => handleShowNewComponent()}><FontAwesomeIcon icon="plus-square" />{t('roomtypes.labels.good_add')}</Button>
                                }
                            </Col>
                            : ''}
                    </Row>
                    {showComponentEdit ?
                        <Row className="border-bottom h5">
                            <Col sm="7">
                            </Col>
                            <Col>
                                <Row className="text-info">
                                    <Col>{t('operations.labels.goods_avaiable_list')}</Col>

                                </Row>
                            </Col>
                        </Row>
                        : ''}
                    <Row >

                        {element.element_list && element.element_list.length > 0 ?
                            <Col>
                                <Row className="mb-1" >
                                    <Col className="bg-secondary text-light ml-1">{t('global.labels.name')} </Col>
                                    <Col className="bg-secondary text-light ml-1">{t('global.labels.brand')} </Col>
                                    {canWrite ?
                                        <Col className="bg-secondary text-light ml-1 text-center" sm="4">{t('global.labels.actions')}</Col>
                                        : ''}
                                </Row>
                                <Row className="source_list_container">
                                    <Col>
                                        {element.element_list
                                            .filter(i => (searchCategory.length === 0 || searchCategory.indexOf(i.category_type) >= 0))
                                            .map((i, key) => (
                                                <Row key={key} className="border-bottom pb-1">
                                                    <Col>{getElementIcon(i)} {i.name}</Col>
                                                    <Col><i>{getBaseObjectValue(i.brand, 'name', locale)}</i> </Col>
                                                    {canWrite ?
                                                        <Col sm="4" className="text-center p-0">
                                                            {i.category_type === 'product' ?
                                                                <InputGroup>
                                                                    <InputGroup.Prepend>
                                                                        <Button size="sm" variant="outline-info" onClick={() => handleDecrementDilution(i)}><FontAwesomeIcon icon="minus" /> </Button>
                                                                    </InputGroup.Prepend>
                                                                    <Form.Control aria-describedby="basic-addon1" value={i.dilution} onChange={(ev) => changeDilution(i, ev.currentTarget.value)} />
                                                                    <InputGroup.Append>
                                                                        <InputGroup.Text >
                                                                            % diluizione
                                                                        </InputGroup.Text>
                                                                    </InputGroup.Append>
                                                                    <InputGroup.Append>
                                                                        <Button size="sm" variant="outline-info" onClick={() => handleIncrementDilution(i)}><FontAwesomeIcon icon="plus" /> </Button>
                                                                    </InputGroup.Append>
                                                                    <InputGroup.Append>
                                                                        <Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteElement(i)} /> </Button>
                                                                    </InputGroup.Append>
                                                                </InputGroup>
                                                                :
                                                                <Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteElement(i)} /> </Button>
                                                            }
                                                        </Col>
                                                        : <Col sm="4" className="text-center p-0">
                                                            {i.category_type === 'product'?
                                                            <>{i.dilution} % diluizione</>
                                                            :''}
                                                            </Col>}
                                                </Row>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            : <Col className="font-italic"> {t('operation.messages.no_element')}</Col>
                        }
                        {showComponentEdit ?
                            <>
                                <Col className="editSection">
                                    {ui_list.good_list && ui_list.good_list.length > 0 ?
                                        <>
                                            <Row className="mb-1" >
                                                <Col className="bg-info text-light ml-1 p-0 text-center" sm="1">{t('global.labels.add')}</Col>
                                                <Col className="bg-info text-light ml-1">{t('structure.labels.component_name')} </Col>
                                                <Col className="bg-info text-light ml-1">{t('global.labels.brand')} </Col>
                                            </Row>
                                            <Row className="source_list_container">
                                                <Col>
                                                    {ui_list.good_list
                                                        .filter(g => (searchCategory.length === 0 || searchCategory.indexOf(g.category_type) !== -1))
                                                        .map((i, key) => (
                                                            <Row key={key} className={styles.source_row}>
                                                                <Col className="p-0 text-center" sm="1">
                                                                    <AddListButton i={i} element_list={element.element_list} add_action={handleAddElement} />
                                                                </Col>
                                                                <Col>{getElementIcon(i)} {i.name}</Col>
                                                                <Col><i>{getBaseObjectValue(i.brand, 'name', locale)}</i> </Col>
                                                            </Row>
                                                        ))}

                                                </Col>
                                            </Row>
                                        </>
                                        : <i> {t('operation.messages.no_component')}</i>
                                    }
                                </Col>
                            </>
                            : ''
                        }
                    </Row>
                </Col>
            </Form.Group>

        </>
    )
}







/////////////////////////////////////////////////////////////////////////////////////
function ElementDpiListForm({ element, functions, t, ui_list, canWrite }) {




    const { locale } = useContext(LocaleContext)
    const { domain } = useContext(DomainContext)
    const {contract} = useContext(GlobalDataContext)

    const def_element_list = [
    ]
    const [element_list, setElementList] = useState(def_element_list)
    const [showComponentEdit, setShowComponentEdit] = useState(false)
    const [searchCategory, setSearchCategory] = useState([])

    const handleCloseComponent = () => setShowComponentEdit(false);

    const handleShowNewComponent = () => {
        setShowComponentEdit(true)
    }

    const handleDeleteDpi = (el) => {
        console.log(el)
        let editlist = element.dpi_list
        const c = checkComponentPresence(el, element.dpi_list, 'id')
        editlist.splice(c, 1)
        console.log("NEW DPI LIST", editlist)
        functions.edit({ name: 'dpi_list', value: editlist })
    }
    const handleAddDpi = (el) => {
        console.log(el)
        let editlist = element.dpi_list || []
        const c = checkComponentPresence(el, editlist, 'id')
        if (c >= 0) {
            alert('dpi present')
        } else {
            editlist.push({ ...el, risk_area_presence: [] })
        }
        functions.edit({ name: 'dpi_list', value: editlist })
    }




    const onChangeRa = (el, index) => {
        let edit_dpi = { ...element.dpi_list[index] }
        console.log(element.dpi_list, edit_dpi)
        if (edit_dpi.risk_area_presence.indexOf(el.value) === -1) {
            edit_dpi.risk_area_presence.push(el.value)
        } else {
            edit_dpi.risk_area_presence.splice(edit_dpi.risk_area_presence.indexOf(el.value))
        }
        let editlist = [...element.dpi_list]
        editlist.splice(index, 1, edit_dpi)
        functions.edit({ name: 'dpi_list', value: editlist })
    }


    return (
        <Form.Group as={Row}>
            <Col>
                <Row className="mb-1">
                    <Col sm="2">
                    </Col>
                    {canWrite ?
                        <Col className="text-right">
                            {showComponentEdit ?
                                <Button variant="outline-secondary" size="sm" onClick={() => handleCloseComponent()}><FontAwesomeIcon icon="check" />{t('structure.labels.component_modend')}</Button>
                                :
                                <Button variant="outline-primary" size="sm" onClick={() => handleShowNewComponent()}><FontAwesomeIcon icon="plus-square" />{t('operations.labels.dpi_add')}</Button>
                            }
                        </Col>
                        : ''}
                </Row>
                <Row className="border-bottom h5">
                    <Col sm="7">
                    </Col>
                    {showComponentEdit ?
                        <>
                            <Col>
                                <Row>
                                    <Col className="text-info">{t('operations.labels.dpi_avaiable_list')}</Col>

                                </Row>
                            </Col>
                        </>
                        : ''}
                </Row>
                <Row>

                    {element.dpi_list && element.dpi_list.length > 0 ?
                        <Col>
                            <Row className="mb-1" >
                                <Col className="bg-secondary text-light ml-1" sm={showComponentEdit ? "5" : "3"}>{t('global.labels.name')} </Col>
                                <Col className="bg-secondary text-light ml-1" sm={showComponentEdit ? "5" : "2"}>{t('global.labels.category')}</Col>
                                {!showComponentEdit ?
                                    <Col className="bg-secondary text-light ml-1 p-1 text-center">
                                        <Row>
                                            <Col>
                                            {contract.type ==='civil'?
                                                t('global.labels.standard_cleaning')
                                            :
                                                t('global.labels.risk_area')
                                            }
                                            </Col>
                                        </Row>
                                        <Row>
                                            {ui_list.risk_area_list.map((a, k) => (
                                                <Col key={k}>
                                                    <RiskareaBadge riskarea={a} />
                                                    {/*                                                     <Badge style={{ background: rgba2hex(a.color), border: "1px solid #fff" }}>&nbsp;&nbsp;</Badge> <br />
                                                    {a.name[locale]} */}
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                    : ''}
                                {canWrite ?
                                    <Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
                                    : ''}
                            </Row>
                            {element.dpi_list.map((di, key) => (
                                <Row key={key} className="border-bottom pb-1">

                                    <Col sm={showComponentEdit ? "5" : "3"}> {getValue(ui_list.dpi_dict[di.id], 'name')}</Col>
                                    <Col sm={showComponentEdit ? "5" : "2"}>
                                        {getBaseObjectValue(ui_list.dpi_dict[di.id].category, 'name', locale)}
                                    </Col>
                                    {!showComponentEdit ?
                                        <Col>
                                            <Form.Group as={Row} controlId={"interventionFrequency" + di.id} className="pb-1">
                                                {ui_list.risk_area_list.map((a, k) => (
                                                    <Col key={k} className="text-center">
                                                        <Form.Check type="checkbox" name="risk_area_presence" checked={di.risk_area_presence.indexOf(a.Id) >= 0} value={a.Id} alt={a.name[locale]} onChange={(event) => onChangeRa(event.currentTarget, key)} />
                                                    </Col>
                                                ))}
                                            </Form.Group>
                                        </Col>
                                        : ''}
                                    {canWrite ?
                                        <Col sm="1" className="text-center">
                                            <Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteDpi(di)} /> </Button>
                                        </Col>
                                        : ''}
                                </Row>
                            ))
                            }
                        </Col>
                        : <Col className="font-italic"> {t('operation.messages.no_element')}</Col>
                    }
                    {showComponentEdit
                        ?
                        <>
                            <Col className="border-left editSection">
                                {ui_list.dpi_list && ui_list.dpi_list.length > 0 ?
                                    <>
                                        <Row className="mb-1" >
                                            <Col className="bg-secondary text-light ml-1 p-0 text-center" sm="1">{t('global.labels.add')}</Col>
                                            <Col className="bg-secondary text-light ml-1">{t('structure.labels.component_name')} </Col>
                                        </Row>
                                        <Row className="source_list_container">
                                            <Col className="pr-4">
                                                {ui_list.dpi_list.map((one_dpi, key) => (
                                                    <>
                                                        {searchCategory.length === 0 || searchCategory.indexOf(one_dpi.category_type) >= 0 ?
                                                            <Row key={key} className={styles.source_row}>
                                                                <Col className="p-0 text-center" sm="1">
                                                                    <AddListButton i={one_dpi} element_list={element.dpi_list} add_action={handleAddDpi} />
                                                                </Col>
                                                                <Col>{one_dpi.name}</Col>
                                                                <Col>{getBaseObjectValue(one_dpi.category, 'name', locale)}</Col>
                                                            </Row>
                                                            : ''}
                                                    </>
                                                ))}

                                            </Col>
                                        </Row>
                                    </>
                                    : <i> {t('operation.messages.no_dpi')}</i>
                                }
                            </Col>
                        </>
                        : ''
                    }
                </Row>
            </Col>
        </Form.Group>

    )
}



export default translate()(ModalOperation)