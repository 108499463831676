import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, ButtonGroup, Row, Col, Form, Button, Card, Spinner } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"


import DomainContext from '../contexts/domainContext';
import GlobalDataContext from '../contexts/globaldataContext';


import {  getSelectedElement, checkComponentPresence, rgba2hex, compareName, getFloatNumber, getValue, getStructureFromWorkareas } from '../../util/ui_utils';
import { ItemProgress, ServiceBadge, RiskareaBadge, ContainerInfoPopover, RoomInfoBadge, ModalInitButton } from '../../components/StructureGraphicElements.js'

import { getCleaningCompanyList  } from "../../Firebase2/Storage2/dbCleaningCompany"




const newworkareareferent = {
	name: '',
	role: '',
	active: true
}



export const ModalReferent = ({ t, functions, curWorkarea, is_new, canWrite, user_list }) => {
	//-----referent form
	const [showEditReferent, setShowEditReferent] = useState(false);
	const [currentEditReferent, setCurrentEditReferent] = useState({})


	const handleShowNewReferent = () => {
		setShowEditReferent(true)
		setCurrentEditReferent(newworkareareferent)
	}

	const handleEditOneReferent = (i) => {
		setShowEditReferent(true)
		setCurrentEditReferent(i)
	}


	const handleCloseReferent = () => setShowEditReferent(false);
	const handleSaveReferent = () => {
		const editlist = [...curWorkarea.referent_list]
		if (is_new) {
			//			currentEditReferent.id = nextId()
			console.log('ADD', currentEditReferent)
			editlist.push(currentEditReferent)
		} else {
			const c = checkComponentPresence(currentEditReferent, curWorkarea.referent_list, 'id')
			editlist.splice(c, 1, currentEditReferent)

		}
		//		setCurWorkarea({ ...curWorkarea, 'referent_list': editlist })
		functions.edit({ name: 'referent_list', value: editlist })
		setShowEditReferent(false);
	}


	const onChangeHandlerReferent = (el) => {
		console.log(el)
		setCurrentEditReferent(el)
	}
	const onChangeUser = (el) => {
		console.log(el)
		setCurrentEditReferent({ ...currentEditReferent, user_id: el.id, user_displayName: el.displayName })

	}


	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} title_new={t('workareas.buttons.add_workarea_referent')}
				action_new={handleShowNewReferent} action_edit={handleEditOneReferent} action_info={handleEditOneReferent} />
			<Modal show={showEditReferent} onHide={handleCloseReferent} size="xl" backdrop="static" className="bg-secondary">
				<Modal.Header closeButton>
					{is_new && <Modal.Title>{t('workareas.labels.new_referent')} </Modal.Title>}
					{!is_new && <Modal.Title>{t('workareas.labels.edit_referent')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<ReferentEditForm element={currentEditReferent} functions={{ edit: onChangeHandlerReferent, edituser: onChangeUser }} t={t} user_list={user_list} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseReferent}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className="btn-myprimary" form="companyForm" onClick={handleSaveReferent}>
							<FontAwesomeIcon icon="save" />  {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>

	)
}

/////////////////////////////////////////////////////////////////////////////////////////////////////
function ReferentEditForm({ element, functions, t, user_list }) {
	const { domain } = useContext(DomainContext)

	const { contractId, structureElements } = useContext(GlobalDataContext)
	//	const [myreferent_role_list, setReferentRoleList] = useState(referent_role_list)
	const [element_list, setElementList] = useState([])
	const [cleaningcompany_list, setCleaningCompanyList] = useState([])
	const initNextValue = async () => {
		let _element_list = []
		for (const s in structureElements.structure_list) {
			console.log(s)
			const ss = structureElements.structure_list[s]
			if (ss.referent_list) {
				_element_list = [
					..._element_list,
					...ss.referent_list.map(r => ({ ...r, id: r.id ? r.id : r.Id, structure_name: ss.name, structure_id: ss.id }))
				]
			}
		}
		/*		for (let c = 0; c < cleaningcompany_list.length; c++) {
					const newelement_list = await getEmployerList2(domain, contractId, { companyId: c.id })
					console.log("employeslist", newelement_list)
				//	setElementList([...element_list, ...newelement_list])
				}
				*/
		console.log(_element_list)
		setElementList(_element_list)
	}

	const initValues = async () => {
		const newcelement_list = await getCleaningCompanyList(domain, contractId)
		setCleaningCompanyList(newcelement_list)
		//		const role_list = await getBaseObjectTypeList2(domain, 'referent_role')
		//		setReferentRoleList(role_list)
	}
	useEffect(() => {
		initNextValue()
	}, [cleaningcompany_list])


	useEffect(() => {
		//		initValues()
		initNextValue()
	}, [])


	return (

		<>
			<Form className="" >
				<Form.Group as={Row}>
					<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
					<Col sm="8">
						<Form.Control size="sm" as="select" onChange={(event) => functions.edit(getSelectedElement(event.target.value, element_list, 'id'))}  >
							<option value="-1">{t('workarea.labels.select_referent')}</option>
							{element_list.map((i, k) => (
								<option key={k} value={i.id}>[{i.structure_name}] {i.lastname} {i.firstname}</option>
							))}
						</Form.Control>
					</Col>

				</Form.Group>
				<Form.Group as={Row}>
					<Form.Label column sm="2">{t('global.labels.claudit_user')}</Form.Label>
					<Col sm="8">
						<Form.Control size="sm" as="select" onChange={(event) => functions.edituser(getSelectedElement(event.target.value, user_list, 'id'))}  >
							<option value="-1">{t('workarea.labels.select_user')}</option>
							{user_list.map((i, k) => (
								<option key={k} value={i.id}>{i.displayName}</option>
							))}
						</Form.Control>
					</Col>

				</Form.Group>
			</Form>
			{/* 			<Form className="" >
				<Form.Group as={Row} controlId="companyName">
					<Form.Label column sm="2">{t('global.labels.role')}</Form.Label>
					<Col sm="8">
						<Form.Control size="sm" as="select" >
							<option value="-1">{t('global.labels.select_role')}</option>;
						{myreferent_role_list.map((i, k) => (
								<option value={i.Id}>{i.name[locale]}</option>

							))}
						</Form.Control>
					</Col>
					<Col sm="2">
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'referent_role', list: myreferent_role_list }} t={t} functions={{ setCurSection: updateData }} />
					</Col>
				</Form.Group>
			</Form> */}
		</>
	)

}
