import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, ButtonGroup, Badge, InputGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import update from 'immutability-helper'
import { format, setDay } from 'date-fns'
import { it } from 'date-fns/locale'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import styles from './constants/styles.js'

import DomainContext from './contexts/domainContext'
import LocaleContext from './contexts/locale.js'
import GlobalDataContext from './contexts/globaldataContext.js';

import ModalOperation from './ModalOperation'
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"

import { ServiceBadge } from "../components/StructureGraphicElements"
import { getSelectedElement, checkComponentPresence, compareName, getValue } from "../util/ui_utils.js"

import FrequencySelector from './FrequencySelector'






///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const checkValidity = (element) => {
	let isvalid = true
	if (!element.service.id) {
		isvalid = false
	}
	if (!element.operation_list || element.operation_list.length === 0) {
		isvalid = false
	}

	return isvalid
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalRoomtypeService({ item, setItem, t, is_new, small, index, item_list, setParentList, ui_list, canWrite }) {
	const { domain } = useContext(DomainContext)
	const { service_dict } = useContext(GlobalDataContext)

	const newroomservice = {
		name: '',
		code: '',
		service: {},
		element_list: [],
		weekdays: [],
		hourlyyield: 0,
		frequency: { element: 'week', times: 1 },
		check_list: {},
		lqa: 0,
		color: { r: 255, g: 255, b: 255, a: 1 }
	}

	const [service_list, setServiceList] = useState([])
	const [showServiceEdit, setShowServiceEdit] = useState(false)
	const [curElement, setCurElement] = useState(newroomservice)
	const [editedElement, setEditedElement] = useState(false)



	const handleCloseService = () => setShowServiceEdit(false);

	const handleSaveService = async () => {

		setShowServiceEdit(false);
		if (is_new) {
			setItem(curElement)
		} else {
			setItem(curElement, index)

		}
	}


	const handleShowNewService = () => {
		console.log('show new')
		setCurElement(newroomservice)
		setShowServiceEdit(true)
		setEditedElement(false)
	}


	const handleShowEditService = () => {
		if (!item.color) {
			item.color = { ...newroomservice.color }
		}
		if (!item.weekdays) {
			item.weekdays = []
		}
		console.log(item)
		setCurElement(item)
		setShowServiceEdit(true)
		setTimeout(function () {
			setEditedElement(false)

		}, 100)
	}


	const onChangeHandlerElement = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curElement[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	const initValues = async () => {
		let servicelist = Object.values(service_dict)
		servicelist.sort(compareName)
		setServiceList(servicelist)
	}

	useEffect(() => {
		initValues()
	}, [])


	const onUpdateFrequency = (el, service) => {
		console.log(el, service)
		let { name, value } = el
		let frequency = { ...curElement.frequency }
		if (frequency) {
			frequency[name] = value
		} else {
			frequency = { times: 1, element: 'day' }
			frequency[name] = value
		}
		onChangeHandlerElement({ name: 'frequency', value: frequency })
	}


	const selectAllDay = () => {
		onChangeHandlerElement({ name: 'weekdays', value: ['0', '1', '2', '3', '4', '5', '6'] })
	}
	const unSelectAllDay = () => {
		onChangeHandlerElement({ name: 'weekdays', value: [] })
	}




	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNewService()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNewService()}><FontAwesomeIcon icon='plus-square' /> {t('structure.labels.activity_add')}</Button>
				)
				: <Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowEditService()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<Modal show={showServiceEdit} onHide={handleCloseService} dialogClassName="custom-modal" backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('roomtypes.labels.new_activity')}</span>
							</>
							:
							<>
								<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('roomtypes.labels.edit_activity')}</span>
							</>
						}&nbsp;
{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.service')} </Form.Label>
							<Col sm="4">
								<Form.Control as="select" className="mb-2" name="service" value={getValue(curElement.service, 'id')} onChange={(event) => onChangeHandlerElement({ name: 'service', type: 'select', value: getSelectedElement(event.target.value, service_list, 'id') })} >
									<option value="-1" >{t('roomtype.labels.select_service')}</option>
									{service_list

										? [Object(service_list).map((f, k) => (
											<option key={k} value={f.id}>{f.name} </option>
										))]

										: ''
									}
								</Form.Control>
							</Col>
							<Col sm="2">
								{curElement.service ?
									<ServiceBadge service={curElement.service} freq={true} />
									: ''}

							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formStructureCF" className="border-bottom">
							<Form.Label column sm="2">{t('structure.labels.hourlyield')}</Form.Label>
							<Col sm="4">
								<InputGroup className="mb-2" >
									<Form.Control type="number" min="0" step="0.1" placeholder="" value={curElement.hourlyyield} name="hourlyyield" onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
									<InputGroup.Append>
										<InputGroup.Text>
											m<sup>2</sup> / h
                							</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2"> {t('service.labels.frequency')}</Form.Label>
							<Col>
								{curElement.service ?
									<>
										{curElement.service.type === 'periodic' ?
											<FrequencySelector element={curElement} functions={{ edit: onUpdateFrequency }} />
											:
											[curElement.service.type === 'ordinary' ?
												<Form.Group as={Row} className="pb-1">
													<Col sm="1">
														<Form.Check type="checkbox" name="weekdays" checked={curElement.weekdays.indexOf("1") >= 0} value="1" label={format(setDay(new Date(), 1), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
													</Col>
													<Col sm="1">
														<Form.Check type="checkbox" name="weekdays" checked={curElement.weekdays.indexOf("2") >= 0} value="2" label={format(setDay(new Date(), 2), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
													</Col>
													<Col sm="1">
														<Form.Check type="checkbox" name="weekdays" checked={curElement.weekdays.indexOf("3") >= 0} value="3" label={format(setDay(new Date(), 3), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
													</Col>
													<Col sm="1">
														<Form.Check type="checkbox" name="weekdays" checked={curElement.weekdays.indexOf("4") >= 0} value="4" label={format(setDay(new Date(), 4), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
													</Col>
													<Col sm="1">
														<Form.Check type="checkbox" name="weekdays" checked={curElement.weekdays.indexOf("5") >= 0} value="5" label={format(setDay(new Date(), 5), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
													</Col>
													<Col sm="1">
														<Form.Check type="checkbox" name="weekdays" checked={curElement.weekdays.indexOf("6") >= 0} value="6" label={format(setDay(new Date(), 6), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
													</Col>
													<Col sm="1">
														<Form.Check type="checkbox" name="weekdays" checked={curElement.weekdays.indexOf("0") >= 0} value="0" label={format(setDay(new Date(), 0), 'cccccc', { locale: it })} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
													</Col>
													<Col>
														<ButtonGroup>
															<span className="btn btn-outline-info btn-sm" onClick={selectAllDay} ><FontAwesomeIcon icon="check-square" /> {t('global.buttons.select_all')}</span>
															<span className="btn btn-outline-info btn-sm" onClick={unSelectAllDay} ><FontAwesomeIcon icon="square" /> {t('global.buttons.unselect_all')}</span>
														</ButtonGroup>

													</Col>
												</Form.Group>
												: ''
											]
										}
									</>
									: <i>selezionare una prestazione per poterne impostare la frequenza</i>
								}
							</Col>
						</Form.Group>
						<ElementFormOneServiceOperations element={curElement} functions={{ edit: onChangeHandlerElement }} t={t} />

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseService}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button disabled={!checkValidity(curElement)} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveService}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormOneServiceOperations({ element, functions, t }) {
	const { domain } = useContext(DomainContext)
	const { operation_dict } = useContext(GlobalDataContext)

	const [source_element_list, setSourceElementList] = useState([])
	const [showComponentEdit, setShowComponentEdit] = useState(false)

	const handleCloseComponent = () => setShowComponentEdit(false);

	const handleShowNewComponent = () => {
		setShowComponentEdit(true)
	}



	const handleAddOperation = (el) => {
		console.log(el, element.operation_list)
		let editlist = element.operation_list || []
		if (editlist) {
			const c = checkComponentPresence(el, element.operation_list, 'id')
			if (c === -1) {
				editlist.push({ ...el, quantity: 1 })
			}
		} else {
			editlist = [{ ...el, quantity: 1 }]
		}
		functions.edit({ name: 'operation_list', value: editlist })
	}


	const handleUpdateFromList = async (el) => {
		//		let editoperation_list = element.operation_list
		functions.edit({ name: 'operation_list', value: el })
	}

	const initValues = async () => {
		let newelement_list = Object.values(operation_dict)
		newelement_list.sort(compareName)
		setSourceElementList([...newelement_list])
	}

	useEffect(() => {
		initValues()
	}, [])


	return (
		<>

			<Form.Group as={Row} controlId="formStructureComponent" className="border-bottom">
				<Col>
					<Row className="border-bottom h5 bg-info text-light">
						<Col sm="7">
							{t('roomtypes.labels.operation_list')}
						</Col>
						{showComponentEdit ?
							<Col className="text-right">
								<ModalOperation is_new={true} t={t} item={source_element_list} setItem={setSourceElementList} />
							</Col>
							: ''}
					</Row>
					<Row className="mb-1">
						<Col className="text-right">
							{showComponentEdit ?
								<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComponent()}><FontAwesomeIcon icon="check" />{t('service.labels.component_modend')}</Button>
								:
								[element.service && element.service.id ?
									<Button variant="outline-primary" size="sm" onClick={() => handleShowNewComponent()}><FontAwesomeIcon icon="edit" />{t('global.labels.start_selection')}</Button>
									:
									<i>selezionare una prestazione per poter aggiungere un'operazione a questa attività</i>
								]
							}
						</Col>
					</Row>
					<Row className="border-right mb-1">

						{element.operation_list && element.operation_list.length > 0 ?
							<Col>
								<Row className="mb-1">
									<Col className="bg-secondary text-light ml-1">{t('global.labels.name')} </Col>
									<Col className="bg-secondary text-light ml-1">{t('operations.labels.actions')}</Col>
									<Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
								</Row>
								<DndProvider backend={HTML5Backend}>
									<OperationListContainer
										element_id={element.id}
										operations={element.operation_list}
										element={element}
										t={t}
										functions={{ update: handleUpdateFromList }}
									/>
								</DndProvider>
							</Col>
							: <Col className="font-italic">

								<Badge variant="warning" size="md">
									<FontAwesomeIcon icon="exclamation-triangle" />
								</Badge>&nbsp;{t('roomtype.messages.no_operation')}
							</Col>
						}
						{showComponentEdit
							?
							<>
								<Col className="border-left editSection">
									{source_element_list && source_element_list
										.filter(i => (i.type_list && i.type_list.some(r => (element.service.type.indexOf(r) >= 0)))).length > 0 ?
										<>
											<Row >
												<Col className="bg-secondary text-light ml-1 p-1 text-center" sm="1">{t('global.labels.add')}</Col>
												<Col className="bg-secondary text-light ml-1">{t('structure.labels.component_name')} </Col>
											</Row>
											{source_element_list.filter(i => (i.type_list && i.type_list.some(r => (element.service.type.indexOf(r) >= 0)))).map((operation, key) => (
												<Row key={key} className={styles.source_row}>
													<Col className="p-0 text-center" sm="1">
														{checkComponentPresence(operation, element.operation_list, 'id') !== -1 ?
															<Button size="sm" variant="secondary" disabled><FontAwesomeIcon icon="check" /><FontAwesomeIcon icon="plus-square" /> </Button>
															:
															<Button size="sm" variant="light" onClick={() => handleAddOperation(operation)}><FontAwesomeIcon icon="arrow-left" /><FontAwesomeIcon icon="plus-square" /> </Button>
														}
													</Col>
													<Col>{operation.name}</Col>
												</Row>))
											}
										</>
										: <i> {t('structure.messages.no_service_operation')}</i>
									}
								</Col>
							</>
							: ''
						}
					</Row>
				</Col>
			</Form.Group>

		</>
	)
}




const ItemTypes = {
	CARD: 'card',
}
const cardstyle = {
	cursor: 'move',
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OperationEditRow = ({ id, card, index, moveCard, removeCard, element }) => {
	const ref = useRef(null)

	const [cardHover, toggleHover] = useState(false)

	const [, drop] = useDrop({
		accept: ItemTypes.CARD,
		hover(item, monitor) {
			if (!ref.current) {
				return
			}
			const dragIndex = item.index
			const hoverIndex = index
			if (dragIndex === hoverIndex) {
				return
			}
			const hoverBoundingRect = ref.current?.getBoundingClientRect()
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			const clientOffset = monitor.getClientOffset()
			const hoverClientY = clientOffset.y - hoverBoundingRect.top
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			moveCard(dragIndex, hoverIndex)
			item.index = hoverIndex
		},
	})
	const [{ isDragging }, drag] = useDrag({
		item: { type: ItemTypes.CARD, id, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})
	drag(drop(ref))
	return (
		<Row ref={ref} className={isDragging ? ' border border-primary text-light' : 'border-bottom mb-1 pb-1'} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
			<Col>
				<Button className="mr-1" variant="secondary" size="sm" >{index + 1}</Button>
				{card.name}
			</Col>
			<Col>
				<OperationSummary element={card} />
			</Col>
			<Col sm="1 p-0 text-center">
				{cardHover ?
					<>
						<Button variant="outline-danger" size="sm" onClick={() => removeCard({ index: index, card: card })}><FontAwesomeIcon icon='trash' /> </Button>
					</> : ''
				}
			</Col>
		</Row>

	)
}




////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OperationListContainer = ({ operations, i_index, element, functions, t }) => {
	const childRefDelete = useRef()

	const [operation_list, setOperationList] = useState(operations)

	const moveOperation = useCallback(
		(dragIndex, hoverIndex) => {
			const dragOperation = operation_list[dragIndex]
			console.log(dragOperation, dragIndex)
			setOperationList(
				update(operation_list, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragOperation],
					],
				}),
			)
		},
		[operation_list],
	)
	const removeOperation = useCallback(
		(data) => {
			const dragOperation = operation_list[data.index]
			const cb = () => {
				console.log("removeCard, cb", dragOperation, data)
				setOperationList(
					update(operation_list, {
						$splice: [
							[data.index, 1]
						],
					}),
				)
			}
			childRefDelete.current.confirmDelete(t('operation.messages.delete_operation_title'), t('operation.messages.delete_operation_message'), cb)
			//			functions.delete(cb)
		},
		[operation_list],
	)
	const renderOperation = (card, index) => {
		return (
			<OperationEditRow
				key={card.id}
				index={index}
				id={card.id}
				card={card}
				element={element}
				moveCard={moveOperation}
				removeCard={removeOperation}
			/>
		)
	}
	useEffect(() => {
		console.log('new rooms', operation_list)
		functions.update(operation_list)
	},
		[operation_list]
	);
	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />
			{operation_list && operation_list.length > 0
				? <div style={cardstyle}>
					{operation_list.map((operation, c) => renderOperation(operation, c, element))}
				</div>
				: <i>{t('roomtype.messages.no_operation')}</i>
			}
		</>
	)
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OperationSummary = ({ element }) => {
	if (element.action_list && element.action_list.length > 0) {

		return (
			<>
				{element.action_list.map((a, k) => (

					<Row key={k}><Col><Badge variant="secondary">{k + 1}</Badge> {a.description}</Col></Row>
				))}
			</>
		)
	} else {
		return (

			<></>
		)
	}

}






export default translate()(ModalRoomtypeService)