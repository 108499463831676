import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, Button, ButtonGroup, OverlayTrigger, Popover, ListGroup, Badge, Card } from 'react-bootstrap';

import { controlCoefficient_dict, roomComposition_dict, timingMode_dict } from "../components/constants/global_definitions"

import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import { ElementFormNoncomplianceAction } from "../components/ElementFormNoncomplianceAction"

import { checkComponentPresence, compareName, getValue, getSelectedElement } from "../util/ui_utils.js"
import { AddListButton } from '../components/StructureGraphicElements.js'




import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import {
	addFurniture as addFurniture2,
	getFurnitureList as getFurnitureList2,
	editFurniture as editFurniture2,
	deleteFurniture as deleteFurniture2,

	addEnvironmentElement as addEnvironmentElement2,
	getEnvironmentElementList as getEnvironmentElementList2,
	editEnvironmentElement as editEnvironmentElement2,
	deleteEnvironmentElement as deleteEnvironmentElement2,

	addEconomicalMaterial as addEconomicalMaterial2,
	getEconomicalMaterialList as getEconomicalMaterialList2,
	editEconomicalMaterial as editEconomicalMaterial2,
	deleteEconomicalMaterial as deleteEconomicalMaterial2
} from '../Firebase2/Storage2/dbRoomMaterial';



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function ElementOperationChecks({ element, functions, t, is_type, is_operation, canWrite }) {
	const { domain } = useContext(DomainContext)
	const { contract, messagegroup_list } = useContext(GlobalDataContext)
	const contractId = contract.id
	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const roomcomposition_dict = roomComposition_dict({ t })
	const newcomponent = {
		name: '',
		type: '',
		control_list: [],
		quantity: 1
	}
	const newgood = {
		name: '',
		control_list: [],
		category: '',
	}




	const [furniture_list, setFurnitureList] = useState([])
	const [environment_element_list, setEnvironmentElementList] = useState([])
	const [economic_material_list, setEconomicMaterialList] = useState([])
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [newComponentEdit, setNewComponentEdit] = useState(true)
	const [curComponent, setCurComponent] = useState(newcomponent)
	const [showGoodEdit, setShowGoodEdit] = useState(false)
	const [curGood, setCurGood] = useState(newgood)
	const [newEdit, setNewEdit] = useState(true)



	const handleCloseComposition = () => setShowComponentEdit(false);

	const handleOpenComposition = () => {
		setCurComponent(newcomponent)
		setNewComponentEdit(true)
		setShowComponentEdit(true)
	}

	//---------------generic function
	const handleAddEl = (el, section) => {
		console.log("ADD EL", section, el)
		let editlist = element[section]
		if (editlist) {
			const c = checkComponentPresence(el, element[section], 'id')
			if (c >= 0) {
				//				editlist[c].quantity = parseInt(editlist[c].quantity) + 1
			} else {
				if (el.name.indexOf('cestino') >= 0) {
					el.is_trash = true
				}
				editlist.push({ ...el, quantity: 1 })
			}
		} else {
			editlist = [{ ...el, quantity: 1 }]
		}
		functions.edit({ name: section, value: editlist })
	}

	const handleSubtractEl = (el, section) => {
		console.log("SUB EL", section, el)
		let editlist = element[section]
		const c = checkComponentPresence(el, element[section], 'id')
		if (editlist[c].quantity > 1) {
			editlist[c].quantity = parseInt(editlist[c].quantity) - 1
		} else {

		}
		functions.edit({ name: section, value: editlist })
	}


	const handleChangeElementControl = (el, section) => {
		console.log("control", el, section, el.index, element[section], element[section].control_list)
		let editlist = element[section]
		try {
			editlist[el.index].control_list = el.value
			functions.edit({ name: section, value: editlist })
		} catch (err) {

		}
	}
	const handleDeleteElControl = (index, control_index, list) => {
		let editlist = element[list]
		let editcontrol = editlist[index].control_list
		editcontrol.splice(control_index, 1)
		editlist[index].control_list = editcontrol
		functions.edit({ name: list, value: editlist })
	}

	const handleDeleteEl = (el, list) => {
		console.log(el, list)
		const cb = () => {
			let editlist = element[list]
			const c = checkComponentPresence(el, element[list], 'id')
			editlist.splice(c, 1)
			functions.edit({ name: list, value: editlist })
		}

		childRefDelete.current.confirmDelete(t('operations.messages.delete_control_title'), t('operations.messages.delete_control'), cb)
	}

	const handleChangeParamEl = (el, event, list) => {
		let editlist = element[list]
		const c = checkComponentPresence(el, element[list], 'id')
		let { name, value, type } = event
		console.log(name, value, type)
		if (element.furniture_list[c][name]) {
			element[list][c][name] = false
		} else {
			if (name === 'in_patient_unit') {
				element[list][c].quantity = element.patient_unit
			}
			element[list][c][name] = true
		}
		functions.edit({ name: list, value: editlist })
	}

	const onUpdatePatientQuantity = (el) => {
		const value = el.value
		let editlist_f = [...element.furniture_list]
		let editlist_e = [...element.environment_element_list]
		editlist_e.forEach(e => {
			if (e.in_patient_unit) {
				e.quantity = value
			}
		})
		editlist_f.forEach(e => {
			if (e.in_patient_unit) {
				e.quantity = value
			}
		})
		functions.setItem({ ...element, patient_unit: value, furniture_list: editlist_f, environment_element_list: editlist_e })
	}
	//----good

	const handleShowNewGood = (list) => {
		newgood.good_type = list
		setCurGoodSection(roomcomposition_dict[list])
		setCurGood(newgood)
		setNewEdit(true)
		setShowGoodEdit(true)
	}

	const handleShowEditGood = (i, list) => {
		i.good_type = list
		setCurGoodSection(roomcomposition_dict[list])
		setCurGood(i)
		setNewEdit(false)
		setShowGoodEdit(true)
	}

	const handleCloseGood = () => setShowGoodEdit(false);

	const onChangeHandlerGood = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurGood({ ...curGood, [name]: value })
	}





	const handleSaveGood = async () => {
		console.log(curGoodSection, newEdit, curGood)
		if (curGoodSection.id === 'furniture_list') {
			if (newEdit) {
				let return_data = await addFurniture2(domain, contractId, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...furniture_list]
					newlist.push(return_data)
					setFurnitureList(newlist)
					setShowGoodEdit(false);
				}
			} else {
				let return_data = await editFurniture2(domain, contractId, curGood.id, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...furniture_list]
					const c = checkComponentPresence(curGood, furniture_list, 'id')
					newlist.splice(c, 1, curGood)
					setFurnitureList(newlist)
					setShowGoodEdit(false);
				}
			}


		} else if (curGoodSection.id === 'environment_element_list') {
			if (newEdit) {
				let return_data = await addEnvironmentElement2(domain, contractId, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...environment_element_list]
					newlist.push(return_data)
					setEnvironmentElementList(newlist)
					setShowGoodEdit(false);
				}
			} else {
				let return_data = await editEnvironmentElement2(domain, contractId, curGood.id, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...environment_element_list]
					const c = checkComponentPresence(curGood, environment_element_list, 'id')
					newlist.splice(c, 1, curGood)
					setEnvironmentElementList(newlist)
					setShowGoodEdit(false);
				}
			}

		} else if (curGoodSection.id === 'economic_material_list') {
			if (newEdit) {
				let return_data = await addEconomicalMaterial2(domain, contractId, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...economic_material_list]
					newlist.push(return_data)
					setEconomicMaterialList(newlist)
					setShowGoodEdit(false)
				}
			} else {
				let return_data = await editEconomicalMaterial2(domain, contractId, curGood.id, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...economic_material_list]
					const c = checkComponentPresence(curGood, economic_material_list, 'id')
					newlist.splice(c, 1, curGood)
					setEconomicMaterialList(newlist)
					setShowGoodEdit(false)
				}
			}

		}

	}




	const handleDeleteGood = (el, category_type) => {
		console.log('delete')
		const cb = async () => {
			let return_data
			if (category_type === 'furniture_list') {
				const editlist = [...furniture_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				return_data = await deleteFurniture2(domain, contractId, el.id)
				setFurnitureList(editlist)

			} else if (category_type === 'environment_element_list') {
				return_data = await deleteEnvironmentElement2(domain, contractId, el.id)
				const editlist = [...environment_element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setEnvironmentElementList(editlist)

			} else if (category_type === 'economic_material_list') {
				return_data = await deleteEconomicalMaterial2(domain, contractId, el.id)
				const editlist = [...economic_material_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setEconomicMaterialList(editlist)

			}
			console.log(return_data)
		}
		console.log(el)
		childRefDelete.current.confirmDelete(roomcomposition_dict[category_type].delete_title, roomcomposition_dict[category_type].delete, cb)
	}


	const [ui_list, setUiList] = useState({})
	const initValues = async () => {
		let floor_list = await getBaseObjectTypeList2(domain, 'floors')
		floor_list = floor_list.sort(compareName)
		let newfurniture_list = await getFurnitureList2(domain, contractId)
		let newenvironment_element_list = await getEnvironmentElementList2(domain, contractId)
		let neweconomic_material_list = await getEconomicalMaterialList2(domain, contractId)
		setUiList({ ...ui_list, floor_list })
		setFurnitureList(newfurniture_list.sort(compareName))
		setEnvironmentElementList(newenvironment_element_list.sort(compareName))
		setEconomicMaterialList(neweconomic_material_list.sort(compareName))
	}



	const [curGoodSection, setCurGoodSection] = useState(roomcomposition_dict['furniture_list'])


	//---------------------------------------------------------------------------------------------------
	const handleImportChecks = (source_list) => {
		console.log("pre import", element.control_list, source_list)
		let newcontrol_list = []
		if (element.control_list) {
			newcontrol_list = element.control_list || []
		}
		newcontrol_list = [...newcontrol_list, ...source_list]
		//		functions.edit({ name: section.id, value: newprocess_section_list })

		//		console.log("AFTER import", global_section, section.id, newprocess_section_list)

	}


	const OneRoomElement = ({ i, k, section, showComponentEdit, canWrite }) => {
		//console.log("GOOD LLIST", section, element_list)
		return (

			<Row key={k} className="border-bottom mb-1 pb-1">
				<Col>{i.name}</Col>
				{section === 'economic_material' && !is_type ?
					<>
						<Col>
							<Form.Control size="sm" type="text" defaultValue={i.istatus} name="status" onChange={(event) => handleChangeParamEl(i, event.target, section)} />
						</Col>
						<Col>
							<Form.Control size="sm" type="text" defaultValue={i.badge} name="badge" onChange={(event) => handleChangeParamEl(i, event.target, section)} />
						</Col>
					</>
					: ''}

				{!showComponentEdit ?
					<Col>

						{canWrite ?
							<Row>
								<Col className="text-right">
									<ButtonGroup>
										<ModalImportChecks section={section} dest_operation_id={element.id} functions={{ import: handleImportChecks }} t={t} />
										<ModalControl is_new={true} t={t} item={i} index={k} parent_index={k} setItem={handleChangeElementControl} section={section} canWrite={canWrite} />
									</ButtonGroup>
								</Col>
							</Row>
							: ''}

						{i.control_list && i.control_list.length > 0 ?
							<>
								<Row className="mb-1 section_title bg-light">
									<Col className="ml-1">{t('global.labels.description')} </Col>
									<Col className="ml-1" sm="3">{t('operation.labels.coefficient')} </Col>
									<Col sm="2" className="ml-1 text-center">{t('global.labels.actions')}</Col>
								</Row>
								{i.control_list.map((c, ckey) => (
									<Row key={ckey}>
										<Col>
											{c.description}

											{c.action_list && c.action_list.length > 0 ?
												<OverlayTrigger
													placement="auto"
													delay={{ show: 250, hide: 200 }}
													overlay={renderCheckActionListPopover(c.action_list, t, messagegroup_list)}
												>
													<Badge variant="secondary" className="ml-1">
														A.C.<FontAwesomeIcon icon="check" />
													</Badge>
												</OverlayTrigger>
												: ''}

										</Col>
										<Col sm="3">{c.coefficient}</Col>
										<Col sm="2" className="text-center">
											<ButtonGroup>
												<ModalControl t={t} item={c} index={ckey} parent_list={i.control_list} parent_index={k} setItem={handleChangeElementControl} section={section} canWrite={canWrite} />
												{canWrite ?
													<Button size="sm" variant="danger" onClick={(el) => handleDeleteElControl(k, ckey, section)}><FontAwesomeIcon icon="trash" /> </Button>
													: ''}
											</ButtonGroup>
										</Col>
									</Row>))
								}
							</>
							: <i> {t('operation.messages.no_control')}</i>
						}
					</Col>
					: ''}
				{canWrite ?
					<Col sm="1" className="p-0 text-center">
						<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteEl(i, section)} /> </Button>
					</Col>
					: ''}

			</Row>
		)

	}



	const OneGoodElement = ({ i, k, list }) => {
		const handleAddElement = (e) => {
			handleAddEl(e, list)
		}
		return (
			<Row key={k} className="border-bottom p-1">
				<Col sm="1" className="p-0 text-center">
					<AddListButton i={i} element_list={element[list]} add_action={handleAddElement} />
				</Col>
				<Col sm="3">{i.category}</Col>
				<Col>{i.name}</Col>
				{canWrite ?
					<Col sm="1" className="p-0 text-center">
						<ButtonGroup>
							<Button size="sm" variant="outline-info" onClick={() => handleShowEditGood(i, list)}><FontAwesomeIcon icon="pencil-alt" /></Button>
							<Button size="sm" variant="outline-danger" onClick={() => handleDeleteGood(i, list)}><FontAwesomeIcon icon="trash" /> </Button>
						</ButtonGroup>
					</Col>
					: ''}
			</Row>
		)
	}


	const OneSection = ({ i, k, list }) => {
		let good_list = furniture_list
		if (list === 'environment_element_list') {
			good_list = environment_element_list
		} else if (list === 'economic_material_list') {
			good_list = economic_material_list
		}

		const [searchTerm, setSearchTerm] = useState('')
		/* 		const handleChangeSearch = (section, s) => {
					console.log(section, s)
					if (s && s.value.length > 0) {
						setSearchTerm(s.value)
					} else {
						setSearchTerm('')
					}
				} */
		return (
			<Card className="mb-4">
				<Card.Header>
					<Container fluid>
						<Row className="h5">
							<Col sm="7" className="text-primary">
								{i.title} {element[list]  && <Badge variant="info">{element[list].length}</Badge>}
							</Col>
							{showComponentEdit ?
								<>
									<Col className="text-info">{i.available}</Col>

									<Col className="text-right"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewGood(list)}><FontAwesomeIcon icon="plus-square" /> {i.add}</Button></Col>
								</>
								: ''}
						</Row>
					</Container>
				</Card.Header>
				<Card.Body className="p-1">
					<Container fluid>
						<Row >


							{element[list] && element[list].length > 0 ?
								<Col>
									<Row className="mb-1">
										<Col className="bg-secondary text-light ml-1">{t('structure.labels.component_name')} </Col>
										<Col className="bg-secondary text-light ml-1">{t('operation.labels.control_params')} </Col>
										<Col className="bg-secondary text-light ml-1 text-center" sm="1" >{showComponentEdit ? '' : t('global.labels.actions')} </Col>
									</Row>
									<Row className="source_list_container">
										<Col className="pr-4">
											{element[list].map((i, key) => (

												<OneRoomElement i={i} key={key} k={key} section={list} showComponentEdit={showComponentEdit} element_list={element[list]} canWrite={canWrite} />

											))}
										</Col>
									</Row>

								</Col>
								: <Col className="font-italic"> {i.empty}</Col>
							}
							{showComponentEdit
								?
								<>
									<Col className="editSection">
										{good_list && good_list.length > 0 ?
											<>
												<Row >
													<Col className="bg-info text-light ml-1" sm="1">&nbsp;</Col>
													<Col className="bg-info text-light ml-1" sm="3">{t('structure.labels.component_category')} </Col>
													<Col className="bg-info text-light ml-1">{t('structure.labels.component_name')} </Col>
													<Col className="bg-info text-light ml-1 text-center" sm="2">{t('global.labels.actions')} </Col>
												</Row>
												<Row className="source_list_container">
													<Col className="pr-4">
														{good_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm[list].toLowerCase()) >= 0))).map((i, key) => (
															<OneGoodElement i={i} key={k} k={k} list={list} canWrite={canWrite} is_operation={is_operation} />
														))}
													</Col>
												</Row>

											</>
											: <i> {i.empty}</i>
										}
									</Col>
								</>
								: ''
							}
						</Row>

					</Container>
				</Card.Body>
			</Card>
		)

	}




	useEffect(() => {
		initValues()
	}, [])

	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />

			{canWrite ?
				<Row className="mb-1">
					<Col className="text-right">
						{showComponentEdit ?
							<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComposition()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
							:
							<Button variant="outline-primary" size="sm" onClick={() => handleOpenComposition()}><FontAwesomeIcon icon="pencil-alt" /> {t('structure.labels.component_mod')}</Button>
						}
					</Col>
				</Row>
				: ''}

			{Object.keys(roomcomposition_dict).map((section, k) => (
				<OneSection i={roomcomposition_dict[section]} list={section} key={k} />
			))}






			<Modal show={showGoodEdit} onHide={handleCloseGood} >
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton>
					{newEdit && <Modal.Title>{curGoodSection.new}</Modal.Title>}
					{!newEdit && <Modal.Title>{curGoodSection.edit}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curGood.name} type="text" name="name" onChange={(event) => onChangeHandlerGood(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.category')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curGood.category} type="text" name="category" onChange={(event) => onChangeHandlerGood(event.target)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseGood}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" form="companyForm" onClick={handleSaveGood}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>


		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderCheckActionListPopover = (action_list, t, messagegroup_list) => {
	const timing_mode_dict = timingMode_dict({ t })

	return (
		<Popover id="popover-basic" className="bg-info border border-info">
			<Popover.Title>
				{t('checks.labels.correctiveactions')}
			</Popover.Title>
			<Popover.Content>
				<ListGroup>
					<ListGroup.Item key="title" variant="info" className="p-1">
						<Container flush>
							<Row>
								<Col >{t('workarea.labels.action')} </Col>
								<Col >{t('workarea.labels.responsability')} </Col>
								<Col >{t('ticket.labels.receiver')}</Col>
								<Col >{t('workarea.labels.timing')} </Col>
							</Row>
						</Container>

					</ListGroup.Item>
					{action_list.map((a, k) => (
						<ListGroup.Item key={k} action variant="light" className="p-1">
							<Container flush>
								<Row >
									<Col className="text-dark font-weight-bold">{a.action}</Col>
									<Col>{a.responsability}</Col>
									<Col>{getValue(getSelectedElement(a.receiver_group, messagegroup_list, 'id'), 'name')}</Col>
									<Col>{a.timing_mode && a.timing_mode.length > 0 ?
										<>
											{a.timing_mode.map((m, k) => (
												<Badge key={k}>{getValue(timing_mode_dict[m], 'label')}</Badge>
											))}
										</>
										: ''
									}
										{a.timing.length > 0 ?
											<>
												({a.timing})
								</> : ''}
									</Col>
								</Row>
							</Container>
						</ListGroup.Item>
					))}
				</ListGroup>
			</Popover.Content>
		</Popover>
	)
}






////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalControl({ item, index, parent_index, setItem, t, is_new, small, parent_list, section, canWrite }) {
	const newcontrol = {
		description: '',
		coefficient: 1,
		control_mode: 'a vista',
		action_list: []
	}
	const controlcoeffiecient = controlCoefficient_dict({t})
	const [showControlEdit, setShowControlEdit] = useState(false)
	const [curControl, setCurControl] = useState(newcontrol)
	const [editedElement, setEditedElement] = useState(false)

	const handleCloseControl = () => setShowControlEdit(false);
	const handleSaveControl = async () => {
		setShowControlEdit(false);
		if (is_new) {
			let newcontrol_list = []
			if (item.control_list) {
				newcontrol_list = [...item.control_list]
			}
			newcontrol_list.push(curControl)
			setItem({ name: 'control_list', value: newcontrol_list, index: parent_index }, section)
		} else {
			let newcontrol_list = [...parent_list]
			newcontrol_list.splice(index, 1, curControl)
			setItem({ name: 'control_list', value: newcontrol_list, index: parent_index }, section)

		}
	}
	const handleShowNewControl = () => {
		console.log('show new')
		setCurControl(newcontrol)
		setShowControlEdit(true)
		setEditedElement(false)
	}
	const handleShowEditControl = () => {
		setCurControl(item)
		setShowControlEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerControl = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curControl[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurControl({ ...curControl, [name]: value })
		setEditedElement(true)
	}
	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNewControl()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNewControl()} variant="outline-primary" size="sm"><FontAwesomeIcon icon='plus-square' /> {t('operation.labels.control_add')}</Button>
				)
				: <Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowEditControl()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<Modal show={showControlEdit} onHide={handleCloseControl} backdrop="static" size="xl" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('roomtypes.labels.new_control')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('roomtypes.labels.edit_control')}</span>
							</>
						}
                        &nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="formOperationNme" className="border-bottom pb-2 mb-2">
							<Form.Label column sm="2">{t('global.labels.description')} </Form.Label>
							<Col sm="6">
								<Form.Control type="text" value={curControl.description} name="description" onChange={(event) => onChangeHandlerControl(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formOperationNme" className="border-bottom pb-2 mb-2">
							<Form.Label column sm="2">{t('operation.labels.coefficient')} </Form.Label>
							<Col sm="6">
								<Form.Control as="select" className="mb-2" name="coefficient" onChange={(event) => onChangeHandlerControl(event.currentTarget)} >
									{Object.keys(controlcoeffiecient).map((f, fk) => (
										<option key={f} value={f} >{f} - {controlcoeffiecient[f].label}</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formOperationNme" className="border-bottom pb-2 mb-2">
							<Form.Label column sm="2">{t('operation.labels.control_mode')} </Form.Label>
							<Col sm="6">
								<Form.Control type="text" value={curControl.control_mode} name="control_mode" onChange={(event) => onChangeHandlerControl(event.currentTarget)} />
							</Col>
							<Col className="text-info">
								<FontAwesomeIcon icon="exclamation-triangle" />&nbsp;
								N.B. Specificare tipo di strumento e metodo ti utilizzo
							</Col>
						</Form.Group>
						<ElementFormNoncomplianceAction functions={{ edit: onChangeHandlerControl }} element={curControl} t={t} />
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseControl}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveControl}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}






////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalImportChecks = ({ dest_intervention_id, dest_param_list, section, functions, t }) => {

	const [showImportCheck, setShowImportCheck] = useState(false)
	const childRefAlert = useRef()

	//-----Process check form
	const handleShowImportCheck = (w, i) => {
		setShowImportCheck(true)
	}

	const handleCloseCheck = () => {
		setShowImportCheck(false);
	}
	const handleImportSectionParameters = (d) => {
		/* 		if (!checkParamPresence(d, dest_param_list)) {
					functions.import(d)
					setShowImportCheck(true)
				} else {
					childRefAlert.current.addAlert('danger', "uno o più parametri già presenti nell'intervento di destinazione. Cancellarli per poter proseguire")
				} */
	}
	let section_list = []

	/* 	for (let d in intervention_dict) {
			const inter = intervention_dict[d]
			if (d !== dest_intervention_id && inter.process_list && inter.process_list[section.id] && inter.process_list[section.id].filter((i) => i.section === section.id && (!i.process_type || i.process_type === 'question')).length > 0) {
				section_list.push({
					intervention_id: inter.id,
					name: inter.name,
					section_process_list: inter.process_list[section.id].filter((i) => i.section === section.id && (!i.process_type || i.process_type === 'question'))
				})
			}
		} */

	//	console.log("import summary", section, section_list)

	return (

		<>
			<Button variant="outline-info" size="sm" disabled={section_list.length === 0} onClick={() => handleShowImportCheck()}>
				<FontAwesomeIcon icon="arrow-right" />{t('workarea.labels.checkprocess_import')}
			</Button>
			{showImportCheck ?
				<Modal show={showImportCheck} onHide={handleCloseCheck} size="xl" className="bg-secondary" >
			<AlertSection ref={childRefAlert} />
					<Modal.Header closeButton>
						<Modal.Title>
							{t('workarea.labels.checkprocess_import')} | {section.label}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
							{section_list.length > 0 ?
								<>
									<Row className="mb-1">
										<Col className="bg-secondary text-light ml-1">{t('workareas.labels.intervention_name')} </Col>
										<Col className="bg-secondary text-light ml-1">{t('workareas.labels.process_indicator')} </Col>
										<Col className="bg-secondary text-light ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
									</Row>
									{section_list.map((s, k) => (
										<Row key={k} className="element_row border-bottom mb-1 pb-1">
											<Col>
												{s.name}
											</Col>
											<Col>
												{s.section_process_list.map((p, kp) => (
													<Row key={kp}>
														<Col>
															{p.description}
														</Col>
													</Row>
												))}
												<Row>

												</Row>
											</Col>
											<Col sm="1">
												<Button variant="info" onClick={() => handleImportSectionParameters(s.section_process_list)} size="sm" >
													<FontAwesomeIcon icon="arrow-right" />{t('global.labels.import')}</Button>
											</Col>
										</Row>
									))}
								</>
								: <i>{t('workareas.message.no_indicators')}</i>}
						</Container>

					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseCheck}>
							{t('modal.close')}
						</Button>
					</Modal.Footer>
				</Modal>
				: ''}
		</>

	)

}