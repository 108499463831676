import React, { useContext, useState, useEffect, useLayoutEffect } from 'react';
import { Container, Row, Col, Modal, Spinner, InputGroup, Form, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { translate } from 'react-polyglot'
import { format, isBefore, isSameDay, } from 'date-fns'
import DateTime from 'react-datetime';
import { useParams } from "react-router-dom"


import LocaleContext from "../components/contexts/locale.js"
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import DashboardContext, { DashboardProvider } from '../components/contexts/dashboardContext.js';
import { InitContractHelper } from "../util/contract"

import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"

import { myTimestampToDate, getStructureFromWorkareas } from "../util/ui_utils.js"



import { firebase } from '../Firebase2/firebaseIndex'


import { getInterventionLogList } from '../Firebase2/Storage2/dbInterventionLog';
import { NewsSection } from '../components/page_sections/homepage_news_section';
import { PlanReportSection } from '../components/page_sections/homepage_check_section';
import { TicketSummary } from '../components/page_sections/homepage_ticket_section';
import { OperatorSummary } from '../components/page_sections/homepage_operator_section.js';
import { RiskareaSummary } from '../components/page_sections/homepage_riskarea_section.js';
import { CalendarSummary } from '../components/page_sections/homepage_calendar_section.js';
import FirebaseContext from '../components/Firebase/context.js';


let userData = {}
try {
	userData = JSON.parse(localStorage.getItem('userData'))
} catch (err) {
	console.log('homeview userdataERROR', userData)
}










let load_page = false
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// 
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const HomeView = ({ t, uData }) => {
	console.log("UDATA", uData)
	return (
		<DashboardProvider>
			<HomeViewContent t={t} uData={uData} />
		</DashboardProvider>

	)
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const HomeViewContent = ({ t, uData }) => {
	//console.log("-------------------init page-------------------")
	const { domain, domain_data } = useContext(DomainContext)
	const { contract, structureElements, setNow, updateNow, now, all_workarea_dict } = useContext(GlobalDataContext)
	//	const {userData} = useContext(FirebaseContext206gg)
	const { initContract } = InitContractHelper()
	const {
		structure_list, setStructureList,
		filterAppliedStructure, setFilterAppliedStructure,
		permission_data, setPermissionData,
		loading_step
	} = useContext(DashboardContext)

	let { cid } = useParams();
	let contractId = cid



	const [claudit_trace, setClauditTrace] = useState(false)

	const [loading, setLoading] = useState(true)
	const [globalloading, setGlobalLoading] = useState(true)
	const [canWrite, setWritePage] = useState(false)
	const [first_load, setFirstLoad] = useState(true)






	const onChangeStructure = async (el) => {
		console.log(el, el.value)
		if (el.value === '-1') {
			setFilterAppliedStructure()
			localStorage.removeItem('filterAppliedStructure')
		} else {
			setFilterAppliedStructure(el.value)
			localStorage.setItem('filterAppliedStructure', el.value)
		}
	}






	const initValues = async () => {
		userData = uData
		localStorage.removeItem('filterAppliedStructure')
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('messages') !== -1)) {
			setWritePage(true)
		}
		if (first_load) {
			setClauditTrace(contract.enable_claudit_trace)
		}
		setLoading(true)
/* 		let now = new Date()
		let d_start = now
		let d_end = now
		d_start.setHours(0)
		d_start.setMinutes(0)
		d_end.setHours(23)
		d_end.setMinutes(59) */

		if (contract && domain.length > 0) {
			load_page = true
			contractId = contract.id
			let _structure_list = structureElements.structure_list
			setGlobalLoading(true)
			console.log("HOME userData", userData)
			let permission_department_list = []
			let permission_structure_list = []
			let permission_workarea_list = []
			if (userData && userData.contract_dict && userData.contract_dict[contractId] && userData.contract_dict[contractId][0]) {
				console.log('userData contract (permission)', userData.contract_dict[contractId])
				if (userData.contract_dict[contractId][0].mode === 'workarea') {
					permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
					console.log("permission workarea_list", permission_workarea_list, all_workarea_dict, userData.contract_dict[contractId][0].workarea)
					permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
					console.log("permission_structure_list", permission_structure_list)
					_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
				} else {
					if (userData.contract_dict[contractId][0].structure) {
						if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
							permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
							console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
						}
						if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
							permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
							_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

						}
						console.log("permission structure_list", permission_structure_list)
					}
				}
			}
			const p_data = { structure_list: permission_structure_list, department_list: permission_department_list, workarea_list: permission_workarea_list }
			console.log(p_data)
			setPermissionData(p_data)
			setStructureList(_structure_list)
		} else {
			console.log('problem')

		}

	}

	useEffect(() => {
		console.log("END LOADING")
			setGlobalLoading(false)
			setLoading(false)
			setFirstLoad(false)

	}, [permission_data])


	useEffect(() => {
		load_page = false
		async function loadData() {
			let contractId = cid
			console.log("HOME reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("HOME init contract return", ret_data)
			}
		}
		if (!contract) {
			loadData()

		}
	}, [])


	useEffect(() => {
		const interval = setInterval(() => {
			if (isSameDay(now, new Date()) ){
				console.log("UPDATE NOW", now, isSameDay(now, new Date()))
				setNow(new Date())
			}
		}, 60000*10);
		return () => clearInterval(interval);
	}, []);


	useLayoutEffect(() => {
		console.log('HOME loadingcontract', contract, load_page)
		if (contract && !load_page) {
			initValues()
			setClauditTrace(contract.enable_claudit_trace)
		}
	}, [contract, uData])


	const getNowDate = () => {
		if (contract) {
			let start_contract = myTimestampToDate(contract.startDate)
			start_contract.setHours(0)
			start_contract.setMinutes(0)
			if (start_contract <= now) {
				return (<b>{format(now, 'dd/MM/yyyy')}</b>)
			} else {
				return (<><b>{format(now, 'dd/MM/yyyy')}</b> <FontAwesomeIcon icon="exclamation-triangle" /> contratto non ancora inziato</>)
			}
		} else {
			return (<><b>{format(now, 'dd/MM/yyyy')}</b> <FontAwesomeIcon icon="exclamation-triangle" /> contratto non ancora inziato</>)
		}


	}

	const validDate = function(current){
		return isBefore(current, new Date())
	}



	//=======================================================================================================
	return (
		<>
			<Container className="titleStickyTop" fluid>

				<Row><Col >
					<PageTitle title={t('navs.pages.daily_summary')} counter={getNowDate()} />

				</Col>
 				<Col sm="2">
                     <DateTime value={now} initialValue={now} dateFormat="DD/MM/yyyy" timeFormat={false} name="start" locale="it" closeOnSelect={true} onChange={(e) => updateNow(e.toDate())} isValid={validDate}/>
				{!isSameDay(now, new Date())?
				<>Sospensioni o dismissioni non vengono calcolate in giorni diversi da oggi</>
				:''}
				</Col>
					{!first_load && structure_list && structure_list.length > 1 ?
						<Col sm="5">
							<InputGroup>
								<InputGroup.Text className={filterAppliedStructure ? "bg-info text-white" : "bg-light"}>
									<FontAwesomeIcon icon="filter" />Filtra struttura
								</InputGroup.Text>
								<Form.Control as="select" name="structure" onChange={(event) => onChangeStructure(event.currentTarget)} >
									<option value="-1" >{t('structure.labels.all_structure')}</option>
									{structure_list && structure_list.length > 0
										? [structure_list.map((f, k) => (
											<option key={k} value={f.id} >{f.name}</option>
										))]
										: ''
									}
								</Form.Control>
							</InputGroup>
						</Col>
						: ''}
				</Row>
			</Container>

			<Container className="justify-content-center" fluid >
				<Row>
					<Col>
						<Row>
							<Col className="border border-primary m-1 rounded">
								<Row className="h3 text-center bg-primary text-light border-bottom border-primary"><Col>Programmazione servizi</Col></Row>
								{contract ?
									<CalendarSummary t={t} canWrite={canWrite} userData={userData} />
									: <>
									</>
								}

							</Col>

						</Row>
						<Row>
							<Col className="border border-primary m-1 rounded">
								<Row className="h3 text-center bg-primary text-light border-bottom border-primary"><Col>Operatori</Col></Row>
								{loading ?
									<>
										<Spinner animation="border" role="status" />&nbsp;Caricamento in corso ({loading_step})
									</>
									: <>
										<Row>
											<Col>
												<OperatorSummary />
											</Col>
										</Row>
									</>}
							</Col>

						</Row>

						{contract ?
							<Row>
								<Col className="border border-primary m-1 rounded">
									<Row className="h3 text-center bg-primary text-light border-bottom border-primary">
										<Col>
											{contract.type === 'civil' ?
												t('global.labels.standard_cleaning')
												:
												t('global.labels.risk_area')
											}
											/lavorazione
										</Col>
									</Row>
									{loading ?
										<>
											<Spinner animation="border" role="status" />&nbsp;Caricamento in corso ({loading_step})
										</>
										: <>
											<Row>
												<Col>
													<RiskareaSummary t={t} />
												</Col>
											</Row>
										</>}
								</Col>
							</Row>
							: <></>}
					</Col>









					<Col>
						{(uData && (uData.isGlobalAdmin || uData.write_page.indexOf('resultcheck') !== -1 || uData.read_page.indexOf('resultcheck') !== -1|| uData.write_page.indexOf('processcheck') !== -1 || uData.read_page.indexOf('processcheck') !== -1)) ?
							<Row>
								<Col className="border border-primary m-1 rounded">
									<Row className="h3 text-center bg-primary text-light border-bottom border-primary"><Col>Controlli</Col></Row>
									{loading ?
										<>
											<Spinner animation="border" role="status" />&nbsp;Caricamento in corso
										</>
										:
										<PlanReportSection t={t} />
									}
								</Col>
							</Row>
							: <></>}


 
						{(uData && (uData.isGlobalAdmin || uData.write_page.indexOf('messages') !== -1 || uData.read_page.indexOf('message') !== -1)) ?
							<Row>
								<Col className="border border-primary m-1 rounded">
									<Row className="h3 text-center bg-primary text-light border-bottom border-primary"><Col>Comunicazioni</Col></Row>
									{loading ?
										<>
											<Spinner animation="border" role="status" />&nbsp;Caricamento in corso
										</>
										:
										<TicketSummary t={t} uData={uData} />
									}

								</Col>
							</Row>
							: <></>}

{/* 						<NewsSection t={t} /> 
 */}					</Col>
				</Row>


			</Container>
			<Footer />








			<Modal show={globalloading} size="sm" backdrop="static" className="bg-secondary" animation={false}>
				<Modal.Body>
					<Container fluid>

						<Row>
							<Col>
									<Image 
										src={domain !== 'admin' && (domain_data && domain_data.logo) ? domain_data.logo : "./Logo.Claudit.registrato.png"}
										style={{maxHeight:'40px', maxWidth:'100px'}}
									/>
							</Col>
							<Col className="text-center">
								<img
									src="/Logo.Claudit.registrato.png"
									height="80"
									className="d-inline-block align-middle"
									alt="CloudIt"
								/>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								Caricamento in corso
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Spinner animation="border" role="status">
									<span className="sr-only">Loading...</span>
								</Spinner>
							</Col>
						</Row>

					</Container>
				</Modal.Body>
			</Modal>

		</>
	)
	//}
}





export default translate()(HomeView);