import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Form, Button, ButtonGroup, Spinner, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { format, subMonths } from 'date-fns'
import { it } from 'date-fns/locale'
import DateTime from 'react-datetime';
import writeXlsxFile from 'write-excel-file'
import { useReactToPrint } from 'react-to-print';

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import { samplingplanTiming_list, checkMode_dict, popoverTiming } from "../components/constants/global_definitions"

import { myTimestampToDate, propComparatorDate, getSelectedElementB, getFloatNumber, getValue, getSelectedElement, myDateToTimestamp, getStructureFromWorkareas, checkComponentPresence } from '../util/ui_utils.js';
import { RiskareaBadge, RoomInfoBadge, ServiceBadge, PageButton } from "../components/StructureGraphicElements"

import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"
import { ModalProcessCheck } from "../components/ModalProcessCheck"
import { ServiceCheckPreview } from "../components/CheckPreview"
import Footer from "../components/Footer"
import PrintFooter from '../components/generic/PrintFooter.js';
import { AnalysisResultData, AnalysisProcessData } from "../components/AnalysisElement"


import { getAllRoomList } from '../Firebase2/Storage2/dbRoom.js';
import { getSamplingPlanList } from "../Firebase2/Storage2/dbSamplingPlan"
import { getInterventionById } from '../Firebase2/Storage2/dbIntervention'
import { getGroupCheckResults } from "../components/FirebaseApi/CheckResultProxy"
import { getControlGroupList } from '../Firebase2/Storage2/dbControlGroup.js';
import { getEmployerList } from '../Firebase2/Storage2/dbEmployers';

import {
	getFurnitureList as getFurnitureList2,

	getEnvironmentElementList as getEnvironmentElementList2,

	getEconomicalMaterialList as getEconomicalMaterialList2,
} from '../Firebase2/Storage2/dbRoomMaterial';

import firebase from 'firebase/app'



let load_page = false
let intervention_dict = {}

const getControlOptionList = (t) => {

	const control_option_list = [
		{ id: 0, label: t('workarea.labels.frequency_monthly'), multiplier: [0.33, 1, 12], month_count: 0 },
		{ id: 1, label: t('frequency.labels.frequency_bimonthly'), multiplier: [0.125, 0.5, 6], month_count: 1 },
		{ id: 2, label: t('workarea.labels.frequency_quarterly'), multiplier: [0.06, 0.33, 4], month_count: 2 },
		{ id: 3, label: t('frequency.labels.frequency_four-monthly'), multiplier: [0.05, 0.25, 3], month_count: 3 },
		{ id: 4, label: t('frequency.labels.frequency_semester'), multiplier: [0.04, 0.16, 2], month_count: 6 },
		{ id: 4, label: t('frequency.labels.frequency_year'), multiplier: [0.02, 0.08, 1], month_count: 12 }
	]
	return control_option_list
}


const getStartDate = (start) => {
	let st = myTimestampToDate(start)
	st.setHours(0)
	st.setMinutes(0)
	return (firebase.firestore.Timestamp.fromDate(st))
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const CheckView = ({ t, uData, check_type }) => {
	const { domain } = useContext(DomainContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict, all_workarea_dict } = useContext(GlobalDataContext)


	//const childRefAlert = useRef()
	//const childRefDelete = useRef()

	let { cid } = useParams();
	let contractId = cid
	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(false)
	const [structure_list, setStructureList] = useState([])
	const [controlgroup_list, setControlgroupList] = useState([])
	//	const [employer_list, setEmployerList] = useState([])
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)
	const [currentCheckMode, setCurrentCheckMode] = useState()

	const [curPage, setCurPage] = useState('list')


	const pages = {
		list: { section_name: t('global.labels.list'), section: 'list', global: true },
		analysis: { section_name: t('checks.labels.analysis'), section: 'analysis' },
	}

	async function getSamplingPlanData() {
		let return_data = await getSamplingPlanList(domain, contractId)
		console.log("plan list", return_data)
		setElementList(return_data.sort(propComparatorDate('startDate')))
	}
	const initValues = async () => {
		const userData = uData
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('samplingplan') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		load_page = true

		if (contract.contradictory && contract.contradictory.length > 0) {
			let _structure_list = structureElements.structure_list
			let edit_permission_department_list = []
			let permission_structure_list = []
			if (userData && userData.contract_dict && userData.contract_dict[contractId]) {
				console.log('userData contract', userData.contract_dict[contractId])
				if (userData.contract_dict[contractId][0].mode === 'workarea') {
					const permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
					permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
					console.log("permission_structure_list", permission_structure_list)
					_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
				} else {
					if (userData.contract_dict[contractId][0].structure) {
						if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
							edit_permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
							console.log("permission department_list", edit_permission_department_list, userData.contract_dict[contractId][0].department)
						}
						if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
							permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
							_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

						}
						console.log("permission structure_list", permission_structure_list)
					}
				}
			}
			const ret_data = await getControlGroupList(domain, contractId)
			const tcontrolgroup_list = ret_data.filter(g => g.structure_list.length > 0)
			let room_structure_dict = {}
			for (const s of _structure_list) {
				const room_list = await getAllRoomList({ structureId: s.id })
				if (room_list && room_list.length > 0) {
					room_structure_dict[s.id] = room_list
				}
			}
			let room_controlgroup_dict = {}
			for (const g of tcontrolgroup_list) {
				console.log("CG", g)
				for (const s of (g.structure_list)) {
					const room_list = await getAllRoomList({ structureId: s })
					if (room_list && room_list.length > 0) {
						if (room_controlgroup_dict[g.id]) {
							room_controlgroup_dict[g.id] = [...room_controlgroup_dict[g.id], room_list]
						} else {
							room_controlgroup_dict[g.id] = room_list
						}
					}
				}
			}
			const furniture_list = await getFurnitureList2(domain, contractId)
			const environment_list = await getEnvironmentElementList2(domain, contractId)
			const economic_list = await getEconomicalMaterialList2(domain, contractId)
			const furniture_dict = Object.assign({}, ...furniture_list.map((x) => ({ [x.id]: x })));
			const environment_dict = Object.assign({}, ...environment_list.map((x) => ({ [x.id]: x })));
			const economic_dict = Object.assign({}, ...economic_list.map((x) => ({ [x.id]: x })));
			setStructureList(_structure_list)
			getSamplingPlanData()
			console.log(room_controlgroup_dict)
			setUiList({ ...ui_list, risk_area_list, risk_area_dict, roomtype_list, roomtype_dict, room_structure_dict, room_controlgroup_dict, furniture_dict: { ...furniture_dict, ...environment_dict, ...economic_dict } })
			setControlgroupList(ret_data.filter(g => g.structure_list.length > 0))
			//let _employers_list = await getEmployerList(domain, contractId, {})
			//console.log("EMP", _employers_list)
			//setEmployerList(_employers_list.filter(e => e.structure_list && e.structure_list.length > 0))
			const cur_check = 'contradictory'
			setCurrentCheckMode(cur_check)
		}
		setLoading(false)
	}


	useEffect(() => {
		load_page = false
		setLoading(true)
		async function loadData() {
			let contractId = cid
			console.log("SAMPLING PLAN reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("SAMPLING PLAN init contract return", ret_data)
			}
		}
		if (!contract) {
			loadData()
		}
	}, [])


	useEffect(() => {
		console.log('SAMPLING PLAN change contract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])






	return (
		<>
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('global.pages.non_compliance_registry')} />
					</Col>
				</Row>
			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col>
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					</Row>
					:
					<>
						{!currentCheckMode ?
							<Row>
								<Col className="font-italic">
									controllo in contraddittorio non abilitato per questo contratto
								</Col>
							</Row>
							:

							<>
								<Row>
									<Col sm="2">
										{Object.keys(pages).map((p, pk) => (
											<>
												<PageButton key={pk} page_name={pages[p].section_name} setCurPage={setCurPage} curPage={curPage} page={pages[p].section} />
											</>
										))}

									</Col>
									<Col>
										{curPage === 'list' ?
											<ListPlanPage t={t} canWrite={canWrite} ui_list={ui_list} controlgroup_list={controlgroup_list} structure_list={structure_list} element_list={element_list} currentCheckMode={currentCheckMode} check_type='results' />
											: ''}
										{curPage === 'analysis' ?
											<AnalysisPlanPage t={t} canWrite={canWrite} ui_list={ui_list} controlgroup_list={controlgroup_list} structure_list={structure_list} element_list={element_list} currentCheckMode={currentCheckMode} />
											: ''}
									</Col>
								</Row>
							</>
						}
					</>
				}

			</Container>
			<Footer />
		</>
	);
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
const ListPlanPage = ({ check_type, t, canWrite, controlgroup_list, structure_list, element_list, currentCheckMode, ui_list }) => {






	return (
		<>

			<Row className="mt-1">
				{controlgroup_list && controlgroup_list.length > 0 ?
					<Col>
						<Row>
							<Col>
								<FontAwesomeIcon icon="info-circle" />  Piani di campionamento con almeno un controllo non conforme
							</Col>
						</Row>
						<Row>
							<Col className="bg-secondary text-light ml-1" sm="2">
								{t('global.labels.name')}
							</Col>
							<Col>
								<Row>

									<Col className="bg-secondary text-light ml-1">
										{t('global.labels.type')}
									</Col>
									<Col className="bg-secondary text-light ml-1">
										{t('samplingplan.labels.start_plan')}
									</Col>
									<Col className="bg-secondary text-light ml-1">
										{t('samplingplan.labels.period')}
									</Col>
									<Col className="bg-secondary text-light ml-1">
										{t('samplingplan.labels.failed_checks')}
									</Col>
									<Col className="bg-secondary text-light ml-1">
										NC su % programmati
									</Col>
									<Col className="bg-secondary text-light ml-1">
										NC su % eseguiti
									</Col>
									<Col className="bg-secondary text-light ml-1 text-center" sm="1">
										{t('global.labels.actions')}
									</Col>
								</Row>
							</Col>
						</Row>
						{controlgroup_list.map((g, key) => (
							<Row className="mb-1 pb-1 border-bottom" key={key}>
								<Col className="font-weight-bold" sm="2">
									{g.name}
								</Col>
								<Col>
									<AnalysisPlanList t={t} element_list={element_list} g={g} ui_list={ui_list} canWrite={canWrite} control_mode={currentCheckMode} check_type={check_type} />
								</Col>
							</Row>
						))}
					</Col>
					:
					<Col className="font-italic">
						{t('structure.labels.no_controlgroup')}
					</Col>
				}
			</Row>
		</>
	)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
const AnalysisPlanList = ({ control_mode, t, check_type, g, s, element_list, ui_list, canWrite }) => {
	const timing_list = samplingplanTiming_list({ t })
	return (
		<Row>
			{element_list.filter(plan => g.id === plan.controlgroup_id && plan.control_mode === 'contradictory' && plan.total_data.failed_check > 0).length > 0 ?
				<Col>
					{element_list.filter(plan => g.id === plan.controlgroup_id && plan.control_mode === 'contradictory' && plan.total_data.failed_check > 0).map((plan, kp) => (
						<Row key={kp}>
							<Col>
								{plan.check_type === 'results' ?
									t('global.pages.resultscheck')
									: t('global.pages.processcheck')
								}
							</Col>
							<Col>
								{console.log(plan)}
								{format(myTimestampToDate(plan.startDate), 'MM/yyyy', { locale: it })}
							</Col>
							<Col>
								{timing_list[plan.check_period].label}
							</Col>
							<Col>
								{plan.total_data ?
									<>
										{plan.total_data.failed_check}
									</> : ''
								}
							</Col>
							<Col>
								{plan.total_data && plan.total_data.failed_check ?
									<>
										<ProgressBar now={plan.total_data.failed_check * 100 / plan.total_data.sum_sampling_count} label={`${plan.total_data.failed_check * 100 / plan.total_data.sum_sampling_count}%`} />
									</>
									: ''}
							</Col>
							<Col>
								{plan.total_data && plan.total_data.failed_check ?
									<>
										<ProgressBar now={plan.total_data.failed_check * 100 / plan.total_data.check_done} label={`${plan.total_data.failed_check * 100 / plan.total_data.check_done}%`} />
									</>
									: ''}
							</Col>
							<Col sm="1">
								<ModalArchiveChecks t={t} controlgroup={g} item={plan} control_mode={plan.control_mode} check_type={plan.check_type} ui_list={ui_list} nc={true} />
							</Col>
						</Row>
					))}
				</Col>
				: <Col>
					Nessun piano con NC presente</Col>}
		</Row>
	)
}





////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalArchiveChecks = ({ is_new, item, structure, controlgroup, control_mode, canWrite, ui_list, t, item_list, sampling_data, setItem, check_type, nc }) => {




	const control_option_list = getControlOptionList(t)


	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contractId, structureElements, service_dict, roomtype_dict, risk_area_dict } = useContext(GlobalDataContext)


	const childRefAlert = useRef()
	const [loading, setLoading] = useState(true)

	const [showPlanEdit, setShowPlanEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curPlan, setCurPlan] = useState(item)
	const [archive_list, setArchiveList] = useState([])
	const [showAll, setShowAll] = useState(false)


	const check_mode_dict = checkMode_dict({ t })
	const handleShowEditPlan = async () => {
		initArchive()
		setShowPlanEdit(true)

	}


	const handleClosePlan = () => {
		setShowPlanEdit(false)
	}



	const handleSavePlan = async () => {
	}

	const onChangeHandlerPlan = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curPlan[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setEditedElement(true)
	}


	const initArchive = async () => {
		setLoading(true)
		const res_data = await getGroupCheckResults({ DomainId: domain, ContractId: contractId, GroupId: controlgroup.id, ControlModeType: control_mode + "_" + check_type, FromDate: curPlan.startDate, ToDate: curPlan.endDate })
		console.log("archive list", res_data)
		if (nc) {
			setArchiveList(res_data.checkResultList.filter(a => getFloatNumber(a.lqr) < getFloatNumber(a.lqa)))
		} else {
			setArchiveList(res_data.checkResultList)
		}
		setLoading(false)
	}


	useEffect(() => {

	}, [])

	const OneResult = ({ a }) => {
		//const room = getSelectedElement(a.room_id, ui_list.room_structure_dict[structure.id], 'id')
		const room = structureElements.all_room_dict[a.room_id]
		const [intervention, setIntervention] = useState({ service: {} })
		const [counts, setCounts] = useState({})
		let roomtype = {}
		console.log("ROOM", room)
		if (room && room.roomtype) {
			roomtype = ui_list.roomtype_dict[room.roomtype.id]
		} else {

		}
		const [curActivity, setCurActivity] = useState({})


		const initResult = async () => {

			let _intervention = intervention_dict[a.intervention_id]

			if (!_intervention) {
				_intervention = await getInterventionById(domain, contractId, a.intervention_id)
				//					console.log("SET", domain, contractId, intervention_id, intervention)
				//updateIntervention(intervention)
				intervention_dict[a.intervention_id] = _intervention
				setIntervention(_intervention)
			} else {
				setIntervention(_intervention)
			}
			setCurActivity(getSelectedElementB(_intervention.service.id, roomtype.service_list, 'service', 'id'))
			console.log("ROOM, intervention", room, _intervention)
			const res_array = Object.values(a.result_dict)
			let _counts = {}
			for (const res_a of res_array) {
				_counts[res_a] = _counts[res_a] ? _counts[res_a] + 1 : 1
			}
			setCounts(_counts)
		}





		useEffect(() => {
			initResult()
		}, [])






		if (check_type === 'results') {


			return (
				<Row className="element_row p-1 border-bottom">
					{console.log(a)}
					<Col>
						<RiskareaBadge riskarea={risk_area_dict[room.risk_area.Id]} />
						<RoomInfoBadge parent_list={room.parent_list} />
						{room.name} <br /><span className="text-secondary">{roomtype_dict[room.roomtype.id].name}</span>
					</Col>
					<Col>
						{intervention.name}<br />
						<ServiceBadge service={service_dict[intervention.service.id]} freq={true} />
					</Col>
					<Col>
						{a.timing_creation && (a.timing_creation === 'post' || a.timing_creation === 'pre') ?
							<>

								{a.timing_validation && (a.timing_validation === 'post' || a.timing_validation === 'pre') ?
									<OverlayTrigger
										placement="right"
										delay={popoverTiming}
										overlay={<Tooltip id="button-tooltip">controllo fuori orario e non conteggiato</Tooltip>}
										message=""
									>
										<FontAwesomeIcon icon="exclamation-triangle" />
									</OverlayTrigger>
									:
									<OverlayTrigger
										placement="right"
										delay={popoverTiming}
										overlay={<Tooltip id="button-tooltip">controllo creato fuori orario ma considerato in orario</Tooltip>}
										message=""
									>
										<FontAwesomeIcon icon="info" />
									</OverlayTrigger>
								}</>
							: ''}&nbsp;
						{format(myTimestampToDate(a.end_check), 'dd/MM/yyyy HH:mm')}
					</Col>
					<Col>
						<ResultBar length={Object.keys(a.result_dict).length} res_dict={counts} />
					</Col>
					<Col className="h5">
						<Badge className={a.lqr < getFloatNumber(a.lqa) ? 'bg-danger text-light' : 'bg-success'}>{a.lqr.toFixed(2)} /{a.lqa}</Badge>
					</Col>
					<Col>
						<ServiceCheckPreview roomtype={roomtype} activity={curActivity} ui_list={ui_list} t={t} roomType_name={room.name + " " + roomtype.name} roomType_elements={[...roomtype.furniture_list, ...roomtype.environment_element_list, ...roomtype.economic_material_list]} res_data={a} />
					</Col>
				</Row>

			)
		} else {
			return (
				<Row>
					{console.log("_::::________________________PROCESS_____________________________________________________::::__")}
					<Col>
						{intervention.name}
						<ServiceBadge service={service_dict[intervention.service.id]} freq={true} />
					</Col>
					<Col>
						{a.timing_creation && (a.timing_creation === 'post' || a.timing_creation === 'pre') ?
							<>

								{a.timing_validation && (a.timing_validation === 'post' || a.timing_validation === 'pre') ?
									<OverlayTrigger
										placement="right"
										delay={popoverTiming}
										overlay={<Tooltip id="button-tooltip">controllo fuori orario e non conteggiato</Tooltip>}
										message=""
									>
										<FontAwesomeIcon icon="exclamation-triangle" />
									</OverlayTrigger>
									:
									<OverlayTrigger
										placement="right"
										delay={popoverTiming}
										overlay={<Tooltip id="button-tooltip">controllo creato fuori orario ma considerato in orario</Tooltip>}
										message=""
									>
										<FontAwesomeIcon icon="info" />
									</OverlayTrigger>
								}</>
							: ''}&nbsp;
						{format(myTimestampToDate(a.end_check), 'dd/MM/yyyy HH:mm')}
					</Col>
					<Col>
						<ResultBar length={Object.keys(a.result_dict).length} res_dict={counts} />
					</Col>
					<Col>
						<Badge className={a.lqr < parseFloat(a.lqa.replace(/,/, '.')) ? 'bg-danger text-light' : 'bg-success'}>{a.lqr.toFixed(2)} /{a.lqa}</Badge>
					</Col>
					<Col>
						{intervention ?
							<ModalProcessCheck element={intervention} ui_list={ui_list} t={t} _preview={a.timing_type} _res_data={a} />
							: <></>}
					</Col>

				</Row>
			)
		}
	}

	return (
		<>

			{nc ?
				<Button className="mr-1" variant="outline-danger" size="sm" onClick={() => handleShowEditPlan()}>Registro N.C.</Button>
				:
				<Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="eye" /> </Button>
			}

			<Modal show={showPlanEdit} onHide={handleClosePlan} dialogClassName="custom-modal" bsclass="full-modal" backdrop="static" >
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>

						{nc ?
							<span className="text-danger">{t('checks.labels.archive_nc')}</span>
							:
							<span className="text-info">{t('checks.labels.archive_checks')}</span>
						}&nbsp;
						{check_type === 'results' ? t('global.pages.resultscheck') : t('global.pages.processcheck')}
						&nbsp;-&nbsp;
						{check_mode_dict[control_mode].label}
						&nbsp;({format(myTimestampToDate(curPlan.startDate), 'dd/MM/yyyy')} - {format(myTimestampToDate(curPlan.endDate), 'dd/MM/yyyy')})
						&nbsp;
						{controlgroup.name}&nbsp;

						{showAll ?
							<Button variant="secondary" onClick={() => setShowAll(!showAll)}>Visualizza solo controlli validi</Button>
							:
							<Button variant="outline-secondary" onClick={() => setShowAll(!showAll)}>Visualizza anche fuori orario</Button>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{loading ?
						<Spinner animation="border" role="status">
							<span className="sr-only">Loading...</span>
						</Spinner>
						:
						<Container fluid>
							{archive_list.filter(a => (a.timing_validation !== 'post' && a.timing_validation !== 'pre') || showAll).length > 0 ?
								<>
									<Row className="border-bottom mb-3 pb-2">
										{check_type === 'results' ?
											<Col className="bg-secondary text-light ml-1 font-weight-bold">{t('global.labels.room')}</Col>
											: <></>}
										<Col className="bg-secondary text-light ml-1" >{t('global.labels.intervention')} | {t('global.labels.service')}</Col>
										<Col className="bg-secondary text-light ml-1" >{t('global.labels.date')}</Col>
										<Col className="bg-secondary text-light ml-1" >{t('global.labels.results')}</Col>
										<Col className="bg-secondary text-light ml-1" >LQR/LQA</Col>
										<Col className="bg-secondary text-light ml-1" ></Col>
									</Row>

									{archive_list.filter(a => (a.timing_validation !== 'post' && a.timing_validation !== 'pre') || showAll).map((a, k) => (
										<OneResult a={a} key={k} />
									))}
								</>
								:
								<i>{t('checks.messages.no_check')}</i>}
						</Container>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClosePlan}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSavePlan}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


const ResultBar = ({ res_dict, length }) => {

	const res_color = {
		true: 'success',
		false: 'danger',
		unavailable: 'warning'
	}

	const res_list = ['true', 'false', 'unavailable']

	return (
		<ProgressBar>
			{res_list.filter(a => Object.keys(res_dict).indexOf(a) >= 0).map((a, k) => (
				<ProgressBar now={parseInt(res_dict[a] * 100 / length)} variant={res_color[a]} key={k} />
			))}
		</ProgressBar>
	)
}


let filterAppliedList = { type: [], risk_area: [], intervention: [], service: [], structure: [], pavillon: [], floor: [], department: [], employer: [], qualification: [], startDate: firebase.firestore.Timestamp.fromDate(subMonths(new Date, 1)), endDate: firebase.firestore.Timestamp.now() }
const defaultfilter = { type: [],risk_area: [], intervention: [], service: [], structure: [], pavillon: [], floor: [], department: [], employer: [], qualification: [], startDate: firebase.firestore.Timestamp.fromDate(subMonths(new Date, 1)), endDate: firebase.firestore.Timestamp.now() }

////////////////////////////////////////////////////////////////////////////////////////////////////////
const AnalysisPlanPage = ({ t, canWrite, controlgroup_list, structure_list, element_list, currentCheckMode, ui_list }) => {

	const control_mode = 'contradictory'
	const componentRef = useRef();
	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)

	const [_filterAppliedList, setFilterAppliedList] = useState(defaultfilter)
	const [_filterAppliedListCompare, setFilterAppliedListCompare] = useState(defaultfilter)
	const [compare, setCompare] = useState(false)
	const [check_type, setCheckType] = useState('results')
	const [archive_list, setArchiveList] = useState([])
	const [archive_filteredlist, setArchiveFilteredList] = useState([])
	const [loading, setLoading] = useState(true)

	const [showAll, setShowAll] = useState(false)

	let { cid } = useParams();
	let contractId = cid

	// -----------------------------------------------
	const onHandleExport = async () => {
		const { COLUMNS, row_list } = {}


		const fileXlsx = await writeXlsxFile(row_list, {
			columns: COLUMNS,
			fileName: `Claudit-${domain}_${contract.name}_NC_${format(new Date(), 'yyyyMMdd')}-${format(new Date(), 'yyyyMMdd')}.xlsx`
		})

		//const blob = new Blob(fileXlsx)
		//saveAs (blob, "export.xlsx");

	};
	const handlePrint = useReactToPrint({
		content: () => {
			const tableStat = componentRef.current.cloneNode(true);
			const PrintElem = document.createElement('div');
			PrintElem.appendChild(tableStat);
			return PrintElem;
		},
		pageStyle: pageStyle,
		documentTitle: `NC: ${check_type} (da ${format(new Date(), 'dd/MM/yy')} a ${format(new Date(), 'dd/MM/yy')})`,
	});

	useEffect(() => {
		initArchive()

	}, [_filterAppliedList, check_type])

	useEffect(() => {
		console.log(archive_list)

	}, [archive_list])

	const initArchive = async () => {
		setLoading(true)
		console.log("ELEMENT LIST", element_list, check_type, control_mode, _filterAppliedList)
		let a_list = []
		if (check_type !== 'micro') {


			for (const g of controlgroup_list) {
				const res_data = await getGroupCheckResults({ DomainId: domain, ContractId: contractId, GroupId: g.id, ControlModeType: control_mode + "_" + check_type, FromDate: _filterAppliedList.startDate, ToDate: _filterAppliedList.endDate })
				a_list = a_list.concat(res_data.checkResultList)
			}
			console.log("LIST", a_list, a_list.map(c => [c.lqa, c.lqr, getFloatNumber(c.lqa) > c.lqr]))
			setArchiveList(a_list.sort(propComparatorDate('end_check')))
			if (showAll) {
				setArchiveFilteredList(a_list.sort(propComparatorDate('end_check')))
			} else {
				setArchiveFilteredList(a_list.filter(a => getFloatNumber(a.lqr) < getFloatNumber(a.lqa)).sort(propComparatorDate('end_check')))
			}
		}
		setLoading(false)
	}

	useEffect(() => {

		if (showAll) {
			setArchiveFilteredList(archive_list.sort(propComparatorDate('end_check')))
		} else {
			setArchiveFilteredList(archive_list.filter(a => getFloatNumber(a.lqr) < getFloatNumber(a.lqa)).sort(propComparatorDate('end_check')))
		}
	}, [showAll])


	return (
		<>
			{loading ?
				<Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner>
				:
				<>

					<Row className="mt-1">
						<Col sm="4">
							<ButtonGroup>
								<Button variant={check_type === 'results' ? 'secondary' : 'outline-secondary'} onClick={() => setCheckType('results')}>{t('global.pages.resultscheck')}</Button>
								<Button variant={check_type === 'process' ? 'secondary' : 'outline-secondary'} onClick={() => setCheckType('process')}>{t('global.pages.processcheck')}</Button>
								<Button variant={check_type === 'micro' ? 'secondary' : 'outline-secondary'} onClick={() => setCheckType('micro')}>Controllo microbiologico</Button>
							</ButtonGroup>

						</Col>
						<Col>
							{check_type !== 'micro' &&

								<ButtonGroup>
									<Button variant={showAll ? 'secondary' : 'outline-secondary'} onClick={() => setShowAll(true)}>{t('global.pages.view_all')}</Button>
									<Button variant={!showAll ? 'secondary' : 'outline-secondary'} onClick={() => setShowAll(false)}>{t('global.pages.view_failed')}</Button>
								</ButtonGroup>
							}
						</Col>
						<Col sm="4">
							<ModalFilter t={t} defaultfilter={defaultfilter} ui_list={ui_list} className="mr-2" setFilterAppliedList={setFilterAppliedList} />
						</Col>
						<Col>
							<Button variant="outline-info" onClick={onHandleExport} ><FontAwesomeIcon icon="file-export" />Esporta xlsx</Button>
							<Button variant="info" onClick={handlePrint} className="ml-1">
								<FontAwesomeIcon icon="print" />  {t('modal.print')}
							</Button>
							{/* <Button disabled variant={compare ? 'primary' : 'outline-primary'} onClick={() => setCompare(!compare)}>Compara</Button> */}
						</Col>
						{compare ?
							<Col>
								<ModalFilter t={t} defaultfilter={defaultfilter} ui_list={ui_list} className="mr-2" setFilterAppliedList={setFilterAppliedListCompare} />
							</Col>
							: ''}
					</Row>
					{archive_list.length > 0 ?
						<>
							{check_type === 'results' &&
								<AnalysisResultData archive_list={archive_filteredlist} ui_list={ui_list} />
							}
							{check_type === 'process' &&
								<AnalysisProcessData archive_list={archive_filteredlist} ui_list={ui_list} t={t} />
							}
							{check_type === 'micro_checks' &&
								<></>
							}
							<div className="d-none">
								<Container fluid ref={componentRef} className="print_page">
									<style type="text/css" media="print">{"\
  @page {\ size: portrait;\ }\
"}</style>
									{check_type === 'results' &&
										<>
											<Row>
												<Col>
													Controllo di risultato
												</Col>
												<Col>
													{format(myTimestampToDate(_filterAppliedList.startDate), 'dd/MM/yyyy')}	- {format(myTimestampToDate(_filterAppliedList.endDate), 'dd/MM/yyyy')}
												</Col>

											</Row>
													<AnalysisResultData archive_list={archive_filteredlist} ui_list={ui_list} is_print={true}/>
										</>
									}
									{check_type === 'process' &&
										<>
											<Row>
												<Col>
													Controllo di Processo
												</Col>
												<Col>
													{format(myTimestampToDate(_filterAppliedList.startDate), 'dd/MM/yyyy')}	- {format(myTimestampToDate(_filterAppliedList.endDate), 'dd/MM/yyyy')}
												</Col>

											</Row>
											<Row>
												<Col>
													<AnalysisProcessData archive_list={archive_filteredlist} ui_list={ui_list} t={t} />
												</Col>
											</Row>
										</>
									}
									{check_type === 'micro_checks' &&
										<>

											<Row>
												<Col>
													Controllo Microbiologico
												</Col>
												<Col>
													{format(myTimestampToDate(_filterAppliedList.startDate), 'dd/MM/yyyy')}	- {format(myTimestampToDate(_filterAppliedList.endDate), 'dd/MM/yyyy')}
												</Col>

											</Row>
										</>
									}
								<PrintFooter />
								</Container>

							</div>
						</>
						:
						<i>Nessun controllo presente (con i filtri selezionati)</i>}
				</>
			}</>
	)
}




////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
const ModalFilter = ({ t, defaultfilter, element_list, calendarRef, ui_list, setFilterAppliedList }) => {

	const { contract, structureElements, risk_area_list , risk_area_dict} = useContext(GlobalDataContext)
	const [showFilter, setShowFilter] = useState(false)




	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			filterAppliedList = { ...filterAppliedList, [section]: [] }
		} else {
			filterAppliedList = (defaultfilter)
		}
		setFilterAppliedList(filterAppliedList)
		//		let calendarApi = calendarRef.current.getApi()
		//		calendarApi.refetchEvents()
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		filterAppliedList = { ...filterAppliedList, [section]: f_data }
		setFilterAppliedList(filterAppliedList)
	}

	const updateAppliedFilterParam = (section, elem) => {
		filterAppliedList = { ...filterAppliedList, [section]: elem }
		setFilterAppliedList(filterAppliedList)

	}


	return (
		<Row>
			<Col sm="3">
				<DateTime initialValue={myTimestampToDate(filterAppliedList.startDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => updateAppliedFilterParam('startDate', myDateToTimestamp(e))} />
				<Form.Text className="text-muted">
					{t('contract.labels.start')}
				</Form.Text>
			</Col>
			<Col sm="3">
				<DateTime initialValue={myTimestampToDate(filterAppliedList.endDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="endDate" locale="it" closeOnSelect={true} onChange={(e) => updateAppliedFilterParam('endDate', myDateToTimestamp(e))} />
				<Form.Text className="text-muted">
					{t('contract.labels.end')}
				</Form.Text>
			</Col>
			<Col>


				<ButtonGroup>
					<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
					{filterAppliedList.pavillon.length > 0 ?
						<>
							{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
								<Button key={k} size="sm" className={filterAppliedList.pavillon.indexOf(d) !== -1 ? '' : 'd-none'}
									variant={filterAppliedList.pavillon.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
									onClick={() => updateAppliedFilter('pavillon', d)}>
									{structureElements.all_pavillon_dict[d].name} <FontAwesomeIcon icon="times" />
								</Button>
							))}
							{filterAppliedList.pavillon.length > 1 ?
								<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
								: ''}
						</>
						: ''}
					{filterAppliedList.department.length > 0 ?
						<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('department')}><FontAwesomeIcon icon="times" /></Button>
						: ''}
				</ButtonGroup>
			</Col>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="xl">
				<Modal.Header>
					<Modal.Title>
						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>

						<Row>
							<Col>
								{t('global.labels.pavillon')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.pavillon.indexOf(structureElements.all_pavillon_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('pavillon', structureElements.all_pavillon_dict[d].id)}
									>
										{structureElements.all_pavillon_dict[d].name}
									</Button>
								))}
								{filterAppliedList.pavillon.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.department')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_department_dict).map((d, k) => (
									<Button className="m-1" key={k} size="sm" variant={filterAppliedList.department.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('department', d)}>
										<i>{getValue(getSelectedElement(structureElements.all_department_dict[d].parent_list[0], structureElements.structure_list, 'id'), 'name')}</i> |
										{structureElements.all_department_dict[d].name}
									</Button>
								))}
								{filterAppliedList.department.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('department')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.pavillon.indexOf(structureElements.all_pavillon_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('pavillon', structureElements.all_pavillon_dict[d].id)}
									>
										{structureElements.all_pavillon_dict[d].name}
									</Button>
								))}
								{filterAppliedList.pavillon.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{contract.type === 'civil' ?
									t('global.labels.standard_cleaning')
									:
									t('global.labels.risk_area')
								}

							</Col>
						</Row>
						<Row>
									{risk_area_list.filter(d  => checkRiskareaPresence(element_list, d.Id)).map((d, k) => (
										<Col sm="4" key={d.Id}>
											<Button block size="sm" className="ml-1 mb-1 text-left"  variant={filterAppliedList.risk_area.indexOf(d.Id) === -1 ? "outline-secondary" : "secondary"}
												onClick={(e) => updateAppliedFilter('risk_area', d.Id)}>
												<RiskareaBadge riskarea={risk_area_dict[getValue(d, 'Id')]} />
											</Button>
										</Col>
									))}
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</Row>
	)
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkRiskareaPresence(e_list, d) {
	return true
			for (let e of e_list) {
				if (e.risk_area && e.risk_area.Id === d) {
					return true
				}
			}
	return false
}

const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  div.page-footer{
	display:none;
  }
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
	display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		background-color: #fff,
		page-break-after: always;
	  }
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;










export default translate()(CheckView)