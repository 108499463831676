import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Badge, Container, ButtonGroup, Row, Col, Form, Button  } from 'react-bootstrap';

import {timingMode_dict} from "../components/constants/global_definitions"

import GlobalDataContext from './contexts/globaldataContext.js';


import { checkComponentPresence, getSelectedElement, getValue } from "../util/ui_utils.js"









const newaction = {
	action: '',
	responsability: '',
	timing: '',
	timing_mode: []
}


export function ElementFormNoncomplianceAction({ element, functions, t }) {

	const { messagegroup_list } = useContext(GlobalDataContext)

	const timing_mode_dict = timingMode_dict({t})

	const [showActionEdit, setShowActionEdit] = useState(false)
	const [newActionEdit, setNewActionEdit] = useState(true)
	const [curAction, setCurAction] = useState(newaction)
	const [editedElement, setEditedElement] = useState(false)

	//----action
	const handleCloseAction = () => setShowActionEdit(false);
	const handleSaveAction = () => {
		if (newActionEdit) {
			let newaction_list = element.action_list || []
			newaction_list.push(curAction)
			console.log(newaction_list)
			functions.edit({ name: 'action_list', value: newaction_list })
		} else {
			let editlist = element.action_list
			//            const c = checkComponentPresence(curAction, element.action_list)
			editlist.splice(curAction.index, 1, curAction)
			functions.edit({ name: 'action_list', value: editlist })
		}
		setShowActionEdit(false);
	}

	const handleDeleteAction = (el, index) => {
		console.log(el)
		let editlist = element.action_list
		const c = checkComponentPresence(el, element.action_list)
		editlist.splice(index, 1)
		functions.edit({ name: 'action_list', value: editlist })
	}

	const handleShowNewAction = () => {
		setCurAction(newaction)
		setNewActionEdit(true)
		setShowActionEdit(true)
		setEditedElement(false)
	}

	const handleShowEditAction = (i, index) => {
		console.log('edit', i, index)
		i.index = index
		setCurAction(i)
		setNewActionEdit(false)
		setShowActionEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerAction = (el) => {
		let { name, value, type } = el 
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
        } else if (type === 'checkbox') {
            let oldcheck = []
            try {
                oldcheck = [...curAction[name]]
            } catch (err) { }
            if (el.checked) {
                if (oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
		}
		console.log(name, value)
		setCurAction({ ...curAction, [name]: value })
		setEditedElement(true)
	}



	return (
		<>
			<Row>
				<Col>
					{t('checks.labels.correctiveactions')}

				</Col>
				<Col className="text-right"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewAction()}><FontAwesomeIcon icon="plus-square"></FontAwesomeIcon>{t('suppliers.labels.action_add')}</Button></Col>
			</Row>
			<Row className="border-bottom  border-right mb-1">
				{element.action_list && element.action_list.length > 0 ?
					<Col>
						<Row >
							<Col className="bg-secondary text-light ml-1">{t('workarea.labels.action')} </Col>
							<Col className="bg-secondary text-light ml-1">{t('workarea.labels.responsability')} </Col>
							<Col className="bg-secondary text-light ml-1">{t('ticket.labels.receiver')} </Col>
							<Col className="bg-secondary text-light ml-1">{t('workarea.labels.timing')} </Col>
							<Col className="bg-secondary text-light ml-1 text-center" sm="2">{t('global.labels.actions')}</Col>
						</Row>
						{element.action_list.map((i, key) => (
							<Row key={key} className="border-bottom mb-1 pt-1">
								<Col className="font-weight-bold">{i.action}</Col>
								<Col>{i.responsability}</Col>
								<Col>{getValue(getSelectedElement(i.receiver_group, messagegroup_list, 'id'), 'name')}</Col>
								<Col>{i.timing_mode && i.timing_mode.length>0?
								<>
								{i.timing_mode.map((m,k)=>(
									<Badge key={k}>{console.log(m)} {getValue(timing_mode_dict[m], 'label')}</Badge>
								))}
								</>
								:''
								} 
								{i.timing.length>0?
								<>
								({i.timing})
								</>:''} 
								</Col>
								<Col className="text-center" sm="2">
									<ButtonGroup>
										<Button size="sm" variant="outline-info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditAction(i, key)} /> </Button>
										<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteAction(i, key)} /> </Button>
									</ButtonGroup>
								</Col>
							</Row>))
						}
					</Col>
					: <Col><i> {t('workarea.messages.no_action')} </i></Col>
				}
			</Row>


			<Modal show={showActionEdit} onHide={handleCloseAction} size="xl" className="bg-secondary" backdrop="static">
				<Modal.Header closeButton>
					{newActionEdit && <Modal.Title>{t('workarea.labels.new_action')}</Modal.Title>}
					{!newActionEdit && <Modal.Title>{t('workarea.labels.edit_action')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('workarea.labels.action')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="action" value={curAction.action} onChange={(event) => onChangeHandlerAction(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('workarea.labels.responsability')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="responsability" value={curAction.responsability} onChange={(event) => onChangeHandlerAction(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom mb-1">
							<Form.Label column sm="2">{t('ticket.labels.receiver')}</Form.Label>
							<Col sm="8">
								<Form.Control as="select" className="mb-2" value={curAction.receiver_group} name="receiver_group" onChange={(event) => onChangeHandlerAction(event.currentTarget)} >
									<option value="-1" >{t('ticket.labels.select_receiver')}</option>
									{messagegroup_list
										? [messagegroup_list.map((r, k) => (
											<option key={k} value={r.id} >{r.name}</option>
										))]
										: ''
									}
								</Form.Control>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.timing_mode')}</Form.Label>
							<Col>
								<Form.Check type="checkbox" className="ml-2" name="timing_mode" value="now" checked={element.timing_mode && element.timing_mode.indexOf("now") !== -1} label={t('ticket.labels.now_mode')} onChange={(event) => onChangeHandlerAction(event.currentTarget)} />
								<Form.Check type="checkbox" className="ml-2" name="timing_mode" value="delayed" checked={element.timing_mode && element.timing_mode.indexOf("delayed") !== -1} label={t('ticket.labels.delayed_mode')} onChange={(event) =>onChangeHandlerAction(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('workarea.labels.timing')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="timing" value={curAction.timing} onChange={(event) => onChangeHandlerAction(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseAction}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" form="companyForm" onClick={handleSaveAction}>
						<FontAwesomeIcon icon="save"></FontAwesomeIcon> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>

		</>
	)
}