import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Spinner, ButtonGroup, Badge } from 'react-bootstrap';
import { format, isSameYear } from 'date-fns'
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import GlobalDataContext from '../components/contexts/globaldataContext.js';
import DomainContext from '../components/contexts/domainContext'
import { InitContractHelper } from "../util/contract"


import ModalMicrobiologicalCheck from "../components/ModalMicrobiologicalCheck"
import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"

import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import Footer from "../components/Footer"
import SearchSection from "../components/SearchSection"

import { checkComponentPresence, getElementCount, myTimestampToDate, getValue } from "../util/ui_utils.js"
import { RoomInfoBadge } from '../components/StructureGraphicElements.js'



import { getMicrobioList as getMicrobiologicalCheckList2, deleteMicrobio as deleteMicrobiologicalCheck2 } from '../Firebase2/Storage2/dbMicrobiologicalCheck';
import {
	getAllRoomList as getAllRoomList2
} from '../Firebase2/Storage2/dbRoom';
import { YearSelector } from '../components/YearSelector.js';






let load_page = false


////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
////////////////////////////////////////////////////////////////////////////////////////////////////////
const MicrobiologicalChecksView = ({ t, uData }) => {
	const { domain } = useContext(DomainContext)
	const { service_dict, roomtype_dict, contract, structureElements } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const now = new Date()
	const [selectedYear, setSelectedYear] = useState(now.getFullYear())

	const { initContract } = InitContractHelper()
	let { cid } = useParams();
	let contractId = cid

	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [viewelement_list, setViewElementList] = useState([])
	const [cleaningcompany_list, setCleaningCompanyList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)

	const initValues = async () => {
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('products') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		if (contract && domain.length > 0) {
			let room_list = []
			for (const structure of structureElements.structure_list) {
				const t_room_list = await getAllRoomList2({ structureId: structure.id })
				console.log(t_room_list)
				room_list = [...room_list, ...t_room_list]
			}
			room_list = room_list.filter(r => has_check(r.roomtype.id))
			const newlist = await getMicrobiologicalCheckList2(domain, contractId)
			console.log("checklist", newlist)

			setElementList(newlist)
			setUiList({ room_list })
			setLoading(false)
		}
	}


	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("MICROBIOLOGICAL reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("MICROBIOLOGICAL init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])


	useEffect(() => {
		console.log('MICROBIOLOGICAL loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])



	const has_check = (roomtype_id) => {
		const rt = roomtype_dict[roomtype_id]
		if (rt) {
			return rt.has_microbiological_check
		} else {
			return false
		}
	}


	useEffect(() => {
		initViewData()
	}, [selectedYear, element_list])


	const initViewData = () => {
		setViewElementList(element_list.filter(e => {
			const y = myTimestampToDate(e.samplingDate).getFullYear()
			console.log(y, selectedYear)
			return y === selectedYear
		}))

	}


	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteMicrobiologicalCheck2(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('microbiologicalchecks.messages.delete_microbiologicalchecks_title'), t('microbiologicalchecks.messages.delete_microbiologicalchecks'), cb)
	}

	const changeYear = (cc) => {
		setSelectedYear(cc)
	}

	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col sm="6">
						<PageTitle title={t('global.pages.microbiologicalcheck')} />
					</Col>
					{!loading && contract ?
						<Col>
							<YearSelector startDate={contract.startDate} endDate={contract.endDate} selectedYear={selectedYear} changeYear={changeYear} />
						</Col>
						: ''}
					<Col style={{ textAlign: 'right' }}>
						{contract && contract.has_microbiological_check && canWrite ?
							<ButtonGroup>
								<ModalMicrobiologicalCheck item={element_list} is_new={true} item_list={element_list} ui_list={ui_list} functions={{ updateData: onUpdateData }} setParentList={setElementList} canWrite={canWrite} t={t} />
							</ButtonGroup>
							: ''}
					</Col>
				</Row>
				{viewelement_list && viewelement_list.length > 0 ?
					<Row className=" p-1 text-light">
						<Col className="bg-secondary ml-1" sm="2">{t('microbiologicalcheck.labels.sampling_date')}</Col>
						<Col className="bg-secondary ml-1" sm="2">{t('microbiologicalcheck.labels.execution_date')}</Col>
						<Col className="bg-secondary ml-1" sm="2">Esito</Col>
						<Col className="bg-secondary ml-1">{t('microbiologicalchecks.labels.rooms')}</Col>
						<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>

				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					:
					<>
						{viewelement_list && viewelement_list.length > 0 ?
							<>
								{viewelement_list.map((i, key) => (
									<Row key={key} className="element_row p-1 border-bottom border-secondary">
										<Col sm="2">
											{format(myTimestampToDate(i.samplingDate), 'dd/MM/yyyy')}
										</Col>
										<Col sm="2">
											{format(myTimestampToDate(i.executionDate), 'dd/MM/yyyy')}
										</Col>
										<Col sm="2">
											{i.result === true ?
												<Badge variant="success">Positivo</Badge>
												:
												<Badge variant="danger">Negativo</Badge>
											}

										</Col>
										<Col>
											{i.room_list.map((room, kr) => (
												<Row key={kr} className={kr < i.room_list.length - 1 ? "border-bottom pb-1" : 'pb-1'}>
													<Col>
														<RoomInfoBadge parent_list={room.parent_list} structure_list={structureElements.structure_list} pavillon_dict={structureElements.all_pavillon_dict} floor_dict={structureElements.all_floor_dict} department_dict={structureElements.all_department_dict} />
														{room.name}</Col>
													<Col className="text-muted">
														{getValue(roomtype_dict[room.roomtype.id], 'name')}
													</Col>
													<Col>{room.result}</Col>
												</Row>

											))}

										</Col>
										<Col className="text-center" sm="1">
											<ButtonGroup>
												<ModalMicrobiologicalCheck item={i} is_new={false} item_list={element_list} ui_list={ui_list} functions={{ updateData: onUpdateData }} setParentList={setElementList} canWrite={canWrite} t={t} />
												{canWrite ?
													<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
													: ''}
											</ButtonGroup>
										</Col>

									</Row>
								))}
							</>
							:
							<Row>
								<Col className="font-italic">
									{t('microbiologicalchecks.messages.no_microbiologicalcheck')}
								</Col>
							</Row>
						}
					</>
				}

			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('global.labels.microbiologicalchecks') })} />

		</>
	);
}

export default translate()(MicrobiologicalChecksView)





