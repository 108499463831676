import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from '../contexts/locale.js'
import { Container, Row, Col, Button, Modal, Tooltip, Spinner, OverlayTrigger, Form, Nav, Tab, InputGroup, ButtonGroup, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { format, setDay, differenceInMinutes, addDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { BlockPicker } from 'react-color';
import reactCSS from 'reactcss'
import { useParams } from "react-router-dom"
import DateTime from 'react-datetime';

import DomainContext from '../contexts/domainContext'
import GlobalDataContext from '../contexts/globaldataContext.js';
import SearchContext, { SearchProvider } from '../contexts/searchContext';
import InterventionContext from '../contexts/interventionContext.js';


import AlertSection from "../AlertSection.js"

import { getSelectedElement, checkComponentPresence, myTimestampToDate, setMyDate, makeid, getTimeStr, myDateToTimestamp, getTimeValue } from "../../util/ui_utils.js"
import { ModalInitButton, WeekDays, ServiceBadge, renderActivityPeriodListPopover} from '../StructureGraphicElements.js'

import firebase from 'firebase/app'

import { addWorkflow as addWorkflow2, editWorkflow as editWorkflow2 } from '../../Firebase2/Storage2/dbWorkflow';
import { editFileTracker as editFileTracker2 } from '../../Firebase2/Storage2/dbFileStorage.js';


import FrequencySelector from '../FrequencySelector'
import { getInterventionById } from '../../Firebase2/Storage2/dbIntervention'


const section = 'workflow'
const defaultcolors = [
	'#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#607d8b", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800",
]

const wd = ["1", "2", "3", "4", "5", "6", "0"]
let d_start = new Date()
let d_end = new Date()
d_start.setHours(8, 0, 0)
d_end.setHours(18, 0, 0)
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalWorkflow({ item, setItem, t, is_new, small, item_list, canWrite, workarea_list }) {
	const { domain } = useContext(DomainContext)
	const { intervention_dict } = useContext(InterventionContext)

	let { cid } = useParams();
	let contractId = cid

	const childRefAlert = useRef()
	const newworkflow = {
		code: '',
		name: '',
		color: { r: 0, g: 0, b: 0, a: 1 },
		week_frequency: {},
		month_frequency: [],
		intervention_list: [],
		note: '',

	}
	const initValues = async () => {
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newworkflow)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newworkflow.media_list = []
		setCurrentElement(newworkflow)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	const updateElement = (el) => {
		setCurrentElement(el)
		setEditedElement(true)

	}
	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addWorkflow2(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data = await editWorkflow2(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('workflow.buttons.workflow_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} dialogClassName="custom-modal" backdrop="static" className="bg-secondary" scrollable={true}>
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('workflows.labels.new_workflow')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('workflows.labels.edit_workflow')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('workflows.labels.view_workflow')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<ElementFormDetails element={curElement} t={t} setElement={updateElement} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
						<ElementFormIntervention element={curElement} t={t} setElement={updateElement} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} workarea_list={workarea_list}/>
						
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ElementFormDetails({ element, setElement, functions, ui_list, t, canWrite }) {
	const { locale } = useContext(LocaleContext)
	const [displayColorPicker, setShowColorPicker] = useState(false)
	const [color, setCurColor] = useState({ r: 0, g: 0, b: 0, a: 1 })
	const [totalMinutes, setTotalMinutes] = useState(0)


	const styles = reactCSS({
		'default': {
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: `rgba(${element.color.r}, ${element.color.g}, ${element.color.b}, ${element.color.a})`,
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				position: 'absolute',
				zIndex: '2',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		},
	});
	const updateCategories = (b) => {
		functions.updateData('workflow_category', b)
	}

	const onChangeHandlerColor = (el) => {
		functions.edit({ name: 'color', value: el.rgb })
	}
	const handleChangeColor = (c) => {
		console.log('set color', c)
		setCurColor(c.rgb)
	}
	const handleShowColorPicker = () => {
		setShowColorPicker(!displayColorPicker)
	}
	const handleCloseColorPicker = () => {
		setShowColorPicker(false)
	}


	useEffect(() => {
		setTotalMinutes(totalWorkflowMinutes())

	}, [element])

	const totalWorkflowMinutes = () => {
		let total_minutes = 0
		try {

			if (element.category === 'monthdays') {
				total_minutes = Object.values(element.month_frequency).map(i => differenceInMinutes(myTimestampToDate(i.end), myTimestampToDate(i.start))).reduce((p, n) => p + n)
			} else if (element.category === 'weekdays') {
				total_minutes = Object.values(element.week_frequency).map(i => differenceInMinutes(myTimestampToDate(i.end), myTimestampToDate(i.start))).reduce((p, n) => p + n)
			} else {

			}
		} catch (err) {

		}
		return total_minutes
	}

	return (
		<>
			<Form.Group as={Row} controlId="workflowName" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="workflowCode" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Row className="border-bottom mb-1 pb-1">
				<Col sm="2">{t('objecttype.labels.color')}</Col>
				<Col>
					<Button variant="outline-info" onClick={handleShowColorPicker}>
						<div style={styles.color} />
					</Button>
					{displayColorPicker ? <div style={styles.popover}>
						<div style={styles.cover} onClick={handleCloseColorPicker} />
						<BlockPicker color={color} colors={defaultcolors} triangle="hide" onChange={handleChangeColor} onChangeComplete={onChangeHandlerColor} />
					</div> : null}
				</Col>
			</Row>


			<Form.Group as={Row} controlId="workflowNote" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}

function ElementFormIntervention({ element, functions, t, canWrite, workarea_list }) {
	const { domain } = useContext(DomainContext)

	const newcomponent = {
		name: '',
		type: '',
		quantity: 1
	}
	
	const { intervention_dict, addDictIntervention, setInterventionDict } = useContext(InterventionContext)
	const [operation_list, setOperationList] = useState([])
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [newComponentEdit, setNewComponentEdit] = useState(true)
	const [curComponent, setCurComponent] = useState(newcomponent)
	

	const editWorkflowIntervention = async (intervention_id, group_id) => {

		let edit_list = []
/* 		for (let g of element_list) {
			if (g.id === group_id) {
				let st_list = [...g.intervention_list]
				if (g.intervention_list && g.intervention_list.indexOf(intervention_id) >= 0) {
					st_list.splice(g.intervention_list.indexOf(intervention_id), 1)
				} else {
					st_list.push(intervention_id)
				}
				g = { ...g, intervention_list: st_list }
				await editWorkflow(domain, contractId, g.id, g)
				console.log(g.intervention_list)
			}
			edit_list.push(g)
		}
		console.log(edit_list)
		setElementList(edit_list) */
	}


	const handleRemove = async (intervention_id, group_id) => {
		let edit_list = []
/* 		for (let g of element_list) {
			if (g.intervention_list && g.intervention_list.indexOf(intervention_id) >= 0) {
				let st_list = [...g.intervention_list]
				st_list.splice(g.intervention_list.indexOf(intervention_id), 1)
				console.log("ADD", st_list)
				g = { ...g, intervention_list: st_list }
				console.log("edit group", g)
				await editWorkflow(domain, contractId, g.id, g)
			}
			edit_list.push(g)
		}
		console.log(edit_list)
		setElementList(edit_list) */

	}
	//----operation
	const handleDeleteIntervention = (el) => {
		console.log(el)
		let editlist = element.intervention_list
		const c = editlist.indexOf(el)
		editlist.splice(c, 1)
		functions.edit({ name: 'intervention_list', value: editlist })
	}
	const handleCloseComposition = () => setShowComponentEdit(false);

	const handleOpenComposition = () => {
		setCurComponent(newcomponent)
		setNewComponentEdit(true)
		setShowComponentEdit(true)
	}
	const handleAddIntervention = (el) => {
		console.log(el)
		let editlist = element.intervention_list
		const c = editlist.indexOf(el.id)
		if (c >= 0) {
			alert('intervention present')
		} else {
			editlist.push(el.id)
		}
		functions.edit({ name: 'intervention_list', value: editlist })
	}
	const initValues = async () => {
	}

	useEffect(() => {
		initValues()
	}, [])
	return (
		<>

			<Form.Group as={Row} controlId="formStructureComponent" className="border-bottom">
				<Col>
			{canWrite ?
				<Row className="mb-1">
					<Col className="text-right">
						{showComponentEdit ?
							<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComposition()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
							:
							<Button variant="outline-primary" size="sm" onClick={() => handleOpenComposition()}><FontAwesomeIcon icon="pencil-alt" /> {t('structure.labels.component_mod')}</Button>
						}
					</Col>
				</Row>
				: ''}

					<Row className="border-bottom h5">
						<Col sm="7">
							{t('global.labels.interventions')}
						</Col>
						{showComponentEdit ?
							<>
								<Col className="text-info">{t('workareas.labels.available_operations')}</Col>
								<Col className="text-right">
								</Col>
							</>
							: ''}
					</Row>
					<Row className="border-bottom  border-right mb-1">

						{element.intervention_list && element.intervention_list.length > 0 ?
							<Col>
								<Row >
									<Col className="bg-secondary text-light ml-1">{t('global.labels.name')} </Col>
									<Col className="bg-secondary text-light ml-1"> </Col>
									{canWrite ?
										<Col className="bg-secondary text-light ml-1" sm="1" >&nbsp;</Col>
										: ''}
								</Row>
								{element.intervention_list.map((i, key) => (
										<OneIntervention key={key} intervention_id={i} handleDeleteIntervention={handleDeleteIntervention} count={0} canWrite={canWrite} t={t}  editWorkflowIntervention={editWorkflowIntervention} intervention_dict={intervention_dict} />
									))
								}
							</Col>
							: <Col> {t('workflows.messages.no_intervention')}</Col>
						}
						{showComponentEdit
							?
							<>
								<Col sm="1"></Col>
								<Col className="border-left bg-info">
								{workarea_list
									.filter(wi => (wi.intervention_list && wi.intervention_list.length > 0)
										|| (wi.periodicintervention_list && wi.periodicintervention_list.length > 0))
									/* 											.filter(i => (filterAppliedList.service.length === 0 ||
																					checkWorkareaInterventionServiceFilter(i, filterAppliedList, intervention_dict, intervention_type))
																					&& (filterAppliedList.structure.length === 0 ||
																						checkStructureFilter(i, filterAppliedList.structure))
																					&& (filterAppliedList.pavillon.length === 0 ||
																						checkPavillonFilter(i, filterAppliedList.pavillon))
																				)*/
									//.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
									.map((w, key) => (
										<Row className="mb-1 pb-1 border-bottom" key={key}>
											<Col className="font-italic">
												{w.name}
												<>
													{w.intervention_list && w.intervention_list.filter(f => typeof f === 'string').length > 0
														?
														<>
															{w.intervention_list.filter(f => typeof f === 'string')
																//	.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
																.map((intervention_id, ki) => (
																	<OneIntervention key={ki} intervention_id={intervention_id} handleAddIntervention={handleAddIntervention}  count={0} workarea={w} globalEdit={true} canWrite={canWrite} t={t}   handleRemove={handleRemove} editWorkflowIntervention={editWorkflowIntervention} />
																))}
														</>
														:
														<i>
														</i>
													}
												</>
												<>
													{w.periodicintervention_list && w.periodicintervention_list.filter(f => typeof f === 'string').length > 0
														?
														<>
															{w.periodicintervention_list.filter(f => typeof f === 'string')
																//																.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
																.map((intervention_id, ki) => (
																	<OneIntervention key={ki} intervention_id={intervention_id} handleAddIntervention={handleAddIntervention} count={0} workarea={w} globalEdit={true} canWrite={canWrite} t={t}  handleRemove={handleRemove} editWorkflowIntervention={editWorkflowIntervention} intervention_dict={intervention_dict} />
																))}
														</>
														:
														<i>
														</i>
													}
												</>
											</Col>
										</Row>
									))}
								</Col>
							</>
							: ''
						}
					</Row>
				</Col>
			</Form.Group>

		</>
	)
}






///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneIntervention = ({ intervention_id,  handleAddIntervention, handleDeleteIntervention, count, velement_list }) => {

	const { domain, loadingdomain } = useContext(DomainContext)
	const { contractId, service_dict } = useContext(GlobalDataContext)
	const { intervention_dict, addDictIntervention, setInterventionDict } = useContext(InterventionContext)
	const { searchTerm } = useContext(SearchContext)

	const [intervention, setIntervention] = useState()
	const [loading, setLoading] = useState(false)
	const [editedElement, setEditedElement] = useState(false)





	useEffect(() => {

		async function fetchData() {
			setLoading(true)

			let intervention = intervention_dict[intervention_id]

			if (!intervention) {
				if (typeof intervention_id === 'string') {
					intervention = await getInterventionById(domain, contractId, intervention_id)
					//					console.log("SET", domain, contractId, intervention_id, intervention)
					//updateIntervention(intervention)
					setInterventionDict((i) => ({ ...i, [intervention.id]: intervention }))
					//					updateInterventionDict(intervention_id,  intervention)
					setIntervention(intervention)
				}
			} else {
				setIntervention(intervention)
			}
			setLoading(false)
		}
		fetchData()
	}, [])



	if (intervention) {
		if (searchTerm.length === 0 || (intervention.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)) {
			return (
				<Col>
					<Row className="mb-1 border-bottom border-light element_row">
						{handleAddIntervention?
						<Col sm="1">
							<Button size="sm" variant="light" onClick={() => handleAddIntervention(intervention)}><FontAwesomeIcon icon="arrow-left" /><FontAwesomeIcon icon="plus-square" /> </Button>
						</Col>
						:''}
						<Col>
{/* 							<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={<Tooltip>Numero Workflow a cui è associato questo intervento ({count})</Tooltip>}>
								<Badge variant={count > 0 ? "info" : 'warning'}>{count}</Badge>
							</OverlayTrigger>
							&nbsp; */}
							<b>{intervention.name}</b>
						</Col>
						<Col>
							<ServiceBadge service={service_dict[intervention.service.id]} />&nbsp;
							{intervention.enable_refill ?
								<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={<Tooltip>Ricarica dispenser</Tooltip>}>
									<FontAwesomeIcon icon="pump-soap" />
								</OverlayTrigger>
								: ''}
						</Col>
						<Col sm="1" className="text-center">
							<FontAwesomeIcon icon="user" /> &times; {intervention.operating_units}

						</Col>
						<Col sm="2" className="text-center p-0">
							{intervention.frequency_mode === 'ordinary' ?
								<>
									{intervention.use_activity_period ?
										<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderActivityPeriodListPopover(intervention.period_list)}>
											<Badge variant="secondary">{intervention.period_list.length} periodi</Badge>
										</OverlayTrigger>
										: ''}
									{getTimeValue(intervention.start)} - {getTimeValue(intervention.end)} <br />
									{intervention.weekdays ?
										<WeekDays weekdays={intervention.weekdays} />
										: ''}
								</>
								:
								<FrequencySelector element={intervention} mode='read' />
							}
						</Col>
						{handleDeleteIntervention?
						<Col sm="1">
							<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteIntervention(intervention.id)} /> </Button>
						</Col>
						:''}
					</Row>

				</Col>
			)
		} else {
			return (
				<></>
			)
		}
	} else {
		return <>
			<Spinner animation="grow" role="status" variant="info" />
		</>
	}

}




export default translate()(ModalWorkflow)