import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, } from 'react-bootstrap';
import ParetoChart from 'pareto-chart'
import Chart from "react-apexcharts";

import { processCheckSection_dict } from "../components/constants/global_definitions"

import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import { myTimestampToDate, propComparator } from "../util/ui_utils.js"

import { getInterventionById } from '../Firebase2/Storage2/dbIntervention'
import { format } from 'date-fns';



//--------------------------------------------------------
export const AnalysisResultData = ({ archive_list, ui_list, is_print }) => {

	const { operation_dict } = useContext(GlobalDataContext)
	const [op_dict, setOpDict] = useState({})
	const [el_dict, setElDict] = useState({})
	const [nc_list, setNcList] = useState([])
	const [pareto_dict, setParetoDict] = useState({})

	const [graph_options, setGraphOptions] = useState({
		chart: {
			toolbar: {
				show: false,
			},
			type: 'bar',
			height: 100,
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true,
			}
		},
		xaxis: { decimalsInFloat: 0 },
		dataLabels: {
			enabled: false
		}
	})
	const [graph_options2, setGraphOptions2] = useState({
		chart: {
			toolbar: {
				show: false,
			},
			type: 'bar',
			height: 100,
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				horizontal: true,
			}
		},
		xaxis: { decimalsInFloat: 0 },
		dataLabels: {
			enabled: false
		}
	})


	useEffect(() => {
		console.log("----", archive_list)
		let ncoperation_dict = {}
		let ncelement_dict = {}
		let pareto_data_dict = {}
		let nc_list = []
		for (const one_a of archive_list) {
			for (const a in one_a.result_dict) {
				if (one_a.result_dict[a] === 'false') {
					const d = a.split("_")
					const op_id = d[0]
					const el_id = d[1]
					if (operation_dict[op_id]) {

						if (ncoperation_dict[op_id]) {
							ncoperation_dict[op_id].count += 1
							if (pareto_data_dict[operation_dict[op_id].name][ui_list.furniture_dict[el_id] ? ui_list.furniture_dict[el_id].name : 'altro']) {
								pareto_data_dict[operation_dict[op_id].name][ui_list.furniture_dict[el_id] ? ui_list.furniture_dict[el_id].name : 'altro'] += 1
							} else {
								pareto_data_dict[operation_dict[op_id].name] = { ...pareto_data_dict[operation_dict[op_id].name], [ui_list.furniture_dict[el_id] ? ui_list.furniture_dict[el_id].name : 'altro']: 1 }

							}
						} else {
							ncoperation_dict[op_id] = { count: 1, id: op_id }
							console.log("----op", operation_dict[op_id], el_id)
							console.log("----op", operation_dict[op_id], el_id, ui_list.furniture_dict[el_id])
							pareto_data_dict[operation_dict[op_id].name] = { [ui_list.furniture_dict[el_id] ? ui_list.furniture_dict[el_id].name : 'altro']: 1 }
						}
						nc_list.push({
							date: one_a.end_check,
							operation: operation_dict[op_id],
							furniture: ui_list.furniture_dict[el_id],
							room: one_a.room,
							corrective_action: one_a.corrective_action_dict[a]
						})
					} else {
						console.log("operation unknown ", op_id)
					}
					if (ncelement_dict[el_id]) {
						ncelement_dict[el_id].count += 1
					} else {
						ncelement_dict[el_id] = { count: 1, id: el_id }
						console.log("----EL", ui_list.furniture_dict[el_id])
					}
				}
			}
		}
		setNcList(nc_list)
		setOpDict(ncoperation_dict)
		setElDict(ncelement_dict)
		setParetoDict(pareto_data_dict)
		setGraphOptions({
			...graph_options, xaxis: {
				categories: Object.values(ncoperation_dict).sort(propComparator('count')).reverse().map(ok => operation_dict[ok.id].name)
			}
		})
		setGraphOptions2({
			...graph_options2, xaxis: {
				categories: Object.values(ncelement_dict).sort(propComparator('count')).reverse().map(ok => ui_list.furniture_dict[ok.id] ? ui_list.furniture_dict[ok.id].name : '')
			}
		})

	}, [archive_list])



	return (
		<>
			{!is_print &&
				<>
					<Row>
						<Col className="bg-info p-1 text-light ">
							Non conformità (Pareto)
							({archive_list.length} controlli)
						</Col>
					</Row>
					<Row className="mt-1">
						{Object.keys(pareto_dict).length > 0 ?
							<Col>
								<ParetoChart
									width={200}
									height={50}
									lineLabel='percentuale totale'
									data={pareto_dict}
								/>
							</Col>
							: <></>}
						<Col>
						</Col>
					</Row>
				</>
			}
			<Row>
				<Col className="bg-info p-1 text-light">
					Non conformità (operazioni)
				</Col>
			</Row>
			{Object.keys(op_dict).length > 0 &&
				<Row>
					<Col>
						<Row>
							<Col className="bg-secondary mt-1 mb-1 text-light mr-1"> operazione</Col>
							<Col className="bg-secondary mt-1 mb-1 text-light" xs="3"> numero NC</Col>
						</Row>
						{Object.values(op_dict).sort(propComparator('count')).reverse().map((o, k) => (
							<Row key={k} className="border-bottom pb-1 mb-1">
								{operation_dict[o.id] ?
									<Col>{operation_dict[o.id].name}</Col>
									:
									<Col>{o.id}</Col>
								}
								<Col xs="3">{op_dict[o.id].count}</Col>
							</Row>
						)
						)}
					</Col>
					<Col>
						<Chart
							options={graph_options}
							series={[{ name: '', data: Object.values(op_dict).sort(propComparator('count')).reverse().map(o => o.count) }]}
							type="bar"
							width="300"
							height={Object.values(op_dict).length * 30 + 30}
						/>
					</Col>
				</Row>

			}

			<Row>
				<Col className="bg-info p-1 text-light">
					Non conformità (elementi)
				</Col>
			</Row>
			{Object.keys(op_dict).length > 0 &&
				<Row>
					<Col md="4" xl="6">
						<Row>
							<Col className="bg-secondary mt-1 mb-1 text-light mr-1"> operazione</Col>
							<Col className="bg-secondary mt-1 mb-1 text-light" xs="3"> numero NC</Col>
						</Row>
						{Object.values(el_dict).sort(propComparator('count')).reverse().map((o, k) => (
							<Row key={k} className="border-bottom pb-1 mb-1">
								{ui_list.furniture_dict[o.id] ?
									<Col>{ui_list.furniture_dict[o.id].name}</Col>
									:
									<Col>{o.id}</Col>
								}
								<Col xs="3">{o.count}</Col>
							</Row>
						)
						)}
					</Col>
					<Col sm="4" xl="4" md="4" className='text-center'>
						{console.log(Object.values(el_dict))}
						<Chart
							options={graph_options2}
							series={[{ name: '', data: Object.values(el_dict).sort(propComparator('count')).reverse().map(o => o.count) }]}
							type="bar"
							width="300"
							height={Object.values(el_dict).length * 30 + 30}
						/>
					</Col>
				</Row>

			}
			<Row>
				<Col className="bg-info p-1 text-light">
					Non conformità (lista)
				</Col>
			</Row>
			<Row>
				<Col className="bg-secondary mt-1 mb-1 text-light mr-1">
					data
				</Col>
				<Col className="bg-secondary mt-1 mb-1 text-light mr-1">
					locale
				</Col>
				<Col className="bg-secondary mt-1 mb-1 text-light mr-1">
					operazione
				</Col>
				<Col className="bg-secondary mt-1 mb-1 text-light mr-1">
					elemento
				</Col>
				<Col className="bg-secondary mt-1 mb-1 text-light mr-1">
					azione correttiva
				</Col>
			</Row>
			{nc_list.map((nc, k) => (
				<Row key={k} className="border-bottom pb-1 mb-1">
					<Col>
						{format(myTimestampToDate(nc.date), 'dd/MM/yyyy HH:mm')}
					</Col>
					<Col>
						{nc.room.name} - {nc.room.roomtype?.name}
					</Col>
					<Col>
						{nc.operation?.name}
					</Col>
					<Col>
						{nc.furniture?.name}
					</Col>
					<Col>
						<CorrectiveAction ca={nc.corrective_action} />
					</Col>
				</Row>
			))}
		</>

	)
}


const CorrectiveAction = ({ ca }) => {
	if (ca) {
		return (
			<>
				{ca.action.action} - {ca.timing.now ? 'immediata' : ca.action.timing}
				{ca.note.length > 0 && (ca.note)} - {ca.action.responsability}
			</>
		)
	} else {
		return (<></>)
	}
}



const getSection = (d, check_section) => {
	for (const c in check_section) {
		if (check_section[c].list.map(n => n.id).indexOf(d) >= 0) {
			return c
		}
	}
}





let intervention_dict = {}
///////////////////////////////////////////////////////////////////////////////
export const AnalysisProcessData = ({ archive_list, ui_list, t }) => {

	const { domain } = useContext(DomainContext)
	const { contractId } = useContext(GlobalDataContext)
	const [el_dict, setElDict] = useState({})

	let check_section = processCheckSection_dict({ t })
	check_section.equipment.list.push({ id: "machine", label: t('global.labels.machines') })
	check_section.equipment.list.push({ id: "cart", label: t('global.labels.carts') })


	useEffect(() => {
		const initData = async () => {
			console.log("----", archive_list, Object.keys(intervention_dict))
			let ncelement_dict = {
				operator: 0,
				equipment: 0,
				skill: 0
			}
			let intervention_section_question = {
				operator: [],
				equipment: [],
				skill: []
			}
			for (const one_a of archive_list) {
				let _intervention = intervention_dict[one_a.intervention_id]

				if (!_intervention) {
					_intervention = await getInterventionById(domain, contractId, one_a.intervention_id)
					//					console.log("SET", domain, contractId, intervention_id, intervention)
					//updateIntervention(intervention)
					intervention_dict[one_a.intervention_id] = _intervention
				} else {
				}
				console.log(_intervention)
				if (_intervention) {
					for (const p in _intervention.process_list) {
						for (const pp in _intervention.process_list[p]) {
							//						console.log(intervention.process_list[p][pp].section, getSection(intervention.process_list[p][pp].section, check_section), intervention.process_list[p][pp].id)
							intervention_section_question[getSection(_intervention.process_list[p][pp].section, check_section)].push(_intervention.process_list[p][pp].id)
							if (one_a.result_dict[_intervention.process_list[p][pp].id] && one_a.result_dict[_intervention.process_list[p][pp].id] === 'false') {
								ncelement_dict[getSection(_intervention.process_list[p][pp].section, check_section)] += 1
							}
						}
					}
				} else {
					console.log("*****UNDEFINED", one_a.intervention_id)
				}
				/* 			for (const a in one_a.result_dict) {
								if (one_a.result_dict[a] === 'false') {
									console.log("--Data", a)
									for (const i in intervention_section_question){
										if (intervention_section_question[i].indexOf(a)>=0){
											console.log(a, "->", i )
										}
									}
				
								}
							} */
			}
			setElDict(ncelement_dict)
			console.log(ncelement_dict)


		}
		initData()

	}, [archive_list])



	return (
		archive_list.length > 0 ?

			<>
				<Row>
					<Col className="bg-info p-1 text-light">Non conformità
					</Col>
				</Row>
				<Row>
					<Col className="bg-secondary mt-1 mb-1 text-light ml-1 mr-1">Sezioni </Col>
					<Col className="bg-secondary mt-1 mb-1 text-light"> numero NC</Col>
				</Row>
				{Object.keys(el_dict).filter(ok => el_dict[ok] > 0).sort().map((ok, k) => (
					<Row key={ok} className="border-bottom pb-1 mb-1">
						{console.log(check_section[ok])}
						<Col>{check_section[ok].label}</Col>
						<Col>{el_dict[ok]}</Col>
					</Row>
				)
				)}
			</>
			:
			<Row>
				<Col>
					Nessuna N.C. di processo presente
				</Col>
			</Row>

	)
}
