import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, ButtonGroup, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import update from 'immutability-helper'
import { format, setDay } from 'date-fns'
import { it } from 'date-fns/locale'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import styles from './constants/styles.js'

import DomainContext from './contexts/domainContext'
import LocaleContext from './contexts/locale.js'
import GlobalDataContext from './contexts/globaldataContext.js';

import DeleteConfirmationSection from "./DeleteConfirmationSection.js"

import { ServiceBadge, FrequencyRender } from "./StructureGraphicElements"
import { getSelectedElement, checkComponentPresence, compareName, getValue } from "../util/ui_utils.js"

import FrequencySelector from './FrequencySelector'






///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const checkValidity = (element) => {
	let isvalid = true
	if (!element.service.id) {
		isvalid = false
	}
	if (!element.operation_list || element.operation_list.length === 0) {
		isvalid = false
	}

	return isvalid
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalCopyActivities({ item, setItem, t, is_new, small, index, item_list, setParentList, ui_list, canWrite }) {
	const { domain } = useContext(DomainContext)
	const { service_dict, roomtype_list, roomtype_dict } = useContext(GlobalDataContext)

	const [showServiceEdit, setShowServiceEdit] = useState(false)
	const [curElement, setCurElement] = useState(item)
	const [editedElement, setEditedElement] = useState(false)
	const [selectedRT, setSelectedRT] = useState(-1)



	const handleCloseService = () => setShowServiceEdit(false);

	const handleDoCopy = async (replace) => {
		setShowServiceEdit(false);
		if (selectedRT !== -1) {

			const s_list = [...roomtype_dict[selectedRT].service_list]
			if (replace) {
				setItem( s_list)
			} else {
				setItem([...curElement, ...s_list] )
			}
		}
	}


	const handleShowNewService = () => {
		console.log('show new')
		setShowServiceEdit(true)
		setSelectedRT(-1)
		setCurElement(item)
		setEditedElement(false)
	}




	const onChangeSelected = (el) => {
		setSelectedRT(el.value)
	}


	return (
		<>
			<Button onClick={() => handleShowNewService()} variant="outline-primary"><FontAwesomeIcon icon='clone' /> {t('structure.labels.copy_activities')}</Button>
			<Modal show={showServiceEdit} onHide={handleCloseService} dialogClassName="custom-modal" backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						<FontAwesomeIcon icon="clone" /> <span className="text-info">{t('structure.labels.copy_activities')}</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.roomtype')} </Form.Label>
							<Col sm="4">
								<Form.Control as="select" className="mb-2" name="service" value={selectedRT} onChange={(event) => onChangeSelected(event.target)} >
									<option value="-1" >{t('roomtype.labels.select_roomtype')}</option>
									{roomtype_list.filter(r => r.service_list && r.service_list.length > 0).map(r => (
										<option key={r.id} value={r.id}>{r.name} [{r.service_list.length}]</option>
									))
									}
								</Form.Control>
							</Col>
						</Form.Group>
						{selectedRT !== -1 && roomtype_dict[selectedRT] ?
							<>
								{roomtype_dict[selectedRT].service_list.map((card, k) => (
									<Row key={k} className='border-bottom mb-1 pb-1' >
										<Col>
											<ServiceBadge service={service_dict[card.service.id]} freq={true} />
										</Col>
										<Col>
											<OperationSummary element={card} ui_list={ui_list} />
										</Col>
										<Col>
											<FrequencyRender element={card} />
										</Col>
										<Col sm="1">
											{card.hourlyyield} m<sup>2</sup> / h
										</Col>
									</Row>
								))}

							</>
							: ''}

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseService}>
						{t('modal.close')}
					</Button>
					<Button disabled={selectedRT === -1} className={editedElement ? "btn-warning" : "btn-primary"} onClick={() => handleDoCopy(true)}>
						<FontAwesomeIcon icon="save" /> {t('modal.replace')}
					</Button>
					<Button disabled={selectedRT === -1} className={editedElement ? "btn-warning" : "btn-primary"} onClick={() => handleDoCopy(false)}>
						<FontAwesomeIcon icon="save" /> {t('modal.add')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}


const OperationSummary = ({ element  }) => {
	const { operation_dict } = useContext(GlobalDataContext)

	const OperationRow = ({ op, k }) => {
		if (operation_dict[op.id]) {
			return (
				<Row><Col><Badge variant="secondary">{k + 1}</Badge> {operation_dict[op.id].name}</Col></Row>
			)
		} else {
			console.log("ERROR", op, operation_dict[op.id])
			return (
				<Row><Col><Badge variant="dark">{k + 1}</Badge>
					<OverlayTrigger
						placement="auto"
						delay={{ show: 250, hide: 150 }}
						overlay=
						{<Tooltip id="button-tooltip">operazione non trovata</Tooltip>}
					><Badge variant="warning" size="md" className="mr-1">
							<FontAwesomeIcon icon="exclamation-triangle" />
						</Badge>
					</OverlayTrigger>
					{op.name}</Col></Row>
			)
		}
	}
	if (element.operation_list && element.operation_list.length > 0) {
		try {

			return (
				<>
					{element.operation_list.map((i, k) => (
						<OperationRow key={k} k={k} op={i} />
					))}
				</>
			)
		} catch (err) {
			return (
				<>
					{element.operation_list.map((i, k) => (
						<Row key={k}><Col><Badge variant="secondary">{k + 1}</Badge> {i.id}</Col></Row>
					))}
				</>
			)

		}
	} else {
		return (

			<></>
		)
	}

}





export default translate()(ModalCopyActivities)