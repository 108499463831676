import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Container, Row, Col, Button, Spinner, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import SearchSection from "../components/SearchSection"
import ModalEquipment from "../components/ModalEquipment"
import ModalImportCatalog from '../components/ModalImportCatalog';

import { checkComponentPresence, getElementPreview, rgba2hex, getBaseObjectValue, getElementCount, compareName } from "../util/ui_utils.js"
import { RenderItemFileBadge } from '../components/StructureGraphicElements.js'



import {
	getProductList as getProductList2,
	deleteProduct as deleteProduct2
} from '../Firebase2/Storage2/dbWarehouse';
import { getCatalogProductList, deleteCatalogProduct } from '../Firebase2/Storage2/dbCatalogWarehouse';
import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"




const companyId = '0'
const section = 'equipment'

const EquipmentsView = ({ t, uData }) => {
	const { domain, domain_data } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	let { cid } = useParams();
	let contractId = cid

	const [ui_list, setUiList] = useState({})
	const [element_list, setElementList] = useState([])
	const [loading, setLoading] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)

	const initValues = async () => {
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('equipments') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		const material_list = await getBaseObjectTypeList2(domain, 'equipment_material')
		const category_list = await getBaseObjectTypeList2(domain, 'equipment_category')
		setUiList({ ...ui_list, brand_list, material_list, category_list })
		let newelement_list = []
		if (domain === 'catalog') {
			newelement_list = await getCatalogProductList(contractId, section, null)
		} else {
			newelement_list = await getProductList2(domain, contractId, companyId, section, null)
		}
		newelement_list.sort(compareName)
		setElementList(newelement_list)
		setLoading(false)
	}


	useEffect(() => {
		initValues()
	}, [])





	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data
			if (domain === 'catalog') {
				return_data = await deleteCatalogProduct(contractId, section, el.id)
			} else {
				return_data = await deleteProduct2(domain, contractId, companyId, section, el.id)

			}
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('product.messages.delete_equipment_title'), t('product.messages.delete_equipment', { name: el.name }), cb)
	}




	return (
		<>

			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('navs.registries.equipments')} />
					</Col>
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col className="text-right">
						{canWrite ?
							<>
								<ModalImportCatalog t={t} section={section} ui_list={ui_list} item_list={element_list} setItem={setElementList} />
								<ModalEquipment is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} canWrite={canWrite} ui_list={ui_list} setUiList={setUiList} />
							</>
							: ''}
					</Col>
				</Row>
				{!loading && element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="1"></Col>
						<Col className="bg-secondary ml-1" sm="2">{t('equipments.labels.code')}</Col>
						<Col className="bg-secondary ml-1">{t('equipments.labels.description')}</Col>
						<Col className="bg-secondary ml-1">{t('equipments.labels.type')}</Col>
						<Col className="bg-secondary ml-1">{t('equipments.labels.brand')}</Col>
						<Col className="bg-secondary ml-1 p-0 text-center" sm="1"><FontAwesomeIcon icon='image' />&nbsp;<FontAwesomeIcon icon='file' /></Col>
						<Col className="bg-secondary ml-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					: <>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
									<Row className="oneCompany p-1 border-bottom" key={key}>
										<Col sm="1">{getElementPreview(i)} </Col>
										<Col sm="2">{i.code}</Col>
										<Col>
											{i.catalog_id !== undefined&&
												<OverlayTrigger
													placement="right"
													delay={{ show: 250, hide: 400 }}
													overlay={renderTooltip}
												>
													<FontAwesomeIcon icon="clipboard-list" className='mr-1'/>
												</OverlayTrigger>
												
											}
											<Badge style={{
												background: rgba2hex(i.color)
											}}>&nbsp;</Badge>
											&nbsp;<b>{i.name}</b>
										</Col>
										<Col>{getBaseObjectValue(i.type, 'name', locale)}</Col>
										<Col>{getBaseObjectValue(i.brand, 'name', locale)}</Col>
										<Col sm="1" className="p0 text-center">
											<RenderItemFileBadge media_list={i.media_list} section={section} position="left-start" />
										</Col>
										<Col className="text-center" sm="1" >
											<ButtonGroup>
												<ModalEquipment is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite && i.catalog_id=== undefined} ui_list={ui_list} setUiList={setUiList} />
												{canWrite ?
													<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
													: ''}
											</ButtonGroup>
										</Col>

									</Row>
								))}
							</>
							: <Row>
								<Col className="font-italic">
									{t('equipments.messages.no_equipment')}
								</Col>
							</Row>
						}
					</>
				}
			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('navs.registries.equipments') })} />

		</>
	)
}



const renderTooltip = (props) => (
	<Tooltip id="button-tooltip" {...props}>
		Prodotto da catalogo
	</Tooltip>
);



export default translate()(EquipmentsView)
