import { isSameDay, setMinutes, setHours } from 'date-fns'
import React, { createContext, useState, useContext } from 'react'
import { firebaseAuth } from '../../provider/AuthProvider'
//import { checkComponentPresence } from '../../util/ui_utils'

const GlobalDataContext = createContext()

export const GlobalDataProvider = (props) => {

	const { userData } = useContext(firebaseAuth)
    
    const [structureElements, setStructureElements] = useState({structure_dict: {}, all_pavillon_dict:{}, all_floor_dict:{}, all_department_dict:{}, all_room_dict:{}, structure_list:[]})
    const [contractId, setContractId ] =useState()
    const [contract, setContract] =useState()
    const [risk_area_list, setRiskarea_list] = useState([])
    const [risk_area_dict, setRiskarea_dict] = useState({})
    const [roomtype_list, setRoomtype_list] = useState([])
    const [roomtype_dict, setRoomtype_dict] = useState({})
    const [service_dict, setService_dict] = useState({})
    const [operation_dict, setOperation_dict] = useState({})
    const [messagegroup_list, setMessagegroup_list] = useState([])
    const [sendmessagegroup_list, setSendMessagegroup_list] = useState([])
    const [now, setNow]= useState(new Date())
    const [workarea_list, setWorkarea_list] = useState([])
    const [all_workarea_dict, setWorkarea_dict] = useState([])
//    const [good_list, setGoodList] = useState([])
//    const [economic_material_list, setEconomicMaterial_list] = useState([])
    

    const updateContract = async (new_contract, rarea_list, rarea_dict, rt_list, rt_dict, sv_dict, op_dict, msg_g_list, wa_list, wa_dict) => {
        setRiskarea_dict(rarea_dict)
        setRiskarea_list(rarea_list)
        setRoomtype_dict(rt_dict)
        setRoomtype_list(rt_list)
        setService_dict(sv_dict)
        setOperation_dict(op_dict)
        setMessagegroup_list(msg_g_list)
        
        setWorkarea_list(wa_list)
        setWorkarea_dict(wa_dict)
        setContract(new_contract)
        setContractId(new_contract.id)
        console.log("-------------  CONTEXT USERDATA------------",wa_dict,  userData)
		if (userData && userData.sendmessagegroups && userData.sendmessagegroups.filter(g => g.contract_id === new_contract.id).length>0){
            const g_list =  userData.sendmessagegroups.filter(g => g.contract_id === new_contract.id).map(g => g.message_group_id)
            console.log("------------------ FILTER -----------------------", g_list)
            setSendMessagegroup_list(msg_g_list.filter(g => g_list.indexOf(g.id)>=0))
        }else{
            setSendMessagegroup_list(msg_g_list)
        }

        console.log("update contract", new_contract, contract)
    }

    const resetContract = () => {
        console.log("reset_contract")
        setContract()
        setContractId()

    }


    const updatePavillon = (el_id, d) => {
        setStructureElements({...structureElements, all_pavillon_dict: {...structureElements.all_pavillon_dict, [el_id]: d}})
    }

    const updateFloor = (el_id, d) => {
        setStructureElements({...structureElements, all_floor_dict: {...structureElements.all_floor_dict, [el_id]: d}})
    }
    const updateDepartment = (el_id, d) => {
        setStructureElements({...structureElements, all_department_dict: {...structureElements.all_department_dict, [el_id]: d}})
    }

    const updateRoom = (el_id, d) => {
        setStructureElements({...structureElements, all_room_dict: {...structureElements.all_room_dict, [el_id]: d}})
    }

    const updateRoomtype = (newelement_list) =>{
		let newelementdict = Object.assign({}, ...newelement_list.map((x) => ({ [x.id]: x })));
        setRoomtype_dict(newelementdict)
        setRoomtype_list(newelement_list)
		sessionStorage.setItem('roomtype_list', JSON.stringify(newelement_list))
		sessionStorage.setItem('roomtype_dict', JSON.stringify(newelementdict))

    }

    const updateRiskarea = () =>{
        setRiskarea_list()
        setRiskarea_dict()

    }

    const updateGood = ({section, good}) =>{

    }

    const updateNow = (ct) => {
        console.log("UPDATE NOW")
        if (isSameDay(ct, new Date())){
            console.log("SAME DAY")
            setNow(new Date())
        }else{
            console.log("NOT SAME DAY", ct, new Date())
            let d = setMinutes(ct, 59)
            d = setHours(d, 23)
            setNow(d)
        }

    }

    return (
        <GlobalDataContext.Provider
            value={{
                updateContract,
                resetContract,
                structureElements, 
                setStructureElements,
                contractId, 
                contract, 
                roomtype_dict,
                roomtype_list,
                risk_area_dict,
                risk_area_list,
                service_dict, 
                operation_dict,
                updateRoomtype,
                updateRiskarea, 
                setOperation_dict,
                setService_dict,
                updateGood,
                now,
                setNow,
                updateNow, 


                updatePavillon,
                updateFloor,
                updateDepartment,
                updateRoom,

                sendmessagegroup_list,
                messagegroup_list,
                all_workarea_dict,
                workarea_list

            }}>

            {props.children}
        </GlobalDataContext.Provider>
    )
}
export default GlobalDataContext