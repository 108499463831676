import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Badge, Spinner } from 'react-bootstrap';
import SortButton from '../components/SortButton.js'
import { translate } from 'react-polyglot';

import GlobalDataContext from '../components/contexts/globaldataContext.js';
import DomainContext from '../components/contexts/domainContext'

import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"


import LocaleContext from "../components/contexts/locale.js"
import { compareOrder } from "../util/ui_utils.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import { getObjectType_list } from "../components/constants/baseobjecttypes"
import ModalBaseObject from "../components/ModalBaseObject"


import { getBaseObjectTypeList as getBaseObjectTypeList2, delBaseObjectType as delBaseObjectType2 } from "../Firebase2/Storage2/dbBaseObject"

const selclass = 'secondary';
const unselclass = 'outline-secondary';
const definition_list = {
	workarea: [
		{ name: { it: 'giro 1 pronto soccorso', en: '' }, is_enabled: true, color: { r: 200, g: 10, b: 90, a: 1 } },
		{ name: { it: 'giro 1 seminterrato', en: '' }, is_enabled: true, color: { r: 255, g: 10, b: 90, a: 1 } },
		{ name: { it: 'giro 2 seminterrato', en: '' }, is_enabled: true, color: { r: 10, g: 255, b: 90, a: 1 } },
	],
	pavilions: [
		{ name: { it: 'ceramica', en: '' }, is_enabled: true, color: { r: 10, g: 10, b: 90, a: 1 } },
		{ name: { it: 'pvc', en: '' }, is_enabled: true, color: { r: 10, g: 10, b: 90, a: 1 } }
	]
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementList({ functions, cursection, t }) {

	let order_list = cursection.list.sort(compareOrder)

	return (
		<>
			{order_list.map((i, key) => (
				<Row className="oneElement p-1 border-bottom" key={key}>
					<Col sm="1"><Badge variant="secondary">{i.order}</Badge></Col>
					<Col>{i.name? i.name.it: '---'} </Col>
					<Col>{i.group && i.group.length>0?i.group:''}</Col>
					<Col sm="1">
						{i.color &&
						<Badge style={{
							background:
								`rgba(${i.color.r}, ${i.color.g}, ${i.color.b}, ${i.color.a})`
						}}>&nbsp; &nbsp;&nbsp;</Badge>
						}
					</Col>
{/* 					<Col sm="1" className="text-center">{i.is_enabled ? <FontAwesomeIcon icon='eye' /> : <FontAwesomeIcon icon='eye-slash' />}</Col> */}
					<Col sm="2" className="text-center">
						<ModalBaseObject is_new={false} element={i} cursection={cursection} t={t} functions={functions} />
						<Button variant="danger" size="sm" onClick={() => functions.delete(i)}><FontAwesomeIcon icon='trash' /> </Button>
					</Col>


				</Row>
			))}
		</>
	)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const BaseObjectView = ({ t, uData }) => {
	const { domain, domain_data } = useContext(DomainContext)

	const { locale } = useContext(LocaleContext)
	const childRefDelete = useRef()
	const [cursection, setCurSection] = useState({ name: 'risk_areas', list: definition_list.risk_areas })
	const [loading, setLoading] = useState(false)
	const [sort, setSort] = useState({ col: 'Id', cresc: true })
	const handleCurSection = async (s) => {
		console.log(s)
		setLoading(true)
		let element_list =  await getBaseObjectTypeList2(domain, s)
		console.log(element_list)
		setCurSection({ name: s, list: element_list })
	}
	useEffect(() => {
		setLoading(false)
	}, [cursection])
	const objectType_list = getObjectType_list({ t: t })

	const handleShowDelete = (el) => {
		const cb = async () => {
			 await delBaseObjectType2(domain, cursection.name, el.Id)
		}
		childRefDelete.current.confirmDelete(t('objecttype.messages.delete_title') + ": " + el.name[locale], t('objecttype.messages.delete_message') + "</br><b>" + el.name[locale] + "</b>(" + cursection.name + ") ?", cb)
	}

	useEffect(() => {
		handleCurSection(cursection.name)
	}, [])
	return (
		<>

			{/* <SubNavBarSettings curpage={'definitions'} t={t} /> */}

			<DeleteConfirmationSection ref={childRefDelete} />
			<Container className="titleStickyTop" fluid>
				<Row>
					<Col>
						<PageTitle title={t('navs.settings.base_objects')} />
					</Col>
					<Col className="text-right">
						<ModalBaseObject is_new={true} cursection={cursection} t={t} functions={{ setCurSection: setCurSection }} />
					</Col>
				</Row>

			</Container>
			<Container fluid>
				<Row>
					<Col sm="2">
						<Row>
							<Col>
								{Object.keys(objectType_list).map((i, k) => (
									<Button key={k} block variant={cursection.name === objectType_list[i].name ? selclass : unselclass} onClick={() => handleCurSection(i)}>{objectType_list[i].label_multi}</Button>
								))}
							</Col>
						</Row>
					</Col>
					<Col>
						{loading ? <Spinner animation="border" role="status">
							<span className="sr-only">Loading...</span>
						</Spinner> 
						: <>
						{cursection.list && cursection.list.length > 0 ?
							<>
								<Row className="p-1 text-light">
									<Col sm="1" className="bg-secondary ml-1 text-center"></Col>
									<Col className="bg-secondary ml-1"><SortButton col="name" sort={sort} callback={setSort} />{t('objecttype.labels.name')}</Col>
									<Col sm="1" className="bg-secondary ml-1 text-center">{t('objecttype.labels.is_enabled')}</Col>
									<Col sm="2" className="bg-secondary ml-1">{t('global.labels.actions')}</Col>
								</Row>
								<ElementList cursection={cursection} functions={{ setCurSection: setCurSection, delete: handleShowDelete }} t={t} />
							</>
							:
							<Row>
								<Col className="fontt-italic">
									{t('global.messages.no_element')} (<span className="text-lowercase">{objectType_list[cursection.name].label_one}</span>)
									</Col>
							</Row>
						}</>
					}
					</Col>
				</Row>
			</Container>
            <Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t , element_list:cursection.list, name:objectType_list[cursection.name].label_multi })} />
		</>
	);
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
const getElementCount = ({element_list, name,  t }) => {
    console.log("COUNT TOTAL DOC", element_list)
	if (element_list){
		return (<>{element_list.length} {name}</>)
	}else{
    	return (<>{0} {name}</>)

	}
}



export default translate()(BaseObjectView)