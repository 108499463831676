import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, Button, ButtonGroup, InputGroup, FormControl, Tooltip, OverlayTrigger, Spinner, Card, Badge } from 'react-bootstrap';

import { goods_labels, materialIntendedUse_dict } from "../components/constants/global_definitions"

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'

import styles from './constants/styles.js'

import { getSelectedElement, checkComponentPresence, getBaseObjectValue, getFloatNumber } from "../util/ui_utils.js"


import { editIntervention as editIntervention2, } from '../Firebase2/Storage2/dbIntervention'
import { AddListButton } from './StructureGraphicElements';
import { OneGood, ElementIcon, OneCartElement, OneSummaryElement } from './InterventionCartComponents';

const contractId = sessionStorage.getItem('contractId')
//const contract = JSON.parse(sessionStorage.getItem('contract'))

function getTotalPatientUnit(rt) {
	if (rt.has_patient_unit && rt.patient_unit && rt.patient_unit > 0) {
		console.log("PU", rt.patient_unit)
		return parseInt(rt.patient_unit)
	} else {
		return 0
	}
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function ModalInterventionCart({ element, workarea, t, ui_list, canWrite, updateWorkarea, functions }) {

	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { service_dict, contract, roomtype_dict } = useContext(GlobalDataContext)
	const [showEditInterventionCart, setShowEditInterventionCart] = useState(false)
	const [currentEditIntervention, setCurrentEditIntervention] = useState(element)
	const [editedElement, setEditedElement] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [economic_material_summary, setEconomicMaterialList] = useState({})
	const [suggested_good_id_list, setSuggestedGoodIdList] = useState([])
	const [summary_count, setSummaryCount] = useState({ pu: 0, trash: 0, count_toilet: 0, size_corridor: 0, size_toilet: 0, size_other: 0 })
	const [showComponentEdit, setShowComponentEdit] = useState(false)

	//	let dimensions = { corridor: 0, toilet: 0, other: 0 }
	//	const yeld_mq = contract.yeld_mq || 18
	const size = element.size || 0


	const section_list = [
				{ category_type: 'cart', max_count: 1, title: t('global.labels.cart_list'), avaiable: t('workarea.labels.available_cart_list'), empty: t('workarea.labels.empty_cart_list') },
				{ category_type: 'machine', title: t('global.labels.machine_list'), avaiable: t('workarea.labels.available_machine_list'), empty: t('workarea.labels.empty_machine_list') },
				{ category_type: 'product', title: t('global.labels.product_list'), avaiable: t('workarea.labels.available_product_list'), empty: t('workarea.labels.empty_product_list') },
				{ category_type: 'equipment', title: t('global.labels.equipment_list'), avaiable: t('workarea.labels.available_equipment_list'), empty: t('workarea.labels.empty_equipment_list') },
		{ category_type: 'cleaningmaterial', title: t('global.labels.cleaningmaterial_list_furniture'), subtype: 'dusting_furniture', avaiable: t('workarea.labels.available_cleaningmaterial_list_furniture'), empty: t('workarea.labels.empty_cleaningmaterial_list') },
				{ category_type: 'cleaningmaterial', title: t('global.labels.cleaningmaterial_list_trash'), subtype: 'dusting_trash', avaiable: t('workarea.labels.available_cleaningmaterial_list_trash'), empty: t('workarea.labels.empty_cleaningmaterial_list') },
				{ category_type: 'cleaningmaterial', title: t('global.labels.cleaningmaterial_list_toilet'), subtype: 'dusting_toilet', avaiable: t('workarea.labels.available_cleaningmaterial_list_toilet'), empty: t('workarea.labels.empty_cleaningmaterial_list') },
				{ category_type: 'cleaningmaterial', title: t('global.labels.cleaningmaterial_list_floor'), subtype: 'washing_floors', avaiable: t('workarea.labels.available_cleaningmaterial_list_floor'), empty: t('workarea.labels.empty_cleaningmaterial_list') },
				{ category_type: 'cleaningmaterial', title: t('global.labels.cleaningmaterial_list_toiletfloor'), subtype: 'washing_toilet_floors', avaiable: t('workarea.labels.available_cleaningmaterial_list_toiletfloor'), empty: t('workarea.labels.empty_cleaningmaterial_list') },
				{ category_type: 'cleaningmaterial', title: t('global.labels.cleaningmaterial_list_umidfloor'), subtype: 'washing_umid_floors', avaiable: t('workarea.labels.available_cleaningmaterial_list_umidfloor'), empty: t('workarea.labels.empty_cleaningmaterial_list') },
				{ category_type: 'cleaningmaterial', title: t('global.labels.cleaningmaterial_list_mechanized'), subtype: 'mechanized_washing', avaiable: t('workarea.labels.available_cleaningmaterial_list_mechanized'), empty: t('workarea.labels.empty_cleaningmaterial_list') },
				{ category_type: 'cleaningmaterial', title: t('global.labels.cleaningmaterial_list_economic'), subtype: 'economic_material', avaiable: t('workarea.labels.available_cleaningmaterial_list_economic'), empty: t('workarea.labels.empty_cleaningmaterial_list') },
				{ category_type: 'dpi', title: t('global.labels.dpi_list'), avaiable: t('workarea.labels.available_dpi_list'), empty: t('workarea.labels.empty_dpi_list') },
	]


	const handleCloseCart = () => setShowEditInterventionCart(false);

	const handleShowEditInterventionCart = () => {
		//		setCurWorkarea(workarea)
		console.log("EDIT CART", element)
		setCurrentEditIntervention(element)
		setShowEditInterventionCart(true)
		setEditedElement(false)
		let tmp_economic_material_summary = {}
		let tmp_suggested_good_id_list = []
		let tmp_summary_count = { pu: 0, trash: 0, count_toilet: 0, size_corridor: 0, size_toilet: 0, size_other: 0 }
		if (element && element.room_list) {

			element.room_list.map((room) => {
				//console.log(r.size)
				const roomtype = roomtype_dict[room.roomtype.id] ? roomtype_dict[room.roomtype.id] : roomtype_dict[room.roomtype.id.id]
				console.log("RT", room.roomtype.id, roomtype)
				if (roomtype) {
					if (roomtype.has_toilet) {
						tmp_summary_count.size_toilet += getFloatNumber(room.size)
						tmp_summary_count.count_toilet += 1
					} else if (roomtype.can_mechanized_washing) {
						tmp_summary_count.size_corridor += getFloatNumber(room.size)
					} else {
						tmp_summary_count.size_other += getFloatNumber(room.size)
					}
					tmp_summary_count.pu += getTotalPatientUnit(roomtype)
					roomtype.furniture_list.forEach(r => {
						if (r.is_trash) {
							tmp_summary_count.trash += r.quantity
						}
					})
					roomtype.environment_element_list.forEach(r => {
						if (r.is_trash) {
							tmp_summary_count.trash += r.quantity
						}
					})
					console.log("MAT", roomtype.economic_material_list)
					roomtype.economic_material_list.forEach(ec => {
						if (ec.is_trash) {
							tmp_summary_count.trash += ec.quantity
						}
						if (tmp_economic_material_summary[ec.id]) {
							tmp_economic_material_summary[ec.id].quantity += parseInt(ec.quantity)
						} else {
							const em_ref_id = checkComponentPresence(ec, ui_list.economic_material_list, 'id')
							if (em_ref_id >= 0) {
								tmp_economic_material_summary[ec.id] = { ...ui_list.economic_material_list[em_ref_id], quantity: parseInt(ec.quantity) }
							}
							console.log(ec.refill_element_list)
							if (element.enable_refill && ec.refill_element_list && ec.refill_element_list.length > 0) {
								tmp_suggested_good_id_list = [...tmp_suggested_good_id_list, ...ec.refill_element_list.map(l => l.id)]
							}
						}
					})

					roomtype.service_list.filter(s => (s.service.id === element.service.id ||
						(element.secondary_service_list && element.secondary_service_list.length > 0 && element.secondary_custom_weekdays[room.id] && element.secondary_custom_weekdays[room.id][s.service.id] && element.secondary_custom_weekdays[room.id][s.service.id].length > 0)
					)).map(s => {
						if (s.operation_list) {
							s.operation_list.map(o => {
								const one_o = ui_list.operation_dict[o.id]
								//console.log("OPERATION", one_o)
								if (one_o.dpi_list) {
									try {
										tmp_suggested_good_id_list = [...tmp_suggested_good_id_list, ...one_o.dpi_list.filter(d => (d.risk_area_presence.indexOf(roomtype.risk_area.Id !== -1))).map(l => l.id)]
									} catch (err) {
										console.log('ERRR', err)

									}
								}
								if (one_o.element_list) {
									tmp_suggested_good_id_list = [...tmp_suggested_good_id_list, ...one_o.element_list.map(l => l.id)]
								}
							})
							//FIXME segnala mancanza di operazioni
						}
					})
					tmp_suggested_good_id_list = tmp_suggested_good_id_list.filter((v, i, a) => a.indexOf(v) === i)
					//				console.log("RoomTyp", rt)
				}
				//console.log(dimensions)
			})
		}
		setEconomicMaterialList(tmp_economic_material_summary)
		setSummaryCount(tmp_summary_count)
		setSuggestedGoodIdList(tmp_suggested_good_id_list)
	}





	const handleSaveInterventionCart = async (index) => {
		setIsSaving(true)
		const return_data = await editIntervention2(domain, contractId, currentEditIntervention.id, currentEditIntervention)

		setIsSaving(false)
		functions.edit(currentEditIntervention)
		setShowEditInterventionCart(false);
	}







	const handleCloseEditComponent = () => setShowComponentEdit(false);

	const handleShowEditComponent = () => {
		setShowComponentEdit(true)
	}

	const handleDeleteElement = (el) => {
		console.log(el)
		let editlist = [...currentEditIntervention.cart_element_list]
		const c = checkComponentPresence(el, editlist, 'id')
		editlist.splice(c, 1)
		setCurrentEditIntervention({ ...currentEditIntervention, cart_element_list: editlist })
		setEditedElement(true)
		//		onChangeHandlerIntervention({ name: 'cart_element_list', value: editlist })
	}



	const getIdealQuantity = (el) => {
		let ideal_quantity = 0
		if (el.category_type === 'cleaningmaterial') {
			const yeld = el.yeld || 18
			console.log("----CLEANING MATERIAL", el.intended_use)
			if (el.intended_use === 'dusting_furniture' || el.intended_use === 'washing_floors' || el.intended_use === 'washing_umid_floors') {
				ideal_quantity = Math.ceil(summary_count.size_other / yeld) + summary_count.pu
				//mq other/18 + total_patient_unit
			} else if (el.intended_use === 'waste_bag') {
				ideal_quantity = summary_count.trash
			} else if (el.intended_use === 'dusting_trash') {
				ideal_quantity = summary_count.trash
				//total trash
			} else if (el.intended_use === 'dusting_toilet') {
				ideal_quantity = summary_count.count_toilet
				//count room
			} else if (el.intended_use === 'washing_toilet_floors') {
				ideal_quantity = summary_count.count_toilet
				//count room toilet
			} else {
				ideal_quantity = 1
			}
		}
		return ideal_quantity

	}

	const handleAddElement = (el) => {
		console.log(el)
		let _editlist = []
		if (currentEditIntervention.cart_element_list) {
			_editlist = [...currentEditIntervention.cart_element_list]
		}
		const c = checkComponentPresence(el, _editlist, 'id')
		if (c >= 0) {
			_editlist[c].quantity += 1;
		} else {
			const ideal_quantity = getIdealQuantity(el)
			let quantity = 1

			if (ideal_quantity > 0) {
				quantity = ideal_quantity
			}
			_editlist.push({ ...el, quantity: quantity, yeld: 0, suggested_quantity: ideal_quantity })
		}
		//onChangeHandlerIntervention({ name: 'cart_element_list', value: editlist })
		setCurrentEditIntervention({ ...currentEditIntervention, cart_element_list: _editlist })
		setEditedElement(true)
	}

	const handleSubtractEl = (el, list) => {
		console.log(el)
		//let editlist = element[list]
		let editlist = [...currentEditIntervention.cart_element_list]
		//		const c = checkComponentPresence(el, element[list], 'id')
		const c = checkComponentPresence(el, editlist, 'id')
		if (editlist[c].quantity > 1) {
			editlist[c].quantity -= 1;
		}
		//functions.edit({ name: list, value: editlist })
		//onChangeHandlerIntervention({ name: 'cart_element_list', value: editlist })
		setCurrentEditIntervention({ ...currentEditIntervention, cart_element_list: editlist })
		setEditedElement(true)
	}

	const handleResetAndSetIdealElement = () => {
		let editlist = []
		if (currentEditIntervention.cart_element_list) {
			editlist = [...currentEditIntervention.cart_element_list]
		}
		//functions.edit({ name: 'cart_element_list', value: editlist })
		suggested_good_id_list.map(g => {
			const el = getSelectedElement(g, ui_list.good_list, 'id')
			const c = checkComponentPresence(el, editlist, 'id')
			if (c === -1) {
				const ideal_quantity = getIdealQuantity(el)
				let quantity = 1
				if (ideal_quantity > 0) {
					quantity = ideal_quantity
				}
				editlist.push({ ...el, quantity: quantity, yeld: 0, suggested_quantity: ideal_quantity })
			}
		})
		//onChangeHandlerIntervention({ name: 'cart_element_list', value: editlist })
		setCurrentEditIntervention({ ...currentEditIntervention, cart_element_list: editlist })
		setEditedElement(true)

	}






	const changeQcart = () => {
		//need to avoid warning

	}








	const OneSection = ({ section }) => {
		if (showComponentEdit || (currentEditIntervention.cart_element_list && currentEditIntervention.cart_element_list.filter(elem => (

			(section.category_type === 'cleaningmaterial' && elem.intended_use === section.subtype)
			|| (section.category_type !== 'cleaningmaterial' && elem.category_type === section.category_type)
		)

		).length > 0)) {

			return (
				<Card className="mb-3">
					<Card.Header>

						<Container fluid>
							<Row className="h5">

								<Col className="text-primary" sm="7">
									{section.category_type}
									<ElementIcon el={section} t={t} />
									{section.title}
								</Col>
								{showComponentEdit ?
									<Col className="text-info">{section.avaiable}</Col>
									: ''}
							</Row>
						</Container>
					</Card.Header>
					<Card.Body>
						<Container fluid>

							<Row >

								{currentEditIntervention.cart_element_list && currentEditIntervention.cart_element_list.filter(elem => (

									(section.category_type === 'cleaningmaterial' && elem.intended_use === section.subtype)
									|| (section.category_type !== 'cleaningmaterial' && elem.category_type === section.category_type))

								).length > 0 ?
									<Col>
										<Row className="mb-1" >
											<Col className="bg-secondary text-light ml-1">{t('global.labels.name')} </Col>
											<Col className="bg-secondary text-light ml-1" sm="5" md="4" xl="2">
												{section.category_type === 'cart' || section.category_type === 'machine' || section.category_type === 'product' ?
													<>
														Azioni
													</>
													:
													<>
														Quantità
													</>
												}
											</Col>
										</Row>
										{currentEditIntervention.cart_element_list.filter(elem => {

											if (section.category_type === 'cleaningmaterial') {
												console.log("USE", elem.intended_use, section.subtype)
												if (elem.intended_use === section.subtype) {
													return true
												}
											} else {
												if (elem.category_type === section.category_type) {
													return true
												}
											}
											return false
										}
										).map((i, key) => (
											<OneCartElement key={key} i={i} t={t}
												canWrite={canWrite} suggested_good_id_list={suggested_good_id_list} changeQcart={changeQcart}
												handleAddElement={handleAddElement} handleSubtractEl={handleSubtractEl} handleDeleteElement={handleDeleteElement}
											/>
										))
										}
									</Col>
									: <Col> {section.empty} </Col>
								}
								{showComponentEdit
									?
									<>
										<Col className=" editSection">
											{ui_list.good_list && ui_list.good_list.filter(elem => (
												(section.category_type === 'cleaningmaterial' && elem.intended_use === section.subtype)
												|| (section.category_type !== 'cleaningmaterial' && elem.category_type === section.category_type))

											).length > 0 ?
												<>
													<Row className="mb-1" >
														<Col className="bg-secondary text-light ml-1 p-0 text-center" sm="1">{t('global.labels.add')}</Col>
														<Col className="bg-secondary text-light ml-1">{t('structure.labels.component_name')}</Col>
													</Row>
													{ui_list.good_list.filter(elem => (
														(section.category_type === 'cleaningmaterial' && elem.intended_use === section.subtype)
														|| (section.category_type !== 'cleaningmaterial' && elem.category_type === section.category_type))
													).map((i, key) => (
														<OneGood t={t} key={key} i={i} increment={section.category_type !== 'cart' && section.category_type !== 'machine'} cart_element_list={currentEditIntervention.cart_element_list} handleAddElement={handleAddElement} suggested_good_id_list={suggested_good_id_list} />
													))
													}
												</>
												: <> {section.empty}</>
											}
										</Col>
									</>
									: ''
								}
							</Row>
						</Container>

					</Card.Body>
				</Card>
			)
		} else {
			return (<></>)
		}
	}



	return (
		<>
			<Button variant="outline-info" size="sm" onClick={() => handleShowEditInterventionCart()} ><FontAwesomeIcon icon="puzzle-piece" />{t('workarea.labels.equipment')}</Button>


			<Modal show={showEditInterventionCart} onHide={handleCloseCart} dialogClassName="custom-modal" backdrop="static" className="bg-secondary" scrollable={true}>
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ''}>
					<Modal.Title>

						{element.name} | <span className="text-info"><FontAwesomeIcon icon="puzzle-piece" /> &nbsp;{t('workarea.labels.equipment')} </span>
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}

					</Modal.Title>
				</Modal.Header>
				<Modal.Header className="bg-light">
					<Container fluid>
						<Row className="border-bottom mb-1 bg-light">
							<Col sm="3" className="h5 text-info">
								<FontAwesomeIcon icon="info-circle" className="text-info" />
								&nbsp;
								{t('workarea.labels.summary_size')}
							</Col>
							<Col sm="5">
								<Row >
									<Col className="bg-secondary text-light ml-1">{t('workarea.labels.corridor_etc')} </Col>
									<Col className="bg-secondary text-light ml-1">{t('workaera.labels.toilet')} </Col>
									<Col className="bg-secondary text-light ml-1">{t('workarea.labels.other')}</Col>
									<Col className="bg-secondary text-light ml-1 font-weight-bold">{t('global.labels.total')}</Col>
								</Row>
								<Row>
									<Col>{summary_count.size_corridor.toFixed(2)} m<sup>2</sup></Col>
									<Col>{summary_count.size_toilet.toFixed(2)} m<sup>2</sup></Col>
									<Col>{summary_count.size_other.toFixed(2)} m<sup>2</sup></Col>
									<Col className="bg-light font-weight-bold">{size.toFixed(2)} m<sup>2</sup></Col>
								</Row>

							</Col>
							{/* 		<Col sm="2" className="h5 text-info">
								<FontAwesomeIcon icon="info-circle" className="text-info" />
					&nbsp;
					{t('workarea.labels.summary_quantity')}
							</Col>
							<Col sm="3">
								<Row >
									<Col className="bg-secondary text-light ml-1">{t('structure.labels.trashes')}</Col>
									<Col className="bg-secondary text-light ml-1">{t('workaera.labels.toilet')} </Col>
									{contract.type === 'hospital' ?
										<Col className="bg-secondary text-light ml-1">U.P.</Col>
										: ''}
								</Row>
								<Row>
									<Col>{summary_count.trash}</Col>
									<Col>{summary_count.count_toilet}</Col>
									{contract.type === 'hospital' ?
										<Col>{summary_count.pu}</Col>
										: ''}
								</Row>

							</Col> */}
						</Row>
						<Row className="border-bottom mb-1 bg-light">
							<Col sm="3" className="h5 text-info">
								<FontAwesomeIcon icon="info-circle" className="text-info" />
								&nbsp;
								Riepilogo dispenser materiale economale
								{element.enable_refill ? <>
									<Badge><FontAwesomeIcon icon="pump-soap" /> Intervento con rifornimento dispenser</Badge>
								</> : <></>}
							</Col>
							{Object.keys(economic_material_summary).length > 0 ?
								<Col sm="5">
									<Row className="mb-1" >
										<Col className="bg-secondary text-light ml-1">{t('global.labels.name')} </Col>
										<Col className="bg-secondary text-light ml-1">{t('global.labels.category')} </Col>
										<Col className="bg-secondary text-light ml-1 text-center" >{t('global.labels.quantity')}</Col>
									</Row>
									{Object.keys(economic_material_summary).map((i, key) => (
										<OneSummaryElement key={key} i={economic_material_summary[i]} />
									))
									}
								</Col>
								: <Col> {t('workarea.labels.empty_cleaningmaterial_list')} </Col>
							}
						</Row>


						<Row>
							<Col className="h5" >
								{t('workarea.labels.composition')}
							</Col>
							{canWrite ?
								<Col className="text-right" sm="4">
									<ButtonGroup>
										<Button variant="outline-secondary" size="sm" onClick={() => handleResetAndSetIdealElement()}><FontAwesomeIcon icon="plus-square" /> <FontAwesomeIcon icon="star" />{t('global.labels.component_ideal_add')}</Button>

										{showComponentEdit ?
											<Button variant="outline-secondary" size="sm" onClick={() => handleCloseEditComponent()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
											:
											<Button variant="outline-primary" size="sm" onClick={() => handleShowEditComponent()}><FontAwesomeIcon icon="plus-square" /> {t('roomtypes.labels.good_add')}</Button>
										}
									</ButtonGroup>
								</Col>
								: ''}
						</Row>


					</Container>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Col>
								{section_list.map((s, k) => (
									<OneSection key={k} section={s} />
								))}
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseCart}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button disabled={isSaving} className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveInterventionCart}>
							{isSaving ?
								<Spinner size="sm" animation="border" role="status" />
								: <FontAwesomeIcon icon="save" />
							}&nbsp;
							{t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default translate()(ModalInterventionCart)





