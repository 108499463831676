import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button, Card, Form, Spinner, ButtonGroup } from 'react-bootstrap';

import QRCode from 'qrcode.react'
import { useReactToPrint } from 'react-to-print';

import EditContext from "../contexts/edit.js"
import DomainContext from '../contexts/domainContext.js';
import LocaleContext from '../contexts/locale.js'
import GlobalDataContext from '../contexts/globaldataContext.js';


import { getBaseObjectValue } from "../../util/ui_utils.js"

import AlertSection from "../AlertSection.js"



////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalPrintQrcode = ({ item, structure, workarea, canWrite, t, item_list, setItem, year, month, uData }) => {
	const { contract, structureElements } = useContext(GlobalDataContext)

	const edit = useContext(EditContext)
	const { domain, domain_data } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)

	const componentRef = useRef();
	const footerRef = useRef();
	const childRefAlert = useRef()
	const contractId = contract.id

	const [showPlanEdit, setShowPrePrint] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curStructure, setCurStructure] = useState()
	const [event_list, setEventList] = useState([])
	const [full_event_list, setFullEventList] = useState([])
	const [viewRoomList, setViewRoomList] = useState([])
	const [viewRoomSummary, setViewRoomSummary] = useState([])
	const [printParams, setPrintParam] = useState([])
	const [showZ, setShowZ] = useState(false)
	const [curMonthValidate, setCurMonthValidate] = useState({})




	const handleShowPrePrint = async () => {
		setShowPrePrint(true)

	}



	const handleClosePrePrint = () => {
		setShowPrePrint(false)
	}


	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle
		/* 		content: () => {
					const tableStat = componentRef.current.cloneNode(true);
					const PrintElem = document.createElement('div');
					PrintElem.appendChild(tableStat);
					return PrintElem;
				},
				pageStyle: pageStyle,
				documentTitle: `qrcode`, */
	});


	const changeSelectedParam = (el_id, param, setParam) => {
		let _param = [...param]
		if (param.indexOf(el_id) >= 0) {
			const c = param.indexOf(el_id)
			_param.splice(c, 1)
		} else {
			_param.push(el_id)
		}
		console.log("UPDATE WA", _param)
		setParam(_param)
	}





	useEffect(() => {
		if (printParams.length === 0 || printParams.length === 2) {
			setEventList(full_event_list)
		} else {
			try {
				if (printParams.indexOf('ordinary') >= 0) {
					setEventList(full_event_list.filter(e => e.extendedProps.type === 'ordinary_intervention'))
				} else if (printParams.indexOf('periodic') >= 0) {
					setEventList(full_event_list.filter(e => e.extendedProps.type === 'periodic_intervention'))
				} else {
					setEventList(full_event_list.filter(e => e.extendedProps.type === 'requested_intervention'))
				}

			} catch (err) {
			}
		}

	}, [printParams])

	useEffect(() => {

	}, [])




	return (
		<>
			<Button variant={"outline-primary"} onClick={() => handleShowPrePrint()}>
				Stampa QR-Code Locali
			</Button>
			<Modal show={showPlanEdit} onHide={handleClosePrePrint} dialogClassName="custom-modal" bsclass="full-modal" backdrop="static" scrollable={true}>
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<AlertSection ref={childRefAlert} />
					<Container fluid>
						<Row className="border-bottom mb-1">
							<Col>
								<Modal.Title>
									Stampa qrCode
								</Modal.Title>
							</Col>
						</Row>
						<Row>
							<Col sm="1"><FontAwesomeIcon icon="cogs" /></Col>
							<Col>

								<Row>
									<Col sm="3">
										Parametri da stampare
									</Col>
									<Col>
										<ButtonGroup size="sm">
											<Button variant={printParams.indexOf('name') >= 0 ? 'secondary' : 'outline-secondary'} className="mr-1 mb-1" onClick={() => changeSelectedParam('name', printParams, setPrintParam)}>{t('structure.labels.code')}</Button>
											<Button disabled variant={printParams.indexOf('department') >= 0 ? 'secondary' : 'outline-secondary'} className="mr-1 mb-1" onClick={() => changeSelectedParam('department', printParams, setPrintParam)}>{t('structure.labels.department')}</Button>
											<Button disabled variant={printParams.indexOf('floor') >= 0 ? 'secondary' : 'outline-secondary'} className="mr-1 mb-1" onClick={() => changeSelectedParam('floor', printParams, setPrintParam)}>{t('structure.labels.floor')}</Button>
										</ButtonGroup>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</Modal.Header>
				<Modal.Body>
					<Container fluid ref={componentRef} className="preprint_page">
						<style type="text/css" media="print">{"\
  @page {\ size: portrait;\ }\
"}</style>
						<Row>
							{edit.editList.map((r, kr) => (
								kr % 50 === 0 ?
									<div className="page-break" />
									:
									<Col xs="3" className="qrlabel single_label" key={kr}>
										<Row>
											<Col xs="12" className="text-center">
												{printParams.indexOf('name') >= 0 ?
													r.name
													: <></>}
											</Col>
										</Row>
										<Row>
											<Col xs="12" className="text-center">
												<QRCode size="140" value={JSON.stringify({ id: r.id, cdc: r.cdc, num: r.name, tipologia: r.roomtype ? r.roomtype.name : '', dimensione: r.size, 'area di rischio': getBaseObjectValue(r.risk_area, 'name', locale), 'tipologia pavimentazione': getBaseObjectValue(r.floor, 'name', locale), parent_list: r.parent_list })} />
											</Col>
										</Row>
									</Col>

							))}
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Container fluid>
						<Row className="h4">
							<Col className="text-right">
								<Button variant="secondary" onClick={handleClosePrePrint} className="mr-1">
									{t('modal.close')}
								</Button>
								<Button variant="info" onClick={handlePrint} className="ml-1">
									<FontAwesomeIcon icon="print" />  {t('modal.print')}
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	)
}



const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  div.page-footer{
	display:none;
  }
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
	display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		page-break-after: always;
	  }
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;


const pageStyleOld = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
	.preprint_page {
	  width:210mm !important;
	  height: 297mm;
	  margin-top:2mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	.print_page {
	  width:210mm !important;
	  height: 297mm;
	  margin-top:2mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	  .single_label {
		width: 50mm;
		height:50mm;
	  }
  div.page-footer{
	display:none;
  }
  @media print {
	html, body, .preprint_page {
	  width:210mm !important;
	  height: 297mm;
	  margin-top:2mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
		display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		page-break-after: always;
	  }
	  .single_label {
		width: 50mm !important;
		height: 50mm !important;
	  }
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;


