import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button, Card, Form, Spinner, ButtonGroup } from 'react-bootstrap';

import writeXlsxFile from 'write-excel-file'
import { addMonths, endOfMonth, format, set, setDate, getDay } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import DateTime from 'react-datetime';

import EditContext from "../contexts/edit.js"
import DomainContext from '../contexts/domainContext.js';
import LocaleContext from '../contexts/locale.js'
import GlobalDataContext from '../contexts/globaldataContext.js';


import { getSelectedElementB, getObjectValue } from "../../util/ui_utils.js"

import AlertSection from "../AlertSection.js"
import { OneTicket } from '../../pages/TicketView.js';
import PrintFooter from '../generic/PrintFooter.js';
import { interventionType_dict } from '../constants/global_definitions.js';




////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalExportStructure = ({  t }) => {
	const { contract, structureElements, roomtype_dict } = useContext(GlobalDataContext)
	const { domain } = useContext(DomainContext)

	const structure_dict = structureElements.all_structure_dict
	const pavillon_dict = structureElements.all_pavillon_dict
	const floor_dict = structureElements.all_floor_dict
	const department_dict = structureElements.all_department_dict

	const edit = useContext(EditContext)

	const componentRef = useRef();
	const childRefAlert = useRef()

	const [showPrePrint, setShowPrePrint] = useState(false)
	const [printParams, setPrintParam] = useState([])
	const [date_range, setDateRange] = useState({ start: new Date, end: addMonths(new Date(), 1) })


	const intervention_type_dict = interventionType_dict()


	const handleShowPrePrint = async () => {
		setShowPrePrint(true)

	}



	const handleClosePrePrint = () => {
		setShowPrePrint(false)
	}



	const changeSelectedParam = (el_id, param, setParam) => {
		let _param = [...param]
		if (param.indexOf(el_id) >= 0) {
			const c = param.indexOf(el_id)
			_param.splice(c, 1)
		} else {
			_param.push(el_id)
		}
		console.log("UPDATE WA", _param)
		setParam(_param)
	}


	const updateDate = (el) => {
		console.log(el)
	}



	useEffect(() => {


	}, [printParams])

	useEffect(() => {
		setDateRange({
			start: setDate(new Date(), 1),
			end: endOfMonth(set(new Date(), { hours: 23, minutes: 59 }))
		})

	}, [])

	const HEADER_ROW = [
		{ value: "Numero locale" },
		{ value: "Tipologia di locale" },
		{ value: "struttura" },
		{ value: "padiglione" },
		{ value: "piano" },
		{ value: "reparto" },
		{ value: "Area di rischio" },
		{ value: "cdc" },
		{ value: "pareti lavabili" },
		{ value: "lavaggio meccanizzato" },
		{ value: "ceratura/deceratura/lucidatura" },
		{ value: "%vetri" },
		{ value: "dimensione" },
		{ value: "stato" },
		{ value: "note" }
	]



	const COLUMNS = [
		{ width: 40 },
		{ width: 40 },
		{ width: 40 },
		{ width: 40 },
		{ width: 40 },
		{ width: 10 },
		{},
		{ width: 10 },
		{ width: 10 },
		{ width: 10 },
		{ width: 10 },
		{ width: 10 },
		{},
		{},
		{}
	]

	// -----------------------------------------------
	const onHandleExport = async () => {
		let row_list = [HEADER_ROW]
		for (const _room of Object.values(structureElements.all_room_dict)) {
			const room_row = [
				{//0
					value: _room.name+''
				},
				{//1
					value: _room.roomtype.name || ''
				},
				{//2
					value: getObjectValue(structure_dict, _room.parent_list[0], 'name') || ''
				},
				{//3
					value: getObjectValue(pavillon_dict, _room.parent_list[1], 'name') || ''
				},
				{//4
					value: getObjectValue(floor_dict, _room.parent_list[2], 'name') || ''
				},
				{//5
					value: getObjectValue(department_dict, _room.parent_list[3], 'name') || ''
				},
				{//6
					value: getObjectValue(_room.risk_area, 'name', 'it') || ''
				},
				{//7
					value:_room.cdc?_room.cdc+'': ''
				},
				{//8
					type: Boolean,
					value: _room.washable_walls === true? true: false
				},
				{//9
					type: Boolean,
					value: _room.can_mechanized_washing === true? true: false
				},
				{//10
					type: Boolean,
					value: _room.need_waxing === true? true: false
				},
				{//11
					value: _room.glass_percentage+' '
				},
				{//12
					value: _room.size || 0
				},
				{//13
					value: _room.status[0]
				},
				{//14
					value: _room.note ||''
				}
			]
			row_list.push(room_row)


		}






		const fileXlsx = await writeXlsxFile(row_list, {
			columns: COLUMNS,
			fileName: `Claudit-${domain}_${contract.name}_anagrafica_strutture_${format(new Date(), 'yyyyMMdd')}.xlsx`
		})

		//const blob = new Blob(fileXlsx)
		//saveAs (blob, "export.xlsx");

	};


	return (
		<>
			<Button variant={"outline-primary"} size="sm" onClick={() => handleShowPrePrint()}>
				<FontAwesomeIcon icon="file-export" />Esporta
			</Button>
			<Modal show={showPrePrint} onHide={handleClosePrePrint} backdrop="static" scrollable={true}>
				<Modal.Header closeButton>
					<AlertSection ref={childRefAlert} />
					<Modal.Title>
						Esportazione strutture
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						Verranno esportati i locali delle strutture visualizzate
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Container fluid>
						<Row className="h4">
							<Col className="text-right">
								<Button variant="secondary" onClick={handleClosePrePrint} className="mr-1">
									{t('modal.close')}
								</Button>
									<Button variant="outline-info" onClick={onHandleExport} ><FontAwesomeIcon icon="file-export" />Esporta xlsx</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	)
}
