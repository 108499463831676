import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, ButtonGroup, Tooltip, OverlayTrigger, Popover, ListGroup, Spinner, Badge, } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import { serviceType_list, good_dict } from "../components/constants/global_definitions"

import { compareCode, compareType, checkComponentPresence, compareName, getValue, myTimestampToDate, getSelectedElement } from "../util/ui_utils.js"

import PageTitle from "../components/PageTitle"
import SearchSection from "../components/SearchSection"
import Footer from "../components/Footer"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalOperation from '../components/ModalOperation'

import { RenderItemFileBadge, RiskareaBadge } from '../components/StructureGraphicElements.js'




import { getOperationList as getOperationList2, deleteOperation as deleteOperation2 } from '../Firebase2/Storage2/dbService';

import { getDpiList as getDpiList2 } from '../Firebase2/Storage2/dbDpi'
import { getProductList as getProductList2 } from '../Firebase2/Storage2/dbWarehouse'


//const contractId = sessionStorage.getItem('contractId')



////////////////////////////////////////////////////////////////////////////////////////////////////////
function getElementCount(elem_list, filterList, searchTerm) {
	if (elem_list) {
		const filt = elem_list.filter(i => (filterList.length === 0 || (i.type_list && i.type_list.some(r => (filterList.indexOf(r) >= 0)))))
			.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
		if ((filterList.length === 0 && searchTerm.length < 2) || filt.length === elem_list.length) {
			return elem_list.length
		} else {
			return filt.length + "/" + elem_list.length
		}
	} else {
		return undefined

	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderCheckTooltip = (props) => (
	<Tooltip id="button-tooltip" {...props}>
		Operazione con controllo di processo obbligatorio
	</Tooltip>
);


const companyId = "0"
const two_row_limit = 5
let load_page = false


////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
////////////////////////////////////////////////////////////////////////////////////////////////////////
const OperationsView = ({ t, uData }) => {


	const { locale } = useContext(LocaleContext)
	const { domain, domain_data } = useContext(DomainContext)
	const { contract, risk_area_list, risk_area_dict, } = useContext(GlobalDataContext)

	console.log('DOMAIN', domain)
	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { initContract } = InitContractHelper()
	let { cid } = useParams();
	let contractId = cid
	const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)

	const service_type = serviceType_list({ t })

	const [filterAppliedList, setFilterAppliedList] = useState({ type: [] })

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}



	const initValues = async () => {
		console.log(domain_data)
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('operations') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		if (contract && domain.length > 0) {
			contractId = contract.id

			const newlist = await getOperationList2(domain, contractId)
			let newelementp_list = await getProductList2(domain, contractId, companyId, 'product', null)
			for (let p = 0; p < newelementp_list.length; p++) {
				newelementp_list[p].category_type = 'product'
			}
			let newelemente_list = await getProductList2(domain, contractId, companyId, 'equipment', null)
			for (let e = 0; e < newelemente_list.length; e++) {
				newelemente_list[e].category_type = 'equipment'
			}
			let newelementc_list = await getProductList2(domain, contractId, companyId, 'cart', null)
			for (let c = 0; c < newelementc_list.length; c++) {
				newelementc_list[c].category_type = 'cart'
			}
			let newelementm_list = await getProductList2(domain, contractId, companyId, 'machine', null)
			for (let m = 0; m < newelementm_list.length; m++) {
				newelementm_list[m].category_type = 'machine'
			}
			let newelementcm_list = await getProductList2(domain, contractId, companyId, 'cleaningmaterial', null)
			for (let cm = 0; cm < newelementcm_list.length; cm++) {
				newelementcm_list[cm].category_type = 'cleaningmaterial'
			}
			let newgood_list = [
				...newelementp_list,
				...newelemente_list,
				...newelementc_list,
				...newelementm_list,
				...newelementcm_list,
			]
			console.log(newgood_list)
			newgood_list = newgood_list.sort(compareName)
			console.log(newgood_list)
			let dpi_list = await getDpiList2(domain, contractId)
			dpi_list = dpi_list.sort(compareName)
			let dpi_dict = Object.assign({}, ...dpi_list.map((x) => ({ [x.id]: x })));
			setUiList({ ...ui_list, risk_area_list, risk_area_dict, dpi_dict, dpi_list, good_list: newgood_list })
			//console.log("source operation_list", newlist)
			newlist.sort(compareCode)
			setElementList(newlist)
			setLoading(false)
		} else {
			console.log('problem')
		}
	}


	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteOperation2(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('operation.messages.delete_operation_title'), t('operations.messages.delete_operation', { operation: el.name }), cb)
	}



	useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("OPERATIONS reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("OPERATIONS init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])


	useEffect(() => {
		console.log('OPERATIONS loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])










	return (
		<>
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col >
						<PageTitle title={t('global.pages.operations')} />
					</Col>
					<Col className="text-center">
						<ButtonGroup size="sm" className="mr-1">
							{filterAppliedList.type.length > 0 ?
								<Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'type': [] })}><FontAwesomeIcon icon="filter" /><FontAwesomeIcon icon="times" /></Button>
								:

								<Button disabled variant="secondary"><FontAwesomeIcon icon="filter" /></Button>
							}
							{service_type.map((g, k) => (
								<Button key={k} size="sm" disabled={element_list && element_list.filter(f => (f.type_list && f.type_list.indexOf(g.id) !== -1)).length === 0}
									variant={filterAppliedList['type'].indexOf(g.id) === -1 ? 'outline-secondary' : "secondary"}
									onClick={(e) => updateAppliedFilter('type', g.id)}>
									<FontAwesomeIcon icon={g.icon} /> {g.label}
								</Button>
							))}
						</ButtonGroup>
					</Col>
					<Col sm={2}>
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }} sm="2">

						{canWrite ?
							<ModalOperation is_new={true} t={t} item_list={element_list} ui_list={ui_list} setParentList={setElementList} canWrite={canWrite} />
							: ''}
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ml-1" sm="1" md="1">{t('operations.labels.code')}</Col>
						<Col className="bg-secondary ml-1">{t('operations.labels.name')}</Col>
						<Col className="bg-secondary ml-1">{t('global.labels.type')}</Col>
						<Col className="bg-secondary ml-1">{t('operations.labels.actions')}</Col>
						<Col className="bg-secondary ml-1">{t('operations.labels.good_equipment')}</Col>
						<Col className="bg-secondary ml-1">{t('operations.labels.dpi')}</Col>
						<Col className="bg-secondary ml-1">{t('operations.labels.controls')}</Col>
						<Col className="bg-secondary ml-1 text-center" sm="2" md="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>

						</Col>
					</Row>
					:
					<>
						{element_list && element_list.length > 0 ?
							<>
								{/* 	{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => ( */}
								{element_list
									.filter(i => (filterAppliedList.type.length === 0 || (i.type_list && i.type_list.some(r => (filterAppliedList.type.indexOf(r) >= 0)))))
									.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
									.map((i, key) => (
										<>
											<Row key={key} className="element_row p-1 border-bottom">
												<Col sm="1" md="1">{i.code}</Col>
												<Col className="font-weight-bold">
													{i.name}
												</Col>
												<Col>

													{i.type_list ?
														<>
															{i.type_list.map((tp, tkey) => (
																<Badge key={tkey} variant="light" className="mr-1">
																	<FontAwesomeIcon icon={getValue(getSelectedElement(tp, service_type, 'id'), 'icon')} />&nbsp;
																	{getValue(getSelectedElement(tp, service_type, 'id'), 'label')}
																</Badge>
															))}
														</>
														:
														''
													}
													{i.do_process_check ?
														<OverlayTrigger
															placement="right"
															delay={{ show: 250, hide: 200 }}
															overlay={renderCheckTooltip}
														>
															<FontAwesomeIcon icon="check" />
														</OverlayTrigger>
														: ''}
												</Col>
												<Col>
													{i.action_list && i.action_list.length > 0 ?
														<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderPopover(t, ui_list, t('global.labels.actions'), i.action_list)}>
															<Badge variant="secondary" className="btn mr-1">{i.action_list.length} {t('global.labels.actions')} </Badge>
														</OverlayTrigger>
														: ''
													}
													<RenderItemFileBadge media_list={i.media_list} section="operation" />
												</Col>
												<Col>
													{i.element_list && i.element_list.length > 0 ?
														<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderPopover(t, ui_list, t('global.labels.goods'), i.element_list, 'goods')}>
															<Badge variant="secondary" className="btn mr-1">{i.element_list.length} {t('global.labels.goods')} </Badge>
														</OverlayTrigger>
														: ''
													}
												</Col>
												<Col>
													{i.dpi_list && i.dpi_list.length > 0 ?
														<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderPopover(t, ui_list, t('global.labels.dpi'), i.dpi_list, 'dpi')}>
															<Badge variant="secondary" className="btn mr-1">{i.dpi_list.length} {t('global.labels.dpi')} </Badge>
														</OverlayTrigger>
														: ''
													}
												</Col>
												<Col>
													{i.furniture_list && i.furniture_list.length > 0 ?
														<OverlayTrigger trigger={['focus', 'hover']} placement="auto-start" overlay={renderPopover(t, ui_list, t('global.labels.furnitures'), i.furniture_list, 'check')}>
															<Badge variant="secondary" className="btn mr-1">{i.furniture_list.length} {t('global.labels.furnitures')} </Badge>
														</OverlayTrigger>
														: ''
													}
													{i.environment_element_list && i.environment_element_list.length > 0 ?
														<OverlayTrigger trigger={['focus', 'hover']} placement="auto-start" overlay={renderPopover(t, ui_list, t('global.labels.environment_elements'), i.environment_element_list, 'check')}>
															<Badge variant="secondary" className="btn mr-1">{i.environment_element_list.length} {t('global.labels.environment_elements')} </Badge>
														</OverlayTrigger>
														: ''
													}
													{i.economic_material_list && i.economic_material_list.length > 0 ?
														<OverlayTrigger trigger={['focus', 'hover']} placement="auto-start" overlay={renderPopover(t, ui_list, t('global.labels.economic_material'), i.economic_material_list, 'check')}>
															<Badge variant="secondary" className="btn mr-1">{i.economic_material_list.length} {t('global.labels.economic_material')} </Badge>
														</OverlayTrigger>
														: ''
													}
												</Col>
												<Col className="text-center" sm="2" md="1">
													<ButtonGroup>
														<ModalOperation t={t} item={i} item_list={element_list} setParentList={setElementList} index={key} ui_list={ui_list} canWrite={canWrite} />
														{canWrite ?
															<Button variant="danger" size="sm"><FontAwesomeIcon icon='trash' onClick={() => handleDelete(i)} /></Button>
															: ''}
													</ButtonGroup>
												</Col>

											</Row>
										</>
									))}
							</>
							: <i> {t('operations.messages.no_operation')}</i>
						}
					</>
				}
			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount(element_list, filterAppliedList.type, searchTerm) + " " + t('global.labels.operations')} />
		</>
	);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const getElementIcon = (a) => {
	try {
		return <span className={a.color}><FontAwesomeIcon icon={a.icon} className="margin-right-icon" /></span>

	} catch (err) {
		return <span ></span>

	}
}





////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderPopover = (t, ui_list, title, subelement_list, category) => {
	const goods_labels = good_dict({ t })

	if (category === 'goods') {
		let belement_list = [...subelement_list]
		belement_list.sort(compareType)

		return (
			<Popover id="popover-basic" className="bg-info border border-info">
				<Popover.Title as="h3">{title}
				</Popover.Title>
				<Popover.Content className="bg-info">
					{belement_list ?
						<ListGroup variant="flush" className={subelement_list.length > two_row_limit ? "d-inline-block" : "d-block"}>
							{belement_list.map((r, k) => (
								<ListGroup.Item key={k} className={subelement_list.length > two_row_limit ?
									"p-2 border-left w-50 " + (k % 2 === 0 ? 'float-left' : 'float-right')
									: ""}>
									<Badge variant="info">&nbsp;-&nbsp;</Badge> {getElementIcon(goods_labels[r.category_type])} {r.description} {r.name}
								</ListGroup.Item>
							))}
						</ListGroup>
						: ''
					}
				</Popover.Content>
			</Popover>

		)
	} else if (category === 'dpi') {
		const OneRa = ({ ra_id }) => {
			const a = ui_list.risk_area_dict[ra_id]
			try {

				return (
					<RiskareaBadge riskarea={a} />
				)
			} catch (err) {
				return (<></>)
			}
		}
		return (
			<Popover id="popover-basic" className="bg-info border border-info">
				<Popover.Title as="h3">{title}
				</Popover.Title>
				<Popover.Content>
					{subelement_list ?
						<ListGroup variant="flush" className="d-block">
							{subelement_list.map((r, k) => (
								<ListGroup.Item key={k} >
									<Badge variant="info">&nbsp;-&nbsp;</Badge>&nbsp;
									{ui_list.dpi_dict[r.id].name}
									{r.risk_area_presence.map((a, k) => (
										<OneRa ra_id={a} key={k} />
									))}
								</ListGroup.Item>
							))}
						</ListGroup>
						: ''
					}
				</Popover.Content>
			</Popover>
		)
	} else if (category === 'check') {
		return (
			<Popover id="popover-basic" className="bg-info border border-warning">
				<Popover.Title as="h3">{title}
				</Popover.Title>
				<Popover.Content className="bg-warning">
					{subelement_list ?
						<ListGroup variant="flush" className={subelement_list.length > two_row_limit ? "d-inline-block" : "d-block"}>
							{subelement_list.map((r, k) => (
								<ListGroup.Item key={k} className={subelement_list.length > two_row_limit ?
									"p-2 border-left w-50 " + (k % 2 === 0 ? 'float-left' : 'float-right')
									: ""}>
									<Badge variant="info">&nbsp;-&nbsp;</Badge> {r.description} {r.name}
									{r.control_list && r.control_list.length > 0 ?
										<>
											{r.control_list.filter(c => c.action_list && c.action_list.length > 0).length > 0 ?
												<Badge variant="warning">{r.control_list.length} <FontAwesomeIcon icon="check" />(A.C.) </Badge>
												:
												<Badge variant="light">{r.control_list.length} <FontAwesomeIcon icon="check" /> </Badge>
											}
										</>
										: <></>}
								</ListGroup.Item>
							))}
						</ListGroup>
						: ''
					}
				</Popover.Content>
			</Popover >

		)

	} else {
		return (
			<Popover id="popover-basic" className="bg-info border border-info">
				<Popover.Title as="h3">{title}
				</Popover.Title>
				<Popover.Content>
					{subelement_list ?
						<ListGroup variant="flush" className="d-block">
							{subelement_list.map((r, k) => (
								<ListGroup.Item key={k} ><Badge variant="info">&nbsp;-&nbsp;</Badge> {r.description} {r.name}</ListGroup.Item>
							))}
						</ListGroup>
						: ''
					}
				</Popover.Content>
			</Popover>

		)

	}
}





export default translate()(OperationsView)