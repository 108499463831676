import React, { useState, useEffect, useRef, createRef, useContext } from 'react';
import { Modal, Container, Row, Col, Form, Button, ButtonGroup, Spinner, Popover, OverlayTrigger, Badge, Tooltip } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { addHours, format, getDay, set } from 'date-fns'
import { it } from 'date-fns/locale'
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import { interventionType_dict, serviceType_list, ticketType_dict } from "../components/constants/global_definitions"

import LocaleContext from "../components/contexts/locale.js"
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"



import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"

import ModalInterventionEvent from '../components/modal/ModalInterventionEvent';
import ModalRequestGenerator from '../components/modal/ModalRequestGenerator';
import { filterRoom } from '../components/RoomSelector'
import { myTimestampToDate, myDateToTimestamp, getValue, getSelectedElementB, getSelectedElement, getElementCount, getStructureFromWorkareas, compareDateParam, setMyDate } from "../util/ui_utils.js"
import { ItemProgress, TicketPath, ServiceBadge, RiskareaBadge, RoomInfoBadge, IsDeleted, IsModified, IsDone, StructureGroupIcon, TicketUserName, SecondarySection } from '../components/StructureGraphicElements.js'


import firebase from 'firebase/app'


import { getEventRange as getEventRange2, addEvent, editEvent } from '../Firebase2/Storage2/dbAgenda';
import { getInterventionById as getInterventionById2, addIntervention } from '../Firebase2/Storage2/dbIntervention'


import { getTicketListForUser as getTicketListForUser2, getTicketListForGroup as getTicketListForGroup2, editTicket as editTicket2, getTicketById, } from "../Firebase2/Storage2/dbTicket"
import { Lock, Unlock } from 'react-bootstrap-icons';
import { ModalExportPlanning } from '../components/modal/ModalExportPlanning';







let curdate = { start: new Date(), end: new Date() }
const newEvent = {
	start: new Date(),
	end: new Date(),
	extendedProps: {
		subject: '',
		location: '',
		shared: -1,
		reminder: false,
		reminder_time: 15,
		note: '',
		periodic_data: {
			date_list: [],
			start: '6:00',
			end: '8:00'
		}
	}
};

const new_intervention = {
	name: '',
	room_list: [],
	group_name: '',
	group_id: -1,
	start: firebase.firestore.Timestamp.fromDate(set(new Date(), { hours: 8, minutes: 0, seconds: 0 })),
	end: firebase.firestore.Timestamp.fromDate(set(new Date(), { hours: 10, minutes: 0, seconds: 0 })),
	weekdays: [],
	service: {},
	frequency_mode: 'requested',
	operating_units: 1,
	max_check_delay: 0,
	operation_list: [],
	intervention_length: 1,
	size: 0,
	use_activity_period: false,
	cart_element_list: [],
	periodic_data: {
		date_list: [],
		start: '6:00',
		end: '8:00'
	}
}

let department_dict = {}
let structure_dict = {}
let workarea_dict = {}
let workarea_referent_dict = []
let used_service_list = []
let intervention_dict = {}

let permission_department_list = []
let permission_structure_list = []
let permission_workarea_list = []


let filterAppliedList = { workarea: [], structure: [], pavillon: [], floor: [], department: [], type: [], workarea_referent: [], is_deleted: false }
const defaultFilter = { workarea: [], structure: [], pavillon: [], floor: [], department: [], type: [], workarea_referent: [] }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const PlanningView = ({ t, uData }) => {
	//console.log("plannint", uData)
	let load_page = false

	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict, service_dict, all_workarea_dict, } = useContext(GlobalDataContext)

	const { initContract } = InitContractHelper()
	const calendarRef = createRef()

	const childRefEvent = useRef()
	const childRefRequest = useRef()

	let { cid } = useParams();
	let contractId = cid
	const service_type = serviceType_list({ t })
	const [api, setApi] = useState()
	const [title, setTitle] = useState('')
	const [curEvent, setCurEvent] = useState(newEvent);
	const [curEventList, setCurrentEventList] = useState([]);
	const [loading, setLoading] = useState(true)
	const [preloading, setPreLoading] = useState(true)
	const [showViewRequestedAssignment, setShowViewRequestedAssignment] = useState(false)
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)

	const [permission_data, setPermissionData] = useState({})


	const handleSave = async (_event, cref, cApi) => {
		await editEvent(domain, contractId, _event.id, _event)
		const userName = uData.isGlobalAdmin ? 'admin' : uData.displayName || 'utente'
		if (_event.extendedProps.type === 'requested_intervention') {
			const _ticket = await getTicketById(domain, contractId, _event.extendedProps.source_message_id)
			console.log(_event.extendedProps.source_message_id, _ticket)
			let curElement = { ..._ticket }
			let msg = _event.extendedProps.is_deleted ? `Assegnazione cancellata da ${userName}.` : `Assegnazione modificata da ${userName}.`
			const answermessage = {
				message: msg,
				createdAt: setMyDate(new Date()),
				status: 'sent',
				userId: uData.id,
				userName: userName
			}
			curElement.message_list = (curElement.message_list) ? [...curElement.message_list, answermessage] : [answermessage]
			curElement.read = false
			curElement.lastEdit = answermessage.createdAt
			console.log(curElement)
			await editTicket2(domain, contractId, curElement.id, curElement)
		}
		try {
			let calendarApi = cref.current.getApi()
			calendarApi.refetchEvents()
		} catch (err) {
			console.log(err, cref)
			try {
				cApi.refetchEvents()
			} catch (err) {
				console.log(err, cApi)
			}
		}
	}


	useEffect(() => {
		console.log("\t\tSTRUCTURE ELEMENTS", structureElements)

	}, [structureElements])


	const handleCreate = async (_ticket, cref, cApi) => {
		//		const _event = { ...curEvent, start: firebase.firestore.Timestamp.fromDate(curEvent.start), end: firebase.firestore.Timestamp.fromDate(curEvent.end) }
		const curElement = { ..._ticket }
		let currentEditIntervention = { ...new_intervention }
		currentEditIntervention.type = 'requested'
		currentEditIntervention.frequency_mode = 'requested'
		currentEditIntervention.service = curElement.service
		const userName = uData.isGlobalAdmin ? 'admin' : uData.displayName || 'utente'
		for (const room of curElement.room_list.filter(r => r.to_assign)) {
			const _room = structureElements.all_room_dict[room.id]
			console.log("ROOM", _room)
			currentEditIntervention.start = myDateToTimestamp(room.start)
			currentEditIntervention.end = myDateToTimestamp(room.end)
			currentEditIntervention = {
				...currentEditIntervention,
				structure_list: [_room.parent_list[0]],
				pavillon_list: [_room.parent_list[1]],
				floor_list: [_room.parent_list[2]],
				department_list: [_room.parent_list[3]],
				room_list: [{ id: _room.id, name: _room.name, roomtype: { id: _room.roomtype.id }, parent_list: _room.parent_list, cdc: _room.cdc || '', size: _room.size }],
				size: _room.size,
				room_count: 1
			}
			const return_data_i = await addIntervention(domain, contractId, currentEditIntervention)
			console.log(currentEditIntervention)
			const newEvent = {
				start: myDateToTimestamp(room.start),
				end: myDateToTimestamp(room.end),
				extendedProps: {
					type: 'requested_intervention',
					workarea_id: room.workarea_id,
					workarea_name: all_workarea_dict[room.workarea_id] ? all_workarea_dict[room.workarea_id].name : '',
					intervention_id: return_data_i.id,
					structure_list: [_room.parent_list[0]],
					pavillon_list: [_room.parent_list[1]],
					floor_list: [_room.parent_list[2]],
					department_list: [_room.parent_list[3]],
					requested_by: _ticket.userName,
					createdBy: userName,
					service_id: curElement.service.id,
					createdAt: myDateToTimestamp(new Date()),
					source_message_id: _ticket.id,
					note: curElement.note
				}
			};
			const ret_event = await addEvent(domain, contractId, newEvent)
			console.log(".... return data (event, intervention): ", ret_event, return_data_i)
		};
		const r_assigned_count = curElement.room_list.filter(r => r.to_assign).length
		const r_list = curElement.room_list.map(r => {
			if (r.to_assign) {
				delete r.to_assign
				r.assigned = true
			}
			return r
		})
		let msg = ''
		if (r_list.filter(r => !r.assigned).length > 0) {
			msg = `Richiesta presa in carico da ${userName}. Assegnazione effettuata per  ${r_assigned_count} locali (su ${r_list.length} totali). ${r_list.filter(r => !r.assigned).length > 0} locali rimanenti.`
		} else {
			msg = `Richiesta presa in carico da ${userName}. Assegnazione effettuata per ${r_assigned_count} locali (Assegnazione completata)`
		}
		const answermessage = {
			message: msg,
			createdAt: setMyDate(new Date()),
			status: 'sent',
			userId: localStorage.getItem('userId'),
			userName: userName
		}
		curElement.room_list = r_list
		const done = r_list.filter(r => r.assigned).length === r_list.length ? 'assigned' : 'partially_assigned'
		curElement.status_history = [...curElement.status_history, { code_id: done, status_date: answermessage.createdAt }]
		curElement.message_list = (curElement.message_list) ? [...curElement.message_list, answermessage] : [answermessage]
		curElement.read = false
		curElement.lastEdit = answermessage.createdAt
		console.log(curElement)
		await editTicket2(domain, contractId, curElement.id, curElement)


		try {
			let calendarApi = cref.current.getApi()
			calendarApi.refetchEvents()
		} catch (err) {
			console.log(err, cref)
			try {
				cApi.refetchEvents()
			} catch (err) {
				console.log(err, cApi)
			}
		}
	}


	function checkReferentFilter(ev_w_id) {
		return filterAppliedList.workarea_referent.filter(r =>
			workarea_referent_dict[r].workarea_list.indexOf(ev_w_id) >= 0
		).length > 0
	}



	const handleDateSelect = (selectInfo) => {
		console.log("handleDateSelect - selectInfo:", selectInfo)
	}

	useEffect(() => {
		//console.log("####.....####...###..NEW FILTER -> ", filterAppliedStructure)
		try {
			console.log("-----------CALENDAR-----------PERMISSION", permission_data, calendarRef.current, api)
			let calendarApi = calendarRef.current.getApi()
			//console.log(calendarApi)
			calendarApi.refetchEvents('msg')
		} catch (err) {
			console.error("ERR", err)
			//getEventList()
		}
		setPreLoading(false)
	}, [permission_data])

	useEffect(() => {
		//let calendarApi = calendarRef.current.getApi();
		setApi(calendarRef)
	}, []);


	const getEventList = async (el) => {
		console.log("start data", el)
		console.log("GET EVENT- permission", permission_data)
		if (el.start >= curdate.start && el.end <= curdate.end) {
			console.log('*************keep data')
		} else {
			curdate = el
		}
		setLoading(true)

		let temp_intervention_dict = { ...intervention_dict }
		let temp_department_dict = { ...department_dict }
		let temp_structure_dict = { ...structure_dict }
		let temp_workarea_dict = { ...workarea_dict }
		let temp_service_list = [...used_service_list]
		//console.log(temp_department_dict)
		//    console.log(temp_intervention_dict)
		let event_list = []

		if (contract && domain.length > 0) {

			let return_data = await getEventRange2(domain, contractId, firebase.firestore.Timestamp.fromDate(el.start), firebase.firestore.Timestamp.fromDate(el.end))

			//    return_data = return_data.filter(e => (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention'))
			
			return_data = return_data.filter(e => (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention' || e.extendedProps.type === 'requested_intervention')

				&& (filterAppliedList.is_deleted || !e.extendedProps.is_deleted)
			)
			console.log("DELETED", return_data.filter(e => e.extendedProps.is_deleted), return_data)
			if (permission_workarea_list.length > 0) {
				return_data = return_data.filter(e => permission_workarea_list.indexOf(e.extendedProps.workarea_id) >= 0)
			} else if (permission_structure_list.length > 0) {
				if (permission_department_list.length > 0) {
					return_data = return_data.filter(e => checkEventPermission(e, permission_department_list))
				} else {
					return_data = return_data.filter(e =>
						(e.extendedProps.structure_list && e.extendedProps.structure_list.some(d => (permission_structure_list.indexOf(d)) >= 0))
					)
				}
			}
			//	console.log("return data", return_data)
			//      console.log(uData)
			//      for (let d = 0; d < return_data.length; d++) {
			for (const one_event of return_data) {
				let insert = false
				if (filterAppliedList.type.length === 0 || filterAppliedList.type.indexOf(one_event.extendedProps.type) !== -1) {
					one_event.start = myTimestampToDate(one_event.start)
					one_event.end = myTimestampToDate(one_event.end)
					//          console.log("return_data[" + d + "].extendedProps: ", return_data[d].extendedProps)
					if (one_event.extendedProps.type === 'ordinary_intervention') {
						if (one_event.extendedProps.intervention_id) { // && (permission_department_list.length === 0 || event.extendedProps.department_list.some(d => permission_department_list.indexOf(d) !== -1))) {
							const response = temp_intervention_dict[one_event.extendedProps.intervention_id] ||
								(await getInterventionById2(domain, contractId, one_event.extendedProps.intervention_id))
							if (response) {
								let department_list_id = []
								if (
									(filterAppliedList.department.length === 0 || one_event.extendedProps.department_list.some(d => filterAppliedList.department.indexOf(d) !== -1)) &&
									(filterAppliedList.structure.length === 0 || one_event.extendedProps.structure_list.some(d => filterAppliedList.structure.indexOf(d) !== -1)) &&
									(filterAppliedList.floor.length === 0 || one_event.extendedProps.floor_list.some(d => filterAppliedList.floor.indexOf(d) !== -1)) &&
									(filterAppliedList.pavillon.length === 0 || one_event.extendedProps.pavillon_list.some(d => filterAppliedList.pavillon.indexOf(d) !== -1)) &&
									(filterAppliedList.workarea.length === 0 || filterAppliedList.workarea.indexOf(one_event.extendedProps.workarea_id) !== -1) &&
									(filterAppliedList.workarea_referent.length === 0 || checkReferentFilter(one_event.extendedProps.workarea_id))
								) {
									insert = true
									for (const s of one_event.extendedProps.structure_list) {
										if (!temp_structure_dict[s]) {
											temp_structure_dict[s] = structureElements.all_structure_dict[s]
										}
									}
								} else {
									console.log("filter present: --not inserted", filterAppliedList, one_event.id)
								}
								/* 								for (const room of response.room_list) {
																	if (!temp_department_dict[room.parent_list[3]]) {
																		temp_department_dict[room.parent_list[3]] = structureElements.all_department_dict[room.parent_list[3]]
																	}
																	if (!temp_structure_dict[room.parent_list[0]]) {
																		temp_structure_dict[room.parent_list[0]] = structureElements.all_structure_dict[room.parent_list[0]]
																	}
																	if (department_list_id.indexOf(room.parent_list[3]) === -1) {
																		department_list_id.push(room.parent_list[3])
																	}
																	if (
																		(filterAppliedList.department.length === 0 || filterAppliedList.department.indexOf(room.parent_list[3]) !== -1) &&
																		(filterAppliedList.structure.length === 0 || filterAppliedList.structure.indexOf(room.parent_list[0]) !== -1) &&
																		(filterAppliedList.workarea.length === 0 || filterAppliedList.workarea.indexOf(one_event.extendedProps.workarea_id) !== -1) &&
																		(filterAppliedList.workarea_referent.length === 0 || checkReferentFilter(one_event.extendedProps.workarea_id))
																	) {
																		insert = true
																	}
																} */
								temp_intervention_dict[one_event.extendedProps.intervention_id] = response
								one_event.extendedProps.name = response.name + " " + response.service.name
								one_event.extendedProps.intervention_data = response
								one_event.extendedProps.summary = "Totale Locali: " + response.room_count
								//								one_event.extendedProps.department_list_id = department_list_id
								if (temp_service_list.indexOf(response.service.id) !== -1) {
									temp_service_list.push(response.service.id)
								}
								if (!temp_workarea_dict[one_event.extendedProps.workarea_id] && all_workarea_dict[one_event.extendedProps.workarea_id]) {
									temp_workarea_dict[one_event.extendedProps.workarea_id] = all_workarea_dict[one_event.extendedProps.workarea_id].name
								}

							}
						} else {
							one_event.extendedProps.name = "Intervento ordinario"  // FIXME
							one_event.extendedProps.summary = "DB OLD DATA"

						}
						one_event.backgroundColor = 'Grey'

					} else if (one_event.extendedProps.type === 'periodic_intervention') {
						//if (event.extendedProps.intervention_id && (permission_department_list.length === 0 || permission_department_list.indexOf(event.extendedProps.intervention_id) >= 0)) {
						if (one_event.extendedProps.intervention_id) { // && (permission_department_list.length === 0 || event.extendedProps.department_list.some(d => permission_department_list.indexOf(d) !== -1))) {
							const response = temp_intervention_dict[one_event.extendedProps.intervention_id] ||
								(await getInterventionById2(domain, contractId, one_event.extendedProps.intervention_id))
							let department_list_id = []
							if (response) {
								if (
									(filterAppliedList.department.length === 0 || one_event.extendedProps.department_list.some(d => filterAppliedList.department.indexOf(d) !== -1)) &&
									(filterAppliedList.structure.length === 0 || one_event.extendedProps.structure_list.some(d => filterAppliedList.structure.indexOf(d) !== -1)) &&
									(filterAppliedList.floor.length === 0 || one_event.extendedProps.floor_list.some(d => filterAppliedList.floor.indexOf(d) !== -1)) &&
									(filterAppliedList.pavillon.length === 0 || one_event.extendedProps.pavillon_list.some(d => filterAppliedList.pavillon.indexOf(d) !== -1)) &&
									(filterAppliedList.workarea.length === 0 || filterAppliedList.workarea.indexOf(one_event.extendedProps.workarea_id) !== -1) &&
									(filterAppliedList.workarea_referent.length === 0 || checkReferentFilter(one_event.extendedProps.workarea_id))
								) {
									insert = true
									for (const s of one_event.extendedProps.structure_list) {
										if (!temp_structure_dict[s]) {
											temp_structure_dict[s] = structureElements.all_structure_dict[s]
										}
									}
								} else {
									console.log("filter present: --not inserted", filterAppliedList, one_event.id)
								}

								/* for (const room of response.room_list) {
									if (!temp_department_dict[room.parent_list[3]]) {
										temp_department_dict[room.parent_list[3]] = structureElements.all_department_dict[room.parent_list[3]]
									}
									if (!temp_structure_dict[room.parent_list[0]]) {
										temp_structure_dict[room.parent_list[0]] = structureElements.all_structure_dict[room.parent_list[0]]
									}
									if (department_list_id.indexOf(room.parent_list[3]) === -1) {
										department_list_id.push(room.parent_list[3])
									}
									if (
										(filterAppliedList.department.length === 0 || filterAppliedList.department.indexOf(room.parent_list[3]) !== -1) &&
										(filterAppliedList.structure.length === 0 || filterAppliedList.structure.indexOf(room.parent_list[0]) !== -1) &&
										(filterAppliedList.workarea.length === 0 || filterAppliedList.workarea.indexOf(one_event.extendedProps.workarea_id) !== -1) &&
										(filterAppliedList.workarea_referent.length === 0 || checkReferentFilter(one_event.extendedProps.workarea_id))
									) {
										insert = true
									} else {
										console.log("FILTER LIST", filterAppliedList)
									}
								} */
								temp_intervention_dict[one_event.extendedProps.intervention_id] = response
								one_event.extendedProps.name = response.name + " " + response.service.name
								one_event.extendedProps.intervention_data = response
								one_event.extendedProps.summary = "Totale Locali: " + response.room_count
								//one_event.extendedProps.department_list_id = department_list_id
								if (temp_service_list.indexOf(response.service.id) !== -1) {
									temp_service_list.push(response.service.id)
								}
								if (!temp_workarea_dict[one_event.extendedProps.workarea_id] && all_workarea_dict[one_event.extendedProps.workarea_id]) {
									temp_workarea_dict[one_event.extendedProps.workarea_id] = all_workarea_dict[one_event.extendedProps.workarea_id].name
								}
							}
						} else {
							one_event.extendedProps.name = "Intervento periodico"  // FIXME
							one_event.extendedProps.summary = "DB OLD DATA"
						}
						one_event.backgroundColor = 'LightGrey'
					} else if (one_event.extendedProps.type === 'requested_intervention') {
						//if (event.extendedProps.intervention_id && (permission_department_list.length === 0 || permission_department_list.indexOf(event.extendedProps.intervention_id) >= 0)) {
						if (one_event.extendedProps.intervention_id) { // && (permission_department_list.length === 0 || event.extendedProps.department_list.some(d => permission_department_list.indexOf(d) !== -1))) {
							const response = temp_intervention_dict[one_event.extendedProps.intervention_id] ||
								(await getInterventionById2(domain, contractId, one_event.extendedProps.intervention_id))
							let department_list_id = []
							console.log("RESP_req", response, one_event.extendedProps.intervention_id)
							if (response) {
								console.log(myTimestampToDate(response.start))

								if (
									(filterAppliedList.department.length === 0 || one_event.extendedProps.department_list.some(d => filterAppliedList.department.indexOf(d) !== -1)) &&
									(filterAppliedList.structure.length === 0 || one_event.extendedProps.structure_list.some(d => filterAppliedList.structure.indexOf(d) !== -1)) &&
									(filterAppliedList.floor.length === 0 || one_event.extendedProps.floor_list.some(d => filterAppliedList.floor.indexOf(d) !== -1)) &&
									(filterAppliedList.pavillon.length === 0 || one_event.extendedProps.pavillon_list.some(d => filterAppliedList.pavillon.indexOf(d) !== -1)) &&
									(filterAppliedList.workarea.length === 0 || filterAppliedList.workarea.indexOf(one_event.extendedProps.workarea_id) !== -1) &&
									(filterAppliedList.workarea_referent.length === 0 || checkReferentFilter(one_event.extendedProps.workarea_id))
								) {
									insert = true
									for (const s of one_event.extendedProps.structure_list) {
										if (!temp_structure_dict[s]) {
											temp_structure_dict[s] = structureElements.all_structure_dict[s]
										}
									}
								} else {
									console.log("filter present: --not inserted", filterAppliedList, one_event.id)
								}
								/* for (const room of response.room_list) {
									if (!temp_department_dict[room.parent_list[3]]) {
										temp_department_dict[room.parent_list[3]] = structureElements.all_department_dict[room.parent_list[3]]
									}
									if (!temp_structure_dict[room.parent_list[0]]) {
										temp_structure_dict[room.parent_list[0]] = structureElements.all_structure_dict[room.parent_list[0]]
									}
									if (department_list_id.indexOf(room.parent_list[3]) === -1) {
										department_list_id.push(room.parent_list[3])
									}
									if (
										(filterAppliedList.department.length === 0 || filterAppliedList.department.indexOf(room.parent_list[3]) !== -1) &&
										(filterAppliedList.structure.length === 0 || filterAppliedList.structure.indexOf(room.parent_list[0]) !== -1) &&
										(filterAppliedList.workarea.length === 0 || filterAppliedList.workarea.indexOf(one_event.extendedProps.workarea_id) !== -1) &&
										(filterAppliedList.workarea_referent.length === 0 || checkReferentFilter(one_event.extendedProps.workarea_id))
									) {
										insert = true
									} else {
										console.log("FILTER LIST", filterAppliedList)
									}
									console.log(insert)
								} */

								temp_intervention_dict[one_event.extendedProps.intervention_id] = response
								one_event.extendedProps.name = response.name + " " + response.service.name
								one_event.extendedProps.intervention_data = response
								one_event.extendedProps.summary = "Totale Locali: " + response.room_count
								//								one_event.extendedProps.department_list_id = department_list_id
								if (temp_service_list.indexOf(response.service.id) !== -1) {
									temp_service_list.push(response.service.id)
								}
								if (!temp_workarea_dict[one_event.extendedProps.workarea_id] && all_workarea_dict[one_event.extendedProps.workarea_id]) {
									temp_workarea_dict[one_event.extendedProps.workarea_id] = all_workarea_dict[one_event.extendedProps.workarea_id].name
								}
							}
						} else {
							one_event.extendedProps.name = "Intervento a richiesta"  // FIXME
							one_event.extendedProps.summary = "DB OLD DATA"
						}
						one_event.backgroundColor = 'LightBlue'
					}
					if (insert) {
						event_list.push(one_event)
					}
				}
			}
			//			console.log('RETURN DATA filtered', event_list)
			used_service_list = temp_service_list
			department_dict = temp_department_dict
			structure_dict = temp_structure_dict
			workarea_dict = temp_workarea_dict
			for (const w_id in workarea_dict) {
				const w = all_workarea_dict[w_id]
				if (w.referent_list && w.referent_list.length > 0) {
					for (const u of w.referent_list.filter(u => u.user_id && u.user_id !== -1)) {
						if (workarea_referent_dict[u.user_id]) {
							workarea_referent_dict[u.user_id].workarea_list.push(w_id)
						} else {
							workarea_referent_dict[u.user_id] = { ...u, workarea_list: [w_id] }
						}
					}
				}
			}
			console.log("REFERENT LIST", workarea_referent_dict)
			intervention_dict = temp_intervention_dict

		}
		console.log("EVENT COUNT", event_list.length)
		setCurrentEventList(event_list)
		setLoading(false)
		return event_list

	}




	const handleEventClick = (clickInfo) => {
		console.log("myevent", clickInfo)
		if (clickInfo.event.extendedProps.type === 'ordinary_intervention' || clickInfo.event.extendedProps.type === 'periodic_intervention' || clickInfo.event.extendedProps.type === 'requested_intervention') {
			const ev = {
				...newEvent,
				id: clickInfo.event.id,
				start: clickInfo.event.start,
				end: clickInfo.event.end,
				extendedProps: { ...clickInfo.event.extendedProps, name: clickInfo.event.extendedProps.name }
			}
			setCurEvent(ev)
			childRefEvent.current.setEvent(ev, handleSave, calendarRef, clickInfo.event._context.calendarApi)
		}
	}



	const handleRequestAssignment = (ticket) => {
		const start = new Date()
		const end = addHours(new Date(), 2)

		const ev = {
			...ticket,
			room_list: ticket.room_list.map((r) => {
				let permission = true
				console.log(permission_data)
				if (permission_data.permission_structure_list) {

					if (permission_data.permission_workarea_list) {
						if (permission_data.permission_workarea_list.length > 0) {
							permission = r.w_list.filter(e => permission_data.permission_workarea_list.indexOf(e) >= 0).length > 0
						}
					} else {
						if (permission_data.permission_structure_list.length > 0) {
							if (permission_data.permission_department_list.length > 0) {
								permission = permission_data.permission_department_list.indexOf(r.parent_list[3]) >= 0
							} else {
								permission = permission_data.permission_structure_list.indexOf(r.parent_list[0]) >= 0
							}
						}
					}
				}
				if (r.assigned) {
					return { ...r, permission: permission }
				} else {
					return { ...r, start: start, end: end, permission: permission }
				}
			}
			),
			intervention_type: 'requested_intervention'
		}
		setCurEvent(ev)
		childRefRequest.current.setTicket(ev, handleCreate, calendarRef, api)

	}





	const handleEvents = (events) => {
		setCurrentEventList(events)
	}


	const initValues = async () => {
		console.log('PLANNING uData', uData)
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('planning') !== -1)) {
			setWritePage(true)
		}
		let userData = uData
		setPreLoading(true)
		if (userData && userData.contract_dict && userData.contract_dict[contractId] && userData.contract_dict[contractId][0]) {
			console.log('userData contract (permission)', userData.contract_dict[contractId])
			if (userData.contract_dict[contractId][0].mode === 'workarea') {
				permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
				console.log("permission workarea_list", permission_workarea_list, all_workarea_dict, userData.contract_dict[contractId][0].workarea)
				permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
				console.log("permission_structure_list", permission_structure_list)
				setPermissionData({ permission_workarea_list, permission_structure_list })
			} else {
				if (userData.contract_dict[contractId][0].structure) {
					if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
						permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
						console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
					}
					if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
						permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
						//structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
					}
					setPermissionData({ permission_department_list, permission_structure_list })
				} else {
					setPreLoading(false)

				}
			}
		} else {
			setPreLoading(false)
		}

		load_page = true
		setUiList({ ...ui_list, risk_area_list, risk_area_dict, service_dict, roomtype_list, roomtype_dict })
		try {
			let calendarApi = calendarRef.current.getApi()
			calendarApi.refetchEvents()
		} catch (err) {
			console.log(err)

		}

	}

	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("PLANNING reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("PLANNING init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()
		}
	}, [])




	useEffect(() => {
		console.log('PLANNING loadingcontract', contract, uData)
		if (contract && uData && !load_page) {
			initValues()
		}
	}, [contract, uData])


	useEffect(() => {
		//console.log('update ref')
		if (calendarRef && calendarRef.current) {
			let calendarApi = calendarRef.current.getApi()
			//	console.log(calendarApi.view)
			//calendarApi.refetchEvents()
			setTitle(calendarApi.view.title)
			setApi(calendarApi)
		}

	}, [calendarRef])










	// --------------------------------------------------------
	const renderEventContent = (info) => {
		if (info.timeText.length === 2) {
			info.timeText = info.timeText + ":00"
		}
		const intervention_type_dict = interventionType_dict({ t })
		let evtId = "event-" + info.event.id;
		let tt = ''
		let room_count = ''
		let secondary = ''
		try {
			tt = format(info.event.start, 'HH:mm', { locale: it }) + " - " + format(info.event.end, 'HH:mm', { locale: it }) + " | "
		} catch (err) {
			console.log(info.event.start, info.event.end, err)
		}
		if (info.event.extendedProps.type === 'ordinary_intervention') {
			try {
				room_count = info.event.extendedProps.intervention_data.room_list.filter(_room => filterRoom(info.event, structureElements.all_room_dict[_room.id], roomtype_dict[structureElements.all_room_dict[_room.id].roomtype.id])).length
			} catch (err) {
				console.log(info.event.extendedProps, err)
			}
		} else {
			room_count = info.event.extendedProps.intervention_data.room_list.length
		}

		const popover = (
			<Popover id="popover-basic">
				<Popover.Title as="h3">{intervention_type_dict[info.event.extendedProps.type].title}:&nbsp;{info.event.extendedProps.intervention_data.name}</Popover.Title>
				<Popover.Content>
					<Container>
						<Row>
							<Col>
								{service_dict && service_dict[info.event.extendedProps.intervention_data.service.id] ?
									<ServiceBadge service={service_dict[info.event.extendedProps.intervention_data.service.id]} freq={true} />
									:
									<>
										prestazione rimossa
									</>}&nbsp;
								{info.event.extendedProps.intervention_data.enable_refill &&
									<FontAwesomeIcon icon="pump-soap" />
								}
							</Col>
						</Row>
						<Row>
							<Col>
								<SecondarySection ev={info.event} intervention_data={info.event.extendedProps.intervention_data} />
							</Col>
						</Row>
						<Row>
							<Col>
								<IsDone contract_execution_mode={contract.execution_mode} end={info.event.end} done={info.event.extendedProps.execution_done} legend={true} deleted={info.event.extendedProps.is_deleted} />
								<IsDeleted is_deleted={info.event.extendedProps.is_deleted} legend={true} />
								<IsModified is_modified={info.event.extendedProps.is_modified} legend={true} />
							</Col>
						</Row>
						<Row>
							<Col>
								{tt}
								<FontAwesomeIcon icon="user" /> &times; {info.event.extendedProps.intervention_data.operating_units}
							</Col>
						</Row>
						<Row>
							<Col>
								{info.event.extendedProps.structure_list &&
									<>
										<StructureGroupIcon level={0} />
										{info.event.extendedProps.structure_list.map((d, k) => (
											<Badge variant="light" key={k} className="mr-1">
												{structureElements.all_structure_dict && structureElements.all_structure_dict[d] ? structureElements.all_structure_dict[d].name : '---'}
											</Badge>
										))}
									</>}
							</Col>
						</Row>
						<Row>
							<Col>
								{info.event.extendedProps.department_list_id &&
									<>
										<StructureGroupIcon level={3} />
										{info.event.extendedProps.department_list_id.map((d, k) => (
											<Badge variant="light" key={k} className="mr-1">
												{department_dict[d] ? department_dict[d].name : '---'}
											</Badge>
										))}
									</>}
								{info.event.extendedProps.department_list &&
									<>
										<StructureGroupIcon level={3} />
										{info.event.extendedProps.department_list.map((d, k) => (
											<Badge variant="light" key={k} className="mr-1">
												{structureElements.all_department_dict[d] ? structureElements.all_department_dict[d].name : '---'}
											</Badge>
										))}
									</>}
							</Col>
						</Row>
						<Row>
							<Col>
								{room_count}
								&nbsp;
								locali

							</Col>
						</Row>
						<Row>
							<Col>
								{info.event.extendedProps.intervention_data.risk_area_summary ? <ItemProgress risk_list={info.event.extendedProps.intervention_data.risk_area_summary} item={info.event.extendedProps.intervention_data} ui_list={ui_list} /> : ''}
							</Col>
						</Row>
					</Container>
				</Popover.Content>
			</Popover>
		);

		if (info.view.type === 'dayGridMonth') { //------mensile

			if (info.event.extendedProps.type === 'ordinary_intervention') {

				return (
					<OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
						<div className="fc-content overflow-hidden" id={evtId} >
							<span className="fc-title">
								<IsDone contract_execution_mode={contract.execution_mode} end={info.event.end} done={info.event.extendedProps.execution_done} deleted={info.event.extendedProps.is_deleted} />
								<IsDeleted is_deleted={info.event.extendedProps.is_deleted} />
								<IsModified is_modified={info.event.extendedProps.is_modified} />
								<SecondarySection ev={info.event} intervention_data={info.event.extendedProps.intervention_data} short={true} />
								<Badge style={{ backgroundColor: info.backgroundColor }} className="text-light">{info.timeText}</Badge>{serviceColor(service_dict, info.event.extendedProps.intervention_data.service)} {info.event.extendedProps.intervention_data.name}
							</span>
						</div>
					</OverlayTrigger>
				)
			} else if (info.event.extendedProps.type === 'periodic_intervention') {
				return (
					<OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
						<div className="fc-content overflow-hidden" id={evtId} >
							<span className="fc-title">
								<IsDone contract_execution_mode={contract.execution_mode} end={info.event.end} done={info.event.extendedProps.execution_done} deleted={info.event.extendedProps.is_deleted} />
								<IsDeleted is_deleted={info.event.extendedProps.is_deleted} />
								<IsModified is_modified={info.event.extendedProps.is_modified} />
								{info.event.extendedProps.is_deleted ?
									<Badge variant="danger">
										<FontAwesomeIcon icon="trash" className="text-white" />
									</Badge>
									: ''}
								<Badge style={{ backgroundColor: info.backgroundColor }} className="text-dark">{info.timeText}</Badge>{serviceColor(service_dict, info.event.extendedProps.intervention_data.service)} {info.event.extendedProps.intervention_data.name}
							</span>
						</div>
					</OverlayTrigger>
				)
			} else if (info.event.extendedProps.type === 'requested_intervention') {
				return (
					<OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
						<div className="fc-content overflow-hidden" id={evtId} >
							<span className="fc-title">
								<IsDone contract_execution_mode={contract.execution_mode} end={info.event.end} done={info.event.extendedProps.execution_done} deleted={info.event.extendedProps.is_deleted} />
								<IsDeleted is_deleted={info.event.extendedProps.is_deleted} />
								<IsModified is_modified={info.event.extendedProps.is_modified} />
								{info.event.extendedProps.is_deleted &&
									<Badge variant="danger">
										<FontAwesomeIcon icon="trash" className="text-white" />
									</Badge>
								}
								<Badge style={{ backgroundColor: info.backgroundColor }} className="text-dark">{info.timeText}</Badge>{serviceColor(service_dict, info.event.extendedProps.intervention_data.service)} {info.event.extendedProps.intervention_data.name}
							</span>
						</div>
					</OverlayTrigger>
				)

			} else {
				return (
					<>
						<b>{info.timeText}</b>
						<i>{info.event.title}</i>
					</>
				)

			}
		} else if (info.view.type === 'timeGridWeek') {  //-------settimanale

			if (info.event.extendedProps.type === 'ordinary_intervention') {
				return (
					<OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
						<div className="fc-content overflow-hidden" id={evtId}>
							<span className="fc-title">
								<IsDone contract_execution_mode={contract.execution_mode} end={info.event.end} done={info.event.extendedProps.execution_done} deleted={info.event.extendedProps.is_deleted} />
								<IsDeleted is_deleted={info.event.extendedProps.is_deleted} />
								<IsModified is_modified={info.event.extendedProps.is_modified} />
								<SecondarySection ev={info.event} intervention_data={info.event.extendedProps.intervention_data} short={true} />
								{serviceColor(service_dict, info.event.extendedProps.intervention_data.service)} {info.event.extendedProps.intervention_data.name}
							</span>
						</div>
					</OverlayTrigger>
				)
			} else {
				return (
					<OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
						<div className="fc-content text-dark overflow-hidden" id={evtId}>
							<span className="fc-title">
								<IsDone contract_execution_mode={contract.execution_mode} end={info.event.end} done={info.event.extendedProps.execution_done} deleted={info.event.extendedProps.is_deleted} />
								<IsDeleted is_deleted={info.event.extendedProps.is_deleted} />
								<IsModified is_modified={info.event.extendedProps.is_modified} />
								{serviceColor(service_dict, info.event.extendedProps.intervention_data.service)} {info.event.extendedProps.intervention_data.service.name}
							</span>
						</div>
					</OverlayTrigger>
				)

			}



		} else { //-----------------------giornaliero

			if (info.event.extendedProps.type === 'ordinary_intervention') {
				try {
					return (
						<OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
							<div className="fc-content" id={evtId}>
								<IsDone contract_execution_mode={contract.execution_mode} end={info.event.end} done={info.event.extendedProps.execution_done} legend={true} deleted={info.event.extendedProps.is_deleted} />
								<IsDeleted is_deleted={info.event.extendedProps.is_deleted} legend={true} />
								<IsModified is_modified={info.event.extendedProps.is_modified} legend={true} />
								<SecondarySection ev={info.event} intervention_data={info.event.extendedProps.intervention_data} short={true} />
								<span className="fc-title"><b>{info.timeText}</b>: <ServiceBadge service={service_dict[info.event.extendedProps.intervention_data.service.id]} freq={true} /> {getValue(info.event.extendedProps, 'name')}</span>
							</div>
						</OverlayTrigger>
					)
				} catch (err) {
					return (
						<>
							<b>{info.timeText}</b>
							<br />
						</>
					)

				}
			} else if (info.event.extendedProps.type === 'periodic_intervention') {

				try {
					return (
						<OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
							<div className="fc-content" id={evtId}>
								<IsDone contract_execution_mode={contract.execution_mode} end={info.event.end} done={info.event.extendedProps.execution_done} legend={true} deleted={info.event.extendedProps.is_deleted} />
								<IsDeleted is_deleted={info.event.extendedProps.is_deleted} legend={true} />
								<IsModified is_modified={info.event.extendedProps.is_modified} legend={true} />
								<span className="fc-title"><b>{info.timeText}</b>: <ServiceBadge service={service_dict[info.event.extendedProps.intervention_data.service.id]} freq={true} /> {getValue(info.event.extendedProps, 'name')}</span>
							</div>
						</OverlayTrigger>
					)
				} catch (err) {
					return (
						<>
							<b>{info.timeText}</b>
							<br />
						</>
					)

				}

			}
			else {
				return (
					<>
						<b>{info.timeText}</b>
						<i>{info.event.title}</i>
					</>
				)

			}

		}
	}








	// --------------------------------------------------------


	//######/////####////calenda API external call
	function handleCalendarNext() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.next()
	}

	function handleCalendarPrev() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.prev()
	}

	function handleCalendarHome() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.today()
	}

	function handleCalendarChangeView(view) {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.changeView(view)
	}





	return (
		<>
			<ModalInterventionEvent ref={childRefEvent} t={t} ui_list={ui_list} canWrite={canWrite} />
			<ModalRequestGenerator ref={childRefRequest} t={t} ui_list={ui_list} canWrite={canWrite} permission_data={permission_data} />
			<Container className="titleStickyTop" fluid>
				<Row>
					<Col sm="6">
						<PageTitle title={t('global.pages.agenda_planning')} counter={<b>{title}</b>} />
					</Col>
					<Col className="text-right" >
						<ModalFilter t={t} defaultfilter={defaultFilter} calendarRef={calendarRef} className="mr-2" />
						<ButtonGroup className="ml-2 mr-2">
							<Button variant="secondary" size="sm" onClick={handleCalendarPrev} ><FontAwesomeIcon icon="angle-left" /></Button>
							<Button variant="secondary" size="sm" onClick={handleCalendarHome} ><FontAwesomeIcon icon="home" /></Button>
							<Button variant="secondary" size="sm" onClick={handleCalendarNext} ><FontAwesomeIcon icon="angle-right" /></Button>
						</ButtonGroup>
						<ButtonGroup>
							<Button variant="secondary" size="sm" onClick={() => handleCalendarChangeView('dayGridMonth')}><FontAwesomeIcon icon="calendar-alt" /> </Button>
							<Button variant="secondary" size="sm" onClick={() => handleCalendarChangeView('timeGridWeek')}><FontAwesomeIcon icon="calendar-week" /></Button>
							<Button variant="secondary" size="sm" onClick={() => handleCalendarChangeView('timeGridDay')}><FontAwesomeIcon icon="calendar-day" /></Button>
						</ButtonGroup>
						{curEventList.length > 0 &&
							<ModalExportPlanning uData={uData} element_list={curEventList} t={t} />
						}
						{showViewRequestedAssignment ?
							<Button variant="info" size="sm" onClick={() => setShowViewRequestedAssignment(!showViewRequestedAssignment)}><FontAwesomeIcon icon="eye-slash" /> Richieste da assegnare</Button>
							:
							<Button variant="info" size="sm" onClick={() => setShowViewRequestedAssignment(!showViewRequestedAssignment)}><FontAwesomeIcon icon="eye" />Richieste da assegnare</Button>
						}
					</Col>
				</Row>

			</Container>


			<Container fluid id="domContainer">
				<Row>
					<Col>
						{contract &&
							preloading ?
							<>
								<Spinner animation="border" role="status" />
								&nbsp;
								Caricamento in corso
							</>
							:
							<FullCalendar
								ref={calendarRef}
								plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
								headerToolbar={false}
								buttonText={
									{
										today: 'oggi',
										month: 'mese',
										week: 'settimana',
										day: 'giorno',
										list: 'lista'
									}

								}
								allDaySlot={false}
								allDayText={'tutto il giorno'}
								firstDay="1"
								locale='it'
								initialView='timeGridWeek'
								eventResize={false}
								editable={false}
								selectable={false}
								height='auto'
								selectMirror={true}
								dayMaxEvents={true}
								weekends={true}
								slotLabelFormat={{ hour: "numeric", minute: "2-digit", omitZeroMinute: false }}
								slotDuration="00:15:00"
								slotMinTime="06:00:00"
								slotMaxTime="21:00:00"
								initialEvents={getEventList}
								select={handleDateSelect}
								eventContent={renderEventContent}
								eventClick={handleEventClick}
								eventsSet={handleEvents}
							/* 
							eventAdd={function(){}}
							eventChange={function(){}}
							eventRemove={function(){}}
							*/
							/>
						}
					</Col>
					{showViewRequestedAssignment ?
						<Col sm="3">
							<RequestSection t={t} uData={uData} canWrite={canWrite} handleRequestAssignment={handleRequestAssignment} />
						</Col>
						: <></>}
				</Row>









			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> :

				<>
					{canWrite ?
						<Unlock />
						: <Lock />}
					{getElementCount({ t, element_list: curEventList, name: t('navs.registries.interventions') })}
				</>
			} />
		</>
	)
}

////////////////////////////////////////////////////////////////////////////////////////////////
const RequestSection = ({ t, uData, canWrite, handleRequestAssignment }) => {
	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)
	let load_page = false

	let { cid } = useParams();
	let contractId = cid
	const { service_dict } = useContext(GlobalDataContext)
	const [message_list, setMessageList] = useState([])
	const [sentmessage_list, setSentMessageList] = useState([])
	//	const ticket_type_dict = ticketType_dict({ t })
	const mailcount = 400

	useEffect(() => {

		const initMessageValues = async () => {
			let userData = uData
			console.log("mesg", contract, domain)
			if (contract && domain.length > 0) {
				console.log("MESSAGE INIT", domain, contract.id, localStorage.getItem('userId'), userData.id)
				let  _sent_message_list = await getTicketListForUser2(domain, contract.id, userData.id || localStorage.getItem('userId'), 'request')
				setMessageList((rc) => [...rc, ..._sent_message_list.filter(m => m.status_history && m.status_history[m.status_history.length - 1].code_id !== 'done' && m.status_history[m.status_history.length - 1].code_id !== 'assigned' && !m.urgent_execution)].sort(compareDateParam).reverse())

				console.log("SENT", _sent_message_list)
				let received_message_list = []
				let _received_message_list = []
				if (false) { //  _sent_message_list.error) {
					//					console.error("ERROR", _sent_message_list)
				} else {

					//				setSentMessageList(send_message_list.slice(1, mailcount))
					//					setSentMessageList(_sent_message_list.filter(m => m.status_history && m.status_history[m.status_history.length - 1].code_id !== 'done' && m.status_history[m.status_history.length - 1].code_id !== 'assigned' && !m.urgent_execution).sort(compareDateParam).reverse())
					if (userData.receiver_group) {
						let receive_message_list = []
						for (let g = 0; g < userData.receiver_group.length; g++) {
							let treceive_message_list = await getTicketListForGroup2(domain, contract.id, userData.receiver_group[g], mailcount)
							receive_message_list = [...receive_message_list, ...treceive_message_list]
						}
						console.log("receiver group")
						setMessageList((rc) => [...rc, ...receive_message_list.filter(m => m.status_history && m.status_history[m.status_history.length - 1].code_id !== 'done' && m.status_history[m.status_history.length - 1].code_id !== 'assigned' && !m.urgent_execution)].sort(compareDateParam).reverse())
					}
				}
				if (userData.messagegroups) {
					for (const group of userData.messagegroups) {
						console.log("GFROUP, ", group)
						let treceive_message_list = await getTicketListForGroup2(domain, contractId, group.message_group_id, mailcount, undefined, 'request')
						console.log("REC-MESSAGE", treceive_message_list)
						if (!treceive_message_list.error) {
							_received_message_list = [..._received_message_list, ...treceive_message_list.messageList]
						}
					}
					console.log("RECEIVED MESAGE LIST", _received_message_list)
					setMessageList((rc) => [...rc, ..._received_message_list.filter(m => m.status_history && m.status_history[m.status_history.length - 1].code_id !== 'done' && m.status_history[m.status_history.length - 1].code_id !== 'assigned' && !m.urgent_execution)].sort(compareDateParam).reverse())
				}
			}
		}
		console.log('HOME loadingcontract', contract)
		if (contract) {
			initMessageValues()
		}
	}, [contract])

	useEffect(() => {
		console.log("UPDATE MESSAGE LIST", message_list)

	}, [message_list])

	return (
		message_list.length > 0 ?
			<>
				<Row>
					<Col className="text-light text-center bg-info mb-1 mr-1  ">
						Richieste da assegnare <Badge variant="light">{message_list.length}</Badge>
					</Col>
				</Row>
				<Row>
					<Col className="bg-secondary text-light mr-1">
						Locale <br />
						Prestazione
					</Col>
					<Col className="bg-secondary text-light mr-1 text-center " sm="2">
						Data richiesta
					</Col>
					<Col className="bg-secondary text-light mr-1 p-0 text-center" sm="1">
						Azioni
					</Col>
				</Row>
				{message_list.map((one_ticket, k) => (
					<Row key={k} className="element_row border-bottom ">
						<Col>
							<TicketPath ticket={one_ticket} t={t} />
							{one_ticket.service ?
								<>
									<br />
									<ServiceBadge service={service_dict[one_ticket.service.id]} freq={true} />
								</>
								: ''}
						</Col>
						<Col sm="2" className="text-center p-1">
							{format(myTimestampToDate(one_ticket.createdAt), 'dd/MM/yyyy HH:mm')}
						</Col>
						<Col sm="1" className="p-1">
							{canWrite ?
								<Button size="sm" variant="info" onClick={() => handleRequestAssignment(one_ticket)} >
									{one_ticket.room_list && one_ticket.room_list.filter(r => r.assigned).length > 0 ?
										<FontAwesomeIcon icon="cog" />
										:
										<FontAwesomeIcon icon="cogs" />
									}
								</Button>
								: <></>}
						</Col>
					</Row>
				))}
			</>
			:
			<Row>
				{console.log("RENDER MSG", message_list)}
				<Col className="text-italic"> Nessuna richiesta da assegnare</Col>
			</Row>


	)
}





////////////////////////////////////////////////////////////////////////////////////////////////
const ElementFilterButton = ({ d, filterAppliedList, department_dict, updateAppliedFilter, structureElements, show_inactive }) => {
	try {
		if (show_inactive) {

			return (

				<Button className="m-1" size="sm" variant={filterAppliedList.department.indexOf(department_dict[d].id) === -1 ? "outline-secondary" : "secondary"} onClick={(e) => updateAppliedFilter('department', department_dict[d].id)}>
					{getValue(getSelectedElement(department_dict[d].parent_list[0], structureElements.structure_list, 'id'), 'name')}:
					{department_dict[d].name}
				</Button>
			)
		} else {
			return (

				<Button size="sm" className={filterAppliedList.department.indexOf(department_dict[d].id) !== -1 ? '' : 'd-none'}
					variant={filterAppliedList.department.indexOf(department_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
					onClick={(e) => updateAppliedFilter('department', department_dict[d].id)}>
					{department_dict[d].name} <FontAwesomeIcon icon="times" />
				</Button>
			)

		}
	} catch (err) {
		return (

			<Button className="m-1" size="sm" variant="outline-secondary" disabled >
				{d}
			</Button>
		)

	}

}

//------------------
function checkEventPermission(e, permission_department_list) {
	console.log("CHECK EVENT PERMISSION: Department", e.extendedProps.department_list, permission_department_list)
	try {
		console.log("check permission departmentt", e.extendedProps.department_list.some(d => (permission_department_list.indexOf(d)) >= 0))
	} catch (err) {
		console.log(err)
	}
	if (e.extendedProps.department_list && e.extendedProps.department_list.some(d => (permission_department_list.indexOf(d)) >= 0)) {
		console.log("IS PRESENT", e.extendedProps.department_list)
		return true
	} else {
		return false
	}

}


////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
const ModalFilter = ({ t, defaultfilter, event_list, calendarRef }) => {
	const { service_dict, structureElements, all_workarea_dict } = useContext(GlobalDataContext)

	const [showFilter, setShowFilter] = useState(false)


	const service_type = serviceType_list({ t })


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			filterAppliedList = { ...filterAppliedList, [section]: [] }
		} else {
			filterAppliedList = (defaultfilter)
		}
		let calendarApi = calendarRef.current.getApi()
		calendarApi.refetchEvents()
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem, is_bool) => {
		let f_data = elem
		if (!is_bool) {
			const c = filterAppliedList[section].indexOf(elem)
			console.log('index', c)
			f_data = [...filterAppliedList[section]]
			if (c === -1) {
				f_data.push(elem)
			} else {
				f_data.splice(c, 1)
			}
		}
		//   setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
		filterAppliedList = { ...filterAppliedList, [section]: f_data }
		let calendarApi = calendarRef.current.getApi()
		//    setShowFilter(false)
		calendarApi.refetchEvents()
	}


	function checkInterventionPresence(d) {
		for (let i in intervention_dict) {
			if (intervention_dict[i].service && intervention_dict[i].service.id === d) {
				return true
			}
		}
		return false
	}

	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				<Button size="sm" className={filterAppliedList.type.indexOf('ordinary_intervention') !== -1 ? '' : 'd-none'}
					variant={filterAppliedList.type.indexOf('ordinary_intervention') === -1 ? "outline-info" : "secondary"}
					onClick={(e) => updateAppliedFilter('type', 'ordinary_intervention')}>
					{t('global.pages.ordinaryintervention')} <FontAwesomeIcon icon="times" />
				</Button>
				<Button size="sm" className={filterAppliedList.type.indexOf('periodic_intervention') !== -1 ? '' : 'd-none'} variant={filterAppliedList.type.indexOf('periodic_intervention') === -1 ? "outline-info" : "secondary"} onClick={(e) => updateAppliedFilter('type', 'periodic_intervention')}>{t('global.pages.programmedintervention')} <FontAwesomeIcon icon="times" /></Button>
				{filterAppliedList.type.length > 0 ?
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('type')}>Tipologia <FontAwesomeIcon icon="times" /></Button>
					: ''}
				{filterAppliedList.floor.length > 0 &&
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('floor')}>Piano<FontAwesomeIcon icon="times" /></Button>
				}
				{filterAppliedList.pavillon.length > 0 &&
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}>Ala/padiglione<FontAwesomeIcon icon="times" /></Button>
				}
				{filterAppliedList.department.length > 0 &&
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('department')}>Reparto<FontAwesomeIcon icon="times" /></Button>
				}
				{filterAppliedList.workarea.length > 0 ?
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('workarea')}>Area di lavoro<FontAwesomeIcon icon="times" /></Button>
					: ''}
				{filterAppliedList.workarea_referent.length > 0 ?
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('workarea_referent')}>Referente<FontAwesomeIcon icon="times" /></Button>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="xl">
				<Modal.Header>
					<Modal.Title>
						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>

						<Row>
							<Col>
								{t('global.labels.type')}
							</Col>
						</Row>
						<Row>
							<Col>
								<ButtonGroup>
									{service_type.map((g, k) => (
										<Button key={k} size="sm" variant={filterAppliedList.type.indexOf(g.intervention_id) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('type', g.intervention_id)}>
											<FontAwesomeIcon icon={g.icon} /> {g.label}
										</Button>
									))}
									{filterAppliedList.type.length > 0 ?
										<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('type')}><FontAwesomeIcon icon="times" /></Button>
										: ''}
								</ButtonGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.structure')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structure_dict).map((d, k) => (
									<Button className="m-1" key={k} size="sm" variant={!structure_dict[d] || filterAppliedList.structure.indexOf(structure_dict[d].id) === -1 ? "outline-secondary" : "secondary"} onClick={(e) => structure_dict[d] && updateAppliedFilter('structure', structure_dict[d].id)}>
										{structure_dict[d] ? structure_dict[d].name : '----'}
									</Button>
								))}
								{filterAppliedList.structure.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.workarea_referent')}
							</Col>
						</Row>
						<Row>
							{Object.values(workarea_referent_dict).length > 0 ?
								<Col>
									{Object.values(workarea_referent_dict).map((d, k) => (
										<Button className="m-1" key={k} size="sm" variant={filterAppliedList.workarea_referent.indexOf(d.user_id) === -1 ? "outline-secondary" : "secondary"} onClick={(e) => updateAppliedFilter('workarea_referent', d.user_id)}>
											{d.user_displayName}
										</Button>
									))}
									{filterAppliedList.workarea_referent.length > 0 &&
										<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('workarea_referent')}><FontAwesomeIcon icon="times" /></Button>
									}
								</Col>
								:
								<Col className="font-italic ml-2">
									Nessun referente inserito
								</Col>
							}
						</Row>
						<Row>
							<Col>
								{t('global.labels.workarea')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(workarea_dict).map((d, k) => (
									<Button className="m-1" key={k} size="sm" variant={filterAppliedList.workarea.indexOf(d) === -1 ? "outline-secondary" : "secondary"} onClick={(e) => updateAppliedFilter('workarea', d)}>
										{workarea_dict[d]}
									</Button>
								))}
								{filterAppliedList.workarea.length > 0 &&
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('workarea')}><FontAwesomeIcon icon="times" /></Button>
								}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.pavillon')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
									<Button size="sm" key={k} className="m-1"
										variant={filterAppliedList.pavillon.indexOf(structureElements.all_pavillon_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('pavillon', structureElements.all_pavillon_dict[d].id)}
									>
										{structureElements.all_pavillon_dict[d].name}
									</Button>
								))}
								{filterAppliedList.pavillon.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.floor')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_floor_dict).map((d, k) => (
									<ElementButtonFilter  
									key={k} 
										is_filter={filterAppliedList.floor.indexOf(structureElements.all_floor_dict[d].id) === -1} 
										el_data={structureElements.all_floor_dict[d]}
										parent_data={structureElements.all_pavillon_dict[structureElements.all_floor_dict[d].parent_list[1]]}
										el_type="floor"
										updateAppliedFilter={updateAppliedFilter}
										/>
								))}
								{filterAppliedList.floor.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('floor')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.department')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(department_dict).map((d, k) => (
									<Button className="m-1" key={k} size="sm" variant={!department_dict[d] || filterAppliedList.department.indexOf(department_dict[d].id) === -1 ? "outline-secondary" : "secondary"} onClick={(e) => updateAppliedFilter('department', department_dict[d].id)}>
										<i>{department_dict[d] && getValue(getSelectedElement(department_dict[d].parent_list[0], structureElements.structure_list, 'id'), 'name')}</i> |
										{department_dict[d] ? department_dict[d].name : '----'}
									</Button>
								))}
								{filterAppliedList.department.length > 0 &&
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('department')}><FontAwesomeIcon icon="times" /></Button>
								}
							</Col>
						</Row>
						<Row>
							<Col>
								<FontAwesomeIcon icon="trash" /> {t('global.labels.deleted')}
							</Col>
						</Row>
						<Row>
							<Col>
								<Button size="sm" variant={filterAppliedList.is_deleted ? "secondary" : "outline-secondary"} onClick={() => updateAppliedFilter('is_deleted', true, true)} >Visualizza cancellati</Button>
								<Button size="sm" variant={!filterAppliedList.is_deleted ? "secondary" : "outline-secondary"} onClick={() => updateAppliedFilter('is_deleted', false, true)} >Nascondi cancellati</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}




const ElementButtonFilter = ({is_filter, el_data, parent_data, el_type, updateAppliedFilter}) => {
	return (

									<Button size="sm" className="m-1"
										variant={is_filter ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter(el_type, el_data.id)}
									>
										{parent_data?.name} | {el_data.name}
									</Button>
	)

}


const serviceColor = (service_dict, s) => {

	try {
		const service = service_dict[s.id] || s

		return (
			<Badge style={{
				background:
					`rgba(${getValue(service.color, 'r')}, ${getValue(service.color, 'g')}, ${getValue(service.color, 'b')}, ${getValue(service.color, 'a')})`,
				border: '1px solid #fff'
			}}>&nbsp;</Badge>
		)
	} catch (err) {
		console.log(err)
		return (<Badge></Badge>)
	}

}

































export default translate()(PlanningView)
