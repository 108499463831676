import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, ButtonGroup, Button, Spinner, Card } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import LocaleContext from '../components/contexts/locale.js'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import EditContext, { EditProvider } from "../components/contexts/edit.js"
import DomainContext from '../components/contexts/domainContext'
import { InitContractHelper, ReloadStructureElements } from "../util/contract"
import { ModalStructure } from "../components/ModalStructure"

import { checkComponentPresence, compareName, compareNameB, getFloatNumber, getBaseObjectValue, getElementCount, getStructureFromWorkareas } from "../util/ui_utils.js"
import { renderPhoneList, RiskareaBadge, StructureGroupIcon } from '../components/StructureGraphicElements.js'

import ListStructureGroupItem from "../components/ListStructureGroupItem.js"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"




import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import {
	deleteStructure as deleteStructure2,
	getAllDepartmentName as getAllDepartmentName2,

} from '../Firebase2/Storage2/dbBaseCompany';
import {
	getAllRoomList as getAllRoomList2
} from '../Firebase2/Storage2/dbRoom';
import { processRoomsStatus, reconcileRooms } from '../components/FirebaseApi/roomStatus_api'
import { getInterventionById } from '../Firebase2/Storage2/dbIntervention'
import { ModalPrintQrcode } from '../components/modal/ModalPrintQrcode.js';
import { ModalDepartmentSelector } from '../components/modal/ModalDepartmentSelector.js';
import { ModalExportStructure } from '../components/modal/ModalExportStructure.js';
import { getFailureTypeList } from '../Firebase2/Storage2/dbFailureType.js';



const newstructure = {
	name: '',
	code: '',
	type: {},
	vat: '',
	cf: '',
	address: {
		street: '',
		number: '',
		zip: '',
		city: '',
		state: ''
	},
	referent_list: [],
	phone_list: [],
	email: '',
	pec: '',
	website: '',
	logo: '',
	sdi: '',
	note: '',
	parent_list: [],
	mq: 0,
	risk_area_summary: [],
	type_name: "structure",
	level: 0,
	childs: [],
	risk_area_summary_decommissioned: { total: { room_count: 0, size: 0 } },
	risk_area_summary_suspended: { total: { room_count: 0, size: 0 } },
	risk_area_summary_active: { total: { room_count: 0, size: 0 } },
	risk_area_summary: { total: { room_count: 0, size: 0 } },
}



//const contractId = sessionStorage.getItem('contractId')

let structure_list = []
try {
	structure_list = JSON.parse(sessionStorage.getItem('structure_list'))
} catch (err) {
	console.log(err)

}



const now = new Date()

const StructureView = ({ t, uData }) => {
	let load_page = false

	const { domain, domain_data } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contract, structureElements, roomtype_list, all_workarea_dict, risk_area_list, risk_area_dict, messagegroup_list } = useContext(GlobalDataContext)
	//	const edit = useContext(EditContext)

	const { initContract } = InitContractHelper()
	let { cid } = useParams();

	const contractId = cid
	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const [element_list, setElementList] = useState([])
	const [loading, setLoading] = useState(false)
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)
	const [permission_department_list, setPermissionDepartment] = useState([])


	const initValues = async () => {
		const userData = uData
		console.log("LICENCE", domain_data.licence_status)
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('companies') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		if (contract && domain.length > 0) {
			console.log("DOMAIN", domain)
			const referent_role_list = await getBaseObjectTypeList2(domain, 'referent_role')
			let floor_list = await getBaseObjectTypeList2(domain, 'floorings')
			let floor_status_list = await getBaseObjectTypeList2(domain, 'element_status')
			let failure_presets = await getFailureTypeList(domain, contractId)
			floor_list.sort(compareNameB(locale))
			floor_status_list.sort(compareNameB(locale))

			setUiList({ ...ui_list, referent_role_list, risk_area_dict, risk_area_list, floor_list, floor_status_list, roomtype_list, receiver_group_list: messagegroup_list, failure_presets })
			let _structure_list = structureElements.structure_list
			//			structure_list = await getStructureList2(domain, contract.id)
			/* 			if (structure_list.error) {
							console.log(structure_list)
			
						} else {
							console.log(structure_list)
			
						} */
			console.log("structure list filtered", _structure_list)
			let edit_permission_department_list = [...permission_department_list]
			let permission_structure_list = []
			if (userData && userData.contract_dict && userData.contract_dict[contractId]) {
				console.log('userData contract', userData.contract_dict[contractId])
				if (userData.contract_dict[contractId][0].mode === 'workarea') {
					const permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
					permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
					console.log("permission_structure_list", permission_structure_list)
					_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
				} else {
					if (userData.contract_dict[contractId][0].structure) {
						if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
							edit_permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
							console.log("permission department_list", edit_permission_department_list, userData.contract_dict[contractId][0].department)
						}
						if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
							permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
							_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

						}
						console.log("permission structure_list", permission_structure_list)
					}
				}
			}
			setPermissionDepartment(edit_permission_department_list)
			if (_structure_list && _structure_list !== null) {
				_structure_list.sort(compareName)
				for (let so = 0; so < _structure_list.length; so++) {
					const s = _structure_list[so]

					let return_data = await getAllDepartmentName2(s.id)
					// referenti
					let referent_list = []
					return_data.filter(dp => (dp.referent_list)).map((dp => {
						let rl = [...dp.referent_list]
						rl.map((one_r) => one_r.department = { name: dp.name, id: dp.id, pavillon_id: dp.parent_list[1] })
						referent_list = [...referent_list, ...rl]
					}))
					_structure_list[so].department_referent_list = referent_list


				} // <<<for structure_list
			} // <<< if (structure_list && structure_list!== null)
			const filtered_w_list = Object.values(all_workarea_dict).filter(w => w.structure_list.some(s => _structure_list.map(ss => ss.id).indexOf(s) >= 0))
			console.log("FILTER", all_workarea_dict, filtered_w_list)
			let _intervention_dict = {}
			for (const w of filtered_w_list) {
				console.log("WORK", w.id, w.name)
				if (w.intervention_list) {
					for (const intervention_id of w.intervention_list) {
						//console.log("INTERVENTION_id O", intervention_id)
						const intervention = await getInterventionById(domain, contractId, intervention_id)
						_intervention_dict[intervention_id] = intervention
					}
				}
				if (w.periodicintervention_list) {

					for (const intervention_id of w.periodicintervention_list) {
						console.log("INTERVENTION_id P", intervention_id)
						const intervention = await getInterventionById(domain, contractId, intervention_id)
						_intervention_dict[intervention_id] = intervention
					}
				}


			}
			console.log("INTERVNETION", _intervention_dict)
			setUiList((prev) => ({ ...prev, intervention_dict: _intervention_dict }))

			setElementList(_structure_list)
			console.log(_structure_list)

		}

		console.log("endLoading")
		setLoading(false)
	}

	const onUpdateData = (data, b) => {
		setUiList({ ...ui_list, [data]: b.list })
	}






	useEffect(() => {
		async function preloadData() {
			const contractId = cid
			load_page = true
			const ret_data = await initContract(contractId)
			console.log("init contract return", ret_data)

		}
		if (!contract && !load_page) {
			preloadData()
		}
	}, [])



	useEffect(() => {
		initValues()
	}, [contract, uData])


	useEffect(() => {
		console.log('====RELOAD STRUCTURE=====')
		initValues()
	}, [structureElements])


	const updateStructure = (tmp_structure_list) => {
		tmp_structure_list = tmp_structure_list.sort(compareName)
		//save
		console.log("UPDATE STRUCTURE LIST")
		setElementList(tmp_structure_list)
		//		functions.edit({ name: 'structure_list', value: tmp_structure_list })
		sessionStorage.setItem("structure_list", JSON.stringify(tmp_structure_list))

	}

	const handleDeleteStructure = (el) => {
		const cb = async () => {
			let return_data = await deleteStructure2(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				console.log('delete', el)
				let editlist = [...element_list]
				//				let editlist = company.structure_list
				const c = checkComponentPresence(el, element_list, 'id')
				if (c !== -1) {

					editlist.splice(c, 1)
				}
				setElementList(editlist)
				console.log('newlist', editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('structure.messages.delete_structure_title'), t('structure.messages.delete_structure', { name: el.name }), cb)
	}

	const importUrl = "/#/" + contractId + '/imports'

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<DeleteConfirmationSection ref={childRefDelete} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('global.pages.structures')} />
					</Col>
					{canWrite &&
						<Col className="text-right">
							<ButtonGroup>
								<ModalExportStructure t={t} />
								<Button variant="outline-info" href={importUrl}><FontAwesomeIcon icon="file-import" /> {t('company.labels.import')}</Button>
								<ModalStructure ui_list={ui_list} is_new={true} t={t} canWrite={canWrite} updateStructure={updateStructure} element_list={element_list} />
							</ButtonGroup>
						</Col>
						}

				</Row>

			</Container>
			<Container fluid>
				{loading ?

					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> {t('global.loading')}

						</Col>
					</Row>
					:
					<>
						<Row>

							{element_list && element_list.length > 0 ?

								(element_list.map((i, key) => (
									<Col key={key} md="12" xl="6" className="mt-2 p-3" >
										<Card className="h-100" key={key}>
											<Card.Header >
												<StructureGroupIcon level={0} />
												<b>{i.name}</b>
											</Card.Header>
											<Card.Body>
												<Row key={key} className="border-bottom mt-1">
													{i.address.street && i.address.street.length > 0 ?
														<Col>{i.address.street} {i.address.city} ({i.address.state})</Col>
														: ''
													}
												</Row>

												{permission_department_list.length === 0 ?
													<ElementRADetails s={i} ui_list={ui_list} t={t} />
													: ''}
												<ReferentList s={i} t={t} />
											</Card.Body>
											<Card.Footer>
												<Row >
													<Col className="text-right">
														<ButtonGroup>
															<ModalStructureSummary structure={i} ui_list={ui_list} canWrite={canWrite} t={t} permission_department_list={permission_department_list} />
															<EditProvider>
																<ModalDepartmentSelector t={t} ui_list={ui_list}  structure={i} />
																<ModalStructureComposition structure={i} ui_list={ui_list} is_new={false} t={t} canWrite={canWrite} updateStructure={updateStructure} element_list={element_list} permission_department_list={permission_department_list} isAdmin={uData.isGlobalAdmin} />
															</EditProvider>
															<ModalStructure structure={i} ui_list={ui_list} is_new={false} t={t} canWrite={canWrite} updateStructure={updateStructure} element_list={element_list} />

															{canWrite &&
																<Button size="sm" variant="danger" onClick={() => handleDeleteStructure(i)}><FontAwesomeIcon icon="trash" /> </Button>
																}
														</ButtonGroup>
													</Col>

												</Row>

											</Card.Footer>
										</Card>
									</Col>
								)))
								:
								<Col>
									<i>{t('company.messages.no_structure')}</i>
								</Col>
							}
						</Row>
					</>
				}





			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('global.labels.structure') })} />
		</>
	);
}

export default translate()(StructureView)














//////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalStructureComposition = ({ is_new, structure, ui_list, t, updateStructure, element_list, canWrite, permission_department_list, isAdmin }) => {
	const { domain } = useContext(DomainContext)
	const { structureElements, contract, setStructureElements } = useContext(GlobalDataContext)
	const edit = useContext(EditContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const contractId = contract.id
	const [showStructureCompositionEdit, setShowStructureCompositionEdit] = useState(false)
	const [curStructure, setCurStructure] = useState(newstructure)
	const [editedElement, setEditedElement] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [loadingOne, setLoadingOne] = useState(false)
	const [mystructureElements, setPageStructureElements] = useState({
		pavillon_list: []
	})


	const handleCloseStructureComposition = () => setShowStructureCompositionEdit(false);

	const handleShowEditStructureComposition = async () => {
		edit.resetEditList([])
		edit.setEditParamList([])
		setLoadingOne(true)
		setShowStructureCompositionEdit(true)
		console.log('edit structure composition', structure, structureElements)
		setCurStructure(structure)
		const pavillon_list = structureElements.pavillon_list
		const floor_list = structureElements.floor_list
		const department_list = structureElements.department_list
		console.log(department_list, pavillon_list, floor_list)
		let room_list = []
		if (permission_department_list && permission_department_list.length > 0) {
			for (const dep_id of permission_department_list) {
				const tmp_room_list = await getAllRoomList2({ departmentId: dep_id })
				room_list = [...room_list, ...tmp_room_list]
			}

		} else {
			room_list = await getAllRoomList2({ structureId: structure.id })
		}
		//		console.log(room_list)
		setPageStructureElements({ pavillon_list, floor_list, department_list, room_list, all_department_dict: structureElements.all_department_dict, all_pavillon_dict: structureElements.all_pavillon_dict, all_floor_dict: structureElements.all_floor_dict })
		setEditedElement(false)
		setLoadingOne(false)
	}

	useEffect(() => {
		console.log(edit.editParentFunctions)

	}, [edit.editParentFunctions])
	/*
	
		const handleSaveStructure = async (close) => {
			console.log("SAVE STRUCTURE", curStructure)
			let return_data = await editStructure2(domain, contractId, curStructure.id, curStructure)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let tmp_structure_list = [...element_list]
				const c = checkComponentPresence(curStructure, element_list, 'id')
				tmp_structure_list.splice(c, 1, curStructure)
				updateStructure(tmp_structure_list)
				setEditedElement(false)
				if (close) {
					setShowStructureCompositionEdit(false);
				}
			}
		}
	
	*/



	const reconcile = async () => {
		setIsSaving(true)
		await processRoomsStatus(domain, contractId, curStructure.id)
		await reconcileRooms(domain, contractId, curStructure.id)
		const tmp_structure_list = await ReloadStructureElements(domain, contractId)
		setStructureElements(tmp_structure_list)
		//		console.log("STRUCTURE_LIST", tmp_structure_list)
		//			setElementList(tmp_structure_list)
		//			updateStructure(tmp_structure_list)
		setIsSaving(false)
	}

	const handleSave = async () => {
		setIsSaving(true)
		let tmp_structure_list = [...element_list]
		console.log("curStructure", curStructure)
		const c = checkComponentPresence(curStructure, element_list, 'id')
		tmp_structure_list.splice(c, 1, curStructure)
		updateStructure(tmp_structure_list)
		//const r_data = await reconcileRooms(domain, contractId, curStructure.id)
		//		await reloadStructureElements
		//		const tmp_structure_list = await ReloadStructureElements()
		//		console.log("STRUCTURE_LIST", tmp_structure_list)
		//			setElementList(tmp_structure_list)
		//			updateStructure(tmp_structure_list)
		setIsSaving(false)
		setEditedElement(false)
	}

	

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<DeleteConfirmationSection ref={childRefDelete} />
			<Button size="sm" variant="outline-info" onClick={() => handleShowEditStructureComposition()}>
				{loadingOne ? <Spinner animation="border" role="status" size="sm">
					<span className="sr-only">Loading...</span>
				</Spinner> :

					<FontAwesomeIcon icon="hospital-alt" />
				}
			</Button>
			<Modal show={showStructureCompositionEdit} onHide={handleCloseStructureComposition} dialogClassName="custom-modal" bsclass="full-modal" backdrop="static" scrollable={true}>
				{loadingOne ?
					<Modal.Body>
						<Container fluid>
							<Row>
								<Col className="text-center">
									<Spinner animation="border" role="status">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</Col>
							</Row>
							<Row>
								<Col className="text-center">
									Caricamento in corso <b>{curStructure.name}</b>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
					:
					<>
						<Modal.Header className={editedElement ? "bg-warning" : ""}>
							<Modal.Title>
								<FontAwesomeIcon icon="pencil-alt" /><span className="text-info"> {t('company.labels.edit_structure_composition')}</span> | <FontAwesomeIcon icon="hospital-alt" /> <b>{curStructure.name}</b> {editedElement ? <FontAwesomeIcon icon="save" /> : ''}

								<ButtonGroup size="sm">
									<Button onClick={() => edit.setEditMultiple(false)} variant={edit.editMultiple ? 'outline-info' : 'info'} ><FontAwesomeIcon icon={["far", "square"]} /> {t('structure.labels.singleedit')}</Button>
									<Button onClick={() => edit.setEditMultiple(true)} variant={!edit.editMultiple ? 'outline-info' : 'info'} ><FontAwesomeIcon icon={["far", "clone"]} /> {t('structure.labels.multiedit')}</Button>
									{edit.editMultiple ?
										<>
											<Button variant={"outline-info"} disabled>
												{edit.editList.length}
											</Button>
											<Button variant={"outline-info"} onClick={() => edit.resetEditList([])}>
												<FontAwesomeIcon icon="times" />
											</Button>{edit.editList.length>0?
											<ModalPrintQrcode t={t} />
											:<></>}
										</>
										: ''
									}
								</ButtonGroup>
							</Modal.Title>
						</Modal.Header>
						<Modal.Header className="bg-light">
							<Container fluid>
								<Row className="p-1 text-light">
									<Col className="bg-secondary ml-1"> {t('global.labels.name')}</Col>
									<Col className="bg-secondary ml-1 text-center" sm="1"> {t('structure.labels.cdc')}</Col>
									<Col className="bg-secondary ml-1 p-0 text-center" sm="1"> m<sup>2</sup></Col>
									<Col className="bg-secondary ml-1 p-0 text-center" sm="1"> Valore</Col>
									<Col className="bg-secondary ml-1  text-center" sm="2">
											{contract && contract.type === 'civil' ?
												t('global.labels.standard_cleaning_short')
												:
												t('global.labels.risk_area_short')
											}
										 </Col>
									<Col className="bg-secondary ml-1 text-center" sm="2">{t('global.labels.actions')}</Col>
								</Row>
							</Container>
						</Modal.Header>
						<Modal.Body>
							<Container fluid >
								<ListStructureGroupItem item={curStructure} is_summary={false} parent={curStructure} setParent={setCurStructure} level={0}
									functions={{ setedit: setEditedElement, setsaving: setIsSaving }}
									structureId={curStructure.id} contractId={contractId} ui_list={ui_list} structureElements={mystructureElements} canWrite={canWrite} permission_department_list={permission_department_list} />
							</Container>
						</Modal.Body>
						<Modal.Footer>

							<Button variant="secondary" disabled={isSaving} onClick={handleCloseStructureComposition}>
								{t('modal.close')}
							</Button>

							<Button disabled={isSaving} variant="outline-primary" onClick={handleSave}>
								{t('modal.save')}
								{isSaving ?
									<Spinner size="sm" animation="border" role="status" />
									: <FontAwesomeIcon icon="save" />
								}
							</Button>
							{isAdmin ?
								<Button disabled={isSaving} variant="outline-warning" onClick={reconcile} >
									{t('structure.buttons.reconcile')}
									{isSaving ?
										<Spinner size="sm" animation="border" role="status" />
										: <FontAwesomeIcon icon="save" />
									}
								</Button>
								: ''}


						</Modal.Footer>
					</>
				}
			</Modal>
		</>
	)
}



//////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalStructureSummary = ({ structure, ui_list, t, permission_department_list }) => {
	const [showStructureSummary, setShowStructureInfo] = useState(false)
	const [curStructure, setCurStructure] = useState(newstructure)

	//----structure info detail
	const handleCloseStructureSummary = () => setShowStructureInfo(false);
	const handleShowInfoStructure = () => {
		console.log('info structure', structure)
		setCurStructure(structure)
		setShowStructureInfo(true)
	}
	return (
		<>

			<Button size="sm" variant="outline-primary" onClick={() => handleShowInfoStructure()}><FontAwesomeIcon icon="info-circle" /> <FontAwesomeIcon icon="building" /></Button>

			<Modal show={showStructureSummary} onHide={handleCloseStructureSummary} dialogClassName="custom-modal" bsclass="full-modal" backdrop="static" scrollable={true}>
				<Modal.Header closeButton>
					<Modal.Title><FontAwesomeIcon icon="info-circle" /> {curStructure.name} | {t('global.labels.pavillon_details')} </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid >
						<PavillonDetails element={curStructure} t={t} ui_list={ui_list} permission_department_list={permission_department_list} />
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseStructureSummary}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ElementRADetails = ({ s, ui_list, t }) => {
	const { contract } = useContext(GlobalDataContext)



	const renderOneRA = (id, s, s_act, s_dec, s_susp) => {


		//		console.log(id, s, s_act, s_dec, s_susp)
		const ss_dec = s_dec || { size: 0, room_count: 0 }
		const ss_act = s_act || { size: 0, room_count: 0 }
		const ss_susp = s_susp || { size: 0, room_count: 0 }

		try {
			const riskarea = ui_list.risk_area_dict[id]
			if (riskarea) {

				return (
					<>
						<Col sm="2">
							<RiskareaBadge riskarea={riskarea} />
						</Col>

						<Col>
							<Row>
								<Col className="p-2">{s.room_count}</Col>
								<Col className="text-right p-2">
									{s.size.toFixed(2)}
								</Col>
							</Row>
						</Col>
						<Col>
							<Row>
								<Col className="p-2 font-weight-bold">{ss_act.room_count}</Col>
								<Col className="text-right p-2 font-weight-bold">
									{ss_act.size.toFixed(2)}
								</Col>
							</Row>
						</Col>
						<Col>
							<Row>
								<Col className={`text-center p-2 ` + (ss_dec.room_count > 0 ? 'text-dark' : ' text-muted')}>{ss_dec.room_count}</Col>
								<Col className={`text-right p-2 ` + (ss_dec.room_count > 0 ? 'text-dark' : ' text-muted')}>
									{ss_dec.size.toFixed(2)}
								</Col>
							</Row>
						</Col>
						<Col>
							<Row>
								<Col className={`text-center p-2 ` + (ss_susp.room_count > 0 ? 'text-danger' : ' text-muted')}>{ss_susp.room_count}</Col>
								<Col className={`text-right p-2 ` + (ss_susp.room_count > 0 ? 'text-danger' : ' text-muted')}>
									{ss_susp.size.toFixed(2)}
								</Col>
							</Row>
						</Col>



					</>
				)
			} else {
				return ''
			}
		} catch (err) {
			return <></>
		}

	} //<<<< renderOneRA





	if (s.risk_area_summary) {
		/* 		if (!s.risk_area_summary.total){
					s.risk_area_summary = {...s.risk_area_summary, total:{room_count:s.room_count, size:s.size}}
				} */
		console.log(s, s.risk_area_summary)
		if (!s.risk_area_summary_active) {
			s.risk_area_summary_active = {}
		}
		if (!s.risk_area_summary_active.total) {
			console.log("MISSING DATA", s.name)
			if (s.risk_area_summary_suspended && s.risk_area_summary_decommissioned) {

				s.risk_area_summary_active = {
					...s.risk_area_summary_active, total:
					{
						room_count: s.room_count - s.risk_area_summary_decommissioned.total.room_count - s.risk_area_summary_suspended.total.room_count,
						size: getFloatNumber(s.size) - getFloatNumber(s.risk_area_summary_decommissioned.total.size) - getFloatNumber(s.risk_area_summary_suspended.total.size)
					}
				}
			} else {
				s.risk_area_summary_active = {
					...s.risk_area_summary_active, total:
					{
						room_count: s.room_count,
						size: getFloatNumber(s.size)
					}
				}
				s.risk_area_summary_suspended = { total: { room_count: 0, size: 0 } }
				s.risk_area_summary_decommissioned = { total: { room_count: 0, size: 0 } }

			}


		}


		return (
			<>
				<Row>
					<Col className="bg-secondary text-center text-white">
						Riepilogo locali
					</Col>
				</Row>
				<Row className="p-1 section_title mb-1">
					<Col className="bg-light text-secondary" sm="2" >
						{contract.type === 'civil' ?
							t('global.labels.standard_cleaning')
							:
							t('global.labels.risk_area')
						}</Col>
					<Col className="p-1 ml-1 bg-light text-secondary text-center border-left border-secondary"  >
						<Row>
							<Col>
								Totali
							</Col>
						</Row>
						<Row>
							<Col className="p-1">
								n.
							</Col>
							<Col className="p-1">
								m<sup>2</sup>
							</Col>
						</Row>
					</Col>
					<Col className="p-1 ml-1 bg-light text-secondary text-center border-left border-secondary" >
						<Row>
							<Col>
								Oggetto di servizio
							</Col>
						</Row>
						<Row>
							<Col className="p-1">
								n.
							</Col>
							<Col className="p-1">
								m<sup>2</sup>
							</Col>
						</Row>
					</Col>
					<Col className="p-1 ml-1 bg-light text-dark text-center border-left border-secondary" >
						<Row>
							<Col>
								<FontAwesomeIcon icon="exclamation-circle" />&nbsp;
								Dismessi
							</Col>
						</Row>
						<Row>
							<Col className="p-1">
								n.
							</Col>
							<Col className="p-1">
								m<sup>2</sup>
							</Col>
						</Row>
					</Col>
					<Col className="p-1 ml-1 bg-light text-danger text-center border-left border-secondary" >
						<Row>
							<Col>
								<FontAwesomeIcon icon="hourglass" />&nbsp;
								Sospesi
							</Col>
						</Row>
						<Row>
							<Col className="p-1">
								n.
							</Col>
							<Col className="p-1">
								m<sup>2</sup>
							</Col>
						</Row>
					</Col>
				</Row>
				{ui_list.risk_area_list ?
					(ui_list.risk_area_list.map((riskarea, rkey) => (
						<span key={rkey}>
							{s.risk_area_summary && s.risk_area_summary[riskarea.Id] ?
								<Row key={riskarea.Id} className="element_row text-center border-bottom mb-1 mt-1">
									{renderOneRA(riskarea.Id,
										s.risk_area_summary[riskarea.Id],
										s.risk_area_summary_active[riskarea.Id],
										s.risk_area_summary_decommissioned[riskarea.Id],
										s.risk_area_summary_suspended[riskarea.Id])}
								</Row>

								: ''}
						</span>
					)))
					: ''}
				<Row className="mt-1 text-secondary">
					<Col className="ml-1 bg-light" sm="2" >{t('global.labels.total')}</Col>
					<Col className="bg-light text-center p-2" >{s.room_count}</Col>
					<Col className="bg-light text-right p-2"  >{(s.size || 0).toFixed(2)}</Col>

					<Col className="bg-light text-center p-2 font-weight-bold" >{s.risk_area_summary_active.total.room_count}</Col>
					<Col className="bg-light text-right p-2 font-weight-bold" >{(s.risk_area_summary_active.total.size || 0).toFixed(2)}</Col>

					<Col className={`bg-light text-center p-2 ` + (s.risk_area_summary_decommissioned.total.room_count > 0 ? 'text-dark' : ' text-muted')} >{s.risk_area_summary_decommissioned.total.room_count}</Col>
					<Col className={`bg-light text-right p-2 ` + (s.risk_area_summary_decommissioned.total.room_count > 0 ? 'text-dark' : ' text-muted')}>{(s.risk_area_summary_decommissioned.total.size || 0).toFixed(2)}</Col>

					<Col className={`bg-light text-center p-2 ` + (s.risk_area_summary_suspended.total.room_count > 0 ? 'text-danger' : ' text-muted')} >{s.risk_area_summary_suspended.total.room_count}</Col>
					<Col className={`bg-light text-right p-2 ` + (s.risk_area_summary_suspended.total.room_count > 0 ? 'text-danger' : ' text-muted')} >{(s.risk_area_summary_suspended.total.size || 0).toFixed(2)}</Col>
				</Row>
			</>
		)
	} else {
		return ''
	}

}





//////////////////////////////////////////////////////////////////////////////////////////////////////////////
function PavillonDetails({ element, t, ui_list, permission_department_list }) {
	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { structureElements } = useContext(GlobalDataContext)

	const [pavillon_list, setPavillonList] = useState([])
	function filterPavillonPermission(pav_id) {
		for (const d_id of permission_department_list) {
			const department = structureElements.all_department_dict[d_id]
			console.log(department.parent_list[1], pav_id, department.parent_list[1] === pav_id)
			if (department.parent_list[1] === pav_id) {
				return true
			}
		}
		return false

	}
	const initValue = async () => {

		let return_data = structureElements.pavillon_list.filter(p => p.parent_list[0] === element.id)
		if (permission_department_list.length > 0) {
			return_data = return_data.filter(p => filterPavillonPermission(p.id))
		}

		return_data = return_data.sort(compareName)
		setPavillonList(return_data.sort(compareName))
	}

	useEffect(() => {
		initValue()
	}, [])

	const renderReferentList = (s, p_id) => {
		return (
			<>
				<Row>
					<Col className="bg-secondary text-center text-white">
						{t('global.labels.referents')}
					</Col>
				</Row>
				{s.department_referent_list && s.department_referent_list.filter(r => (r.department.pavillon_id === p_id)).length > 0 ?
					<>

						<Row className="p-1 section_title mb-1">
							<Col className="ml-1 bg-light text-secondary" >{t('global.labels.name')}</Col>
							<Col className="ml-1 bg-light text-secondary" >{t('global.labels.lastname')}</Col>
							<Col className="ml-1 bg-light text-secondary" >{t('global.labels.role')}</Col>
							<Col className="ml-1 bg-light text-secondary" >{t('global.labels.phone')}</Col>
							<Col className="ml-1 bg-light text-secondary" >{t('global.labels.department')}</Col>
						</Row>
						{s.department_referent_list.filter(r => (r.department.pavillon_id === p_id)).map((r, k) => (
							<Row key={k}>
								<Col>
									{r.firstname}
								</Col>
								<Col>
									{r.lastname}
								</Col>
								<Col>
									{getBaseObjectValue(r.role, 'name', locale)}
								</Col>
								<Col>
									{renderPhoneList(r.phone_list)}
								</Col>
								<Col>
									{r.department.name}
								</Col>
							</Row>

						))}
					</>
					:
					<Row>
						<Col className="text-center font-italic">
							Nessun referente presente
						</Col>
					</Row>

				}
			</>
		)
	}

	const renderOnePavillonData = (p) => {

		return (
			<>
				<ElementRADetails s={p} ui_list={ui_list} t={t} />
				{renderReferentList(element, p.id)}
			</>

		)

	}


	if (permission_department_list.length === 1) {
		const p = structureElements.all_department_dict[permission_department_list[0]]
		return (
			<Row>
				<Col sm="12" md="12" xl="6" className="mt-2 p-2">
					<Card className="h-100" >
						<Card.Header >

							<StructureGroupIcon level={1} />
							{p.name}
						</Card.Header>
						<Card.Body>
							{renderOnePavillonData(p)}
						</Card.Body>
					</Card>
				</Col>

			</Row>

		)

	} else if (permission_department_list === 0) {
		return (
			<Row>
				{pavillon_list.map((p, k) => (
					<Col key={k} sm="12" md="12" xl="6" className="mt-2 p-2">
						<Card className="h-100" key={k}>
							<Card.Header >

								<StructureGroupIcon level={1} />
								{p.name}
							</Card.Header>
							<Card.Body>
								{renderOnePavillonData(p)}
							</Card.Body>
						</Card>
					</Col>
				))}

			</Row>

		)

	} else {
		//FIXME -> filtra reparti se più di uno
		return (

			<Row>
				{pavillon_list.map((p, k) => (
					<Col key={k} sm="12" md="12" xl="6" className="mt-2 p-2">
						<Card className="h-100" key={k}>
							<Card.Header >

								<StructureGroupIcon level={1} />
								{p.name}
							</Card.Header>
							<Card.Body>

								{renderOnePavillonData(p)}
							</Card.Body>
						</Card>
					</Col>
				))}

			</Row>
		)

	}

}











//////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ReferentList = ({ s, t }) => {
	const { locale } = useContext(LocaleContext)
	return (
		<>
			<Row>
				<Col className="bg-secondary text-center text-white">
					{t('global.labels.referents')}
				</Col>
			</Row>
			{s.referent_list && s.referent_list.length > 0 ?
				<>

					<Row className="p-1 section_title mb-1">
						<Col className="ml-1 bg-light text-secondary" >{t('global.labels.name')}</Col>
						<Col className="ml-1 bg-light text-secondary" >{t('global.labels.lastname')}</Col>
						<Col className="ml-1 bg-light text-secondary" >{t('global.labels.role')}</Col>
						<Col className="ml-1 bg-light text-secondary" >{t('global.labels.phone')}</Col>
					</Row>
					{s.referent_list.map((r, k) => (
						<Row key={k} className="element_row border-bottom">
							<Col>
								{r.firstname}
							</Col>
							<Col>
								{r.lastname}
							</Col>
							<Col>
								{getBaseObjectValue(r.role, 'name', locale)}
							</Col>
							<Col>
								{renderPhoneList(r.phone_list)}
							</Col>
						</Row>

					))}
				</>
				:
				<Row>
					<Col className="text-center font-italic">
						Nessun referente presente
					</Col>
				</Row>

			}
		</>
	)
}








//////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormStructureComposition({ element, functions, t, ui_list, structureElements, canWrite, permission_department_list }) {
	const { contract } = useContext(GlobalDataContext)

	const updateStructure = (structure) => {
		console.log("REFRESH STRUCTURE", structure)
		functions.edit(structure)
	}
	const contractId = contract.id

	return (
		<>
			<ListStructureGroupItem item={element} is_summary={false} parent={element} setParent={updateStructure} level={0}
				functions={{ setedit: functions.setedit, setsaving: functions.setsaving }}
				structureId={element.id} contractId={contractId} ui_list={ui_list} structureElements={structureElements} canWrite={canWrite} permission_department_list={permission_department_list} />
		</>
	)
}


