import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Form, Button, Card, ButtonGroup, Spinner, InputGroup, OverlayTrigger, Popover, ProgressBar, Tooltip } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { format, setDate, addMonths, endOfMonth } from 'date-fns'
import { it } from 'date-fns/locale'
import DateTime from 'react-datetime';
import Chart from "react-apexcharts";
import { useReactToPrint } from 'react-to-print';

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import { samplingplanTiming_list, checkMode_dict, checkType_dict, checkStrengthType_dict, getControlOption_list } from "../components/constants/global_definitions"

import { compareOrder, getValue, rgba2hex, checkComponentPresence, myTimestampToDate, getSelectedElement, getSelectedElementB, getFloatNumber, getFixedNumber, compareDateCustomParam, propComparatorDate, myDateToTimestamp, getElementPreview } from '../util/ui_utils.js';
import { RiskareaBadge, RoomInfoBadge, ServiceBadge, PageButton, getMatrix, ResultBar } from "../components/StructureGraphicElements"

import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import GlobalQuestion from "../components/GlobalQuestion.js"
import ProcessCheck from "../components/ProcessCheck"
import { ModalProcessCheck } from "../components/ModalProcessCheck"
import PlanningCheck from "../components/PlanningCheck"
import { ServiceCheckPreview } from "../components/CheckPreview"
import Footer from "../components/Footer"
import { AnalysisResultData, AnalysisProcessData } from "../components/AnalysisElement"
import { ModalSamplingProcessPlan } from '../components/modal/ModalSamplingProcessPlan.js';
import { ModalSamplingResultPlan, renderPlanPopover } from '../components/modal/ModalSamplingResultPlan.js';
import { ModalArchiveChecks } from '../components/modal/ModalArchiveChecks.js';

import { getAllRoomList } from '../Firebase2/Storage2/dbRoom.js';
import { addSamplingParam, deleteSamplingParam, editSamplingParam, getSamplingPlanList } from "../Firebase2/Storage2/dbSamplingPlan"
import { getInterventionById } from '../Firebase2/Storage2/dbIntervention'
import { getGroupCheckResults } from "../components/FirebaseApi/CheckResultProxy"
import { getControlGroupList } from '../Firebase2/Storage2/dbControlGroup.js';
import { getEmployerList } from '../Firebase2/Storage2/dbEmployers';
import {
	getFurnitureList as getFurnitureList2,

	getEnvironmentElementList as getEnvironmentElementList2,

	getEconomicalMaterialList as getEconomicalMaterialList2,
} from '../Firebase2/Storage2/dbRoomMaterial';

import firebase from 'firebase/app'


const matrix_list = [
	{ min_index: 0, max_index: 0, level1: 0, level2: 0, level3: 0, lqa4: 0, lqa6: 0, lqa10: 0, pqa: 0, pql: 0 },
	{ min_index: 1, max_index: 1, level1: 1, level2: 1, level3: 1, lqa4: 0, lqa6: 0, lqa10: 0, pqa: 0, pql: 0 },
	{ min_index: 2, max_index: 8, level1: 2, level2: 2, level3: 3, lqa4: 0, lqa6: 0, lqa10: 0, pqa: 0, pql: 0 },
	{ min_index: 9, max_index: 15, level1: 2, level2: 3, level3: 5, lqa4: 0, lqa6: 0, lqa10: 0, pqa: 0, pql: 0 },
	{ min_index: 16, max_index: 25, level1: 3, level2: 5, level3: 8, lqa4: 0, lqa6: 0, lqa10: 1, pqa: 91.90, pql: 52.80 },
	{ min_index: 26, max_index: 50, level1: 5, level2: 8, level3: 13, lqa4: 0, lqa6: 1, lqa10: 2, pqa: 96.10, pql: 55.20 },
	{ min_index: 51, max_index: 90, level1: 5, level2: 13, level3: 20, lqa4: 1, lqa6: 2, lqa10: 3, pqa: 96.60, pql: 42.10 },
	{ min_index: 91, max_index: 150, level1: 8, level2: 20, level3: 32, lqa4: 2, lqa6: 3, lqa10: 5, pqa: 98.80, pql: 41.60 },
	{ min_index: 151, max_index: 280, level1: 13, level2: 32, level3: 50, lqa4: 3, lqa6: 5, lqa10: 7, pqa: 98.80, pql: 21.20 },
	{ min_index: 281, max_index: 500, level1: 20, level2: 50, level3: 80, lqa4: 5, lqa6: 7, lqa10: 10, pqa: 99.00, pql: 32.80 },
	{ min_index: 501, max_index: 1200, level1: 32, level2: 80, level3: 125, lqa4: 7, lqa6: 10, lqa10: 14, pqa: 98.80, pql: 0.80 },
	{ min_index: 1201, max_index: 3200, level1: 50, level2: 125, level3: 200, lqa4: 10, lqa6: 14, lqa10: 21, pqa: 99.00, pql: 0.10 },
	{ min_index: 3201, max_index: 10000, level1: 80, level2: 200, level3: 315, lqa4: 14, lqa6: 21, lqa10: 29, pqa: 99.00, pql: 0.00 }
]

const level_list = {
	'level1': { label: 'Liv.1' },
	'level2': { label: 'Liv.2' },
	'level3': { label: 'Liv.3' },
}

const lqa_list = {
	'lqa4': { label: '4%' },
	'lqa6': { label: '6.5%' },
	'lqa10': { label: '10%' }
}

const month_list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
let load_page = false




////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const CheckView = ({ t, uData, check_type }) => {
	const { domain, domain_data } = useContext(DomainContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict } = useContext(GlobalDataContext)


	const childRefAlert = useRef()
	const childRefDelete = useRef()

	let { cid } = useParams();
	let contractId = cid
	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(false)
	const [structure_list, setStructureList] = useState([])
	const [controlgroup_list, setControlgroupList] = useState([])
	const [employer_list, setEmployerList] = useState([])
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)
	const [currentControlMode, setCurrentControlMode] = useState()

	const [curPage, setCurPage] = useState('params')


	const pages = {
		params: { section_name: t('global.labels.params'), section: 'params', global: true },
		sampling_plan: { section_name: t('checks.labels.sampling_plan'), section: 'sampling_plan' },
		analysis: { section_name: t('checks.labels.analysis'), section: 'analysis' },
	}

	async function getSamplingPlanData() {
		let return_data = await getSamplingPlanList(domain, contractId)
		console.log("plan list", return_data)
		setElementList(return_data.sort(propComparatorDate('startDate')))
	}
	const initValues = async () => {
		const userData = uData
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('samplingplan') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		load_page = true

		let tstructure_list = structureElements.structure_list
		let edit_permission_department_list = []
		let permission_structure_list = []
		if (userData && userData.contract_dict) {
			console.log('userData contract', userData.contract_dict[contractId])
			if (userData.contract_dict[contractId] && userData.contract_dict[contractId][0].structure) {
				if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
					edit_permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
					console.log("permission department_list", edit_permission_department_list, userData.contract_dict[contractId][0].department)
				}
				if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
					permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
					tstructure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

				}
				console.log("permission structure_list", permission_structure_list)
			}
		}
		const ret_data = await getControlGroupList(domain, contractId)
		const tcontrolgroup_list = ret_data.filter(g => g.structure_list.length > 0)
		let room_structure_dict = {}
		for (const s of tstructure_list) {
			const room_list = await getAllRoomList({ structureId: s.id })
			if (room_list && room_list.length > 0) {
				room_structure_dict[s.id] = room_list
			}
		}
		let room_controlgroup_dict = {}
		for (const g of tcontrolgroup_list) {
			console.log("CG", g)
			for (const s of (g.structure_list)) {
				const room_list = await getAllRoomList({ structureId: s })
				if (room_list && room_list.length > 0) {
					if (room_controlgroup_dict[g.id]) {
						room_controlgroup_dict[g.id] = [...room_controlgroup_dict[g.id], room_list]
					} else {
						room_controlgroup_dict[g.id] = room_list
					}
				}
			}
		}
		setStructureList(tstructure_list)
		getSamplingPlanData()
		console.log(room_controlgroup_dict)
		const furniture_list = await getFurnitureList2(domain, contractId)
		const environment_list = await getEnvironmentElementList2(domain, contractId)
		const economic_list = await getEconomicalMaterialList2(domain, contractId)
		const furniture_dict = Object.assign({}, ...furniture_list.map((x) => ({ [x.id]: x })));
		const environment_dict = Object.assign({}, ...environment_list.map((x) => ({ [x.id]: x })));
		const economic_dict = Object.assign({}, ...economic_list.map((x) => ({ [x.id]: x })));
		console.log("FORN", furniture_list)
		setUiList({ ...ui_list, risk_area_list, risk_area_dict, roomtype_list, roomtype_dict, room_structure_dict, room_controlgroup_dict, furniture_dict: { ...furniture_dict, ...environment_dict, ...economic_dict } })
		setLoading(false)
		setControlgroupList(ret_data.filter(g => g.structure_list.length > 0))
		let _employers_list = await getEmployerList(domain, contractId, {})
		console.log("EMP", _employers_list)
		setEmployerList(_employers_list.filter(e => e.structure_list && e.structure_list.length > 0))
		let cur_check = ''
		if (contract.autocheck && contract.autocheck.indexOf(check_type) >= 0) {
			cur_check = 'autocheck'
		} else if (contract.contradictory && contract.contradictory.indexOf(check_type) >= 0) {
			cur_check = 'contradictory'
		} else if (contract.client_checks && contract.client_checks.indexOf(check_type) >= 0) {
			cur_check = 'client_checks'
		}
		setCurrentControlMode(cur_check)
	}


	useEffect(() => {
		load_page = false
		setLoading(true)
		async function loadData() {
			let contractId = cid
			console.log("SAMPLING PLAN reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("SAMPLING PLAN init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])


	useEffect(() => {
		console.log('SAMPLING PLAN change contract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])






	return (
		<>
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={check_type === 'results' ? t('global.pages.resultscheck') : t('global.pages.processcheck')} />
					</Col>
					{!pages[curPage].global ?
						<Col>
							<ButtonGroup>
								{contract && contract.autocheck && contract.autocheck.indexOf(check_type) >= 0 ?
									<Button variant={currentControlMode === 'autocheck' ? 'secondary' : 'outline-secondary'} onClick={() => setCurrentControlMode('autocheck')}>{t('contract.labels.autocheck')}</Button>
									: ''}
								{contract && contract.contradictory && contract.contradictory.indexOf(check_type) >= 0 ?
									<Button variant={currentControlMode === 'contradictory' ? 'secondary' : 'outline-secondary'} onClick={() => setCurrentControlMode('contradictory')}>{t('contract.labels.contradictory')}</Button>
									: ''}
								{contract && contract.client_checks && contract.client_checks.indexOf(check_type) >= 0 ?
									<Button variant={currentControlMode === 'client_checks' ? 'secondary' : 'outline-secondary'} onClick={() => setCurrentControlMode('client_checks')}>{t('contract.labels.client_checks')}</Button>
									: ''}
							</ButtonGroup>
						</Col>
						: ''}
				</Row>
			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col>
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					</Row>
					:
					<Row>
						<Col sm="2">
							{Object.keys(pages).map((p, pk) => (
								<>
									<PageButton key={pk} page_name={pages[p].section_name} setCurPage={setCurPage} curPage={curPage} page={pages[p].section} />
								</>
							))}

						</Col>
						<Col>

							{curPage === 'params' ?
								<ParamPage check_type={check_type} t={t} canWrite={canWrite} ui_list={ui_list} controlgroup_list={controlgroup_list} structure_list={structure_list} currentCheckMode={currentControlMode} />
								: ''}
							{curPage === 'sampling_plan' ?
								<SamplingPlanSection check_type={check_type} t={t} canWrite={canWrite} ui_list={ui_list} controlgroup_list={controlgroup_list} structure_list={structure_list} element_list={element_list} setElementList={setElementList} currentCheckMode={currentControlMode} employer_list={employer_list} />
								: ''}
							{curPage === 'planning' ?
								<PlanningCheck check_type={check_type} t={t} canWrite={canWrite} ui_list={ui_list} controlgroup_list={controlgroup_list} structure_list={structure_list} currentCheckMode={currentControlMode} />
								: ''}
							{curPage === 'analysis' ?
								<AnalysisPlanPage check_type={check_type} t={t} canWrite={canWrite} ui_list={ui_list} controlgroup_list={controlgroup_list} structure_list={structure_list} element_list={element_list} currentControlMode={currentControlMode} isAdmin={uData.isGlobalAdmin} />
								: ''}

						</Col>
					</Row>
				}

			</Container>
			<Footer />
		</>
	);
}





////////////////////////////////////////////////////////////////////////////////////////////////////////
const ParamPage = ({ check_type, t, canWrite, structure_list, currentCheckMode, ui_list }) => {
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict } = useContext(GlobalDataContext)
	console.log("CHECKTYPE", check_type)

	return (
		<>

			<GlobalQuestion key={check_type} check_type={check_type} t={t} canWrite={canWrite} />
			{check_type === 'process' ?
				<>
					<ProcessCheck canWrite={canWrite} t={t} />
				</>
				: ''}
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
const SamplingPlanSection = ({ check_type, t, canWrite, structure_list, controlgroup_list, element_list, setElementList, currentCheckMode, ui_list, employer_list }) => {

	const { contract } = useContext(GlobalDataContext)


	const childRefDelete = useRef()
	const childRefAlert = useRef()

	let { cid } = useParams();
	let contractId = cid


	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />

			<Row className="mt-1">
				<Col className="h5 text-primary">
					{t('checks.labels.sampling_plan')}
				</Col>
				<Col className="font-weight-bold h5 text-right">
					{contract && contract.check_weight && contract.check_weight[currentCheckMode] ?
						<>{t('checks.labels.weight_coefficient')}: <Badge variant="info">{contract.check_weight[currentCheckMode][check_type]} % </Badge> </>
						: <>{t('checks.messages.no_weight')}</>}
				</Col>
			</Row>
			<Row className="mt-1">
				{console.log("EMP2", employer_list)}
				{controlgroup_list && controlgroup_list.length > 0 ?
					<Col>
						<Row>
							<Col className="bg-secondary text-light ml-1">
								{t('global.labels.name')}
							</Col>
							<Col className="bg-secondary text-light ml-1">
								{t('samplingplan.labels.start_plan')}
							</Col>
							<Col className="bg-secondary text-light ml-1">
								{t('samplingplan.labels.period')}
							</Col>
							<Col className="bg-secondary text-light ml-1">
								{t('samplingplan.labels.checks')}
							</Col>
							<Col className="bg-secondary text-light ml-1 text-center" sm="2">
								{t('global.labels.actions')}
							</Col>
						</Row>
						{controlgroup_list.map((g, key) => (
							<Row className="mb-1 pb-1 border-bottom" key={key}>
								<Col>
									<Row className="mb-1">
										<Col className="font-weight-bold" sm="5">
											{g.name}
										</Col>
										{canWrite ?
											<Col className="text-right">
												{check_type === 'results' ?
													<ModalSamplingResultPlan controlgroup={g} is_new={true} control_mode={currentCheckMode} canWrite={canWrite} ui_list={ui_list} t={t} item_list={element_list} setItem={setElementList} />
													:
													<ModalSamplingProcessPlan controlgroup={g} is_new={true} control_mode={currentCheckMode} canWrite={canWrite} ui_list={ui_list} t={t} item_list={element_list} setItem={setElementList} employer_list={employer_list} />
												}
											</Col>
											: ''}

									</Row>
									<PlanList t={t} element_list={element_list} g={g} setElementList={setElementList} ui_list={ui_list} canWrite={canWrite} control_mode={currentCheckMode} check_type={check_type} childRefAlert={childRefAlert} childRefDelete={childRefDelete} employer_list={employer_list} />
								</Col>
							</Row>
						))}
					</Col>
					:
					<Col className="font-italic">
						{t('structure.labels.no_control_group_configured')}
					</Col>
				}
			</Row>
		</>
	)
}








////////////////////////////////////////////////////////////////////////////////////////////////////////
const PlanList = ({ control_mode, t, check_type, g, s, element_list, ui_list, setElementList, canWrite, childRefDelete, childRefAlert, employer_list }) => {
	const { domain } = useContext(DomainContext)
	const { contract, risk_area_list } = useContext(GlobalDataContext)
	const timing_list = samplingplanTiming_list({ t })
	let { cid } = useParams();
	let contractId = cid
	const now = firebase.firestore.Timestamp.now()


	const handleDeletePlan = (plan) => {
		const cb = async () => {
			console.log(domain, contractId, plan.id)
			const return_data = await deleteSamplingParam(domain, contractId, plan.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const c = checkComponentPresence(plan, element_list, 'id')
				console.log("delete index", c, element_list)
				if (c >= 0) {
					element_list.splice(c, 1)
				}
				console.log(element_list)
				setElementList(element_list)
			}
		}
		childRefDelete.current.confirmDelete(t('sampleplan.messages.delete_plan_title'), t('sampleplan.messages.delete_plan', { plan: '' }), cb)
	}
	return (

		<Row>
			{element_list.filter(plan => g.id === plan.controlgroup_id && plan.control_mode === control_mode && plan.check_type === check_type).length > 0 ?
				<Col>
					{element_list.filter(plan => g.id === plan.controlgroup_id && plan.control_mode === control_mode && plan.check_type === check_type).map((plan, kp) => (
						<Row key={kp}>
							<Col>
								{plan.code}
							</Col>
							<Col>
								{format(myTimestampToDate(plan.startDate), 'dd/MM/yyyy', { locale: it })}
							</Col>
							<Col>
								{timing_list[plan.check_period].label}
								{plan.endDate < now ?
									<OverlayTrigger trigger={['focus', 'hover']} placement="auto" overlay={<Tooltip >Periodo terminato</Tooltip>}>
										<Badge variant="square" className="btn mr-1"><FontAwesomeIcon icon="square" /> </Badge>
									</OverlayTrigger>
									:
									<Badge variant="square" className="btn mr-1"><FontAwesomeIcon icon="play-circle" style={{ color: 'green' }} /> </Badge>
								}
								({format(myTimestampToDate(plan.endDate), 'dd/MM/yyyy', { locale: it })})
							</Col>
							<Col>
								{plan.total_data ?
									<>
										{check_type === 'results' ?
											<OverlayTrigger trigger={['focus', 'hover']} placement="left-start" overlay={renderPlanPopover(t, plan, false, contract.type, risk_area_list)}>
												<Badge variant="secondary" className="btn mr-1">{contract.control_validation_mode === 'global' ? plan.total_data.sampling_count : plan.total_data.sum_sampling_count}</Badge>
											</OverlayTrigger>
											:
											<>
												{plan.total_data.sampling_count} (Ac: {plan.total_data.ac_check_count})
											</>
										}
									</> : ''}
							</Col>
							<Col className="text-center" sm="2">
								<ButtonGroup>
									{check_type === 'results' ?
										<ModalSamplingResultPlan controlgroup={g} is_new={false} item={plan} control_mode={control_mode} canWrite={canWrite && plan.endDate > now} ui_list={ui_list} t={t} item_list={element_list} setItem={setElementList} />
										: ''}
									{check_type === 'process' ?
										<ModalSamplingProcessPlan controlgroup={g} is_new={false} item={plan} control_mode={control_mode} canWrite={canWrite && plan.endDate > now} ui_list={ui_list} t={t} item_list={element_list} setItem={setElementList} employer_list={employer_list} />
										: ''}
									{check_type === 'microbiological' ?
										<ModalSamplingMicrobiologicalPlan controlgroup={g} is_new={false} item={plan} control_mode={control_mode} canWrite={canWrite && plan.endDate > now} ui_list={ui_list} t={t} item_list={element_list} setItem={setElementList} />
										: ''}
									<Button variant="outline-danger" onClick={() => handleDeletePlan(plan)} size="sm"><FontAwesomeIcon icon="trash" /></Button>
								</ButtonGroup>
							</Col>
						</Row>
					))}
				</Col>
				: <Col className="font-italic">
					Nessun piano di campionamento configurato
				</Col>}
		</Row>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
const AnalysisPlanPage = ({ check_type, t, canWrite, controlgroup_list, structure_list, element_list, currentControlMode, ui_list, isAdmin }) => {

	const { domain } = useContext(DomainContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict } = useContext(GlobalDataContext)


	const childRefDelete = useRef()
	const childRefAlert = useRef()

	let { cid } = useParams();
	let contractId = cid


	return (
		<>

			<Row className="mt-1">
				<Col className="h5 text-primary">
					{t('checks.labels.analysis')}
				</Col>
			</Row>
			<Row className="mt-1">
				{controlgroup_list && controlgroup_list.length > 0 ?
					<Col>
						<Row>
							<Col className="bg-secondary text-light ml-1" sm="2">
								{t('global.labels.name')}
							</Col>
							<Col>
								<Row>

									<Col className="bg-secondary text-light ml-1">
										{t('samplingplan.labels.start_plan')}
									</Col>
									<Col className="bg-secondary text-light ml-1">
										{t('samplingplan.labels.period')}
									</Col>
									<Col className="bg-secondary text-light ml-1">
										{t('samplingplan.labels.planned_checks')}
									</Col>
									<Col className="bg-secondary text-light ml-1">
										{t('samplingplan.labels.done_checks')}
									</Col>
									<Col className="bg-secondary text-light ml-1">
										{t('samplingplan.labels.undone_checks')}
									</Col>
									<Col className="bg-secondary text-light ml-1">
										LQR medio
									</Col>
									{currentControlMode === 'contradictory' ?
										<Col className="bg-secondary text-light ml-1 text-center" sm="1">
											Accettazione del lotto
										</Col>
										: ''}
									<Col className="bg-secondary text-light ml-1 text-center" sm="1">
										IR
									</Col>
									<Col className="bg-secondary text-light ml-1 text-center">
										Indice di pulizia
									</Col>

									<Col className="bg-secondary text-light ml-1 text-center" sm="1">
										{t('global.labels.actions')}
									</Col>
								</Row>
							</Col>
						</Row>
						{controlgroup_list.map((g, key) => (
							<Row className="mb-1 pb-1 border-bottom" key={key}>
								<Col className="font-weight-bold" sm="2">
									{g.name}
								</Col>
								<Col>
									<AnalysisPlanList t={t} element_list={element_list} g={g} ui_list={ui_list} canWrite={canWrite} control_mode={currentControlMode} check_type={check_type} isAdmin={isAdmin} />
								</Col>
							</Row>
						))}
					</Col>
					:
					<Col className="font-italic">
						{t('structure.labels.no_structure')}
					</Col>
				}
			</Row>
		</>
	)
}


const BadgeResultCount = ({ mode, total_data, lqa }) => {
	let str = "-"
	console.log("TOTAL_DATA", total_data)
	if (total_data) {

		if (mode === 'global') {
			if (total_data[lqa]) {
				str = total_data[lqa].sampling_count
			}else{
				str = total_data.sampling_count
			}
		} else {
			str = total_data.sum_sampling_count
		}
	}
	return (
		<Badge variant="secondary" className="btn mr-1">{str}</Badge>

	)
}


const getSubRowClassName = (now, endDate, index, length) => {
	let str = ''
	if (index<length-1){
		str += " border-bottom mb-1 pb-1"
	}
	if (endDate<now){
		str+= " bg-light"
	}
	str+= " element_row"
	return str
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
const AnalysisPlanList = ({ control_mode, t, check_type, g, s, element_list, ui_list, canWrite, isAdmin }) => {
	const timing_list = samplingplanTiming_list({ t })
	const { contract, risk_area_list } = useContext(GlobalDataContext)
	const now = firebase.firestore.Timestamp.now()
	const el_filtered = element_list.filter(plan => g.id === plan.controlgroup_id && plan.control_mode === control_mode && plan.check_type === check_type)
	return (
		<>
				{el_filtered.map((plan, kp) => (
					<Row key={kp} className={getSubRowClassName(now, plan.endDate, kp, el_filtered.length)}>
						<Col>
							{format(myTimestampToDate(plan.startDate), 'MM/yyyy', { locale: it })}
						</Col>
						{console.log("PLAN DATA", plan)}
						<Col>
							{timing_list[plan.check_period].label}

							{plan.endDate < now ?
								<OverlayTrigger trigger={['focus', 'hover']} placement="auto" overlay={<Tooltip >Periodo terminato</Tooltip>}>
									<Badge variant="square" className="btn mr-1"><FontAwesomeIcon icon="square" /> </Badge>
								</OverlayTrigger>
								:
									<Badge variant="square" className="btn mr-1"><FontAwesomeIcon icon="play-circle" style={{ color: 'green' }} /> </Badge>
								}
						</Col>
						<Col>
							{check_type === 'results' ?
								<OverlayTrigger trigger={['focus', 'hover']} placement="auto" overlay={renderPlanPopover(t, plan, false, contract.type, risk_area_list)}>
									<BadgeResultCount mode={contract.control_validation_mode} lqa={plan.lqa} total_data={plan.total_data || plan.data} />
								</OverlayTrigger>
								:
								<>
									{plan.total_data && plan.total_data.sampling_count}
								</>
							}
						</Col>
						<Col>
							{check_type === 'results' && plan.total_data.check_done ?
								<OverlayTrigger trigger={['focus', 'hover']} placement="auto" overlay={renderPlanPopover(t, plan, true, contract.type, risk_area_list)}>
									<Badge variant="secondary" className="btn mr-1">{plan.total_data.check_done || 0}</Badge>
								</OverlayTrigger>
								:
								<>
									{plan.total_data.check_done || 0}
								</>
							}
							{plan.total_data && plan.total_data.check_done > 0 ?
								<ProgressBar>
									<ProgressBar now={(plan.total_data.check_done - plan.total_data.failed_check) * 100 / plan.total_data.check_done} style={{ backgroundColor: 'green' }} />
									<ProgressBar now={plan.total_data.failed_check * 100 / plan.total_data.check_done} style={{ backgroundColor: 'red' }} />
								</ProgressBar>
								: ''}
						</Col>
						{contract.control_validation_mode === 'global' ?
							<Col>
								{getCheckCount(plan.total_data.check_done, plan.total_data.sampling_count)}
								{plan.total_data && plan.total_data.check_done > 0 &&
									<ProgressBar>
										<ProgressBar now={(plan.total_data.check_done - plan.total_data.failed_check) * 100 / plan.total_data.sampling_count} style={{ backgroundColor: 'green' }} />
										<ProgressBar now={plan.total_data.failed_check * 100 / plan.total_data.sampling_count} style={{ backgroundColor: 'red' }} />
									</ProgressBar>
								}
							</Col>
							:
							<Col>
								{getCheckCount(plan.total_data.check_done, plan.total_data.sum_sampling_count)}
								{plan.total_data && plan.total_data.check_done > 0 &&
									<ProgressBar>
										<ProgressBar now={(plan.total_data.check_done - plan.total_data.failed_check) * 100 / plan.total_data.sum_sampling_count} style={{ backgroundColor: 'green' }} />
										<ProgressBar now={plan.total_data.failed_check * 100 / plan.total_data.sum_sampling_count} style={{ backgroundColor: 'red' }} />
									</ProgressBar>
								}
							</Col>

						}
						<Col>
							{plan.total_data.average_lqr ? plan.total_data.average_lqr.toFixed(2) : plan.total_data.average_lqr}
						</Col>
						{control_mode === 'contradictory' &&
							<Col sm="1">
								{plan.total_data && (contract.control_validation_mode === 'global' ? plan.total_data.sampling_count : plan.total_data.sum_sampling_count) - plan.total_data.check_done <= 0 ?
									<>
										{plan.total_data.check_done - plan.total_data.failed_check}
									</>
									:
									<>
										campione non completato
									</>
								}
							</Col>
						}
						<Col sm="1">
							{plan.notAccepted === true &&
								<>
									{plan.total_data.ir_sum ? <>{(-plan.total_data.ir_sum).toFixed(2)}%</> : plan.total_data.ir_sum}
								</>}
						</Col>
						<Col sm>
							{plan.total_data.clean_index && plan.total_data.clean_index.toFixed(2)}
						</Col>
						<Col sm="1" className="text-center">
							{plan.id === "8ovCkE10K3X21K2skpP1" &&
								console.log("PLAN", plan, myTimestampToDate(plan.startDate), myTimestampToDate(plan.endDate))
							}
							<ModalArchiveChecks t={t} controlgroup={g} item={plan} control_mode={control_mode} check_type={check_type} ui_list={ui_list} canWrite={canWrite} isAdmin={isAdmin} />
							{control_mode === 'contradictory' ?
								<ModalArchiveChecks t={t} controlgroup={g} item={plan} control_mode={control_mode} check_type={check_type} ui_list={ui_list} nc={true} canWrite={canWrite} isAdmin={isAdmin} />
								: ''}
						</Col>
					</Row>
				))}
		</>
	)
}



const getCheckCount = (check_done, sampling_count) => {
	if (check_done) {
		const c = sampling_count - check_done
		console.log("COUNT", c)
		if (c >= 0) {
			return c
		} else {
			return 0
		}

	} else {
		return sampling_count
	}
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalSamplingMicrobiologicalPlan = ({ is_new, item, structure, controlgroup, canWrite, ui_list, t, item_list, sampling_data, setItem }) => {



	const newsampling_plan = {
		controlgroup_id: controlgroup.id,
		check_period: 1,
		startDate: firebase.firestore.Timestamp.fromDate(setDate(new Date(), 1)),
		ac_check_count: 0,
		level: 'level2',
		lqa: 'lqa10',
		check_type: 'microbiological',
		control_mode: 'microbiological',
		user_id: -1
	}
	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contractId } = useContext(GlobalDataContext)


	const childRefAlert = useRef()

	const [showPlanEdit, setShowPlanEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [partialData, setPartialData] = useState({ ac_check_count0: 0, ac_check_count1: 0, ac_check_count2: 0 })
	const [curControlGroup, setCurControlGroup] = useState()
	const [curPlan, setCurPlan] = useState(newsampling_plan)

	const handleShowEditPlan = async () => {
		setCurControlGroup(controlgroup)
		setShowPlanEdit(true)
		if (is_new) {
			setCurPlan(newsampling_plan)
		}
		setEditedElement(false)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)

	}


	const handleClosePlan = () => {
		setShowPlanEdit(false)
	}



	const handleSavePlan = async () => {
		console.log("____________________________________SAVE_____________________________", !sampling_data)
		let t_element_list = [...item_list]
		if (is_new) {
			let return_data = await addSamplingParam(domain, contractId, curPlan)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				setShowPlanEdit(false);
				t_element_list.push(curPlan)
				console.log("SET LIST", t_element_list)
				setItem(t_element_list)
			}
		} else {
			let return_data = await editSamplingParam(domain, contractId, curPlan)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				console.log(item_list)
				const c = checkComponentPresence(curPlan, t_element_list, 'id')
				if (c >= 0) {
					t_element_list.splice(c, 1, curPlan)
					setItem(t_element_list)
				}
				setShowPlanEdit(false);
			}
		}
	}

	const onChangeHandlerPlan = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curPlan[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurPlan({ ...curPlan, [name]: value })
		setEditedElement(true)
	}




	useEffect(() => {
		updateData()
	}, [curPlan])



	const updateData = () => {
		let temp_data = { ...partialData }
		for (let r = 0; r < matrix_list.length; r++) {
			if (matrix_list[r].max_index >= curPlan.ac_check_count && matrix_list[r].min_index <= curPlan.ac_check_count) {
				temp_data.data = matrix_list[r]
			}
		}
		try {
			temp_data.ac_check_count0 = Math.ceil(temp_data.data[curPlan.level] * control_option_list[curPlan.check_period].multiplier[0])
			temp_data.ac_check_count1 = Math.ceil(temp_data.data[curPlan.level] * control_option_list[curPlan.check_period].multiplier[1])
			temp_data.ac_check_count2 = Math.ceil(temp_data.data[curPlan.level] * control_option_list[curPlan.check_period].multiplier[2])
			setPartialData(temp_data)

		} catch (err) {

		}
	}



	const control_option_list = samplingplanTiming_list({ t })


	return (
		<>
			<AlertSection ref={childRefAlert} />
			{canWrite ?
				(is_new ?
					<Button className="mr-1" variant="primary" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="plus-square" />{t('samplingplan.labels.new_microbio_plan')} </Button>
					:
					<Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
				)
				:
				<Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="eye" /> </Button>
			}
			{curControlGroup ?
				<Modal show={showPlanEdit} onHide={handleClosePlan} dialogClassName="custom-modal" bsclass="full-modal" backdrop="static" >
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						<Modal.Title>
							{canWrite ?
								(is_new ?
									<>
										<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('samplingplan.labels.new_plan')}</span>
									</>
									:
									<>
										<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('samplingplan.labels.edit_plan')}</span>
									</>
								)
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('roomtypes.labels.view_plan')}</span>
								</>
							}&nbsp;
							({t('samplingplan.labels.microbiological_check')})
							: {controlgroup.name}&nbsp;
							{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
							<Form.Group as={Row} controlId="formProductBrand" className="border-bottom">
								<Form.Label column sm="2">{t('samplingplan.labels.start_plan')}</Form.Label>
								<Col sm="8">
									<DateTime initialValue={myTimestampToDate(curPlan.startDate)} dateFormat='MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerPlan({ name: 'startDate', value: myDateToTimestamp(e.toDate()) })} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.period')} </Form.Label>
								<Col sm="3">
									<Form.Control as="select" className="mb-2" value={curPlan.check_period} name="check_period" onChange={(event) => onChangeHandlerPlan(event.target)} >
										{control_option_list.map((l, k) => (
											<option key={k} value={l.id}>{l.label}</option>
										))}
									</Form.Control>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.ac_check_count')} </Form.Label>
								<Col sm="3">
									<Form.Control type="number" className="mb-2" value={curPlan.ac_check_count} min="0" name="ac_check_count" onChange={(event) => onChangeHandlerPlan(event.target)} />
								</Col>
							</Form.Group>
							<Row className="text-light text-center">
								<Col className="bg-secondary ml-1 pt-2">
									Numero controlli
									<Row className="border-top border-light">
										<Col className="p-1">Sett.</Col>
										<Col className="p-1">Mese</Col>
										<Col className="p-1">Anno</Col>
									</Row>
								</Col>
							</Row>
							<Row className="text-center">
								<Col>
									<Row>
										<Col>{partialData.ac_check_count0}</Col>
										<Col>{partialData.ac_check_count1}</Col>
										<Col>{partialData.ac_check_count2}</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClosePlan}>
							{t('modal.close')}
						</Button>
						{canWrite ?
							<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSavePlan}>
								<FontAwesomeIcon icon="save" /> {t('modal.save')}
							</Button>
							: ''}
					</Modal.Footer>
				</Modal>
				: ''}
		</>
	)
}



const ModalSamplingPlanCopy = ({ t, element_list, item, setItem, mode }) => {

	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contractId } = useContext(GlobalDataContext)

	const [sourceType, setSourceType] = useState(mode)
	const [sourcePlan, setSourcePlan] = useState(item)

	const childRefAlert = useRef()

	const [showPlanCopy, setShowPlanCopy] = useState(false)
	const [editedElement, setEditedElement] = useState(false)



	const handleClosePlanCopy = () => {
		setShowPlanCopy(false)
	}


	const handleSaveCopy = () => {

		setShowPlanCopy(false)
	}



	return (
		<>
			<Button variant="outline-info" onClick={() => setShowPlanCopy(true)} size="sm">{t('samplingplan.labels.copy_planfromother')}</Button>
			<Modal show={showPlanCopy} onHide={handleClosePlanCopy} backdrop="static" className="bg-secondary" size="xl">
				<Modal.Header closeButton>
					<Modal.Title>

					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Col>
								<Form.Check type="radio" name="type" value="autocheck" checked={sourceType === 'autocheck'} label={t('contract.labels.autocheck')} onChange={(event) => setSourceType(event.currentTarget.value)} />
							</Col>
							<Col>
								<Form.Check type="radio" name="type" value="contradictory" checked={sourceType === 'contradictory'} label={t('contract.labels.contradictory')} onChange={(event) => setSourceType(event.currentTarget.value)} />
							</Col>
							<Col>
								<Form.Check type="radio" name="type" value="client_checks" checked={sourceType === 'client_checks'} label={t('contract.labels.client_checks')} onChange={(event) => setSourceType(event.currentTarget.value)} />
							</Col>
						</Form.Group>
						<Row>
							<Col>
								{t('samplingplan.labels.select_plan')}
							</Col>
						</Row>
						{element_list.map((plan, k) => (
							<Row key={k}>
								<Col>
								</Col>
							</Row>
						))}


					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClosePlanCopy}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>

		</>
	)
}



/* 
	
	
////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalSimulation = ({ item, canWrite, ui_list, t, sampling_data }) => {
	
	const { locale } = useContext(LocaleContext)
	
	const [showPlanEdit, setShowPlanEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curElement, setCurElement] = useState()
	const [element_list, setElementList] = useState([])
	const [check_period, setCheckPeriod] = useState(1)
	const [check_multiplier, setCheckMultiplier] = useState([0.125, 0.2, 6])
	const [totalValue, setTotalValue] = useState({})
	const [selectedLevel, setSelectedLevel] = useState('level2')
	const [riskAreaDict, setRiskAreaDict] = useState({})
	const [room_list, setRoomList] = useState([])
	
	const handleShowSimulation = async () => {
		setShowPlanEdit(true)
		setEditedElement(false)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
		if (item) {
	
			const cId = item
			const rl = await getAllRoomList({ structureId: cId.id })
			setRoomList(rl)
			setCurElement(item)
		}
	
	}
	const handleClosePlan = () => {
		setShowPlanEdit(false)
	}
	
	useEffect(() => {
		console.log(item)
		if (room_list && sampling_data) {
			getRandomRoomList(item)
		}
	}, [room_list])
	
	
	
	
	const getRndInteger = (max) => {
		return Math.floor(Math.random() * max)
	}
	
	
	
	
	
	const getRandomRoomList = async (item) => {
		let risk_area_dict = {}
		for (let i = 0; i < ui_list.risk_area_list.length; {
			risk_area_dict[ui_list.risk_area_list[i].Id] = { ...ui_list.risk_area_list[i] }
		}
		//let risk_area_dict = temp_list.reduce((r, x) => ({...r, [x.Id]{ })
	
		console.log("INIT dict", risk_area_dict, ui_list.risk_area_dict)
		room_list.map(r => {
			const ra_id = getValue(r.risk_area, 'Id')
			const room_type_id = getValue(r.roomtype, 'id')
			const room_type = ui_list.roomtype_dict[room_type_id]
			const sampling_ra_data = sampling_data.element_list[ra_id]
			console.log(ra_id, sampling_ra_data)
			//			console.log("SAMPLE, ", sampling_data.element_list, sampling_ra_data)
	
			if (!risk_area_dict[ra_id]) {
				risk_area_dict[undefined] = { name: { it: 'altro', en: 'other' }, color: { r: 50, g: 50, b: 50, a: 50 } }
			}
			if (risk_area_dict[ra_id].room_type_list) {
				if (risk_area_dict[ra_id].room_type_list[room_type_id]) {
					risk_area_dict[ra_id].room_type_list[room_type_id].count += 1
				} else {
					risk_area_dict[ra_id].room_type_list[room_type_id] = { count: 1, name: getValue(room_type, 'name'), sample: 0 }
				}
			} else {
				risk_area_dict[ra_id].room_type_list = {}
				risk_area_dict[ra_id].room_type_list[room_type_id] = { count: 1, name: getValue(room_type, 'name'), sample: 0 }
	
			}
	
			if (!risk_area_dict[ra_id].room_list) {
				risk_area_dict[ra_id].room_list = []
			}
			risk_area_dict[ra_id].room_list.push(room_type_id)
	
		})
	
	
		console.log("presample", risk_area_dict)
		for (let b in sampling_data.element_list) {
			const ra = risk_area_dict[b]
			if (ra) {
				//				let count = ra.count
				let level_count = sampling_data.element_list[b].ac_check_count
				for (let s = 0; s < level_count; {
	
					try {
						const index = getRndInteger(risk_area_dict[b].room_list.length)
						const selected_type = risk_area_dict[b].room_list[index]
	
						if(selected_type) {
							risk_area_dict[b].room_type_list[selected_type].sample += 1
						} else {
							risk_area_dict[b].room_type_list[undefined].sample += 1
						}
					} catch(err) {
					}
				}
			}
		}
	
	
		console.log(risk_area_dict)
		setRiskAreaDict(risk_area_dict)
	}
	
	
	
	return (
		<>
			<Button className="mr-1" variant="secondary" size="sm" onClick={() => handleShowSimulation()}><FontAwesomeIcon icon="cogs" /> {t('samplingplan.labels.dosimulate_plan')}</Button>
			{curElement ?
				<Modal show={showPlanEdit} onHide={handleClosePlan} dialogClassName="custom-modal" bsclass="full-modal" backdrop="static" scrollable={true}>
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						<Modal.Title>
							<FontAwesomeIcon icon="cogs" /> <span className="text-info">{t('samplingplan.labels.simulation')}</span>
						: {item.name}&nbsp;
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
	
	
							<Card className="border-info">
								<Card.Header className="bg-secondary">
									<Row >
										<Col className="h3 text-light">Tipologie locali da controllare</Col>
										<Col className="text-right">
										</Col>
									</Row>
	
								</Card.Header>
								<Card.Body>
	
									<Row className="mt-2 mb-2">
										<Col className="bg-secondary text-light">Area di rischio</Col>
										<Col className="bg-secondary text-light ml-1">Tipologia di locale</Col>
										<Col className="bg-secondary text-light ml-1">Numero locali totali</Col>
										<Col className="bg-secondary text-light ml-1">Campionatura</Col>
									</Row>
									{Object.keys(riskAreaDict).map((key, i) => (
										<>
											{riskAreaDict[key].room_type_list ?
												<Row key={i} className="border-bottom mb-3">
													<Col sm="3">
														<Row>
															<Col>
																{riskAreaDict[key].name[locale]}</Col>
															<Col>
																<Badge className="d-flex" style={{
																	background:
																		`rgba(${riskAreaDict[key].color.r}, ${riskAreaDict[key].color.g}, ${riskAreaDict[key].color.b}, ${riskAreaDict[key].color.a})`
																}}>&nbsp;&nbsp;</Badge>
															</Col>
														</Row>
	
													</Col>
	
													<Col>
														{Object.keys(riskAreaDict[key].room_type_list).map((rkey, r) => (
															<Row key={r + i} className={riskAreaDict[key].room_type_list[rkey].sample > 0 ? "mb-1 border selected_row" : 'font-italic text-muted'}>
																<Col>
																	{riskAreaDict[key].room_type_list[rkey].sample > 0 ? <FontAwesomeIcon icon="check" /> : ''}
																	{riskAreaDict[key].room_type_list[rkey].name}
																</Col>
																<Col>{riskAreaDict[key].room_type_list[rkey].count}</Col>
																<Col className={riskAreaDict[key].room_type_list[rkey].sample > 0 ? "font-weight-bold" : ''}>{riskAreaDict[key].room_type_list[rkey].sample}</Col>
															</Row>
														))}
													</Col>
												</Row>
												: ''
											}
										</>
	
									))}
								</Card.Body>
								<Card.Footer className="bg-info text-muted"></Card.Footer>
	
							</Card>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClosePlan}>
							{t('modal.close')}
						</Button>
						<Button variant="light" onClick={getRandomRoomList} ><FontAwesomeIcon icon="cogs" />{t('samplingplan.labels.repeat_simulation')}</Button>
	
					</Modal.Footer>
				</Modal>
				: ''}
		</>
	)
}
	
 */




export default translate()(CheckView)