import React, { useEffect, useState } from 'react';

import './App.css';
import { Route, Switch, Redirect, HashRouter, useLocation, useHistory, useParams } from "react-router-dom";

import HomeView from "./pages/HomeView.js";
import CatalogHomeView from "./pages/CatalogHomeView.js";
import TestView from "./pages/TestView.js";
import AgendaView from "./pages/AgendaView.js";
import PlanningView from "./pages/PlanningView.js";
import CheckPlanningView from "./pages/CheckPlanningView.js";
import WorkareaView from './pages/WorkareaView';
import StructureView from "./pages/StructureView"
import CleaningCompanyView from "./pages/CleaningCompanyView.js";
import EmployesView from './pages/EmployesView';
import FailureView from './pages/FailureView';
import TrainingView from "./pages/TrainingView"
import TrainingPlanningView from "./pages/TrainingPlanningView"
import TrainerView from "./pages/TrainerView"
import ControlToolView from "./pages/ControlToolView"
import BaseObjectView from './pages/BaseObjectView';
import MachineView from './pages/MachineView';
import CartView from './pages/CartView';
import EquipmentsView from './pages/EquipmentView';
import ProductsView from './pages/ProductView';
import SuppliersView from './pages/SuppliersView';
import OperationsView from './pages/OperationsView';
import UsersView from './pages/UsersView';
import UserSettingsView from './pages/UserSettingsView';
import WarehouseView from './pages/WarehouseView';
import RoomTypesView from './pages/RoomTypesView';
import RoomTypeImageView from './pages/RoomTypeImageView';
import RulesView from './pages/RulesView';
import OrganizationChartView from './pages/OrganizationChartView';
import ContractChoose from './pages/ContractChoose';
import CostCenterView from './pages/CostCenterView';
import ContractDashboard from './pages/ContractDashboard'
import WorkContractView from './pages/WorkContractView';
import WorkshiftsView from './pages/WorkshiftsView';
import NavBarGlobal from "./components/NavBarGlobal.js";
import CheckMatrixView from "./pages/CheckMatrixView"
import CheckView from "./pages/CheckView"
import AmountView from "./pages/AmountView"
import CleaningMaterialView from "./pages/CleaningMaterialView"
import InterventionView from "./pages/InterventionView"
import WorkflowView from "./pages/WorkflowView";
import ServiceView from "./pages/ServiceView";
import NewsView from "./pages/NewsView";
import CatalogView from "./pages/CatalogView";
import SystemgroupView from "./pages/SystemgroupView";
import DocumentsStorageView from "./pages/DocumentsStorageView";
import DocumentsStructureView from "./pages/DocumentsStructureView";
import DocumentsContractView from "./pages/DocumentsContractView";
import DocumentsSystemView from "./pages/DocumentsSystemView";
import DocumentsSystemAdminView from "./pages/DocumentsSystemAdminView";
import ImportStructureView from "./pages/ImportStructureView"
import OperatorAssignmentView from "./pages/OperatorAssignmentView"
import OperatorWorkshiftView from "./pages/OperatorWorkshiftView"
import TicketView from "./pages/TicketView"
import ExecutionreportView from "./pages/ExecutionreportView"
import InterventionreportView from "./pages/InterventionreportView"
import DpiView from "./pages/DpiView"
import FailureTypeView from "./pages/FailureTypeView"
import DilutionSystemView from "./pages/DilutionSystemView"
import ClothingView from "./pages/ClothingView"
import YieldSummaryView from "./pages/YieldSummaryView"
import ClientsView from "./pages/ClientsView"
import ControlGroupView from "./pages/ControlGroupView"
import ClauditlicenceView from "./pages/ClauditlicenceView"
import TemplateView from "./pages/TemplateView"
import MicrobiologicalCheckView from "./pages/MicrobiologicalCheckView"
import MediaTypeView from "./pages/MediaTypeView"
import CustomerSatisfactionView from "./pages/CustomerSatisfactionView"
import VariationRegisterView from "./pages/VariationRegisterView"
import NonComplianceView from './pages/NonComplianceView';
import CheckDefinitionsView from "./pages/CheckDefinitionsView"
import TrainingReportView from './pages/TrainingReportView';
import NotFound from './pages/NotFound';
import NotPermitted from './pages/NotPermitted';
import 'react-datetime/css/react-datetime.css'
import 'moment/locale/it'
import ImportEmployersView from './pages/ImportEmployersView';
//import EditContext from './components/contexts/edit';
//import { areIntervalsOverlappingWithOptions } from 'date-fns/fp';
//import {AuthProvider} from './components/Firebase/AuthProvider'


/* 
import { getDomainData as getDomainData2 } from './Firebase2/Storage2/dbDomain';




let subdata = { subdomain: '', subodmain_data: {} }

// ==========
const getSubdomain = async () => {
  //DELETED: const hostbaseLength = hostbase.split(".").length
  const host = window.location.host;
  //DELETED: const protocol = window.location.protocol;

  const hostpivot = host.indexOf(hostbase)
  let subdomain = ""
  if (hostpivot > 0) {
    subdomain = host.slice(0, hostpivot - 1)
    let olddomain = window.sessionStorage.getItem('domain')
    let oldcounter = window.sessionStorage.getItem('domain_count') || 0
    console.log("OLDDOMAIN", olddomain, subdomain)
    window.sessionStorage.setItem("domain", subdomain)
    window.sessionStorage.setItem("domain_count", oldcounter + 1)
  }

  if (subdomain.length > 0) {

    if (subdomain !== 'old') {
      const return_data = await getDomainData2(subdomain)
      console.log("domain_data", return_data)
      sessionStorage.setItem("domain_data", JSON.stringify(return_data))
      if (!return_data.error) {
        return { subdomain: subdomain, subdomain_data: return_data }
      } else {
        return { subdomain: subdomain, subodmain_data: {} }
      }
    } else {
      return { subdomain: subdomain, subdomain_data: {} }
    }
  } else {
    sessionStorage.removeItem("domain_data")
  }

}
 */


////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const AuthApp = ({ userData, appsubdomain }) => {
    let lang = 'it'

    if (localStorage.getItem('language')) {
        lang = localStorage.getItem('language')
    }
    //const {handleSignup} = useContext(firebaseAuth)
    //console.log("TOKN", token)
    //const token = window.sessionStorage.token
    //console.log(handleSignup)
    //DEBUG: console.log("APP.js   ", token)

    const [subdomain, setSubDomain] = useState(appsubdomain)

    const { pathname } = useLocation();





    function checkPagePermission(page) {
        if (page.length === 0 || userData.isAdmin || (userData.read_page && userData.read_page.indexOf(page) !== -1) || (userData.write_page && userData.write_page.indexOf(page) !== -1)) {
            return true
        } else {
            return false
        }
    }


    const HomeRoute = () => {
        if (subdomain && subdomain.subdomain === 'admin') {
            return (
                <ClauditlicenceView uData={userData} />
            )
        } else if (subdomain && subdomain.subdomain === 'news') {
            return (
                <NewsView uData={userData} />
            )
        } else if (subdomain && subdomain.subdomain === 'catalog') {
            return (
                <CatalogView uData={userData} />
            )
        } else {
            if (userData.isAdmin || (userData.read_page && userData.read_page.indexOf('contractdashboard') !== -1) || (userData.write_page && userData.write_page.indexOf('contractdashboard') !== -1)) {
                return (
                    <ContractDashboard uData={userData} fromMenu={true} />
                )
            } else {
                return (
                    <ContractChoose uData={userData} fromMenu={true} />

                )
            }
        }
    }

    const getFirstPagePermission = () => {

        console.log("%cPERMISSION", "background:blue;",userData.write_page, userData.read_page)
        if (userData.write_page.length > 0) {
            console.log("PUSH WRITE")
            return "/"+userData.write_page[0]
        } else if (userData.read_page.length > 0) {
            console.log("PUSH READ")
            return "/"+userData.read_page[0]
        } else {
            return "/notpermitted"
        }
        
    }




    return (

        <>
            {userData ?
                <>
                    <HashRouter>
                        <NavBarGlobal uData={userData} />
                        <Switch>

                            <Route path="/:cid/test"  >
                                <TestView uData={userData} />
                            </Route>

                            <Route path="/:cid/home"  >
                                {console.log("AUTHAPP app-router>home USERDATA", userData)}
                                {checkPagePermission('home') ?
                                    <>
                                        {subdomain.subdomain === 'catalog' ?
                                            <CatalogHomeView uData={userData} />
                                            :
                                            <HomeView uData={userData} />
                                        }
                                    </>
                                    :
                                    <MyRouteHomePage uData={userData} />
                                }
                            </Route>


                            <Route path="/:cid/ticket"  >
                                {checkPagePermission('messages') ?
                                    <TicketView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/agenda"  >
                                {checkPagePermission('agenda') ?
                                    <AgendaView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/failure"  >
                                {checkPagePermission('failure') ?
                                    <FailureView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>


                            <Route path="/:cid/services"  >
                                {checkPagePermission('services') ?
                                    <ServiceView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/operations"  >
                                {checkPagePermission('operations') ?
                                    <OperationsView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/roomtypes"  >
                                {checkPagePermission('room_types') ?
                                    <RoomTypesView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/yieldsummary"  >
                                {checkPagePermission('yield_summary') ?
                                    <YieldSummaryView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>


                            <Route path="/:cid/companies"  >
                                {checkPagePermission('companies') ?
                                    <StructureView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/imports"  >
                                {checkPagePermission('companies') ?
                                    <ImportStructureView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/variationregister"  >
                                {checkPagePermission('variationregister') ?
                                    <VariationRegisterView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>








                            <Route path="/:cid/workareas"  >
                                {checkPagePermission('workareas') ?
                                    <WorkareaView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/ordinaryintervention"  >
                                {checkPagePermission('ordinaryintervention') ?
                                    <InterventionView uData={userData} intervention_type="ordinary" />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/periodicintervention"  >
                                {checkPagePermission('programmedintervention') ?
                                    <InterventionView uData={userData} intervention_type="periodic" />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/workflows"  >
                                {checkPagePermission('workflows') ?
                                    <WorkflowView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/planning"  >
                                {checkPagePermission('planning') ?
                                    <PlanningView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/operatorworkshift"  >
                                {checkPagePermission('operatorworkshift') ?
                                    <OperatorWorkshiftView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/operatorassignment"  >
                                {checkPagePermission('operatorassignment') ?
                                    <OperatorAssignmentView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>




                            <Route path="/:cid/organizationchart"  >
                                {checkPagePermission('organizationchart') ?
                                    <OrganizationChartView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/training"  >
                                {checkPagePermission('training') ?
                                    <TrainingView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/workcontract"  >
                                {checkPagePermission('workcontract') ?
                                    <WorkContractView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/employes"  >
                                {checkPagePermission('employes') ?
                                    <EmployesView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/import_employes"  >
                                {checkPagePermission('employes') ?
                                    <ImportEmployersView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/trainingplanning"  >
                                {checkPagePermission('training') ?
                                    <TrainingPlanningView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/workshift"  >
                                {checkPagePermission('workshift') ?
                                    <WorkshiftsView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/trainingreport"  >
                                {checkPagePermission('trainingreport') ?
                                    <TrainingReportView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>



                            <Route path="/:cid/checkparamsdefinitions"  >
                                {checkPagePermission('checkparamsdefinitions') ?
                                    <CheckDefinitionsView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/controlgroup"  >
                                {checkPagePermission('controlgroup') ?
                                    <ControlGroupView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/resultcheck"  >
                                {checkPagePermission('resultcheck') ?
                                    <CheckView uData={userData} check_type="results" />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/checkplanning"  >
                                {checkPagePermission('checkplanning') ?
                                    <CheckPlanningView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/processcheck"  >
                                {checkPagePermission('processcheck') ?
                                    <CheckView uData={userData} check_type="process" />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/microbiologicalcheck"  >
                                {checkPagePermission('microbiologicalcheck') ?
                                    <MicrobiologicalCheckView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/checkmatrix"  >
                                {checkPagePermission('checkmatrix') ?
                                    <CheckMatrixView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/customersatisfaction"  >
                                {checkPagePermission('customersatisfaction') ?
                                    <CustomerSatisfactionView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>




                            <Route path="/:cid/economicreport"  >
                                {checkPagePermission('economicreport') ?
                                    <AmountView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/noncomplianceregistry"  >
                                {checkPagePermission('noncomplianceregistry') ?
                                    <NonComplianceView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/executionreport"  >
                                {checkPagePermission('executionreport') ?
                                    <ExecutionreportView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/interventionreport"  >
                                {checkPagePermission('interventionreport') ?
                                    <InterventionreportView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>




                            <Route path="/:cid/documentsstorage"  >
                                {checkPagePermission('documentsstorage') ?
                                    <DocumentsStorageView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/documentsstructure"  >
                                {checkPagePermission('documentsstructure') ?
                                    <DocumentsStructureView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/documentscontract"  >
                                {checkPagePermission('documentscontract') ?
                                    <DocumentsContractView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/documentssystem"  >
                                {checkPagePermission('documentssystem') ?
                                    <DocumentsSystemView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>



                            <Route path="/:cid/settings"  >
                                {checkPagePermission('') ?
                                    <Redirect to="/definitions" />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/definitions"  >
                                {checkPagePermission('definitions') ?
                                    <BaseObjectView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/cleaningcompanies"  >
                                {checkPagePermission('cleaningcompanies') ?
                                    <CleaningCompanyView mylocation='test' uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/machines"  >
                                {checkPagePermission('machines') ?
                                    <MachineView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/carts"  >
                                {checkPagePermission('carts') ?
                                    <CartView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/cleaningmaterials" >
                                {checkPagePermission('cleaningmaterials') ?
                                    <CleaningMaterialView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/materials" >
                                {checkPagePermission('cleaningmaterials') ?
                                    <CleaningMaterialView uData={userData} subsection="other" />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/economicmaterials" >
                                {checkPagePermission('cleaningmaterials') ?
                                    <CleaningMaterialView uData={userData} subsection="economic_material" />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/suppliers"  >
                                {checkPagePermission('suppliers') ?
                                    <SuppliersView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/equipments"  >
                                {checkPagePermission('equipments') ?
                                    <EquipmentsView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/products"  >
                                {checkPagePermission('products') ?
                                    <ProductsView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/clients"  >
                                {checkPagePermission('clients') ?
                                    <ClientsView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/trainer"  >
                                {checkPagePermission('trainer') ?
                                    <TrainerView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/controltool"  >
                                {checkPagePermission('controltool') ?
                                    <ControlToolView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/warehouse"  >
                                {checkPagePermission('warehouse') ?
                                    <WarehouseView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/rules"  >
                                {checkPagePermission('rules') ?
                                    <RulesView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/users"  >
                                {checkPagePermission('users') ?
                                    <UsersView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/dpi"  >
                                {checkPagePermission('dpi') ?
                                    <DpiView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/failuretype"  >
                                {checkPagePermission('failuretype') ?
                                    <FailureTypeView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/clothing"  >
                                {checkPagePermission('clothing') ?
                                    <ClothingView uData={userData} />
                                    :
                                    <Route component={NotPermitted} requestPath="/clothing"/>
                                }
                            </Route>
                            <Route path="/:cid/costcenter"  >
                                {checkPagePermission('costcenter') ?
                                    <CostCenterView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/:cid/dilutionsystem"  >
                                {checkPagePermission('dilutionsystem') ?
                                    <DilutionSystemView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>


                            <Route path="/notpermitted"  >
                                <NotPermitted />
                            </Route>


                            <Route exact path="/"  >
                                <HomeRoute />
                            </Route>

                            <Route path="/contractdashboard"  >
                                {console.log("render contractdashboard", subdomain)}
                                {subdomain && ((subdomain.subdomain === 'admin' && userData.isAdmin) || subdomain.subdomain === 'news' || subdomain.subdomain === 'catalog') ?
                                    <Redirect to="/" />
                                    :
                                    <>
                                        {userData.isAdmin 
                                        || (userData.read_page && userData.read_page.indexOf('contractdashboard') !== -1) 
                                        || (userData.write_page && userData.write_page.indexOf('contractdashboard') !== -1) ?
                                            <>
                                                {console.log("|||_____end redirect to ADMIN contract")}
                                                <ContractDashboard uData={userData} fromMenu={true} />
                                            </>
                                            :
                                            <>
                                                {console.log("|||_____end redirect to contract choose")}
                                                <ContractChoose uData={userData} fromMenu={true} />
                                            </>
                                        }
                                    </>
                                }
                            </Route>

                            <Route path="/usersettings">
                                {checkPagePermission('') ?
                                    <UserSettingsView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>

                            <Route path="/systemgroup"  >
                                {subdomain.subdomain === 'admin' && userData.isAdmin ?
                                    <SystemgroupView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/template"  >
                                {subdomain.subdomain === 'admin' && userData.isAdmin ?
                                    <TemplateView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/roomtypeimage"  >
                                {subdomain.subdomain === 'admin' && userData.isAdmin ?
                                    <RoomTypeImageView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/documentssystemadmin"  >
                                {checkPagePermission('documentssystemadmin') ?
                                    <DocumentsSystemAdminView uData={userData} />
                                    :
                                    <Route component={NotPermitted} />
                                }
                            </Route>
                            <Route path="/mediatype"  >
                                {subdomain.subdomain === 'admin' && userData.isAdmin ?
                                    <MediaTypeView uData={userData} />
                                    :
                                    <Route component={NotPermitted} requestPath="/mediatype" />
                                }
                            </Route>
                            <Route component={NotFound} />


                        </Switch>

                    </HashRouter>

                </>
                : ''}
        </>

    );


}

export default AuthApp;



const MyRouteHomePage = ({ uData }) => {
    let { cid } = useParams()
    const history = useHistory()
    const [noRoute, setNoRoute] = useState(false)
    const userData = uData

     useEffect(() => {
        const getFirstPagePermission = () => {

            console.log("%cPERMISSION", "background:blue;",userData.write_page, userData.read_page)
            if (userData.write_page.length > 0) {
                console.log("PUSH WRITE")
                history.push("/"+cid+"/"+userData.write_page[0])
            } else if (userData.read_page.length > 0) {
                console.log("PUSH READ")
                history.push("/"+cid+"/"+userData.read_page[0])
            } else {
                console.log("%cNO ROUTE", "background: blue")
                setNoRoute(true)
            }
            
        }
        getFirstPagePermission()

    }, [cid])
    return (<>
    </>)

}