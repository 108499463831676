import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab, InputGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'

import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';


import AlertSection from "./AlertSection.js"

import { getSelectedElement, checkComponentPresence, getValue } from "../util/ui_utils.js"
import { ModalInitButton } from './StructureGraphicElements.js'


import { addControlGroup, editControlGroup } from '../Firebase2/Storage2/dbControlGroup';



//const contractId = sessionStorage.getItem('contractId')


////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalControlGroup({ item, setItem, t, is_new, small, item_list, index, canWrite }) {
	const { domain } = useContext(DomainContext)
	const { structureElements, contractId} = useContext(GlobalDataContext)
	const structure_dict = structureElements.all_structure_dict

	const childRefAlert = useRef()
	const newcheckgroup = {
		name: '',
		code: '',
		note: '',
		structure_list:[],
		check_period:{}
	}


	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newcheckgroup)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newcheckgroup.media_list = []
		setCurrentElement(newcheckgroup)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}




	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addControlGroup(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data = await editControlGroup(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('checkgroup.buttons.checkgroup_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('checkgroups.labels.new_checkgroup')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('checkgroups.labels.edit_checkgroup')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('checkgroups.labels.view_checkgroup')}</span>
								</>
							]
						}
                        : {curElement.name}&nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>

						<Form.Group as={Row} controlId="checkgroupName" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="code" value={curElement.code} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="checkgroupName" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="name" value={curElement.name} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="checkgroupCode" className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="note" value={curElement.note} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						{curElement.structure_list.length>0?
						<>
						<Row>
							<Col className="bg-info text-light">
								Strutture associate
							</Col>
						</Row>
						{curElement.structure_list.map((s,k)=>(
							<Row>
								{structure_dict[s].name}
							</Row>
						))}
						</>
						:<i>nessuna struttura associata</i>}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}





export default translate()(ModalControlGroup)