import React, { useState, useEffect, useRef, createRef, useContext } from 'react';
import { Modal, Container, Row, Col, Form, Button, ButtonGroup, Spinner, Popover, OverlayTrigger, Badge, Tooltip } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import { format, getDay, isValid, set } from 'date-fns'
// pick a date util library
import DateTime from 'react-datetime';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import 'react-day-picker/lib/style.css';
import { it } from 'date-fns/locale'

import { serviceType_list, checkMode_dict, checkType_dict } from "../components/constants/global_definitions"

import LocaleContext from "../components/contexts/locale.js"
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"
import { useReactToPrint } from 'react-to-print';



import { createEventId } from '../event-utils'
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"

import { myTimestampToDate, getValue, getSelectedElementB, getSelectedElement, getElementCount, myDateToTimestamp } from "../util/ui_utils.js"
import { ItemProgress, renderFrequency, ServiceBadge, RiskareaBadge, RoomInfoBadge } from '../components/StructureGraphicElements.js'


import firebase from 'firebase/app'
import PrintFooter from '../components/generic/PrintFooter';

import { getCheckEventRange as getEventRange2, addCheckEvent, editCheckEvent, deleteCheckEvent } from '../Firebase2/Storage2/dbCheckAgenda';
import { getMicrobioList as getMicrobiologicalCheckList2 } from '../Firebase2/Storage2/dbMicrobiologicalCheck';

import { getControlGroupList } from '../Firebase2/Storage2/dbControlGroup'
import { getCustomerSatisfactionList as getCustomerSatisfactionList2 } from '../Firebase2/Storage2/dbCustomerSatisfaction';







let curdate = { start: new Date(), end: new Date() }
const newEvent = {
	start: new Date(),
	end: new Date(),
	extendedProps: {
		structure: '',
		controlgroup: '',
		check_mode: 'autocheck',
		result_control_count: 0,
		process_control_count: 0,
		reminder_time: 15,
		note: '',
	}
};
let filterAppliedList = { structure: [], check_mode: [], controlgroup: [], show_microbiological: true, show_customer: true }



let department_dict = {}
let used_service_list = []
let intervention_dict = {}

let permission_department_list = []
let permission_structure_list = []


const defaultFilter = { structure: [], check_mode: [], controlgroup: [] }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const CheckPlanningView = ({ t, uData }) => {
	//console.log("plannint", uData)
	let load_page = false

	const { domain } = useContext(DomainContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict, service_dict, operation_dict, } = useContext(GlobalDataContext)

	const { initContract } = InitContractHelper()
	const calendarRef = createRef()
	const childRefAlert = useRef()
	const childRefDelete = useRef()
	const componentRef = useRef();

	let { cid } = useParams();
	let contractId = cid


	const [title, setTitle] = useState('')
	const [showEdit, setShow] = useState(false);
	const [showViewEvent, setShowView] = useState(false);
	const [newEdit, setNew] = useState(true);
	const [originalDate, setOriginalDate] = useState(new Date())
	const [curEvent, setCurEvent] = useState(newEvent);
	const [currentEvents, setCurrentEvents] = useState([]);
	const [loading, setLoading] = useState(true)
	//  const [intervention_dict, setInterventionDict] = useState({})
	const [ui_list, setUiList] = useState({})
	//  const [filterAppliedList, setFilterAppliedList] = useState({ department: [], type: [] })
	//  const [department_dict, setDepartmentDict] = useState({})
	//  const [used_service_list, setUsedServiceList] = useState([])
	const [canWrite, setWritePage] = useState(false)


	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		console.log('index', c)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		//   setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
		filterAppliedList = { ...filterAppliedList, [section]: f_data }
		let calendarApi = calendarRef.current.getApi()
		//    setShowFilter(false)
		calendarApi.refetchEvents()
	}




	const resetAppliedFilter = (section) => {
		filterAppliedList = { ...filterAppliedList, [section]: [] }
		setShowFilter(false)
		let calendarApi = calendarRef.current.getApi()
		calendarApi.refetchEvents()
	}


	const check_type_dict = checkType_dict({ t })
	const check_mode_dict = checkMode_dict({ t })



	useEffect(() => {

	}, [filterAppliedList])



	const onChangeHandler = (event) => {
		let { name, value } = event;
		console.log("EDIT", name,  value)
		if (name !== 'start' && name !== 'end') {
			console.log(name, value)
			let newprops = { ...curEvent.extendedProps, [name]: value }
			setCurEvent({ ...curEvent, extendedProps: newprops })
		} else {
			try {
				const d = myTimestampToDate(value)
				value = set(name === 'end'? curEvent.start: curEvent.end, { hours: d.getHours(), minutes: d.getMinutes() })
			} catch (err) {
				console.log("ERR", err)

			}
			setCurEvent({ ...curEvent, [name]: value })
			console.log("newEventDate", curEvent)
		}
	}



	const handleClose = () => {
		setShow(false)
	}



	const handleCloseViewEvent = () => {
		setShowView(false)
	}



	const handlePrint = useReactToPrint({
		content: () => {
			const tableStat = componentRef.current.cloneNode(true);
			const PrintElem = document.createElement('div');
			PrintElem.appendChild(tableStat);
			return PrintElem;
		},
		pageStyle: pageStyle,
		documentTitle: `Planning controlli: ${title}`,
	});

	const [showFilterEvent, setShowFilter] = useState(false)

	const handleCloseFilterEvent = () => {
		setShowFilter(false)
	}




	const handleSave = async () => {


		if (newEdit) {
			const return_data = await addCheckEvent(domain, contractId, curEvent)
			handleEventAdd(return_data)
			console.log(return_data)

		} else {
			const return_data = await editCheckEvent(domain, contractId, curEvent.id, curEvent)
		}
		try {

			let calendarApi = calendarRef.current.getApi()
			calendarApi.refetchEvents()
		} catch (err) {
			console.log(err, calendarRef)
		}
		setShow(false)
	}




	const handleDateSelect = (selectInfo) => {
		console.log("handleDateSelect - selectInfo:", selectInfo)
		setCurEvent({ ...newEvent, start: selectInfo.date, end: selectInfo.date })
		setNew(true)
		setShow(true)
	}




	const getEventList = async (el) => {
		console.log("start data", el)
		if (el.start >= curdate.start && el.end <= curdate.end) {
			console.log('*************keep data')
		} else {
			curdate = el
		}
		//FIXME force load on start
		curdate = el
		setLoading(true)

		let temp_intervention_dict = { ...intervention_dict }
		let temp_department_dict = { ...department_dict }
		let temp_service_list = [...used_service_list]
		//console.log(temp_department_dict)
		//    console.log(temp_intervention_dict)
		let event_list = []

		if (contract && domain.length > 0) {

			let return_data = await getEventRange2(domain, contractId, firebase.firestore.Timestamp.fromDate(el.start), firebase.firestore.Timestamp.fromDate(el.end))

			//    return_data = return_data.filter(e => (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention'))
			console.log("return data", return_data)
			//      console.log(uData)
			//      for (let d = 0; d < return_data.length; d++) {
			for (const event of return_data) {
				let insert = false
				if ((filterAppliedList.check_mode.length === 0 || filterAppliedList.check_mode.indexOf(event.extendedProps.check_mode) !== -1)
					&& (filterAppliedList.controlgroup.length === 0 || filterAppliedList.controlgroup.indexOf(event.extendedProps.controlgroup) !== -1)) {
					event.start = myTimestampToDate(event.start)
					event.end = myTimestampToDate(event.end)
					//          console.log("return_data[" + d + "].extendedProps: ", return_data[d].extendedProps)
					event_list.push(event)
				}
			}
			const micro_list = await getMicrobiologicalCheckList2(domain, contractId)
			console.log("MICRO", micro_list)
			let newelement_list = await getCustomerSatisfactionList2(domain, contractId)


			event_list = [...event_list, ...micro_list.map(m => ({
				...m,
				start: myTimestampToDate(m.executionDate),
				extendedProps: { check_mode: 'micro_checks', isMicro: true },
				allDay: true
			})),
			...newelement_list.map(s => ({ ...s, type: 'customer', start: myTimestampToDate(s.startDate), end: myTimestampToDate(s.endDate) }))]
			console.log('RETURN DATA filtered', event_list)
			used_service_list = temp_service_list
			department_dict = temp_department_dict
			intervention_dict = temp_intervention_dict

		}
		console.log("EVENT COUNT", event_list)
		setCurrentEvents(event_list)
		setLoading(false)
		return event_list

	}




	const handleEventClick = (clickInfo) => {
		console.log("myevent", clickInfo.event.extendedProps)
		setCurEvent({ ...newEvent, id: clickInfo.event.id, start: clickInfo.event.start, end: clickInfo.event.end, extendedProps: clickInfo.event.extendedProps })
		setOriginalDate(clickInfo.event.start)
		console.log(curEvent)
		setNew(false)
		setShow(true)
	}




	const handleEventAdd = (addInfo) => {
		console.log("ADD", addInfo)

	}




	const handleEvents = (events) => {
		setCurrentEvents(events)
	}



	let structure_list = structureElements.structure_list

	const initValues = async () => {
		console.log('PLANNING uData', uData)
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('planning') !== -1)) {
			setWritePage(true)
		}
		let userData = uData
		if (userData && userData.contract_dict) {
			console.log('userData contract', userData.contract_dict[contractId])
			if (userData.contract_dict[contractId] && userData.contract_dict[contractId][0].structure) {
				if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {

					permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
					console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
				}
				if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
					permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
					structure_list = structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
					//structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

				}
			}
		}

		const ret_data = await getControlGroupList(domain, contractId)
		const tcontrolgroup_list = ret_data.filter(g => g.structure_list.length > 0)
		load_page = true
		const all_controlgroup_dict = Object.assign({}, ...tcontrolgroup_list.map((x) => ({ [x.id]: x })));
		setUiList({ ...ui_list, risk_area_list, risk_area_dict, service_dict, roomtype_list, roomtype_dict, controlgroup_list: tcontrolgroup_list, all_controlgroup_dict })
		console.log(calendarRef)
		try {
			let calendarApi = calendarRef.current.getApi()
			calendarApi.refetchEvents()
		} catch (err) {

		}

	}







	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("PLANNING reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("PLANNING init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])








	useEffect(() => {
		console.log('PLANNING loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])





	useEffect(() => {
		if (calendarRef && calendarRef.current) {
			let calendarApi = calendarRef.current.getApi()
			console.log(calendarApi.view)
			setTitle(calendarApi.view.title)
		}

	}, [calendarRef])



	// --------------------------------------------------------
	const renderEventContent = (info) => {
		if (info.timeText.length === 2) {
			info.timeText = info.timeText + ":00"
		}
		let evtId = "event-" + info.event.id;
		let tt = ''
		let room_count = ''
		{ console.log(info) }
		try {
			tt = format(info.event.start, 'HH:mm', { locale: it }) + " - " + format(info.event.end, 'HH:mm', { locale: it })
		} catch (err) {
			//console.log(info.event.start, info.event.end)
		}

		const getTypeColor = () => {
			if (info.event.extendedProps.check_mode === 'contradictory') {
				return "text-light bg-primary"
			} else if (info.event.extendedProps.check_mode === 'autocheck') {
				return 'text-light bg-info'
			} else {
				return 'text-light bg-secondary'
			}
		}

		console.log(check_mode_dict, info.event.extendedProps.check_mode)
		if (info.event.extendedProps.type === 'customer') {
			return (
				<>
					<div id={evtId} style={{ backgroundColor: 'lightblue', color: 'blue' }}>
						Customer satisfaction: <b>{info.event.extendedProps.name}</b>&nbsp;

					</div>
				</>
			)
		} else {


			if (ui_list.all_controlgroup_dict && info.event.extendedProps.controlgroup && ui_list.all_controlgroup_dict[info.event.extendedProps.controlgroup]) {

				return (
					<>
						<div id={evtId} className={getTypeColor()}>
							<b>{tt}</b>&nbsp;
							{ui_list.all_controlgroup_dict[info.event.extendedProps.controlgroup].name}&nbsp;
							(<b>{check_mode_dict[info.event.extendedProps.check_mode].label}</b>)
						</div>
					</>
				)

			} else {
				return (
					<>
						<div id={evtId} className={getTypeColor()}>
							<b>{tt}</b>&nbsp;
							{check_mode_dict[info.event.extendedProps.check_mode].label}
						</div>
					</>
				)

			}
		}


	}






	// --------------------------------------------------------
	const EventInfo = () => {
		try {
			return (
				<>
					<Modal.Header closeButton>
						<Modal.Title>
							{format(curEvent.start, 'cccc dd/MM (HH:mm', { locale: it })} - {format(curEvent.end, 'HH:mm)', { locale: it })} | {getValue(curEvent.extendedProps.intervention_data, 'name')}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container>
							<Row>
								<Col>
									{curEvent.extendedProps.type === 'ordinary_intervention' ?
										(t('workarea.labels.ordinary_intervention'))
										: (t('workarea.labels.periodic_intervention'))
									}
								</Col>
								<Col>
									<ServiceBadge service={service_dict[curEvent.extendedProps.intervention_data.service.id]} freq={true} />
								</Col>
							</Row>

							<Row>
								<Col>
									{curEvent.extendedProps.intervention_data && curEvent.extendedProps.intervention_data.room_list && curEvent.extendedProps.intervention_data.room_list.length > 0 ?
										<>
											<Row className="p-1 text-light mt-4">
												<Col className="ml-1 text-info" >{t('workarea.labels.roomlist')}</Col>
											</Row>
											<Row className="p-1 mb-1">
												{/* 			<Col className="ml-1 bg-light text-secondary" >{t('global.labels.position')}</Col> */}
												<Col className="ml-1 bg-secondary text-light" >{t('global.labels.name')}</Col>
												<Col className="ml-1 bg-secondary text-light" >{t('structures.labels.room_type')}</Col>
												<Col className="ml-1 bg-secondary text-light" sm="2" ></Col>
												<Col className="ml-1 bg-secondary text-light text-center" sm="1" >m<sup>2</sup></Col>
												<Col className="ml-1 bg-secondary text-light text-center" sm="2" >
													{contract && contract.type === 'civil' ?
														t('global.labels.standard_cleaning_short')
														:
														t('global.labels.risk_area_short')
													}
												</Col>
												<Col className="ml-1 bg-secondary text-light" sm="1" >Cdc</Col>
											</Row>
										</>
										: ''
									}
								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</>
			)
		} catch (err) {
			console.log(err, curEvent)
			return (
				<Modal.Body>

				</Modal.Body>
			)

		}

	}


	//######/////####////calenda API external call
	function handleCalendarNext() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.next()
	}

	function handleCalendarPrev() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.prev()
	}

	function handleCalendarHome() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.today()
	}

	function handleCalendarChangeView(view) {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.changeView(view)
	}



	const handleDelete = async () => {
		const cb = async () => {
			const return_data = await deleteCheckEvent(domain, contractId, curEvent.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				try {

					let calendarApi = calendarRef.current.getApi()
					calendarApi.refetchEvents()
				} catch (err) {
					console.log(err, calendarRef)
				}
				setShow(false)
			}
		}
		childRefDelete.current.confirmDelete(t('plancheck.messages.delete_plancheck_title'), t('planchecks.messages.delete_plancheck'), cb)
	}


	const checkValidity = () => {
		let validity = true
		try {
			if (ui_list.all_controlgroup_dict[curEvent.extendedProps.controlgroup]) {
				validity = validity && true
			} else {
				validity = validity && false
			}
			if (myTimestampToDate(curEvent.start) >= myTimestampToDate(curEvent.end)) {
				validity = validity && false
			}
			console.log(validity, curEvent.extendedProps.controlgroup, curEvent.start, curEvent.end)

		} catch (err) {

		}
		return validity
	}






	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row>
					<Col sm="6">
						<PageTitle title={t('global.pages.check_planning')} counter={<b>{title}</b>} />
						{loading ?
							<>
								<Spinner animation="border" role="status" />
								&nbsp;
								Caricamento in corso
							</>
							: ''}
					</Col>
					<Col className="text-right" >
						<Button variant="info" onClick={handlePrint} size="sm" className="ml-1">
							<FontAwesomeIcon icon="print" />  {t('modal.print')}
						</Button>
						<ModalFilter t={t} defaultfilter={defaultFilter} calendarRef={calendarRef} className="mr-2" ui_list={ui_list} />
						<ButtonGroup className="ml-2">
							<Button variant="secondary" size="sm" onClick={handleCalendarPrev} ><FontAwesomeIcon icon="angle-left" /></Button>
							<Button variant="secondary" size="sm" onClick={handleCalendarHome} ><FontAwesomeIcon icon="home" /></Button>
							<Button variant="secondary" size="sm" onClick={handleCalendarNext} ><FontAwesomeIcon icon="angle-right" /></Button>
						</ButtonGroup>
					</Col>
				</Row>

			</Container>



			<Container fluid id="domContainer" ref={componentRef}>
				<style type="text/css" media="print">{"\
  @page {\ size: landscape;\ }\
"}</style>
				<Row>
					{/*
          <Col sm="2">
            <HelpButton title='Istruzioni' text='<ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
          ' mycontainer={myref} />
            {renderSidebar()}

          </Col>
          */}
					<Col >

						{contract ?
							<>
								<FullCalendar

									ref={calendarRef}
									plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
									headerToolbar={false}
									buttonText={
										{
											today: 'oggi',
											month: 'mese',
											week: 'settimana',
											day: 'giorno',
											list: 'lista'
										}

									}
									allDaySlot={false}
									allDayText={'tutto il giorno'}
									firstDay="1"
									locale='it'
									initialView='dayGridMonth'
									eventResize={false}
									editable={false}
									selectable={false}
									height='auto'
									selectMirror={true}
									dayMaxEvents={true}
									weekends={true}
									slotLabelFormat={{ hour: "numeric", minute: "2-digit", omitZeroMinute: false }}
									slotDuration="00:15:00"
									initialEvents={getEventList}
									select={handleDateSelect}
									eventContent={renderEventContent}
									eventClick={handleEventClick}
									eventsSet={handleEvents}
									eventAdd={handleEventAdd}
									dateClick={handleDateSelect}
								/* 
								eventAdd={function(){}}
								eventChange={function(){}}
								eventRemove={function(){}}
								*/
								/>
								<div className='printonly_visible'>

									<PrintFooter />
								</div>
							</>
							: <></>}

					</Col>
				</Row>

				<Modal show={showEdit} onHide={handleClose} size="xl">
					<Modal.Header closeButton>
						<Modal.Title>
							{newEdit ?
								<>{t('checkplan.labels.new_plan')}
								</>
								:
								<>
									{t('checkplan.labels.edit_plan')}
								</>}
							&nbsp;<TitleDate d={curEvent.start} d2={curEvent.end} />
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>

							<Form >
								<Form.Group as={Row} controlId="eventSubject">
									<Form.Label column sm="2"> {t('global.labels.controlgroup')}</Form.Label>
									<Col>
										<Form.Control as="select" value={curEvent.extendedProps.controlgroup} className="mb-2" name="controlgroup" onChange={(event) => onChangeHandler(event.currentTarget)} >
											<option value={"-1"} >{t('controlgroup.labels.select_controlgroup')}</option>
											{ui_list.controlgroup_list && ui_list.controlgroup_list.length > 0
												? [ui_list.controlgroup_list.map((f, k) => (
													<option key={k} value={f.id} >{f.name}</option>
												))]
												: ''
											}
										</Form.Control>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className="border-bottom">
									<Form.Label column sm="2">{t('checkplan.labels.check_mode')}</Form.Label>
									{contract && contract.autocheck && contract.autocheck.length > 0 ?
										<Col>
											<Form.Check type="radio" name="check_mode" value="autocheck" checked={curEvent.extendedProps.check_mode === 'autocheck'} label={t('contract.labels.autocheck')} onChange={(event) => onChangeHandler(event.currentTarget)} />
										</Col>
										: ''}
									{contract && contract.contradictory && contract.contradictory.length > 0 ?
										<Col>
											<Form.Check type="radio" name="check_mode" value="contradictory" checked={curEvent.extendedProps.check_mode === 'contradictory'} label={t('contract.labels.contradictory')} onChange={(event) => onChangeHandler(event.currentTarget)} />
										</Col>
										: ''}
									{contract && contract.client_checks && contract.client_checks.length > 0 ?
										<Col>
											<Form.Check type="radio" name="check_mode" value="client_checks" checked={curEvent.extendedProps.check_mode === 'client_checks'} label={t('contract.labels.client_checks')} onChange={(event) => onChangeHandler(event.currentTarget)} />
										</Col>
										: ''}
								</Form.Group>
								<Form.Group as={Row}>
									<Form.Label column sm="2">{t('checkplan.labels.result_control_count')}</Form.Label>
									<Col sm="8">
										<Form.Control size="sm" type="number" min="0" name="result_control_count" value={curEvent.extendedProps.result_control_count} onChange={(event) => onChangeHandler(event.currentTarget)} />
									</Col>
								</Form.Group>
								<Form.Group as={Row}>
									<Form.Label column sm="2">{t('checkplan.labels.process_control_count')}</Form.Label>
									<Col sm="8">
										<Form.Control size="sm" type="number" min="0" name="process_control_count" value={curEvent.extendedProps.process_control_count} onChange={(event) => onChangeHandler(event.currentTarget)} />
									</Col>
								</Form.Group>
								<Form.Group as={Row}>
									<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
									<Col sm="8">
										<Form.Control size="sm" type="text" name="note" defaultValue={curEvent.extendedProps.note} onChange={(event) => onChangeHandler(event.currentTarget)} />
									</Col>
								</Form.Group>
								<Form.Group as={Row} controlId="eventStart">
									<Form.Label column sm="2">{t('agenda.labels.time')}</Form.Label>
									<Col sm="4" className={!isValid(myTimestampToDate(curEvent.start)) && 'border-bottom border-danger'}>
										<DateTime initialValue={myTimestampToDate(curEvent.start)} dateFormat={false} timeFormat={'HH:mm'} name="start" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'start', value: myDateToTimestamp(e) })} />
									</Col>
									<Col sm="4" className={!isValid(myTimestampToDate(curEvent.end)) && 'border-bottom border-danger'}>
										<DateTime initialValue={myTimestampToDate(curEvent.end)} dateFormat={false} timeFormat={'HH:mm'} name="end" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'end', value: myDateToTimestamp(e) })} />
									</Col>
								</Form.Group>
							</Form>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
						<Button className="btn-danger" form="companyForm" onClick={handleDelete}>
							<FontAwesomeIcon icon="trash" />{t('modal.delete')}
						</Button>
						<Button className="btn-myprimary" disabled={!checkValidity()} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" />{t('modal.save')}
						</Button>
					</Modal.Footer>
				</Modal>






				<Modal show={showViewEvent} onHide={handleCloseViewEvent} size="xl">
					<EventInfo />
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseViewEvent}>
							{t('modal.close')}
						</Button>
					</Modal.Footer>
				</Modal>





			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list: currentEvents, name: t('checks.labels.checks') })} />
		</>
	)
}




////////////////////////////////////////////////////////////////////////////////////////////////
const TitleDate = ({ d, d2 }) => {
	let d_s = ''

	try {
		d_s = format(myTimestampToDate(d), ': cccc dd/MM ', { locale: it })
	} catch (err) {
		try {
			d_s = format(myTimestampToDate(d2), ': cccc dd/MM ', { locale: it })
		} catch (err) {
		}
	}

	return (<>
		{d_s}
	</>)
}



////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
const ModalFilter = ({ t, defaultfilter, event_list, calendarRef, ui_list }) => {
	const { contract, service_dict, structureElements } = useContext(GlobalDataContext)

	const [showFilter, setShowFilter] = useState(false)


	const service_type = serviceType_list({ t })


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			filterAppliedList = { ...filterAppliedList, [section]: [] }
		} else {
			filterAppliedList = (defaultfilter)
		}
		let calendarApi = calendarRef.current.getApi()
		calendarApi.refetchEvents()
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		let calendarApi = calendarRef.current.getApi()
		filterAppliedList = { ...filterAppliedList, [section]: f_data }
		calendarApi.refetchEvents()
	}


	function checkInterventionPresence(d) {
		for (let i in intervention_dict) {
			if (intervention_dict[i].service && intervention_dict[i].service.id === d) {
				return true
			}
		}
		return false
	}

	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.controlgroup.length > 0 ?
					<>
						{filterAppliedList.controlgroup.map((s, sk) => (
							<Button size="sm" variant="outline" onClick={() => updateAppliedFilter('controlgroup', s)}>{ui_list.all_controlgroup_dict[s].name}</Button>
						))}
						<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('controlgroup')}><FontAwesomeIcon icon="times" /></Button>
					</>
					: ''}
				{filterAppliedList.check_mode.length > 0 ?
					<>
						{filterAppliedList.check_mode.indexOf('autocheck') >= 0 ?
							<Button size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('check_mode', 'autocheck')}>
								{t('contract.labels.autocheck')}
							</Button>
							: ''}
						{filterAppliedList.check_mode.indexOf('contradictory') >= 0 ?
							<Button size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('check_mode', 'contradictory')}>
								{t('contract.labels.contradictory')}
							</Button>
							: ''}
						{filterAppliedList.check_mode.indexOf('client_checks') >= 0 ?
							<Button size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('check_mode', 'client_checks')}>
								{t('contract.labels.client_checks')}
							</Button>
							: ''}
						<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('check_mode')}><FontAwesomeIcon icon="times" /></Button>
					</>

					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
				<Modal.Header>
					<Modal.Title>

						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>

						<Row>
							{t('global.labels.mode')}
						</Row>
						<Row>
							<Col>
								<ButtonGroup>
									{contract && contract.autocheck && contract.autocheck.length > 0 ?
										<Button size="sm" variant={filterAppliedList.check_mode.indexOf('autocheck') === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('check_mode', 'autocheck')}>
											{t('contract.labels.autocheck')}
										</Button>
										: ''}
									{contract && contract.contradictory && contract.contradictory.length > 0 ?
										<Button size="sm" variant={filterAppliedList.check_mode.indexOf('contradictory') === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('check_mode', 'contradictory')}>
											{t('contract.labels.contradictory')}
										</Button>
										: ''}
									{contract && contract.client_checks && contract.client_checks.length > 0 ?
										<Button size="sm" variant={filterAppliedList.check_mode.indexOf('client_checks') === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('check_mode', 'client_checks')}>
											{t('contract.labels.client_checks')}
										</Button>
										: ''}
									{filterAppliedList.check_mode.length > 0 ?
										<Button variant="outline-secondary" onClick={() => resetAppliedFilter('check_mode')}><FontAwesomeIcon icon="times" /></Button>
										: ''}
								</ButtonGroup>
							</Col>
						</Row>
						{/* 			<Row>
							{t('global.labels.structure')}
						</Row>
						<Row>
							<Col>
								<ButtonGroup>
									{structureElements.structure_list.map((g, k) => (
										<Button key={k} size="sm" variant={filterAppliedList.structure.indexOf(g.id) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('structure', g.id)}>
											{g.name}
										</Button>
									))}
									{filterAppliedList.structure.length > 0 ?
										<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
										: ''}
								</ButtonGroup>
							</Col>
						</Row> */}
						<Row>
							{t('global.labels.controlgroup')}
						</Row>
						<Row>
							<Col>
								{ui_list.controlgroup_list ?
									<ButtonGroup>
										{ui_list.controlgroup_list.map((g, k) => (
											<Button key={k} size="sm" variant={filterAppliedList.controlgroup.indexOf(g.id) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('controlgroup', g.id)}>
												{g.name}
											</Button>
										))}
										{ui_list.controlgroup_list.length > 0 ?
											<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('controlgroup')}><FontAwesomeIcon icon="times" /></Button>
											: ''}
									</ButtonGroup>
									: ''}
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}









































export default translate()(CheckPlanningView)





const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:297mm,
	  height: 210mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  div.page-footer{
	display:none;
  }
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
	display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		background-color: #fff,
		page-break-after: always;
	  }
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;
